import React, { useState } from 'react'
import PropTypes from 'prop-types'
import Button from '@material-ui/core/Button'
import Grid from '@material-ui/core/Grid/Grid'
import SubscriptionItem from './SubscriptionItem'
import Typography from '@material-ui/core/Typography/Typography'
import FormControlLabel from '@material-ui/core/FormControlLabel'
import Switch from '@material-ui/core/Switch'
import VoucherForm from '../../components/VoucherForm'

const TYPES = t => [
  {
    label: t('Trial'),
    subTitleAnnually: t('trial You can cancel it any time in the trial period'),
    subTitleMonthly: t('trial You can cancel it any time in the trial period'),
    usdAnnually: 0,
    usdMonthly: 0,
    count: 0,
    desc: t('The app is fully functional'),
    testCount: 12,
    items: [
      { label: t('Full HD Videos'), disabled: true },
      { label: `12 ${t('Test videos')}`, disabled: false },
      { label: `1 ${t('User')}`, disabled: false },
      { label: `1 ${t('Real estate avatar')}`, disabled: false },
      { label: `1 ${t('Contact page')}`, disabled: false },
      { label: t('Creating office network'), disabled: true },
      { label: t('Custom start animations'), disabled: true }
    ]
  },
  {
    label: t('Solo'),
    subTitleAnnually: t('Per month billed annually'),
    subTitleMonthly: t('Per month billed monthly'),
    usdAnnually: 16,
    usdMonthly: 20,
    count: 4,
    desc: t(
      'Perfect for a single real estate agents or small office with 1 user'
    ),
    testCount: 12,
    items: [
      { label: `4 ${t('Full HD Videos')}`, disabled: false },
      { label: `12 ${t('Test videos')}`, disabled: false },
      { label: `1 ${t('User')}`, disabled: false },
      { label: `3 ${t('Real estate avatars')}`, disabled: false },
      { label: `1 ${t('Contact page')}`, disabled: false },
      { label: t('Creating office network'), disabled: true },
      { label: t('Custom start animations'), disabled: true }
    ]
  },
  {
    label: t('Office'),
    subTitleAnnually: t('Per month billed annually'),
    subTitleMonthly: t('Per month billed monthly'),
    usdAnnually: 45,
    usdMonthly: 55,
    count: 15,
    desc: t('Best for a real estate office with more people creating videos'),
    testCount: 40,
    items: [
      { label: `15 ${t('Full HD Videos')}`, disabled: false },
      { label: `45 ${t('Test videos')}`, disabled: false },
      { label: `10 ${t('User')}`, disabled: false },
      { label: `20 ${t('Real estate avatars')}`, disabled: false },
      { label: `20 ${t('Contact pages')}`, disabled: false },
      { label: t('Creating office network'), disabled: true },
      { label: t('Custom start animations'), disabled: true }
    ]
  },
  {
    label: t('Extra'),
    subTitleAnnually: t('Per month billed annually '),
    subTitleMonthly: t('Per month billed monthly '),
    usdAnnually: 100,
    usdMonthly: 120,
    count: 50,
    desc: t('Prepared for a real estate network Quickly add new offices'),
    testCount: 150,
    items: [
      { label: `50 ${t('Full HD Videos')}`, disabled: false },
      { label: `150 ${t('Test videos')}`, disabled: false },
      { label: t('Unlimited user'), disabled: false },
      { label: t('Unlimited estate avatar'), disabled: false },
      { label: t('Unlimited Contact pages'), disabled: false },
      { label: t('Creating office network'), disabled: false },
      { label: t('Custom start animations'), disabled: false }
    ]
  }
]

const Subscription = ({
  open,
  onClose,
  t,
  createSubscription,
  subscription,
  unsubscribe,
  classes,
  selected,
  handleSaveVoucher,
  profile,
  changeSubscription,
}) => {
  const { voucher, lastPayment } = profile
  const [showYear, setShowYear] = useState(
    subscription && subscription.subscriptionType > 3
  )
  const showSelected =
    subscription &&
    ((showYear && subscription.subscriptionType > 3) ||
      (!showYear && subscription.subscriptionType <= 3))

  const selectedIndex =
    subscription &&
    (showYear
      ? subscription.subscriptionType - 4
      : subscription.subscriptionType)

  const isActive =
    subscription && (subscription.status === 'ACTIVE' || subscription.status === 'APPROVED')
  return (
    <React.Fragment>
      <Grid
        container
        justify="space-between"
        alignItems="center"
        className={classes.top}>
        <Grid item>
          {' '}
          {selected === null && (
            <Typography className={classes.desc}>
              {t(
                'Pick an account plan that fits your needs. Your current plan is highlighted.'
              )}
            </Typography>
          )}
        </Grid>
        <Grid item>
          {' '}
          <Grid xs={12} item>
            <Typography
              component={'span'}
              variant="subtitle1"
              className={classes.billingType}>
              {t('Billing type')}:
            </Typography>
            <Typography
              component={'span'}
              variant="subtitle1"
              className={classes.toggleLabel}>
              {t('Monthly')}
            </Typography>
            <Switch
              checked={showYear}
              onChange={e => setShowYear(e.target.checked)}
              value="checkedB"
              color="secondary"
            />
            <Typography
              component={'span'}
              variant="subtitle1"
              className={classes.toggleLabel}>
              {t('Annually')}
            </Typography>
          </Grid>
        </Grid>
      </Grid>

      <Grid
        container
        spacing={4}
        className={classes.items}
        alignItems="stretch">
        {TYPES(t).map((i, index) => (
          <SubscriptionItem
            subtitle={showYear ? i.subTitleAnnually : i.subTitleMonthly}
            description={i.desc}
            gridWidth={3}
            cancelling={
              selectedIndex === index && subscription.cancelling && showSelected
            }
            key={index}
            preSelected={selected === index}
            disable={
              (lastPayment && index === 0 && selectedIndex !== -1) ||
              (subscription &&
              (['APPROVAL_PENDING', 'APPROVED'].indexOf(subscription.status) !==
                -1 ||
                subscription.cancelling))
            }
            active={
              selectedIndex === index &&
              showSelected &&
              (subscription.status === 'ACTIVE' ||
                subscription.status === 'APPROVED')
            }
            isUpgrade={isActive && selectedIndex <= index - (showYear ? 4 : 0)}
            isDowngrade={isActive && selectedIndex > index - (showYear ? 4 : 0)}
            onSubscribed={() => createSubscription(index + (showYear ? 4 : 0))}
            onUnsubscribed={() => unsubscribe()}
            label={i.label}
            count={i.count}
            usd={showYear ? i.usdAnnually : i.usdMonthly}
            items={i.items}
            onChangeSubscription={() => {
              changeSubscription(index + (showYear ? 4 : 0))
            }}
          />
        ))}
        {subscription &&
          (subscription.status === 'APPROVAL_PENDING' ||
            subscription.status === 'APPROVED') && (
            <Grid item xs={12}>
              <Typography gutterBottom variant="p" component="p">
                {t('Subscription request created')}
              </Typography>
              <div className={classes.actionsButtons}>
                <Button
                  color={'primary'}
                  variant="contained"
                  onClick={() => {
                    window.location.href = subscription.link
                  }}>
                  {t('Finished request')}
                </Button>{' '}
                <Button
                  color={'secondary'}
                  variant="contained"
                  primary
                  onClick={() => {
                    unsubscribe()
                  }}>
                  {t('Cancel request')}
                </Button>
              </div>
            </Grid>
          )}
      </Grid>
      <VoucherForm onSubmit={handleSaveVoucher} initialValues={{ voucher }} />
    </React.Fragment>
  )
}

Subscription.propTypes = {
  t: PropTypes.func.isRequired,
  classes: PropTypes.object.isRequired,
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  changeSubscription: PropTypes.func.isRequired,
  unsubscribe: PropTypes.func.isRequired,
  createSubscription: PropTypes.func.isRequired,
  handleSaveVoucher: PropTypes.func.isRequired,
  subscription: PropTypes.object,
  lastPayment: PropTypes.object,
  selected: PropTypes.number,
  closePreselected: PropTypes.func
}

export default Subscription
