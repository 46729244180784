import grey from '@material-ui/core/colors/grey'

export default theme => ({
  wrap: {
    padding: '10px 20px',
    paddingBottom: 62 + 10
  },
  label: {
    color: theme.palette.primary.main,
    fontWeight: 500,
    margin: '15px 0'
  },
  table: {
    borderCollapse: 'collapse',
    '& table, th, td': {
      border: `1px solid ${grey[500]}`,
      textAlign: 'center'
    },
    '& td:first-child': {
      textAlign: 'left'
    },
    '& th, td': {
      padding: '10px 14px',
      color: 'white',
      fontWeight: 400
    },
    '& svg+svg': {
      marginLeft: 3
    },
    '& tbody tr:last-child td': {
      border: 'none'
    }
  },
  icon: {
    width: 35,
    height: 37
  },
  error: {
    color: theme.palette.error.main
  },
  submitLabel: {
    marginRight: 10,
    marginTop: 20
  },
  submitWrap: {
    position: 'fixed',
    left: 0,
    bottom: 0,
    background: '#424242',
    width: '100%',
    padding: 13,
    textAlign: 'right'
  },
  tableWrap: {
    margin: '0 20px',
  },
  tables: {
    flexFlow: 'wrap-reverse',
    display: 'flex',
    marginLeft: -20,
    marginRight: -20,
  },
  submitBtn: {
    marginRight: theme.spacing.unit * 2
  }
})
