import grey from '@material-ui/core/colors/grey'

export default theme => ({
  propertyItemContainer: {
    backgroundColor: grey[700],
    '& + $propertyItemContainer': {
      marginTop: theme.spacing(1)
    }
  },
  propertyItem: {
    paddingRight: 120
  },
  name: {
    marginBottom: theme.spacing(2)
  }
})
