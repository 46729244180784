import { withHandlers, compose, lifecycle } from 'recompose'
import { withStyles } from '@material-ui/core/styles'
import styles from './Subscription.styles'
import { withI18n } from 'react-i18next'
import firebaseApp from 'firebase'
import withNotifications from '../../modules/notification/components/withNotifications'
import withLoader from '../../modules/loader/components/withLoader'
import connect from 'react-redux/es/connect/connect'
import { spinnerWhileLoading, withSubscription } from '../../utils/components'
import { withRouter } from 'react-router-dom'
import { REGISTER_PATH, SUBSCRIPTION_PATH } from '../../constants/paths'
import SubmissionError from 'redux-form/es/SubmissionError'
import { withFirebase } from 'react-redux-firebase'

export default compose(
  connect(({ firebase: { auth, profile } }) => ({
    auth,
    profile
  })),
  withNotifications,
  withLoader,
  withSubscription,
  withFirebase,
  withRouter,
  withHandlers({
    handleSaveVoucher: props => values => {
      const voucher = (values.voucher || '').trim()
      return new Promise(resolve => {
        if (voucher) {
          resolve(
            firebaseApp.functions().httpsCallable('checkVoucher')({
              code: voucher
            })
          )
        } else {
          resolve()
        }
      })
        .then(() => {
          return props.firebase.updateProfile({
            voucher
          })
        })
        .catch(err => {
          if (err.message) {
            if (err.message === 'voucher_not_exists') {
              throw new SubmissionError({
                voucher: 'Voucher is not exists',
                _error: 'Submit failed!'
              })
            } else if (err.message === 'voucher_inactive') {
              throw new SubmissionError({
                voucher: 'Voucher is inactive',
                _error: 'Submit failed!'
              })
            }
          }
          console.error('Error:', err) // eslint-disable-line no-console
          props.showError(err.message || 'Could not save form')
          return Promise.reject(err)
        })
    },
    createSubscription: props => subscriptionType => {
      const { showError, setLoader, clearLoader, groupId, location } = props
      setLoader()
      return firebaseApp
        .functions()
        .httpsCallable('createSubscription')({
          subscriptionType,
          pathname: window.location.origin + location.pathname
        })
        .then(({ data }) => {
          window.location.href = data.link
        })
        .catch(err => {
          console.error('Error:', err) // eslint-disable-line no-console
          showError(err.message || 'Create subscription error')
          return Promise.reject(err)
        })
        .finally(() => {
          clearLoader()
        })
    },
    changeSubscription: props => subscriptionType => {
      const { showError, setLoader, clearLoader, location } = props
      setLoader()
      return firebaseApp
        .functions()
        .httpsCallable('unsubscribe')({})
        .then(() =>
          firebaseApp.functions().httpsCallable('createSubscription')({
            subscriptionType,
            pathname: window.location.origin + location.pathname
          })
        )
        .then(({ data }) => {
          window.location.href = data.link
        })
        .catch(err => {
          console.error('Error:', err) // eslint-disable-line no-console
          showError(err.message || 'Create subscription error')
          return Promise.reject(err)
        })
        .finally(() => {
          clearLoader()
        })
    },
    unsubscribe: props => () => {
      const { showError, setLoader, clearLoader, groupId } = props
      setLoader()
      return firebaseApp
        .functions()
        .httpsCallable('unsubscribe')({})
        .catch(err => {
          console.error('Error:', err) // eslint-disable-line no-console
          showError(err.message || 'Unsubscribe error')
          return Promise.reject(err)
        })
        .finally(() => {
          clearLoader()
        })
    }
  }),
  withStyles(styles),
  withI18n()
)
