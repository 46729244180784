export default theme => ({
  settings: {
    display: 'flex'
  },
  avatarCurrent: {
    width: '100%',
    maxWidth: '13rem',
    height: 'auto',
    cursor: 'pointer'
  },
  meta: {
    ...theme.flexColumnCenter,
    flexBasis: '60%'
  }
})
