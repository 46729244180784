import React, { useState } from 'react'
import PropTypes from 'prop-types'
import List from '@material-ui/core/List/List'
import ListItem from '@material-ui/core/ListItem/ListItem'
import Avatar from '@material-ui/core/Avatar/Avatar'
import AddIcon from '@material-ui/icons/Add'
import ListItemText from '@material-ui/core/ListItemText/ListItemText'
import CreateProjectDialog from 'components/CreateProjectDialog'
import { formatDateTime } from '../../../../../../utils/date'
import ListItemIcon from '@material-ui/core/ListItemIcon'
import FormControlLabel from '@material-ui/core/FormControlLabel'
import Checkbox from '@material-ui/core/Checkbox'

export const NewslettersPage = ({
  classes,
  t,
  newsletterItems,
  addProject,
  goToNewsletterProject,
  handleChangeActive
}) => {
  const [showCreateProject, setCreateProject] = useState(false)
  return (
    <React.Fragment>
      <List component="nav" className={classes.list}>
        <ListItem button onClick={() => setCreateProject(true)}>
          <ListItemIcon>
            <Avatar className={classes.addIcon}>
              <AddIcon />
            </Avatar>
          </ListItemIcon>
          <ListItemText primary={t('create new newsletter')} />
        </ListItem>
        {newsletterItems
          .sort((a, b) => b.creationTime - a.creationTime)
          .map(item => (
            <ListItem
              button
              className={classes.item}
              key={item.key}
              onClick={() => goToNewsletterProject(item.key)}>
              <ListItemText primary={item.name} />
              <div className={classes.textBlock}>
                <FormControlLabel
                  className={classes.activeCheckbox}
                  control={
                    <Checkbox
                      color="primary"
                      checked={item.active !== false}
                      onClick={e => e.stopPropagation()}
                      onChange={e =>
                        handleChangeActive(item.key, e.target.checked)
                      }
                    />
                  }
                  labelPlacement={'start'}
                  label={t('Active')}
                />
                <div className={item.published && classes.textBlockPublish}>
                  {t(item.published ? 'published' : 'not published')}
                </div>
                {t('Created')}: {formatDateTime(item.creationTime)}
              </div>
            </ListItem>
          ))}
      </List>
      <CreateProjectDialog
        open={showCreateProject}
        onClose={() => setCreateProject(false)}
        onSubmit={name => {
          setCreateProject(false)
          addProject(name)
        }}
      />
    </React.Fragment>
  )
}

NewslettersPage.proptypes = {
  classes: PropTypes.object.isRequired, // from enhancer (withStyles),
  newsletterItems: PropTypes.array.isRequired,
  propertyList: PropTypes.array.isRequired,
  agentItems: PropTypes.array.isRequired,
  goToNewsletterProject: PropTypes.func.isRequired,
  downloadProject: PropTypes.func.isRequired,
  cloneProject: PropTypes.func.isRequired,
  addProject: PropTypes.func.isRequired,
  handleChangeActive: PropTypes.func.isRequired,
  t: PropTypes.func.isRequired
}

export default NewslettersPage
