import { compose, withHandlers } from 'recompose'
import { withStore } from '../../../utils/components'
import * as actions from '../actions'

const withSystem = compose(
  withStore,
  withHandlers({
    openSelectGroup: ({ store }) => () =>
      actions.openSelectGroup()(store.dispatch),
    closeSelectGroup: ({ store }) => () =>
      actions.closeSelectGroup()(store.dispatch)
  })
)

export default withSystem
