import React, { useState } from 'react'
import PropTypes from 'prop-types'
import Navbar from 'containers/NavbarAdmin'
import { createMuiTheme, MuiThemeProvider } from '@material-ui/core'
import ThemeSettings from '../../theme'
import grey from '@material-ui/core/colors/grey'

const theme = createMuiTheme({
  ...ThemeSettings,
  palette: {
    ...ThemeSettings.palette,
    type: 'light',
    background: {
      default: grey[800]
    }
  }
})

export const CoreLayout = ({ children, classes, authExists }) => {
  return (
    <div className={authExists && classes.root}>
      <Navbar />
      <MuiThemeProvider theme={theme}>
        <main className={classes.content}>{children}</main>
      </MuiThemeProvider>
    </div>
  )
}

CoreLayout.propTypes = {
  classes: PropTypes.object.isRequired, // from enhancer (withStyles)
  children: PropTypes.element.isRequired,
  handleCloseWelcomeDialog: PropTypes.func.isRequired
}

export default CoreLayout
