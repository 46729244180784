import { compose } from 'redux'
import { withStyles } from '@material-ui/core/styles'
import styles from './DescriptionPage.styles'
import { withHandlers } from 'recompose'
import withNotifications from 'modules/notification/components/withNotifications'
import { connect } from 'react-redux'
import firebase from 'firebase/app'
import { withI18n } from 'react-i18next'

export default compose(
  connect((_, { project, match }) => ({
    text: project.textBlocks[match.params.descriptionId],
    descriptionId: parseInt(match.params.descriptionId)
  })),
  withNotifications,
  withHandlers({
    updateText: props => text => {
      const { showError, groupId, projectId, match, setEditTime } = props
      const { descriptionId } = match.params
      return firebase
        .set(
          `groups/${groupId}/properties/${projectId}/textBlocks/${descriptionId}`,
          text
        )
        .then(() => setEditTime())
        .catch(err => {
          console.error('Error:', err) // eslint-disable-line no-console
          showError(err.message || 'Could not update description')
          return Promise.reject(err)
        })
    }
  }),
  withStyles(styles),
  withI18n()
)
