import { compose } from 'redux'
import { withStyles } from '@material-ui/core/styles'
import styles from './SettingsPage.styles'
import { withHandlers, withProps } from 'recompose'
import withNotifications from 'modules/notification/components/withNotifications'
import { connect } from 'react-redux'
import firebase from 'firebase/app'
import { withI18n } from 'react-i18next'
import { firebaseConnect } from 'react-redux-firebase'
import get from 'lodash/get'
import { withEditTime } from '../../../../../../utils/components'

export default compose(
  firebaseConnect(({ groupId }) => {
    return [
      {
        path: `/groups/${groupId}/admins/${firebase
          .auth()
          .currentUser.email.replace(/\./g, '%2E')}`
      },
      {
        path: `/groups/${groupId}/onlyGroupColors`
      }
    ]
  }),
  connect(({ firebase: { data, profile } }, { groupId }) => {
    const emailKey = firebase.auth().currentUser.email.replace(/\./g, '%2E')
    return {
      isAdmin: !!get(data, `groups.${groupId}.admins.${emailKey}`, false),
      onlyGroupColors: get(data, `groups.${groupId}.onlyGroupColors`, false)
    }
  }),
  withNotifications,
  withEditTime,
  withHandlers({
    updateSettings: props => (name, value) => {
      const { showError, groupId, projectId, setEditTime } = props
      return firebase
        .set(
          `groups/${groupId}/properties/${projectId}/settings/${name}`,
          value
        )
        .then(() => setEditTime())
        .catch(err => {
          console.error('Error:', err) // eslint-disable-line no-console
          showError(err.message || 'Could not update settings')
          return Promise.reject(err)
        })
    },
    updateGroupColors: props => (name, value) => {
      const { showError, groupId } = props
      return firebase
        .set(`groups/${groupId}/colors/${name}`, value)
        .catch(err => {
          console.error('Error:', err) // eslint-disable-line no-console
          showError(err.message || 'Could not update settings')
          return Promise.reject(err)
        })
    }
  }),
  withStyles(styles),
  withI18n()
)
