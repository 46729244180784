import React from 'react'
import PropTypes from 'prop-types'
import { Link } from 'react-router-dom'
import { withI18n } from 'react-i18next'
import { withStyles } from '@material-ui/core'
import { CLIENT_PATH } from '../constants/paths'

const styles = () => ({
  root: {
    textAlign: 'center',
    marginTop: 100
  },
  link: {
    color: 'white',
    textDecoration: 'underline!important'
  }
})

const ProjectNotFound = ({ t, classes }) => (
  <div className={classes.root}>
    {t('project not found')}
    <br />
    <Link className={classes.link} to={CLIENT_PATH}>
      {t('go to list')}
    </Link>
  </div>
)

ProjectNotFound.propTypes = {
  t: PropTypes.func.isRequired,
  classes: PropTypes.object.isRequired
}

export default withI18n()(withStyles(styles)(ProjectNotFound))
