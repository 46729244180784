import React, { useState } from 'react'
import PropTypes from 'prop-types'
import ProjectList from './ProjectList'
import CreateProjectDialog from 'components/CreateProjectDialog'

export const Home = ({
  classes,
  t,
  propertyItems,
  goToPropertyProject,
  downloadProject,
  cloneProject,
  addProject,
  agentItems,
  onCopySettings,
  changePropertyValue,
  updateNewsletter,
  deleteProperty,
  newsletter
}) => {
  const [createProjectType, setCreateProjectType] = useState(null)
  return (
    <React.Fragment>
      <ProjectList
        items={propertyItems.sort((a, b) => b.creationTime - a.creationTime)}
        onDownload={downloadProject}
        onClone={cloneProject}
        onSelect={goToPropertyProject}
        onAdd={() => setCreateProjectType('property')}
        agentItems={agentItems}
        onCopySettings={onCopySettings}
        onDeleteProperty={deleteProperty}
        changePropertyValue={changePropertyValue}
        newsletter={newsletter}
        updateNewsletter={updateNewsletter}
      />
      <CreateProjectDialog
        open={createProjectType}
        onClose={() => setCreateProjectType(null)}
        onSubmit={name => {
          setCreateProjectType(null)
          addProject(name)
        }}
      />
    </React.Fragment>
  )
}

Home.proptypes = {
  classes: PropTypes.object.isRequired, // from enhancer (withStyles),
  propertyItems: PropTypes.array.isRequired,
  agentItems: PropTypes.array.isRequired,
  goToPropertyProject: PropTypes.func.isRequired,
  downloadProject: PropTypes.func.isRequired,
  cloneProject: PropTypes.func.isRequired,
  addProject: PropTypes.func.isRequired,
  changePropertyValue: PropTypes.func.isRequired,
  updateNewsletter: PropTypes.func.isRequired,
  t: PropTypes.func.isRequired
}

export default Home
