export default theme => ({
  root: {
    ...theme.flexColumnCenter
  },
  tableRow: {
    cursor: 'pointer'
  },
  tableTopPanel: {
    backgroundColor: theme.palette.primary.main,
    padding: `${theme.spacing.unit}px ${2 * theme.spacing.unit}px`,
    display: 'flex',
    alignItems: 'center'
  },
  addButton: {
    color: theme.palette.primary.contrastText
  },
  addIcon: {
    marginRight: theme.spacing.unit
  }
})
