import React from 'react'
import PropTypes from 'prop-types'
import Button from '@material-ui/core/Button'
import { withI18n } from 'react-i18next'
import Grid from '@material-ui/core/Grid/Grid'
import Card from '@material-ui/core/Card/Card'
import Typography from '@material-ui/core/Typography/Typography'
import withStyles from '@material-ui/core/es/styles/withStyles'
import CardContent from '@material-ui/core/CardContent/CardContent'
import CardActions from '@material-ui/core/CardActions/CardActions'
import grey from '@material-ui/core/colors/grey'
import CardActionArea from '@material-ui/core/CardActionArea'
import cn from 'classnames'

const styles = theme => ({
  card: {
    // background: grey[900],
    // backgroundColor: 'rgba(0, 172, 193, 0.06)',
    boxShadow: 'none',
    border: `1px solid ${grey[400]}`,
    height: '100%',
    position: 'relative',
    textAlign: 'center'
  },
  cardActive: {
    borderColor: theme.palette.primary.main,
    borderWidth: 2
  },
  title: {
    fontWeight: 300
  },
  desc: {
    minHeight: 82
  },
  disabled: {
    color: grey[300]
  },
  subtitle: {
    color: grey[500]
  },
  btn: {
    position: 'absolute',
    bottom: 6,
    left: 6,
    width: 'calc(100% - 12px)'
  },
  actions: {
    height: 51
  }
})

const SubscriptionItem = ({
  label,
  classes,
  preSelected,
  usd,
  onSubscribed,
  onUnsubscribed,
  active,
  disable,
  gridWidth,
  onClickChange,
  description,
  subtitle,
  cancelling,
  index,
  items,
  onChangeSubscription,
  isUpgrade,
  isDowngrade,
  t
}) => (
  <Grid item md={gridWidth}>
    <Card className={cn(classes.card, preSelected && classes.cardActive)}>
      <CardContent>
        <Typography
          color={'primary'}
          className={classes.title}
          gutterBottom
          variant="h5"
          component="h2">
          {label}
        </Typography>
        <Typography gutterBottom variant="h4" className={classes.title}>
          ${usd}
        </Typography>
        <Typography gutterBottom className={classes.subtitle} variant="body2">
          {subtitle}
        </Typography>
        <Typography
          variant="body2"
          component="p"
          color="primary"
          className={classes.desc}>
          {description}
        </Typography>
        {items.map((i, index) => (
          <Typography
            variant="body2"
            className={i.disabled && classes.disabled}
            key={index}>
            {i.label}
          </Typography>
        ))}
      </CardContent>
      <CardActions className={classes.actions}>
        {cancelling ? (
          <Button
            className={classes.btn}
            fullWidth
            size="large"
            variant="contained"
            color="secondary"
            disabled>
            {t('Cancelling')}
          </Button>
        ) : (
          <React.Fragment>
            {active && (
              <Button
                fullWidth
                className={classes.btn}
                size="large"
                variant="contained"
                color="secondary"
                onClick={onUnsubscribed}>
                {t('Unsubscribe')}
              </Button>
            )}
            {!active &&
              !disable &&
              (isUpgrade || isDowngrade ? (
                <Button
                  fullWidth
                  className={classes.btn}
                  variant="contained"
                  size="large"
                  color="primary"
                  onClick={onChangeSubscription}>
                  {t(isUpgrade ? 'Upgrade' : 'Downgrade')}
                </Button>
              ) : (
                <Button
                  fullWidth
                  className={classes.btn}
                  variant="contained"
                  size="large"
                  color="primary"
                  onClick={onSubscribed}>
                  {t('Subscribe')}
                </Button>
              ))}
            {!active && disable && (
              <Button
                fullWidth
                className={classes.btn}
                disabled
                size="large"
                color="primary"
                fullWidth
                variant="contained"
                onClick={onSubscribed}>
                {t('Subscribe')}
              </Button>
            )}
            {onClickChange && (
              <Button className={classes.btn} onClick={onClickChange}>
                {t('Change')}
              </Button>
            )}
          </React.Fragment>
        )}
      </CardActions>
    </Card>
  </Grid>
)

SubscriptionItem.propTypes = {
  classes: PropTypes.object.isRequired,
  subtitle: PropTypes.string.isRequired,
  onSubscribed: PropTypes.func.isRequired,
  onUnsubscribed: PropTypes.func.isRequired,
  label: PropTypes.string.isRequired,
  count: PropTypes.number.isRequired,
  active: PropTypes.number.isRequired,
  usd: PropTypes.number.isRequired,
  gridWidth: PropTypes.number.isRequired,
  disable: PropTypes.func.isRequired,
  description: PropTypes.string.isRequired,
  onClickChange: PropTypes.func.isRequired,
  preSelected: PropTypes.bool.isRequired
}

export default withStyles(styles)(withI18n()(SubscriptionItem))
