import React from 'react'
import PropTypes from 'prop-types'
import { URL_PREFIX } from 'constants/paths'
import VideoPicker from 'components/VideoPicker'
import ImagePicker from 'components/ImagePicker'
import Label from 'components/Label'
import Grid from '@material-ui/core/Grid'
import BackgroundPicker from './BackgroundPicker'
import DurationPicker from './DurationPicker'
import LayoutPicker from './LayoutPicker'
import VideoTransitionItem from 'components/VideoTransitionItem'
import Switch from '@material-ui/core/Switch'
import Typography from '@material-ui/core/Typography'

const ITEMS_VIDEOS = [
  {
    id: 'L1',
    src:
      'https://firebasestorage.googleapis.com/v0/b/editor-53bde/o/videos%2Finitial%2FL1_min.mp4?alt=media&token=03ffd632-d48d-4c65-a48b-44d02eb64f5e',
    groups: [
      '-LY7bWwEzO6ElElq_Fm9',
      '-LYLyO99kOniIkFHAQGO'
    ]
  },
  {
    id: 'L2',
    src:
      'https://firebasestorage.googleapis.com/v0/b/editor-53bde/o/videos%2Finitial%2FL2_min.mp4?alt=media&token=6906fe4d-d5c3-4792-aa32-f813a170a162',
    groups: [
      '-LY7bWwEzO6ElElq_Fm9',
      '-LYLyO99kOniIkFHAQGO',
      '-LYQhoO4QBWZ68mmn9Ad' // C21
    ]
  }
]

const TRANSITION_ITEMS = [
  {
    id: '1',
    src:
      'https://firebasestorage.googleapis.com/v0/b/editor-53bde/o/videos%2Fintro%2Fea1.mp4?alt=media&token=c27b9f54-e33d-4fe7-a570-fbc678e48341'
  },
  {
    id: '2',
    src:
      'https://firebasestorage.googleapis.com/v0/b/editor-53bde/o/videos%2Fintro%2Fea2.mp4?alt=media&token=0c7bbc1e-4417-49cc-bf54-c4191d298255'
  },
  {
    id: '3',
    src:
      'https://firebasestorage.googleapis.com/v0/b/editor-53bde/o/videos%2Fintro%2Fea3.mp4?alt=media&token=773104d3-8fbf-4a45-a057-7fa19705f142'
  },
  {
    id: '4',
    src:
      'https://firebasestorage.googleapis.com/v0/b/editor-53bde/o/videos%2Fintro%2Fea4.mp4?alt=media&token=bab92691-0c71-4a16-a56b-ca66e4e7d959'
  },
  {
    id: '5',
    src:
      'https://firebasestorage.googleapis.com/v0/b/editor-53bde/o/videos%2Fintro%2Fea5.mp4?alt=media&token=eae9b1e2-6d6d-4d51-8f21-b5ef34f5bc05'
  },
  {
    id: '6',
    src:
      'https://firebasestorage.googleapis.com/v0/b/editor-53bde/o/videos%2Fintro%2Fea6.mp4?alt=media&token=16fff7e2-ac75-4f48-9a90-20c76a99bcc3'
  }
]

const IntroPage = ({ t, ...props }) => {
  const privateVideos = ITEMS_VIDEOS.filter(
    i => !i.groups || i.groups.indexOf(props.groupId) !== -1
  )

  return (
    <Grid container spacing={5}>
      {privateVideos.length > 0 && (
        <Grid item xs={12}>
          <Typography
            component={'span'}
            variant="subtitle1"
            className={props.classes.toggleLabel}>
            {t('Standard')}
          </Typography>
          <Switch
            checked={props.project.settings.enterAnimation !== 'L0'}
            onChange={e =>
              props.updateEnterAnimation(
                e.target.checked ? privateVideos[0].id : 'L0'
              )
            }
          />
          <Typography
            component={'span'}
            variant="subtitle1"
            className={props.classes.toggleLabel}>
            {t('Custom made')}
          </Typography>
        </Grid>
      )}
      {props.project.settings.enterAnimation === 'L0' ? (
        <React.Fragment>
          <div className={props.classes.divider} />
          <Grid item xs={12}>
            <Label
              primary={t('Element configuration')}
              className={props.classes.label}
            />
            <LayoutPicker
              activeId={props.project.settings.layout}
              onActive={props.updateLayout}
            />
          </Grid>
          <Grid item xs={12}>
            <BackgroundPicker
              type={props.project.settings.introBackgroundType}
              onChangeType={props.updateBackgroundType}
              value={props.project.settings.introBackground}
              onChange={props.updateBackground}
              propertyImages={props.project.images.filter(i => i.img)}
              projectImages={props.backgrounds}
              onUploadImage={props.uploadImage}
              isAdmin={props.isAdmin}
            />
          </Grid>
          <Grid item xs={12}>
            <Label
              primary={t('The length of the into animation')}
              className={props.classes.label}
            />
            <DurationPicker
              value={props.project.settings.introDuration}
              onChange={props.updateDuration}
            />
          </Grid>
          <Grid item xs={12}>
            <Label
              primary={t('Video transition')}
              secondary={t(
                'between the input animation and the next block'
              )}
              className={props.classes.label}
            />
            <VideoTransitionItem
              items={TRANSITION_ITEMS}
              activeId={props.project.settings.introTransition}
              onActive={props.updateTransition}
            />
          </Grid>
        </React.Fragment>
      ) : (
        <React.Fragment>
          <div className={props.classes.divider} />
          <Grid item xs={12}>
            <VideoPicker
              disabled
              startTime={0}
              videoWidth={500}
              items={ITEMS_VIDEOS.filter(
                i => !i.groups || i.groups.indexOf(props.groupId) !== -1
              )}
              activeId={props.project.settings.enterAnimation}
              onActive={props.updateEnterAnimation}
            />
          </Grid>
        </React.Fragment>
      )}
    </Grid>
  )
}

IntroPage.propTypes = {
  t: PropTypes.func.isRequired,
  isAdmin: PropTypes.bool.isRequired,
  updateLayout: PropTypes.func.isRequired,
  updateBackground: PropTypes.func.isRequired,
  updateDuration: PropTypes.func.isRequired,
  updateEnterAnimation: PropTypes.func.isRequired,
  project: PropTypes.object.isRequired,
  classes: PropTypes.object.isRequired
}

export default IntroPage
