import React from 'react'
import Paper from '@material-ui/core/Paper'
import Typography from '@material-ui/core/Typography'
import { withStyles } from '@material-ui/core'
import Button from '@material-ui/core/Button'
import { withRouter } from 'react-router-dom'
import { CLIENT_PATH } from '../constants/paths'
import { withI18n } from 'react-i18next'

const styles = theme => ({
  root: {
    width: 300,
    padding: theme.spacing.unit * 2
  },
  desc: {
    marginTop: theme.spacing.unit * 2,
    marginBottom: theme.spacing.unit * 2
  }
})

const DisableSubscriptionInfo = ({ classes, history, t }) => (
  <Paper className={classes.root}>
    <Typography variant="h5">
      {t('Registration not successful - e-mail already in use')}
    </Typography>
    <Typography variant="p" className={classes.desc}>
      {t(
        "Your e-mail is already linked to an existing group and can't be used for standalone registration. Please use a different e-mail address."
      )}
    </Typography>
    <Button variant="outlined" onClick={() => history.push(CLIENT_PATH)}>
      OK
    </Button>
  </Paper>
)

export default withI18n()(
  withRouter(withStyles(styles)(DisableSubscriptionInfo))
)
