import React, { useState } from 'react'
import PropTypes from 'prop-types'
import Button from '@material-ui/core/Button'
import Dialog from '@material-ui/core/Dialog'
import DialogActions from '@material-ui/core/DialogActions'
import DialogContent from '@material-ui/core/DialogContent'
import DialogTitle from '@material-ui/core/DialogTitle'
import { withI18n } from 'react-i18next'
import PropertiesList from '../../../components/PropertisList/PropertiesList'
import List from '@material-ui/core/List/List'
import { withStyles } from '@material-ui/core/styles'
import { forceCheck } from 'react-lazyload'
import SearchInput from '../../../components/SearchInput'
import Grid from '@material-ui/core/Grid/Grid'
import { filterItem } from '../../../utils/utils'
import { useSessionStorageState } from 'react-storage-hooks'
import SortMenu from '../../../components/SortMenu'

const styles = theme => ({
  search: {
    margin: `0 ${theme.spacing.unit * 3}px ${theme.spacing.unit}px`,
    display: 'inline-block'
  },
  filter: {
    paddingRight: 24
  }
})

const AddPropertyDialog = props => {
  const [searchText, setSearchText] = useState('')
  const { t, classes, propertyItems, agentItems, onSelect } = props
  const [sortItems, setSortItems, writeError] = useSessionStorageState(
    'sortItemsNewsletter',
    1
  )
  return (
    <Dialog
      open={props.open}
      onClose={props.onClose}
      scroll={'paper'}
      fullWidth
      maxWidth="md">
      <DialogTitle>{t('add property')}</DialogTitle>
      <Grid container>
        <Grid item>
          <SearchInput
            classes={{ root: classes.search }}
            value={searchText}
            onChange={value => {
              setSearchText(value)
            }}
          />
        </Grid>
      </Grid>
      <DialogContent onScroll={() => forceCheck()}>
        <List component="nav">
          <PropertiesList
            items={filterItem(propertyItems, searchText)}
            agentItems={agentItems}
            onSelect={onSelect}
          />
        </List>
      </DialogContent>
      <DialogActions>
        <Button onClick={props.onClose} color="primary">
          {t('close')}
        </Button>
      </DialogActions>
    </Dialog>
  )
}

AddPropertyDialog.propTypes = {
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired
}

export default withStyles(styles)(withI18n()(AddPropertyDialog))
