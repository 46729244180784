const W = '#FFFFFF'
const B = '#000000'
const G = '#3C3C3C'
const Y = '#FFEA27'
const LY = '#F6FFCA'
const DB = '#105D7F'
const LB = '#6CD2FF'
const V = '#9B4769'

export const PALETTE_ITEMS_BUBBLE = [
  { id: 0, colors: { color0: G, color1: Y, color2: W, color3: Y } },
  { id: 1, colors: { color0: G, color1: W, color2: W, color3: Y } },
  { id: 2, colors: { color0: W, color1: DB, color2: W, color3: DB } },
  { id: 3, colors: { color0: B, color1: LB, color2: W, color3: LB } },
  { id: 4, colors: { color0: W, color1: V, color2: W, color3: Y } },
  { id: 5, colors: { color0: LY, color1: V, color2: W, color3: G } },
  { id: 6, colors: { color0: G, color1: LY, color2: W, color3: G } },
  { id: 7, colors: { color0: DB, color1: LY, color2: W, color3: DB } }
]

export const PALETTE_ITEMS_PLACE = [
  { id: 0, colors: { color0: G, color1: Y, color2: G, color3: W } },
  { id: 1, colors: { color0: W, color1: G, color2: G, color3: W } },
  { id: 2, colors: { color0: W, color1: DB, color2: G, color3: W } },
  { id: 3, colors: { color0: B, color1: LB, color2: G, color3: W } },
  { id: 4, colors: { color0: V, color1: W, color2: W, color3: V } },
  { id: 5, colors: { color0: V, color1: W, color2: G, color3: LY } },
  { id: 6, colors: { color0: G, color1: W, color2: G, color3: LY } },
  { id: 7, colors: { color0: DB, color1: W, color2: G, color3: LY } }
]

export const MAX_TEXT_BLOCKS = 3
export const MAX_IMAGES = 6

const d = {
  t: 'd',
  d: {
    r: 21,
    a: 'p',
    b: {
      p:
        '/groups/-LY7bWwEzO6ElElq_Fm9/properties/-LhZWa6AhvSm0mBL_Q_J/images/0',
      d: {
        bubbleType: 'call-out-2',
        callOut1: {
          bubble: { x: 1186.2146892655362, y: 445.1977401129943 },
          enable: false,
          pointer: { x: 1399.3744089619547, y: 283.47663732693184 }
        },
        callOut2: {
          bubble: { x: 798.9830508474574, y: 680.9039548022599 },
          enable: false,
          pointer: { x: 577.74011299435, y: 471.0734463276835 }
        },
        id: 0,
        img: {
          height: 1080,
          id: '0',
          imageId: '0',
          minPath:
            'https://firebasestorage.googleapis.com/v0/b/editor-53bde.appspot.com/o/groups%2F-LY7bWwEzO6ElElq_Fm9%2Fproperties%2F-LhZWa6AhvSm0mBL_Q_J%2Fmin_0.jpg?alt=media',
          path:
            'https://firebasestorage.googleapis.com/v0/b/editor-53bde.appspot.com/o/groups%2F-LY7bWwEzO6ElElq_Fm9%2Fproperties%2F-LhZWa6AhvSm0mBL_Q_J%2F0.jpg?alt=media',
          width: 1920
        },
        infoText: { enable: false, position: 'top' },
        offsetTop: 0.5,
        roomTexts: { name: '', size: '' },
        showRoomSize: true,
        zoom: {
          bubble: { x: 1175.2050473186114, y: 499.8107255520505 },
          enable: false,
          pointer: { x: 529.9999999999998, y: 824 },
          text: ''
        }
      }
    }
  }
}

export const propertyImageItem = (id, isProperty) => ({
  offsetTop: 0.5,
  img: null,
  id,
  bubbleType: isProperty ? 'zoom' : 'call-out-1',
  roomTexts: isProperty ? { name: '', size: '' } : null,
  showRoomSize: isProperty ? true : null,
  callOut1: {
    bubble: { x: 1186, y: 445 },
    enable: false,
    pointer: { x: 1399, y: 283 }
  },
  callOut2: {
    bubble: { x: 798, y: 680 },
    enable: false,
    pointer: { x: 577, y: 471 }
  },
  zoom: {
    enable: false,
    pointer: {
      x: 530,
      y: 824
    },
    bubble: {
      x: 1175.2050473186118,
      y: 499.8107255520505
    },
    text: ''
  },
  infoText: {
    enable: false,
    position: 'top'
  }
})

export const propertyItem = () => ({
  id: Date.now(),
  texts: {
    address1: '',
    address2: '',
    area: '',
    code: '',
    intro: '',
    introRows: ['', '', '', '', ''],
    price: '',
    type: ''
  },
  images: {
    0: propertyImageItem(0),
    1: propertyImageItem(1),
    2: propertyImageItem(2),
    3: propertyImageItem(3)
  }
})

export const newsletterProject = name => ({
  name: name,
  creationTime: Date.now(),
  published: false,
  publishProcessing: false,
  properties: [],
  showWeb: true,
  active: true
})

export const propertyProject = (name, contactKey = null, agentKey = null) => ({
  name: name,
  newsletterVideoKey: null,
  newsletterPropertyKey: null,
  creationTime: Date.now(),
  agentKey,
  contactKey,
  type: 'main',
  settings: {
    layout: 1,
    introDuration: 2,
    introTransition: '1',
    introBackground: null,
    introBackgroundType: 'basic',
    textBackgroundCustomColors: {
      color0: '#F00999',
      color1: '#300D99',
      color2: '#ffffff'
    },
    textBackgroundColors: 'FFFFFF/F74EC1/4F6AE1',
    textBackgroundType: 0,
    textColor: 0,
    textColorCustom: {
      color0: '#F00999'
    },
    design: {
      bubble: {
        activeId: 0,
        type: 'defined',
        custom: PALETTE_ITEMS_BUBBLE[0].colors,
        pointer: 'round',
        animation: 'inside'
      },
      transitions: {
        activeId: 0,
        type: 'defined',
        custom: PALETTE_ITEMS_BUBBLE[0].colors
      },
      place: {
        activeId: 0,
        type: 'defined',
        custom: PALETTE_ITEMS_PLACE[0].colors,
        animation: 'bottom'
      }
    },
    enterAnimation: 'L0',
    videoTransitionImages: '1',
    videoTransitionModules: 'distortion zoom',
    music: 1,
    backgroundMusic: true,
    animationSounds: true,
    contactAnimation: 1
  },
  map: {
    textBubble: '',
    textAddress: '',
    textBubbleRows: ['', '', ''],
    mapFull: {
      center: { lat: -34.397, lng: 150.644 },
      zoom: 9
    },
    mapDetail: {
      center: { lat: -34.397, lng: 150.644 },
      zoom: 11
    }
  },
  texts: {
    code: '',
    basicType: '',
    basicProperty: '',
    basicLocation: '',
    basicPrice: '',
    introItems: [
      {
        intro: '',
        introRows: ['', '', '', '', '']
      }
    ],
    description: '',
    url: '',
    urlActive: true
  },
  itemsSort: [],
  textBlocks: {
    6: {
      intro: '',
      introRows: ['', '', '', '', '']
    },
    7: {
      intro: '',
      introRows: ['', '', '', '', '']
    },
    8: {
      intro: '',
      introRows: ['', '', '', '', '']
    }
  },
  images: {
    0: propertyImageItem(0, true),
    1: propertyImageItem(1, true),
    2: propertyImageItem(2, true),
    3: propertyImageItem(3, true),
    4: propertyImageItem(4, true),
    5: propertyImageItem(5, true)
  }
})

export const contact = name => ({
  name,
  creationTime: Date.now(),
  design: '01',
  contact: {
    color: '#0076C1',
    activeGroupColor: false,
    map: {
      center: null,
      offsetCenter: { lat: -34.397, lng: 150.644 },
      label: ''
    },
    otherNoteIcon: 'info',
    enableZoom: true,
    showIcon: true,
    otherNote: ''
  },
  texts: {
    intro: {
      color: '#777777',
      activeGroup: false,
      text: ''
    },
    name1: {
      color: '#0076C1',
      activeGroup: false,
      text: ''
    },
    name2: {
      color: '#000000',
      activeGroup: false,
      text: ''
    }
  }
})

export const account = ({ name = '', phone = '', email = '' }) => ({
  name,
  phone,
  email,
  creationTime: Date.now(),
  image: null
})

export const ITEMS_WAVES = [
  {
    id: 'FFFFFF/F74EC1/4F6AE1',
    src:
      'https://firebasestorage.googleapis.com/v0/b/editor-53bde/o/videos%2FtextBackground%2Fw1.mp4?alt=media&token=9f85663a-be50-4a57-a6a0-ba2b6e2a647a'
  },
  {
    id: 'FFFFFF/2FB8BD/1B3268',
    src:
      'https://firebasestorage.googleapis.com/v0/b/editor-53bde/o/videos%2FtextBackground%2Fw2.mp4?alt=media&token=05dc0ca0-e391-4a33-a977-ab731eb34d32'
  },
  {
    id: 'FFFFFF/3B0F64/91082F',
    src:
      'https://firebasestorage.googleapis.com/v0/b/editor-53bde/o/videos%2FtextBackground%2Fw3.mp4?alt=media&token=fb4c6d85-0fe4-474f-a57d-c2e87d0be025'
  },
  {
    id: 'FFFFFF/44217A/0F1F64',
    src:
      'https://firebasestorage.googleapis.com/v0/b/editor-53bde/o/videos%2FtextBackground%2Fw4.mp4?alt=media&token=c3a4d677-9768-4a62-bbc1-87717633a8a1'
  },
  {
    id: 'FFFFFF/B920F9/692CE6',
    src:
      'https://firebasestorage.googleapis.com/v0/b/editor-53bde/o/videos%2FtextBackground%2Fw5.mp4?alt=media&token=68155225-ffa4-4c0f-8f94-f28ce5af9a18'
  },
  {
    id: 'FFFFFF/BD20EA/EA2092',
    src:
      'https://firebasestorage.googleapis.com/v0/b/editor-53bde/o/videos%2FtextBackground%2Fw6.mp4?alt=media&token=e3d7963b-3a10-4d8d-800c-1b8942e414e1'
  },
  {
    id: 'FFFFFF/E62C61/F5B55A',
    src:
      'https://firebasestorage.googleapis.com/v0/b/editor-53bde/o/videos%2FtextBackground%2Fw7.mp4?alt=media&token=c00dec18-9ad8-4f1a-aa71-ae5c19cde50c'
  },
  {
    id: 'FFFFFF/E648FE/37ECBF',
    src:
      'https://firebasestorage.googleapis.com/v0/b/editor-53bde/o/videos%2FtextBackground%2Fw8.mp4?alt=media&token=570b113e-579a-4b4c-9bf1-61c1d8f81c68'
  },
  {
    id: 'FFFFFF/FE5948/27AEDB',
    src:
      'https://firebasestorage.googleapis.com/v0/b/editor-53bde/o/videos%2FtextBackground%2Fw9.mp4?alt=media&token=ab22a278-8ccc-453d-ad1b-9660ac170788'
  }
]

export const ITEMS_STRIPES = [
  {
    id: '000000/000000/4F6AE1',
    src:
      'https://firebasestorage.googleapis.com/v0/b/editor-53bde/o/videos%2FtextBackground%2Fs1.mp4?alt=media&token=930169f5-a31d-436d-957f-4a0d8cb8eaf9'
  },
  {
    id: '000000/000000/27AEDB',
    src:
      'https://firebasestorage.googleapis.com/v0/b/editor-53bde/o/videos%2FtextBackground%2Fs2.mp4?alt=media&token=bb685b5e-c059-4312-a30c-9272c323bf43'
  },
  {
    id: '000000/000000/1B3268',
    src:
      'https://firebasestorage.googleapis.com/v0/b/editor-53bde/o/videos%2FtextBackground%2Fs3.mp4?alt=media&token=eea8fddb-9589-4366-9761-c12f86312f2e'
  },
  {
    id: '000000/000000/44217A',
    src:
      'https://firebasestorage.googleapis.com/v0/b/editor-53bde/o/videos%2FtextBackground%2Fs4.mp4?alt=media&token=45bfb77a-0b4c-423d-ae8f-d7221bd00acf'
  },
  {
    id: '000000/000000/3B0F64',
    src:
      'https://firebasestorage.googleapis.com/v0/b/editor-53bde/o/videos%2FtextBackground%2Fs5.mp4?alt=media&token=e233f9d3-f0cb-40fb-a6f2-5700c9b58913'
  },
  {
    id: '000000/000000/B920F9',
    src:
      'https://firebasestorage.googleapis.com/v0/b/editor-53bde/o/videos%2FtextBackground%2Fs6.mp4?alt=media&token=94d8ac69-2541-4446-b9b6-272fe8b72fb3'
  },
  {
    id: '000000/000000/E62C61',
    src:
      'https://firebasestorage.googleapis.com/v0/b/editor-53bde/o/videos%2FtextBackground%2Fs7.mp4?alt=media&token=9a7ecd8b-4435-4e28-b1da-445ca250261f'
  },
  {
    id: '000000/000000/F5B55A',
    src:
      'https://firebasestorage.googleapis.com/v0/b/editor-53bde/o/videos%2FtextBackground%2Fs8.mp4?alt=media&token=72faae86-3e53-434b-b398-686691af4f76'
  },
  {
    id: '000000/000000/F5B55A',
    src:
      'https://firebasestorage.googleapis.com/v0/b/editor-53bde/o/videos%2FtextBackground%2Fs9.mp4?alt=media&token=9eb7e633-48e4-48d5-91ae-531f8386b45b'
  },
  {
    id: '000000/000000/37ECBF',
    src:
      'https://firebasestorage.googleapis.com/v0/b/editor-53bde/o/videos%2FtextBackground%2Fs10.mp4?alt=media&token=50a2ac75-bdae-45bb-8924-9853eff1db79'
  },
  {
    id: '000000/000000/FE5948',
    src:
      'https://firebasestorage.googleapis.com/v0/b/editor-53bde/o/videos%2FtextBackground%2Fs11.mp4?alt=media&token=0bcc628f-5beb-4ce1-9c0b-af8d67e999ac'
  },
  {
    id: '000000/000000/F74EC1',
    src:
      'https://firebasestorage.googleapis.com/v0/b/editor-53bde/o/videos%2FtextBackground%2Fs12.mp4?alt=media&token=fe1452ff-c64e-4aa8-902c-44f6ddadc3fb'
  }
]

export const ITEMS_OTHER = [
  {
    id: 'Abstract-1.mp4',
    src:
      'https://firebasestorage.googleapis.com/v0/b/editor-53bde/o/videos%2FtextBackground%2FAbstract-1-480.mp4?alt=media&token=f5c2aa3a-8917-4680-a9d5-2ae9dbf29eab'
  },
  {
    id: 'Bokeh-blue.mp4',
    src:
      'https://firebasestorage.googleapis.com/v0/b/editor-53bde/o/videos%2FtextBackground%2FBokeh-blue-480.mp4?alt=media&token=65cc355b-0242-4d67-8ea5-e51ca52e663e'
  },
  {
    id: 'Colorful-streak.mp4',
    src:
      'https://firebasestorage.googleapis.com/v0/b/editor-53bde/o/videos%2FtextBackground%2FColorful-streak-480.mp4?alt=media&token=29f2d4e8-8ec5-40f6-98a2-5ca3d8f3388f'
  },
  {
    id: 'Earth-1.mp4',
    src:
      'https://firebasestorage.googleapis.com/v0/b/editor-53bde/o/videos%2FtextBackground%2FEarth-1-480.mp4?alt=media&token=4106bb6a-af43-4e61-9e28-8c9b278dd853'
  },
  {
    id: 'Earth-2.mp4',
    src:
      'https://firebasestorage.googleapis.com/v0/b/editor-53bde/o/videos%2FtextBackground%2FEarth-2-480.mp4?alt=media&token=26403f70-94d7-4c31-948f-a6773a333077'
  },
  {
    id: 'Jellyfish.mp4',
    src:
      'https://firebasestorage.googleapis.com/v0/b/editor-53bde/o/videos%2FtextBackground%2FJellyfish-480.mp4?alt=media&token=a8eac6a0-d29f-4ee1-af73-283213192833'
  },
  {
    id: 'Lights.mp4',
    src:
      'https://firebasestorage.googleapis.com/v0/b/editor-53bde/o/videos%2FtextBackground%2FLights-480.mp4?alt=media&token=1d4da033-951d-44fb-a80b-a01e7dfd25a0'
  },
  {
    id: 'Nebula.mp4',
    src:
      'https://firebasestorage.googleapis.com/v0/b/editor-53bde/o/videos%2FtextBackground%2FNebula-480.mp4?alt=media&token=322ec4dd-d545-462d-b9f3-9198d63f2665'
  },
  {
    id: 'Octagon.mp4',
    src:
      'https://firebasestorage.googleapis.com/v0/b/editor-53bde/o/videos%2FtextBackground%2FOctagon-480.mp4?alt=media&token=0b0f2bab-3984-4a8c-9791-c1b107941bc7'
  }
]

export const groupColors = {
  textBlocksFontColor: {
    color0: '#989109'
  },
  textBlocksBackgroundColors: {
    color0: '#F00999',
    color1: '#300D99',
    color2: '#ffffff'
  },
  videoTransitionsColors: { color0: G, color1: Y, color2: W, color3: Y },
  infoBubbleColors: { color0: G, color1: Y, color2: W, color3: Y },
  imageDescriptionColors: { color0: G, color1: Y, color2: G, color3: W },
  contact: {
    intro: '#777777',
    name1: '#0076C1',
    name2: '#000000',
    main: '#0076C1'
  }
}
