import { grey } from '@material-ui/core/colors'

export default theme => ({
  title: {
    marginBottom: theme.spacing.unit * 3
  },
  billingForm: {
    width: 700,
    padding: theme.spacing.unit * 7
  },
  continueButton: {
    marginTop: theme.spacing.unit * 3
  }
})
