import { withStyles } from '@material-ui/core/styles'
import styles from './NewslettersPage.styles'
import { compose, withHandlers } from 'recompose'
import { withI18n } from 'react-i18next'
import { firebaseConnect } from 'react-redux-firebase'
import connect from 'react-redux/es/connect/connect'
import { spinnerWhileLoading } from 'utils/components'
import get from 'lodash/get'
import map from 'lodash/map'
import { withRouter } from 'react-router-dom'
import { CLIENT_PATH, PROPERTY_PATH, NEWSLETTER_PATH } from 'constants/paths'
import firebaseApp from 'firebase/app'
import withNotifications from 'modules/notification/components/withNotifications'
import withLoader from 'modules/loader/components/withLoader'
import * as defaultValues from 'defaultValues'

const getLastContact = (profile, groupId) =>
  firebaseApp
    .database()
    .ref(`groups/${groupId}/contacts/${profile.lastContact}`)
    .once('value')
    .then(s => {
      if (!s.exists() || s.val().deleted) {
        return null
      } else {
        return profile.lastContact
      }
    })

const getLastAgent = (profile, groupId) => {
  const { email } = firebaseApp.auth().currentUser
  return firebaseApp
    .database()
    .ref(`groups/${groupId}/agents/`)
    .once('value')
    .then(s => {
      const val = s.val()
      if (!val) {
        return null
      }
      const currentAgent = Object.keys(val).find(
        key => val[key].email === email && !val[key].deleted
      )
      return currentAgent || null
    })
}

export default compose(
  connect(({ firebase: { profile } }) => ({
    profile,
    groupId: profile && profile.actualGroup
  })),
  spinnerWhileLoading(['profile']),
  firebaseConnect(props => {
    return [
      {
        path: `groups/${props.groupId}/newsletters`
      },
      {
        path: `groups/${props.groupId}/name`
      }
    ]
  }),
  connect(({ firebase: { data } }, { groupId }) => ({
    newsletterItems: map(
      get(data, `groups.${groupId}.newsletters`),
      (value, key) => ({
        ...value,
        key
      })
    ),
    groupName: get(data, `groups.${groupId}.name`)
  })),
  withStyles(styles),
  withRouter,
  withNotifications,
  withLoader,
  withHandlers({
    cloneProject: props => projectId => {
      const { showError, groupId, setLoader, clearLoader, history } = props
      setLoader()
      firebaseApp
        .functions()
        .httpsCallable('cloneProject')({
          projectId,
          groupId,
          type: 'properties'
        })
        .then(({ data }) => {
          history.push(`${CLIENT_PATH}${PROPERTY_PATH}/${data.projectId}`)
        })
        .catch(err => {
          console.error('Error:', err) // eslint-disable-line no-console
          showError(err.message || 'Project clone error')
          return Promise.reject(err)
        })
        .finally(() => {
          clearLoader()
        })
    },
    addProject: props => name => {
      const { firebase, showError, history, groupId, profile } = props
      Promise.all([
        getLastAgent(profile, groupId),
        getLastContact(profile, groupId)
      ]).then(([agentKey, contactKey]) => {
        return firebase
          .push(`groups/${groupId}/newsletters`, {
            ...defaultValues.newsletterProject(name),
            createdBy: firebase.auth().currentUser.uid
          })
          .then(snap => {
            history.push(`${CLIENT_PATH}${NEWSLETTER_PATH}/${snap.key}`)
          })
          .catch(err => {
            console.error('Error:', err) // eslint-disable-line no-console
            showError(err.message || 'Could not delete project')
            return Promise.reject(err)
          })
      })
    },
    goToNewsletterProject: ({ history }) => projectId => {
      history.push(`${CLIENT_PATH}${NEWSLETTER_PATH}/${projectId}`)
    },
    handleChangeActive: props => (newsletterKey, active) => {
      const { groupId, showError } = props
      firebaseApp
        .set(`groups/${groupId}/newsletters/${newsletterKey}/active`, active)
        .catch(err => {
          console.error('Error:', err) // eslint-disable-line no-console
          showError(err.message || 'Newsletter active error')
          return Promise.reject(err)
        })
    }
  }),
  withI18n()
)
