export default theme => ({
  root: {
    ...theme.flexColumn,
    flexGrow: 1,
    //margin: '.2rem'
  },
  submit: {
    //...theme.flexColumnCenter,
    //justifyContent: 'center',
    flexGrow: 1,
    paddingTop: theme.spacing.unit * 2,
    paddingBottom: theme.spacing.unit * 2
  },
  helper: {
    ...theme.typography.subtitle2,
    color: theme.palette.grey[500]
  }
})
