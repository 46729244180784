import cyan from '@material-ui/core/colors/cyan'
import grey from '@material-ui/core/colors/grey'

export default theme => ({
  root: {
    color: 'white'
  },
  header: {
    borderBottom: `2px solid  ${cyan[600]}`
  },
  menuItem: {
    display: 'inline-block',
    padding: '13px 20px',
    cursor: 'pointer',
    textTransform: 'uppercase',
    textDecoration: 'none',
    color: 'white',
    '&+*': {
      marginLeft: 10
    },
    '&:first-child': {
      marginLeft: 20
    },
    '&:hover': {
      backgroundColor: cyan[600]
    }
  },
  menuItemActive: {
    backgroundColor: cyan[600]
  },
  content: {
    padding: 20,
    backgroundColor: grey[800]
  }
})
