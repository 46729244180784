import React from 'react'
import PropTypes from 'prop-types'
import Button from '@material-ui/core/Button'
import TextField from '@material-ui/core/TextField'
import Dialog from '@material-ui/core/Dialog'
import DialogActions from '@material-ui/core/DialogActions'
import DialogContent from '@material-ui/core/DialogContent'
import DialogContentText from '@material-ui/core/DialogContentText'
import DialogTitle from '@material-ui/core/DialogTitle'
import { withI18n } from "react-i18next";

class CreateProjectDialog extends React.Component {
  static propTypes = {
    open: PropTypes.bool.isRequired,
    onSubmit: PropTypes.func.isRequired,
    onClose: PropTypes.func.isRequired
  }

  state = {
    text: ''
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.open && !this.props.open) {
      this.setState({
        text: ''
      })
    }
  }

  handleSubmit = e => {
    e.preventDefault()
    if (this.state.text !== '') {
      this.props.onSubmit(this.state.text, e)
    }
  }

  render() {
    const { t } = this.props
    return (
      <div>
        <Dialog open={this.props.open} onClose={this.props.onClose}>
          <form onSubmit={this.handleSubmit}>
            <DialogTitle>{t('create new project')}</DialogTitle>
            <DialogContent>
              <DialogContentText>{t('add project name')}</DialogContentText>
              <TextField
                autoFocus
                margin="dense"
                label={t('project name')}
                fullWidth
                value={this.state.text}
                onChange={e =>
                  this.setState({
                    text: e.target.value
                  })
                }
              />
            </DialogContent>
            <DialogActions>
              <Button onClick={this.props.onClose} color="primary">
                {t('close')}
              </Button>
              <Button
                color="primary"
                type="submit"
                disabled={this.state.text === ''}>
                {t('create')}
              </Button>
            </DialogActions>
          </form>
        </Dialog>
      </div>
    )
  }
}

export default withI18n()(CreateProjectDialog)
