import React from 'react'
import PropTypes from 'prop-types'
import SummaryMultiTable from './SummaryMultiTable'

const SummaryTable = ({ items, label }) => {
  const scheme = items.map((i, index) => ({
    label: i.label,
    name: index.toString(),
    optional: i.optional
  }))
  const item = {}
  items.forEach((i, index) => {
    item[index] = i.ok
  })
  return <SummaryMultiTable label={label} items={[item]} scheme={scheme} />
}

SummaryTable.propTypes = {
  items: PropTypes.array.isRequired,
  label: PropTypes.string.isRequired
}

export default SummaryTable
