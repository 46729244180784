import React from 'react'
import PropTypes from 'prop-types'
import { Field } from 'redux-form'
import Button from '@material-ui/core/Button'
import { TextField } from 'redux-form-material-ui'

export const AccountForm = ({
  account,
  handleSubmit,
  submitting,
  pristine,
  classes,
  t
}) => (
  <form className={classes.root} onSubmit={handleSubmit}>
    <div className={classes.fields}>
      <Field
        margin="normal"
        fullWidth
        name="displayName"
        component={TextField}
        label={t('display name')}
      />
      <Field
        margin="normal"
        name="email"
        label={t('email')}
        component={TextField}
        fullWidth
      />
    </div>
    <Button color="primary" type="submit" disabled={pristine || submitting}>
      {t(submitting ? 'saving' : 'save')}
    </Button>
  </form>
)

AccountForm.propTypes = {
  t: PropTypes.func.isRequired,
  account: PropTypes.object,
  classes: PropTypes.object.isRequired, // from enhancer (withStyles)
  handleSubmit: PropTypes.func.isRequired, // from enhancer (reduxForm)
  pristine: PropTypes.bool.isRequired, // from enhancer (reduxForm)
  submitting: PropTypes.bool.isRequired // from enhancer (reduxForm)
}

export default AccountForm
