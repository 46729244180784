export const types = [
  { label: 'Trial', usd: 20, count: 50 },
  { label: 'Solo', usd: 50, count: 500 },
  { label: 'Office', usd: 100, count: 2000 },
  { label: 'Extra', usd: 100, count: 2000 },
  { label: 'Trial', usd: 20, count: 50 },
  { label: 'Solo', usd: 50, count: 500 },
  { label: 'Office', usd: 100, count: 2000 },
  { label: 'Extra', usd: 100, count: 2000 }
]
