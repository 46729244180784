export default theme => ({
  label: {
    marginBottom: 15,
    marginTop: 20
  },
  labelText: {
    marginBottom: 0
  },
  textField: {
    width: 300
  },
  inputLabel: {
    whiteSpace: 'nowrap'
  }
})
