import React from 'react'
import PropTypes from 'prop-types'
import Dialog from '@material-ui/core/Dialog'
import List from '@material-ui/core/List'
import ListItem from '@material-ui/core/ListItem'
import ListItemText from '@material-ui/core/ListItemText'
import DialogTitle from '@material-ui/core/DialogTitle'
import { withI18n } from 'react-i18next'

const ConfirmDialog = ({ open, groups, onSelect, t }) => (
  <Dialog open={open}>
    <DialogTitle>{t('select group')}</DialogTitle>
    <List>
      {groups.map(item => (
        <ListItem
          button
          onClick={() => onSelect(item.groupId)}
          key={item.groupId}>
          <ListItemText primary={item.name} />
        </ListItem>
      ))}
    </List>
  </Dialog>
)

ConfirmDialog.propTypes = {
  t: PropTypes.func.isRequired,
  open: PropTypes.bool.isRequired,
  onSelect: PropTypes.func.isRequired,
  groups: PropTypes.arrayOf(
    PropTypes.shape({
      key: PropTypes.string.isRequired,
      name: PropTypes.string.isRequired
    })
  ).isRequired
}

export default withI18n()(ConfirmDialog)
