import { compose } from 'redux'
import styles from './IntroPage.styles'
import { withStyles } from '@material-ui/core'
import { withI18n } from 'react-i18next'
import withNotifications from 'modules/notification/components/withNotifications'
import { withHandlers, withProps } from 'recompose'
import firebase from 'firebase/app'
import { firebaseConnect } from 'react-redux-firebase'
import { connect } from 'react-redux'
import { get } from 'lodash'
import withLoader from 'modules/loader/components/withLoader'
import map from 'lodash/map'
import { withEditTime } from '../../../../../../../../utils/components'
import { addGroupFile } from '../../../../../../../../utils/utils'

export default compose(
  firebaseConnect(({ groupId }) => {
    return [`groups/${groupId}/backgrounds`]
  }),
  connect(({ firebase: { data } }, { groupId }) => {
    const backgrounds = map(
      get(data, `groups.${groupId}.backgrounds`, {}),
      (value, key) => ({
        ...value,
        id: key
      })
    )

    return {
      backgrounds
    }
  }),
  withNotifications,
  withLoader,
  withEditTime,
  withHandlers({
    updateLayout: props => id => {
      const { showError, groupId, projectId, setEditTime } = props
      return firebase
        .set(`groups/${groupId}/properties/${projectId}/settings/layout`, id)
        .then(() => setEditTime())
        .catch(err => {
          console.error('Error:', err) // eslint-disable-line no-console
          showError(err.message || 'Could not update layout')
          return Promise.reject(err)
        })
    },
    updateBackground: props => id => {
      const { showError, groupId, projectId, setEditTime } = props
      return firebase
        .set(
          `groups/${groupId}/properties/${projectId}/settings/introBackground`,
          id
        )
        .then(() => setEditTime())
        .catch(err => {
          console.error('Error:', err) // eslint-disable-line no-console
          showError(err.message || 'Could not update background')
          return Promise.reject(err)
        })
    },
    updateBackgroundType: props => id => {
      const { showError, groupId, projectId, setEditTime } = props
      return firebase
        .set(
          `groups/${groupId}/properties/${projectId}/settings/introBackgroundType`,
          id
        )
        .then(() => setEditTime())
        .catch(err => {
          console.error('Error:', err) // eslint-disable-line no-console
          showError(err.message || 'Could not update background')
          return Promise.reject(err)
        })
    },
    updateDuration: props => id => {
      const { showError, groupId, projectId, setEditTime } = props
      return firebase
        .set(
          `groups/${groupId}/properties/${projectId}/settings/introDuration`,
          id
        )
        .then(() => setEditTime())
        .catch(err => {
          console.error('Error:', err) // eslint-disable-line no-console
          showError(err.message || 'Could not update duration')
          return Promise.reject(err)
        })
    },
    updateTransition: props => id => {
      const { showError, groupId, projectId, setEditTime } = props
      return firebase
        .set(
          `groups/${groupId}/properties/${projectId}/settings/introTransition`,
          id
        )
        .then(() => setEditTime())
        .catch(err => {
          console.error('Error:', err) // eslint-disable-line no-console
          showError(err.message || 'Could not update duration')
          return Promise.reject(err)
        })
    },
    updateEnterAnimation: props => id => {
      const { showError, groupId, projectId, setEditTime } = props
      return firebase
        .set(
          `groups/${groupId}/properties/${projectId}/settings/enterAnimation`,
          id
        )
        .then(() => setEditTime())
        .catch(err => {
          console.error('Error:', err) // eslint-disable-line no-console
          showError(err.message || 'Could not update duration')
          return Promise.reject(err)
        })
    },
    uploadImage: props => file => {
      const {
        showError,
        groupId,
        setLoader,
        clearLoader,
        projectId,
        setEditTime
      } = props
      setLoader()
      const storageRef = firebase.storage().ref()
      const backgroundKey = Date.now() + ''
      const path = `backgrounds/${backgroundKey}.jpg`
      const ref = storageRef.child(`groups/${groupId}/${path}`)

      return addGroupFile(groupId, path, file)
        .then(() =>
          ref.getDownloadURL().then(downloadURL =>
            firebase.set(`groups/${groupId}/backgrounds/${backgroundKey}`, {
              url: downloadURL
            })
          )
        )
        .then(() => {
          return firebase.update(
            `groups/${groupId}/properties/${projectId}/settings`,
            { introBackground: backgroundKey, introBackgroundType: 'uploaded' }
          )
        })
        .then(() => setEditTime())
        .catch(err => {
          console.error('Error:', err) // eslint-disable-line no-console
          showError(err.message || 'Could not update background')
          return Promise.reject(err)
        })
        .finally(() => clearLoader())
    }
  }),
  withI18n(),
  withStyles(styles)
)
