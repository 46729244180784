import React, { useState } from 'react'
import PropTypes from 'prop-types'
import Button from '@material-ui/core/Button'
import Dialog from '@material-ui/core/Dialog'
import DialogActions from '@material-ui/core/DialogActions'
import { withStyles } from '@material-ui/core/styles'
import AppBar from '@material-ui/core/AppBar'
import Tabs from '@material-ui/core/Tabs'
import Tab from '@material-ui/core/Tab'
import Typography from '@material-ui/core/Typography'
import { withI18n } from 'react-i18next'
import Subscription from '../../containers/Subscription'
import SubscriptionInfo from '../../containers/SubscriptionInfo'
import SubscriptionHistory from '../../containers/SubscriptionHistory'
import BillingInformation from '../../containers/BillingInformation'
import Account from '../../containers/Account/components/AccountPage'
import grey from '@material-ui/core/es/colors/grey'
import Grid from '@material-ui/core/Grid'

function TabContainer(props) {
  return (
    <Typography component="div" style={{ padding: 8 * 3 }}>
      {props.children}
    </Typography>
  )
}

TabContainer.propTypes = {
  children: PropTypes.node.isRequired
}

const styles = theme => ({
  root: {
    backgroundColor: grey[200]
  },
  content: {
    padding: theme.spacing.unit * 4,
    paddingTop: theme.spacing.unit * 4,
    overflow: 'auto'
  },
  widthMd: {
    width: 1000
  },
  title: {
    marginBottom: theme.spacing.unit * 2
  },
  divider: {
    height: 1,
    backgroundColor: grey[400],
    marginLeft: -theme.spacing.unit * 4,
    marginRight: -theme.spacing.unit * 4,
    marginBottom: theme.spacing.unit * 4
  }
})

const SubscriptionDialog = props => {
  const { classes, t, handleSaveBillingInfo, showSubscription } = props
  const [tab, changeTab] = useState(0)

  const handleClose = () => props.onClose()

  return (
    <Dialog
      onClose={handleClose}
      scroll={'body'}
      open={props.open}
      maxWidth="md"
      classes={{ paperWidthMd: classes.widthMd }}>
      <div className={classes.root}>
        <AppBar position="static">
          <Tabs value={tab} onChange={(_, tabId) => changeTab(tabId)}>
            {showSubscription && <Tab label={t('Billing')} />}
            {showSubscription && <Tab label={t('Plans')} />}
            {showSubscription && <Tab label={t('Past invoices')} />}
          </Tabs>
        </AppBar>
        <div className={classes.content}>
          {tab === 0 && (
            <Grid container>
              <Grid item>
                <Typography variant="h5" className={classes.title}>
                  {t('Billing & Invoicing Info')}
                </Typography>
                <BillingInformation />
                <div className={classes.divider} />
                <Typography variant="h5" className={classes.title}>
                  {t('Account Plan Subscriptions')}
                </Typography>
                <SubscriptionInfo />
              </Grid>
            </Grid>
          )}
          {tab === 1 && <Subscription />}
          {tab === 2 && <SubscriptionHistory />}
        </div>
        <DialogActions>
          <Button onClick={handleClose} color="primary">
            {t('close')}
          </Button>
        </DialogActions>
      </div>
    </Dialog>
  )
}

SubscriptionDialog.propTypes = {
  classes: PropTypes.object.isRequired,
  onClose: PropTypes.func.isRequired,
  handleSaveBillingInfo: PropTypes.func.isRequired,
  open: PropTypes.bool.isRequired,
  showSubscription: PropTypes.bool.isRequired
}

export default withI18n()(withStyles(styles)(SubscriptionDialog))
