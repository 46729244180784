import React from 'react'
import PropTypes from 'prop-types'
import Button from '@material-ui/core/Button'
import Dialog from '@material-ui/core/Dialog'
import DialogActions from '@material-ui/core/DialogActions'
import DialogContent from '@material-ui/core/DialogContent'
import DialogContentText from '@material-ui/core/DialogContentText'
import DialogTitle from '@material-ui/core/DialogTitle'
import { URL_PREFIX } from 'constants/paths'
import { Link } from 'react-router-dom'
import { withI18n } from 'react-i18next'

const SentDialog = ({ open, t, type }) => (
  <div>
    <Dialog open={open}>
      <DialogTitle>{t(type === 'preview' ? 'Preview submitted' : 'project submitted')}</DialogTitle>
      <DialogContent>
        <DialogContentText>
          {t(
            type === 'preview'
              ? 'Your Preview video was sent to production'
              : 'Your Final video was sent to production'
          )}
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button
          component={Link}
          to={`${URL_PREFIX}/`}
          color="primary"
          autoFocus>
          {t('go to list of projects')}
        </Button>
      </DialogActions>
    </Dialog>
  </div>
)

SentDialog.propTypes = {
  t: PropTypes.func.isRequired,
  open: PropTypes.bool.isRequired,
  type: PropTypes.string.isRequired
}

export default withI18n()(SentDialog)
