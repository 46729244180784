import grey from '@material-ui/core/colors/grey'

export default theme => ({
  label: {
    marginTop: 20,
    marginBottom: 15,
    '&:first-child': {
      marginTop: 0
    }
  },
  pointerItem: {
    width: 47,
    border: 'transparent 2px solid',
    padding: 5,
    display: 'inline-block',
    '& img': {
      display: 'block'
    },
    cursor: 'pointer'
  },
  pointerItemActive: {
    borderColor: theme.palette.primary.main
  },
  example1: {
    width: 500
  },
  example2: {
    width: 300
  },
  placeAnimationWrap: {
    marginLeft: 23,
    marginBottom: 11
  },
  divider: {
    height: 1,
    backgroundColor: 'rgba(255, 255, 255, 0.12)',
    margin: `${theme.spacing.unit * 3}px -20px`
  }
})
