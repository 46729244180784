import React from 'react'
import PropTypes from 'prop-types'
import Button from '@material-ui/core/Button'
import SummaryTable from './SummaryTable'
import SentDialog from '../SentDialog'
import moment from 'moment'

const Circle = ({ color }) => (
  <svg height="14" width="14">
    <circle
      cx="7"
      cy="7"
      r="6"
      stroke={color}
      strokeWidth="2"
      fill="transparent"
    />
  </svg>
)

Circle.propTypes = {
  color: PropTypes.string.isRequired
}

const SendVideo = ({
  classes,
  project,
  sendVideo,
  contact,
  agent,
  t,
  lastPayment,
  showPreviewSentDialog,
  showFinalSentDialog
}) => {
  const isAllOk = true //[...Array(numberOfProperty)].filter((_, index) => !isPropertyOk(project[index])).length === 0
  const contactTextOk =
    contact && contact.texts.intro.text && contact.texts.name1.text
  const contactContactOk =
    contact &&
    contact.contact.email &&
    contact.contact.phone &&
    contact.contact.web &&
    contact.contact.office
  const contactMapOk = contact && contact.contact.map.center
  const contactLogoOk = contact && contact.contact.logo
  const contactOk =
    contactTextOk && contactContactOk && contactMapOk && contactLogoOk
  const agentName = agent && agent.name
  const agentPhone = agent && agent.phone
  const agentEmail = agent && agent.email
  const agentAvatar = agent && agent.image
  const agentOk = !agent || (agentName && agentEmail && agentPhone)
  const basicCode = project.texts.code
  const basicOfferType = project.texts.basicType
  const basicProperty = project.texts.basicProperty
  const basicLocation = project.texts.basicLocation
  const basicPrice = project.texts.basicPrice
  const basicOk =
    basicCode && basicOfferType && basicProperty && basicPrice && basicLocation
  const mapAddress = project.map.textAddress
  const mapBubble = project.map.textBubble
  const mapOk = mapAddress
  const haveSubscription =
    lastPayment && moment(lastPayment.time).isAfter(moment())
  const haveCreditVideos = haveSubscription && lastPayment.videos > 0
  const haveCreditTests = haveSubscription && lastPayment.tests > 0
  const isOk = contactOk && agentOk && isAllOk && basicOk && mapOk // && haveCredit // && !project.images.find((i) => !i.img)
  return (
    <div className={classes.wrap}>
      {/*<div className={classes.tables}>
        <div className={classes.tableWrap}>
          <SummaryMultiTable
            label={t('images')}
            namePrefix={t('photo')}
            scheme={[{ label: t('photo'), name: 'img', optional: true }]}
            items={project.images.map(i => ({
              img: !!i.img
            }))}
          />
        </div>
      </div>*/}
      <div className={classes.tables}>
        <div className={classes.tableWrap}>
          <SummaryTable
            label={t('credits')}
            items={[
              { label: t('subscription'), ok: haveSubscription },
              {
                label:
                  t('enable videos') +
                  ` (left ${haveCreditVideos ? lastPayment.videos : 0})`,
                ok: haveCreditVideos
              },
              {
                label:
                  t('enable tests') +
                  ` (left ${haveCreditTests ? lastPayment.tests : 0})`,
                ok: haveCreditTests
              }
            ]}
          />
        </div>
        <div className={classes.tableWrap}>
          <SummaryTable
            label={t('basic data')}
            items={[
              { label: t('order code'), ok: basicCode },
              { label: t('offer type'), ok: basicOfferType },
              { label: t('property type'), ok: basicProperty },
              {
                label: t('property location'),
                ok: basicLocation
              },
              { label: t('property price'), ok: basicPrice }
              //{ label: t('property description'), ok: basicIntro },
            ]}
          />
        </div>
        <div className={classes.tableWrap}>
          <SummaryTable
            label={t('contact')}
            items={[
              { label: t('intro animation texts'), ok: contactTextOk },
              { label: t('contact information'), ok: contactContactOk },
              { label: t('map'), ok: contactMapOk },
              { label: t('logo'), ok: contactLogoOk }
            ]}
          />
        </div>
        <div className={classes.tableWrap}>
          <SummaryTable
            label={t('agent')}
            items={[
              { label: t('name and surname'), ok: agentName, optional: !agent },
              { label: t('phone number'), ok: agentPhone, optional: !agent },
              { label: t('email'), ok: agentEmail, optional: !agent },
              { label: t('avatar'), ok: agentAvatar, optional: true }
            ]}
          />
        </div>
        <div className={classes.tableWrap}>
          <SummaryTable
            label={t('map')}
            items={[
              { label: t('address in map'), ok: mapAddress },
              {
                label: t('location information'),
                ok: mapBubble,
                optional: true
              }
            ]}
          />
        </div>
      </div>
      <div className={classes.submitWrap}>
        <span className={classes.submitLabel}>
          {!isOk ? (
            <span className={classes.error}>{t('cannot submit order')}</span>
          ) : (
            t('order ready for submit')
          )}
        </span>
        <Button
          variant="contained"
          color="secondary"
          disabled={!isOk || !haveCreditTests}
          className={classes.submitBtn}
          onClick={() => sendVideo('preview')}>
          {t('submit preview')}
        </Button>
        <Button
          variant="contained"
          color="primary"
          disabled={!isOk || !haveCreditVideos}
          onClick={() => sendVideo('video')}>
          {t('submit order')}
        </Button>
      </div>
      <SentDialog
        type={showPreviewSentDialog ? 'preview' : 'video'}
        open={showPreviewSentDialog || showFinalSentDialog}
      />
    </div>
  )
}

SendVideo.propTypes = {
  classes: PropTypes.object.isRequired,
  property: PropTypes.object.isRequired,
  sendVideo: PropTypes.func.isRequired,
  contact: PropTypes.object.isRequired,
  lastPayment: PropTypes.object.isRequired,
  subscriptionIsValid: PropTypes.bool,
  agent: PropTypes.object.isRequired
}

export default SendVideo
