import React, { useEffect, useRef } from 'react'
import PropTypes from 'prop-types'
import Button from '@material-ui/core/Button'
import Dialog from '@material-ui/core/Dialog'
import DialogActions from '@material-ui/core/DialogActions'
import DialogContent from '@material-ui/core/DialogContent'
import DialogTitle from '@material-ui/core/DialogTitle'
import TextField from '@material-ui/core/TextField/TextField'
import { withI18n } from 'react-i18next'

const AddGroupDialog = ({ open, onClose, onAdd, t }) => {
  const inputEl = useRef(null)
  const input2El = useRef(null)
  const handleSubmit = e => {
    e.preventDefault()
    onAdd(inputEl.current.value, input2El.current.value)
    onClose()
  }
  return (
    <Dialog open={open} onClose={onClose}>
      <form onSubmit={handleSubmit}>
        <DialogTitle>{t('add group')}</DialogTitle>
        <DialogContent>
          <TextField
            inputRef={inputEl}
            autoFocus
            margin="dense"
            label={t('name')}
            fullWidth
            required
          />
          <TextField
            inputRef={input2El}
            margin="dense"
            label={t('email')}
            type="email"
            fullWidth
            required
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={onClose} color="primary">
            {t('close')}
          </Button>
          <Button component={'button'} type="submit" color="primary">
            {t('add')}
          </Button>
        </DialogActions>
      </form>
    </Dialog>
  )
}

AddGroupDialog.propTypes = {
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.bool.isRequired,
  onAdd: PropTypes.bool.isRequired
}

export default withI18n()(AddGroupDialog)
