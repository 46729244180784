import grey from '@material-ui/core/colors/grey'

export default theme => ({
  title: {
    paddingLeft: 12
  },
  list: {
    height: 'calc(100vh - 88px)',
    overflow: 'auto',
    color: 'white'
  },
  addIcon: {
    backgroundColor: theme.palette.primary.light
  },
  item: {
    backgroundColor: '#2d2d2d',
    height: 60,
    marginTop: theme.spacing.unit * 2
  },
  textBlock: {
    textAlign: 'right',
    paddingRight: theme.spacing.unit * 2,
    position: 'absolute',
    right: 0,
    color: grey[500]
  },
  textBlockPublish: {
    color: theme.palette.primary.light
  },
  activeCheckbox: {
    position: 'absolute',
    right: 238,
    top: 1
  }
})
