import React from 'react'
import TextField from '@material-ui/core/TextField'
import Checkbox from '@material-ui/core/Checkbox'
import FormControlLabel from '@material-ui/core/FormControlLabel'

export const renderTextField = ({
  input,
  label,
  meta: { touched, error },
  ...custom
}) => (
  <TextField
    label={label}
    error={!!error}
    margin="normal"
    helperText={touched && error}
    {...input}
    {...custom}
  />
)

export const renderCheckbox = ({ input, label }) => (
  <FormControlLabel
    label={label}
    control={
      <Checkbox
        label={label}
        checked={input.value ? true : false}
        onChange={input.onChange}
      />
    }
  />
)
