import React from 'react'
import PropTypes from 'prop-types'
import { Link } from 'react-router-dom'
import AppBar from '@material-ui/core/AppBar'
import Toolbar from '@material-ui/core/Toolbar'
import Typography from '@material-ui/core/Typography'
import { ADMIN_PATH } from 'constants/paths'
import LogoImg from 'assets/logo.svg'
import Button from '@material-ui/core/Button'
import AdminIcon from '@material-ui/icons/ExitToApp'

export const Navbar = ({
  classes,
  goToClient,
  t
}) => (
  <AppBar position="fixed" className={classes.root}>
    <Toolbar classes={{ regular: classes.toolbar }}>
      <Typography
        variant="h6"
        color="inherit"
        className={classes.title}
        component={Link}
        to={ADMIN_PATH}>
        <img className={classes.logo} src={LogoImg} alt="logo" />
        <span className={classes.navbarText}>administration</span>
      </Typography>
      <Button variant="flat" className={classes.goToClientBtn} onClick={goToClient}>
        <AdminIcon className={classes.buttonIcon} />
        {t('client')}
      </Button>
    </Toolbar>
  </AppBar>
)

Navbar.propTypes = {
  classes: PropTypes.object.isRequired, // from enhancer (withStyles)
  displayName: PropTypes.string, // from enhancer (flattenProps - profile)
  avatarUrl: PropTypes.string, // from enhancer (flattenProps - profile)
  authExists: PropTypes.bool, // from enhancer (withProps - auth)
  goToAccount: PropTypes.func.isRequired, // from enhancer (withHandlers - router)
  goToClient: PropTypes.func.isRequired, // from enhancer (withHandlers - router)
  goToSubscription: PropTypes.func.isRequired, // from enhancer (withHandlers - router)
  handleLogout: PropTypes.func.isRequired, // from enhancer (withHandlers - firebase)
  closeAccountMenu: PropTypes.func.isRequired, // from enhancer (withHandlers - firebase)
  handleMenu: PropTypes.func.isRequired, // from enhancer (withHandlers - firebase)
  anchorEl: PropTypes.object // from enhancer (withStateHandlers - handleMenu)
}

export default Navbar
