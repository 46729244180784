import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { withStyles } from '@material-ui/core/styles'
import Input from '@material-ui/core/Input'
import InputLabel from '@material-ui/core/InputLabel'
import FormHelperText from '@material-ui/core/FormHelperText'
import FormControl from '@material-ui/core/FormControl'
import { withI18n } from 'react-i18next'
import VideoPicker from 'components/VideoPicker'
import ColorPicker from 'components/ColorPicker'
import RadioGroup from '@material-ui/core/RadioGroup'
import FormControlLabel from '@material-ui/core/FormControlLabel'
import Radio from '@material-ui/core/Radio'
import Grid from '@material-ui/core/Grid'

const ITEMS_VIDEOS = [
  {
    id: '01',
    src:
      'https://firebasestorage.googleapis.com/v0/b/editor-53bde/o/videos%2Fcontact%2Fcontact1.mp4?alt=media&token=583f2f2a-e163-4abf-9213-0465a9f099f6',
    label: '1'
  },
  {
    id: '02',
    src:
      'https://firebasestorage.googleapis.com/v0/b/editor-53bde/o/videos%2Fcontact%2Fcontact2.mp4?alt=media&token=e300c2b9-4d7b-43ff-af34-ff2b8550ee98',
    label: '2'
  }
]

const hexToRgb = hex => {
  const result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex)
  return result
    ? {
        red: parseInt(result[1], 16),
        green: parseInt(result[2], 16),
        blue: parseInt(result[3], 16)
      }
    : null
}

const getBackgroundColor = hex => {
  const { red, green, blue } = hexToRgb(hex)
  return red * 0.299 + green * 0.587 + blue * 0.114 > 186
    ? 'transparent'
    : '#ffffff'
}

const styles = theme => ({
  container: {
    display: 'flex',
    flexWrap: 'wrap'
  },
  formControl: {
    margin: theme.spacing.unit,
    width: 350
  },
  formLabel: {
    whiteSpace: 'nowrap'
  },
  colorWrap: {
    position: 'relative',
    display: 'inline-block'
  },
  colorPickerWrap: {
    marginLeft: theme.spacing.unit
  },
  pickerBg: {
    position: 'fixed',
    top: 0,
    left: 0,
    width: '100%',
    height: '100%',
    cursor: 'pointer',
    zIndex: 1
  },
  colorText: {
    cursor: 'pointer'
  }
})

class Texts extends Component {
  state = {
    showPicker: null
  }

  handleClickPicker = (e, item) => {
    this.setState({
      showPicker: item.id
    })
  }

  handleClickClosePicker = () => {
    this.setState({
      showPicker: null
    })
  }

  handleChangeColor = (id, color) =>
    this.props.onChangeTexts({
      ...this.props.texts,
      [id]: { ...this.props.texts[id], color }
    })

  handleChangeText = (id, text) =>
    this.props.onChangeTexts({
      ...this.props.texts,
      [id]: { ...this.props.texts[id], text }
    })

  handleChangeActiveGroup = (id, activeGroup) =>
    this.props.onChangeTexts({
      ...this.props.texts,
      [id]: { ...this.props.texts[id], activeGroup }
    })

  render() {
    const { classes, texts, t } = this.props

    const items = [
      {
        label: t('intro text'),
        id: 'intro',
        helper: t('intro text helper'),
        required: true
      },
      {
        label: t('name 1'),
        id: 'name1',
        helper: t('name 1 helper'),
        required: true
      },
      {
        label: t('name 2'),
        id: 'name2',
        helper: t('name 2 helper')
      }
    ]

    return (
      <div className={classes.root}>
        {items
          .map(i => ({ ...i, ...texts[i.id] }))
          .map(item => (
            <div key={item.id}>
              <FormControl className={classes.formControl}>
                <InputLabel className={classes.formLabel} shrink>
                  {item.helper}
                </InputLabel>
                <Input
                  value={item.text}
                  placeholder={item.label}
                  onChange={e => {
                    this.handleChangeText(item.id, e.target.value)
                  }}
                />
                {item.text === '' && item.required && (
                  <FormHelperText error>{t('required field')}</FormHelperText>
                )}
              </FormControl>
              <div className={classes.colorWrap}>
                {!this.props.onlyGroupColors ? (
                  <React.Fragment>
                    <RadioGroup
                      row
                      value={item.activeGroup ? '1' : '0'}
                      onChange={e =>
                        this.handleChangeActiveGroup(
                          item.id,
                          e.target.value === '1'
                        )
                      }>
                      <FormControlLabel
                        value={'0'}
                        control={<Radio />}
                        label={
                          <Grid container alignItems={'center'}>
                            <Grid item>{t('custom')}</Grid>
                            <Grid item className={classes.colorPickerWrap}>
                              <ColorPicker
                                color={item.color}
                                onChange={color =>
                                  this.handleChangeColor(item.id, color)
                                }
                              />
                            </Grid>
                          </Grid>
                        }
                      />
                      <FormControlLabel
                        value={'1'}
                        control={<Radio />}
                        label={
                          <Grid container alignItems={'center'}>
                            <Grid item>{t('Group colors')}</Grid>
                            <Grid item className={classes.colorPickerWrap}>
                              <ColorPicker
                                color={this.props.colors[item.id]}
                                onChange={
                                  this.props.isAdmin &&
                                  (color =>
                                    this.props.updateGroupColors(
                                      item.id,
                                      color
                                    ))
                                }
                              />
                            </Grid>
                          </Grid>
                        }
                      />
                    </RadioGroup>
                  </React.Fragment>
                ) : (
                  <ColorPicker
                    color={this.props.colors[item.id]}
                    onChange={
                      this.props.isAdmin &&
                      (color => this.props.updateGroupColors(item.id, color))
                    }
                  />
                )}
              </div>
            </div>
          ))}
        <VideoPicker
          videoWidth={500}
          startTime={0}
          items={ITEMS_VIDEOS}
          activeId={this.props.design}
          onActive={this.props.onChangeDesign}
        />
      </div>
    )
  }
}

Texts.propTypes = {
  classes: PropTypes.object.isRequired,
  texts: PropTypes.object.isRequired,
  onChangeTexts: PropTypes.func.isRequired,
  design: PropTypes.object.isRequired,
  onChangeDesign: PropTypes.func.isRequired,
  isAdmin: PropTypes.bool.isRequired,
  updateGroupColors: PropTypes.func.isRequired,
  onlyGroupColors: PropTypes.bool.isRequired,
  colors: PropTypes.object.isRequired
}

export default withI18n()(withStyles(styles)(Texts))
