import React from 'react'
import { withStyles } from '@material-ui/core/styles/index'
import PropTypes from 'prop-types'
import cn from 'classnames'
import Grid from '@material-ui/core/Grid'
import { URL_PREFIX } from '../../../../../../../../constants/paths'
import { withI18n } from 'react-i18next'

const LAYOUT_ITEMS = [
  { id: 1, src: URL_PREFIX + '/images/General-setup-1s.jpg' },
  { id: 2, src: URL_PREFIX + '/images/General-setup-2s.jpg' },
  { id: 3, src: URL_PREFIX + '/images/General-setup-3s.jpg' },
  { id: 4, src: URL_PREFIX + '/images/General-setup-4s.jpg' }
]

const styles = theme => ({
  item: {
    cursor: 'pointer',
    border: 'solid 2px transparent',
    position: 'relative',
    '& >span': {
      position: 'absolute'
    }
  },
  label: {
    color: theme.palette.primary.main,
    marginBottom: 5
  },
  active: {
    border: `solid 2px ${theme.palette.primary.main}`
  },
  noneActive: {
    border: 'solid 2px transparent'
  },
  image: {
    maxWidth: 300
  },
  logo1: {
    left: 23,
    bottom: 31,
    fontSize: 18,
    color: 'black'
  },
  logo2: {
    left: 23,
    bottom: 31,
    fontSize: 18,
    color: 'black'
  },
  title1: {
    left: 0,
    color: 'white',
    fontSize: 20,
    right: 0,
    textAlign: 'center',
    top: 47
  },
  title2: {
    left: 0,
    color: 'white',
    fontSize: 20,
    right: 0,
    textAlign: 'center',
    top: 47
  },
  title3: {
    left: 0,
    color: 'white',
    fontSize: 30,
    right: 0,
    textAlign: 'center',
    top: 70
  },
  desc1: {
    left: 106,
    bottom: 25,
    fontSize: 12,
    color: 'black',
    fontWeight: 600,
    lineHeight: '13px'
  },
  desc2: {
    left: 106,
    bottom: 29,
    fontSize: 12,
    color: 'black',
    fontWeight: 600,
    lineHeight: '13px'
  }
})

const Img1 = ({ classes, activeId, itemId, src }) => (
  <React.Fragment>
    <img
      src={src}
      alt="layout preview"
      className={cn(
        classes.image,
        activeId === itemId ? classes.active : classes.noneActive
      )}
    />
  </React.Fragment>
)

const LayoutPicker = ({ classes, activeId, onActive, t, readOnly }) => (
  <Grid container spacing={2} className={classes.content}>
    {LAYOUT_ITEMS.map(item => (
      <Grid
        item
        className={classes.item}
        style={{ cursor: !readOnly ? 'pointer' : 'default' }}
        onClick={e => !readOnly && onActive(item.id, e)}>
        {item.label && <div className={classes.label}>{item.label}</div>}
        <Img1
          classes={classes}
          activeId={activeId}
          itemId={item.id}
          src={item.src}
        />
        {item.id === 1 && (
          <React.Fragment>
            <span className={classes.title1}>{t('Basic data')}</span>
            <span className={classes.logo1}>{t('LOGO')}</span>
            <span className={classes.desc1}>
              {t('Real estate agent name')}
              <br />
              {t('Real estate office')}
              <br />
              {t('Contacts')}
            </span>
          </React.Fragment>
        )}
        {item.id === 2 && (
          <React.Fragment>
            <span className={classes.title2}>{t('Basic data')}</span>
            <span className={classes.logo2}>{t('LOGO')}</span>
            <span className={classes.desc2}>
              {t('Real estate office')}
              <br />
              {t('Contacts')}
            </span>
          </React.Fragment>
        )}
        {item.id === 3 && (
          <React.Fragment>
            <span className={classes.title3}>Basic data</span>
          </React.Fragment>
        )}
      </Grid>
    ))}
  </Grid>
)

LayoutPicker.propTypes = {
  classes: PropTypes.object.isRequired,
  activeId: PropTypes.number.isRequired,
  onActive: PropTypes.func.isRequired,
  items: PropTypes.array.isRequired,
  readOnly: PropTypes.bool
}

export default withI18n()(withStyles(styles)(LayoutPicker))
