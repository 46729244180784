import React from 'react'
import PropTypes from 'prop-types'
import Typography from '@material-ui/core/Typography'
import { formatDateTime } from 'utils/date'
import IconButton from '@material-ui/core/IconButton'
import { makeStyles } from '@material-ui/core'
import grey from '@material-ui/core/colors/grey'
import SvgIcon from '@material-ui/core/SvgIcon/SvgIcon'
import Checkbox from '@material-ui/core/Checkbox'

const useStyles = makeStyles(theme => ({
  root: {
    display: 'flex',
    justifyContent: 'center',
    flexDirection: 'column'
  },
  item: {
    '&+$item': {
      marginTop: -4
    }
  },
  previewName: {
    display: 'inline-block',
    marginRight: theme.spacing.unit * 2
  },
  previewTime: {
    color: grey[600],
    marginRight: theme.spacing.unit * 2,
    position: 'absolute',
    fontSize: 12,
    marginTop: 16
  },
  fakeSpace: {
    visibility: 'hidden',
    fontSize: 12
  },
  icon: {
    width: 25,
    height: 25
  }
}))

const DownloadIcon = props => (
  <SvgIcon
    component={() => (
      <svg
        {...props}
        xmlns="http://www.w3.org/2000/svg"
        version="1.1"
        viewBox="0 0 224 224"
        width="32px"
        height="32px">
        <g
          fill="none"
          fill-rule="nonzero"
          stroke="none"
          stroke-width="1"
          stroke-linecap="butt"
          stroke-linejoin="miter"
          stroke-miterlimit="10"
          stroke-dasharray=""
          stroke-dashoffset="0"
          font-family="none"
          font-weight="none"
          font-size="none"
          text-anchor="none"
          style={{ mixBlendMode: 'normal' }}>
          <path d="M0,224v-224h224v224z" fill="none" />
          <g fill="#ffffff">
            <g id="surface1">
              <path d="M98,14v14h14v-14zM98,42v14h14v-14zM98,70v60.59375l-31.9375,-30.625l-9.625,10.0625l48.5625,46.64843l48.5625,-46.64843l-9.625,-10.0625l-31.9375,30.625v-60.59375zM14,140v35c0,11.53907 9.46093,21 21,21h140c11.53907,0 21,-9.46093 21,-21v-35h-14v35c0,3.9375 -3.0625,7 -7,7h-140c-3.9375,0 -7,-3.0625 -7,-7v-35z" />
            </g>
          </g>
        </g>
      </svg>
    )}
  />
)

const ProgressIcon = props => (
  <SvgIcon
    component={() => (
      <svg
        {...props}
        xmlns="http://www.w3.org/2000/svg"
        version="1.1"
        viewBox="0 0 224 224"
        width="32px"
        height="32px">
        <g
          fill="none"
          fill-rule="nonzero"
          stroke="none"
          stroke-width="1"
          stroke-linecap="butt"
          stroke-linejoin="miter"
          stroke-miterlimit="10"
          stroke-dasharray=""
          stroke-dashoffset="0"
          font-family="none"
          font-weight="none"
          font-size="none"
          text-anchor="none"
          style={{ mixBlendMode: 'normal' }}>
          <path d="M0,224v-224h224v224z" fill="none" />
          <g fill="#ffffff">
            <g id="surface1">
              <path d="M105,14c-6.07032,0 -11.97657,0.65625 -17.71875,1.75l2.73438,13.78125c4.8125,-0.98437 9.84375,-1.53125 14.98437,-1.53125c2.67968,0 5.30468,0.16407 7.875,0.4375c2.40625,0.21875 4.8125,0.60157 7.10938,1.09375v-0.05468c35.4375,6.94532 62.01562,38.0625 62.01562,75.52343c0,37.46093 -26.57812,68.57813 -62.01562,75.52343v-0.05468c-2.35157,0.49218 -4.75782,0.875 -7.21875,1.09375c-2.57032,0.27343 -5.14063,0.4375 -7.76563,0.4375c-5.14062,0 -10.11718,-0.65625 -14.98437,-1.58593l-2.73438,13.67187c5.74218,1.14845 11.64843,1.91407 17.71875,1.91407c50.20313,0 91,-40.79687 91,-91c0,-50.20312 -40.79687,-91 -91,-91zM54.46875,29.36718c-9.89843,6.67188 -18.32032,15.25782 -24.99218,25.15625l11.64843,7.76563c5.57813,-8.36718 12.74218,-15.64063 21.10938,-21.27343zM98,56v56h-28l35,35l35,-35h-28v-56zM15.91407,87.28125c-1.14843,5.74218 -1.91407,11.64843 -1.91407,17.71875c0,6.07032 0.65625,11.97657 1.75,17.71875l13.78125,-2.73437c-0.98437,-4.8125 -1.53125,-9.84375 -1.53125,-14.98438c0,-5.14062 0.65625,-10.11718 1.64063,-14.98437zM41.01563,147.76563l-11.64845,7.76562c6.67188,9.89843 15.25782,18.32032 25.15625,24.99218l7.76563,-11.64843c-8.36718,-5.57812 -15.64063,-12.74218 -21.27343,-21.10937z" />
            </g>
          </g>
        </g>
      </svg>
    )}
  />
)

const SubItems = ({
  items,
  showLess,
  onDownload,
  label,
  withCheckbox,
  activeItem,
  onChangeItem
}) => {
  const classes = useStyles()
  return (
    <div className={classes.root}>
      {items
        .filter((i, index) => !showLess || items.length === index + 1)
        .sort((a, b) => a.sendTime - b.sendTime)
        .map((child, index) => (
          <div key={child.index} className={classes.item}>
            <Typography
              className={classes.previewName}
              color={'primary'}
              component={'span'}
              variant={'span'}>
              {label} {child.index + 1}
              {child.index < 9 && <span className={classes.fakeSpace}>0</span>}
            </Typography>
            <span className={classes.previewTime}>
              {formatDateTime(child.sendTime, true)}
            </span>
            <span className={classes.fakeSpace}>
              {formatDateTime(child.sendTime)}
            </span>
            <IconButton
              className={classes.margin}
              disabled={!child.done}
              onClick={e => {
                e.stopPropagation()
                onDownload(child.key)
              }}>
              {child.done ? (
                <DownloadIcon className={classes.icon} />
              ) : (
                <ProgressIcon className={classes.icon} />
              )}
            </IconButton>
            {withCheckbox && (
              <Checkbox
                onClick={e => e.stopPropagation()}
                disabled={!child.done}
                checked={activeItem === child.key}
                onChange={e => {
                  onChangeItem(e.target.checked ? child.key : null)
                }}
              />
            )}
          </div>
        ))}
    </div>
  )
}

SubItems.propTypes = {
  items: PropTypes.array.isRequired,
  showLess: PropTypes.bool,
  withCheckbox: PropTypes.bool,
  onDownload: PropTypes.func.isRequired,
  title: PropTypes.string.isRequired,
  activeItem: PropTypes.string,
  onChangeItem: PropTypes.func
}

export default SubItems
