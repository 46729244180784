import React from 'react'

const CalloutColorsExample = ({ textBg, text, pointer, rope }) => (
  <svg
    version="1.1"
    id="Vrstva_4"
    x="0px"
    y="0px"
    viewBox="0 0 209 50"
    style={{ enableBackground: 'new 0 0 209 50' }}>
    <style
      type="text/css"
      dangerouslySetInnerHTML={{
        __html: `	.st0{fill:none;stroke:${rope};stroke-width:2;stroke-linejoin:round;stroke-miterlimit:10;}
  .st1{fill:none;stroke:${pointer};stroke-width:2;stroke-linecap:round;stroke-miterlimit:10;stroke-dasharray:2.8233,4.7055;}
  .st2{fill:none;stroke:${rope};stroke-width:4;stroke-linejoin:round;stroke-miterlimit:10;}
  .st3{fill:${textBg};stroke:${textBg};stroke-width:14;stroke-linejoin:round;stroke-miterlimit:10;}
  .st4{fill:${text};}
  .st6{font-size:10px;}
  .st7{fill:#0039E5;stroke:${textBg};stroke-width:14;stroke-linecap:round;stroke-linejoin:round;stroke-miterlimit:10;}
`
      }}
    />
    <line id="lano_lupy" className="st0" x1="58" y1="25" x2="76" y2="43" />
    <circle id="pointer_lupy" cx="76" cy="43" r="4" fill={rope} />
    <g id="kruh_pointeru_lupy">
      <circle className="st1" cx="76" cy="43" r="6" />
    </g>
    <circle id="lupa" className="st2" cx="44" cy="17" r="15" />
    <line id="label_lupy" className="st3" x1="0" y1="16" x2="35" y2="16" />
    <text
      id="text_lupy"
      transform="matrix(1 0 0 1 5.75 19.875)"
      className="st4 st5 st6">
      TEXT
    </text>
    <line
      id="lano_calloutu"
      className="st0"
      x1="124"
      y1="20"
      x2="147"
      y2="43"
    />
    <circle id="pointer_calloutu" cx="147" cy="43" r="4" fill={rope} />
    <g id="kruh_pointeru_calloutu">
      <circle className="st1" cx="147" cy="43" r="6" />
    </g>
    <line
      id="label_calloutu"
      className="st7"
      x1="177"
      y1="16"
      x2="202"
      y2="16"
    />
    <text
      id="text_calloutu"
      transform="matrix(1 0 0 1 178.75 19.875)"
      className="st4 st5 st6">
      TEXT
    </text>
    <line
      id="label_textinfo"
      className="st7"
      x1="97"
      y1="16"
      x2="122"
      y2="16"
    />
    <text
      id="text_textinfa"
      transform="matrix(1 0 0 1 98.75 19.875)"
      className="st4 st5 st6">
      TEXT
    </text>
  </svg>
)

export default CalloutColorsExample
