import React from 'react'
import { withStyles } from '@material-ui/core/styles/index'
import PropTypes from 'prop-types'
import Grid from '@material-ui/core/Grid'
import cn from 'classnames'
import VideoWrap from './VideoWrap'

const styles = theme => ({
  wrap: {
    marginBottom: 20
  },
  video: {
    width: '100%',
    display: 'block'
  },
  videoContainer: {
    position: 'relative',
    overflow: 'hidden',
    cursor: 'pointer',
    '&:hover div': {
      display: 'none'
    }
  },
  videoActive: {
    border: `2px solid ${theme.palette.primary.main}`
  },
  videoLabel: {
    textAlign: 'center',
    position: 'absolute',
    top: 0,
    left: -2,
    width: 'calc(100% + 4px)',
    backgroundColor: 'rgba(0,0,0,0.5)',
    color: 'white',
    lineHeight: '30px'
  }
})

const VideoTransitionItem = ({
  classes,
  title,
  infoText,
  activeId,
  onActive,
  className,
  items
}) => (
  <div className={cn(classes.wrap, className)}>
    <div>
      <Grid container spacing={1}>
        {items.map(item => (
          <Grid key={item.id} item xs={6} sm={4} lg={3}>
            <div
              className={cn(
                classes.videoContainer,
                item.id === activeId && classes.videoActive
              )}
              onClick={e => onActive(item.id, e)}>
              <VideoWrap className={classes.video} src={item.src} />
              <div className={classes.videoLabel}>{item.label}</div>
            </div>
          </Grid>
        ))}
      </Grid>
    </div>
  </div>
)

VideoTransitionItem.propTypes = {
  classes: PropTypes.object.isRequired,
  className: PropTypes.string.isRequired,
  activeId: PropTypes.number.isRequired,
  onActive: PropTypes.func.isRequired,
  items: PropTypes.array.isRequired
}

export default withStyles(styles)(VideoTransitionItem)
