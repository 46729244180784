import moment from 'moment'

export const formatDateTime = (unixTime, fromNow = false) => {
  unixTime = unixTime || moment().add(-20, 'd')
  if (
    fromNow &&
    moment(unixTime)
      .add(7, 'days')
      .isAfter(moment())
  ) {
    return moment(unixTime).fromNow()
  } else {
    return moment(unixTime).format('YYYY-MM-DD HH:mm')
  }
}
