import React from 'react'
import PropTypes from 'prop-types'
import { withI18n } from 'react-i18next'
import {
  LOOK_PATH,
  CONTACT_PATH,
  DESCRIPTION_PATH,
  INTRO_PATH,
  MUSIC_PATH,
  TRANSITIONS_PATH,
  AGENT_PATH
} from 'constants/paths'
import { NavLink, Redirect, Route, Switch } from 'react-router-dom'
import Intro from '../../routes/Intro'
import Description from '../../routes/Description'
import Transitions from '../../routes/Transitions'
import Music from '../../routes/Music'
import Look from '../../routes/Look'
import Contact from '../../routes/Contact'
import Agent from '../../routes/Agent'

const MENU_ITEMS = [
  { path: INTRO_PATH, label: 'intro animations' },
  { path: DESCRIPTION_PATH, label: 'Text blocks' },
  { path: TRANSITIONS_PATH, label: 'video transitions' },
  { path: MUSIC_PATH, label: 'music' },
  { path: LOOK_PATH, label: 'appearance' },
  { path: AGENT_PATH, label: 'agent' },
  { path: CONTACT_PATH, label: 'contact' }
]

const renderChildren = (routes, match, parentProps) =>
  routes.map(route => (
    <Route
      key={`${match.url}-${route.path}`}
      path={`${match.url}/${route.path}`}
      render={props => <route.component {...parentProps} {...props} />}
    />
  ))

const Settings = ({
  classes,
  t,
  match,
  project,
  projectId,
  groupId,
  updateSettings,
  colors,
  isAdmin,
  updateGroupColors,
  onlyGroupColors,
  setEditTime
}) => {
  const handleChange = name => value => {
    updateSettings(name, value)
  }

  return (
    <div className={classes.root}>
      <div className={classes.header}>
        {MENU_ITEMS.map(i => (
          <NavLink
            to={`${match.path}/${i.path}`}
            activeClassName={classes.menuItemActive}
            className={classes.menuItem}>
            {t(i.label)}
          </NavLink>
        ))}
      </div>
      <div className={classes.content}>
        <Switch>
          <Redirect
            exact
            from={match.path}
            to={`${match.path}/${Intro.path}`}
          />
          {renderChildren([Intro], match, {
            project,
            projectId,
            groupId,
            isAdmin
          })}
          {renderChildren([Transitions], match, {
            project,
            projectId,
            groupId,
            value: project.settings.videoTransitionImages,
            onChange: handleChange('videoTransitionImages')
          })}
          {renderChildren([Music], match, {
            project,
            projectId,
            groupId,
            value: project.settings.music,
            onChange: handleChange('music')
          })}
          {renderChildren([Look], match, {
            project,
            projectId,
            groupId,
            value: project.settings.design,
            onChange: handleChange('design'),
            colors,
            updateGroupColors,
            onlyGroupColors,
            isAdmin
          })}
          {renderChildren([Contact], match, {
            project,
            projectId,
            groupId,
            isAdmin,
            updateGroupColors,
            onlyGroupColors,
            colors,
            modifyEnable: true
          })}
          {renderChildren([Agent], match, {
            project,
            projectId,
            groupId,
            setEditTime,
            modifyEnable: true
          })}
          {renderChildren([Description], match, {
            project,
            projectId,
            groupId,
            colors,
            isAdmin,
            updateSettings,
            updateGroupColors,
            onlyGroupColors
          })}
        </Switch>
      </div>
    </div>
  )
}
Settings.propTypes = {
  classes: PropTypes.object.isRequired,
  updateSettings: PropTypes.func.isRequired,
  project: PropTypes.object.isRequired,
  onlyGroupColors: PropTypes.bool.isRequired,
  isAdmin: PropTypes.bool.isRequired,
  updateGroupColors: PropTypes.func.isRequired,
  colors: PropTypes.object.isRequired
}

export default withI18n()(Settings)
