import grey from '@material-ui/core/colors/grey'

export default theme => ({
  root: {
    margin: '20px 20px 0'
  },
  item: {
    '&+*': {
      marginTop: theme.spacing.unit * 3
    }
  },
  panel: {
    padding: theme.spacing.unit * 2,
    backgroundColor: grey[800],
    marginBottom: theme.spacing.unit * 2,
    color: 'white'
  },
  icon: {
    width: 30,
    marginRight: theme.spacing.unit * 2
  }
})
