import React, { Component } from 'react'
import Button from '@material-ui/core/Button'
import PropTypes from 'prop-types'
import List from '@material-ui/core/List'
import ListItem from '@material-ui/core/ListItem'
import Radio from '@material-ui/core/Radio'
import ListItemText from '@material-ui/core/ListItemText'
import Avatar from '@material-ui/core/Avatar'
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction'
import IconButton from '@material-ui/core/IconButton'
import EditIcon from '@material-ui/icons/Edit'
import moment from 'moment'
import DetailDialog from './DetailDialog'
import DeleteIcon from '@material-ui/icons/Delete'
import AvatarIcon from './avatar.png'
import { withI18n } from 'react-i18next'
import ListItemAvatar from '@material-ui/core/ListItemAvatar'
import Divider from '@material-ui/core/Divider'

class Agents extends Component {
  state = {
    showCreateDialog: false,
    currentAgentId: null,
    newAgentValues: {}
  }

  getMaxNumberOfAvatars() {
    const { lastPayment } = this.props
    if (!lastPayment || lastPayment.status === 'ACTIVE') {
      return 1
    } else {
      const { subscriptionIndex } = lastPayment
      if ([1, 5,].indexOf(subscriptionIndex) !== -1) {
        return 3
      } else if ([2, 6].indexOf(subscriptionIndex) !== -1) {
        return 20
      } else if (subscriptionIndex === 3 || subscriptionIndex === 7) {
        return Infinity
      } else {
        return 1
      }
    }
  }

  render() {
    const {
      classes,
      agentItems,
      deleteAgent,
      addImageAgent,
      addAgent,
      updateAgentValues,
      setAgent,
      deleteImageAgent,
      project,
      t,
      selectEnable,
      modifyEnable
    } = this.props

    const maxNumberOfAvatar = this.getMaxNumberOfAvatars()

    const handleChangeAgent = key => {
      setAgent(key)
      //setLastAgent(key)
    }

    const handleClickDelete = item => {
      deleteAgent(item.key)
    }

    const getAgentValues = currentAgentId => {
      const item = this.props.agentItems.find(
        ({ key }) => currentAgentId === key
      )
      return item && item.values
    }

    const { agentKey } = project

    const { currentAgentId } = this.state

    const contactValues = getAgentValues(currentAgentId)

    return (
      <div className={classes.content}>
        {modifyEnable && (
          <Button
            variant="contained"
            className={classes.button}
            disabled={maxNumberOfAvatar <= agentItems.length}
            color="primary"
            onClick={() =>
              this.setState({ showCreateDialog: true, newAgentValues: {} })
            }>
            {t('create new agent')}
          </Button>
        )}
        <div className={classes.listWrap}>
          <List component="nav">
            {selectEnable && (
              <ListItem button onClick={() => handleChangeAgent(null)}>
                <Radio
                  checked={!agentKey}
                  onChange={() => handleChangeAgent(null)}
                  tabIndex={-1}
                  disableRipple
                />
                <ListItemText primary={t('none active')} />
              </ListItem>
            )}
            {[...agentItems].reverse().map(item => (
              <>
                {selectEnable && (
                  <Divider
                    key={item.key + 'd'}
                    variant="inset"
                    component="li"
                  />
                )}
                <ListItem
                  key={item.key}
                  button={selectEnable}
                  onClick={() => selectEnable && handleChangeAgent(item.key)}>
                  {selectEnable && (
                    <Radio
                      checked={agentKey === item.key}
                      onChange={e => handleChangeAgent(item.key)}
                      tabIndex={-1}
                      disableRipple
                    />
                  )}
                  <ListItemAvatar>
                    <Avatar
                      alt="avatar"
                      src={item.values.image || AvatarIcon}
                    />
                  </ListItemAvatar>
                  <ListItemText
                    primary={item.values.name}
                    secondary={moment(item.creationTime).format(
                      'YYYY-MM-DD HH:mm'
                    )}
                  />
                  {modifyEnable && (
                    <ListItemSecondaryAction>
                      <IconButton onClick={e => handleClickDelete(item)}>
                        <DeleteIcon />
                      </IconButton>
                      <IconButton
                        onClick={() =>
                          this.setState({ currentAgentId: item.key })
                        }>
                        <EditIcon />
                      </IconButton>
                    </ListItemSecondaryAction>
                  )}
                </ListItem>
              </>
            ))}
          </List>
        </div>
        {contactValues && (
          <DetailDialog
            values={contactValues || {}}
            open={contactValues}
            onChange={values => {
              updateAgentValues(currentAgentId, values)
            }}
            onAddImage={file => addImageAgent(currentAgentId, file)}
            onClose={() => {
              this.setState({ currentAgentId: null })
            }}
            onDeleteImage={() => deleteImageAgent(currentAgentId)}
            edit
          />
        )}

        <DetailDialog
          open={this.state.showCreateDialog}
          values={this.state.newAgentValues}
          onChange={values => {
            this.setState({
              newAgentValues: values
            })
          }}
          onSubmit={file => {
            addAgent(this.state.newAgentValues, file)
            this.setState({
              showCreateDialog: null
            })
          }}
          onAddImage={file => addImageAgent(currentAgentId, file)}
          onClose={() => {
            this.setState({ showCreateDialog: false })
          }}
        />
      </div>
    )
  }
}

Agents.propTypes = {
  classes: PropTypes.object.isRequired,
  agentItems: PropTypes.array.isRequired,
  agentKey: PropTypes.func.isRequired,
  setAgent: PropTypes.func.isRequired,
  deleteAgent: PropTypes.func.isRequired,
  addAgent: PropTypes.func.isRequired,
  updateAgentValues: PropTypes.func.isRequired,
  addImageAgent: PropTypes.func.isRequired,
  deleteImageAgent: PropTypes.func.isRequired,
  project: PropTypes.object.isRequired,
  lastPayment: PropTypes.object,
  selectEnable: PropTypes.bool,
  modifyEnable: PropTypes.bool
}

export default withI18n()(Agents)
