import normalize from 'normalize-for-search'
import firebase from 'firebase/app'
import { firebase as firebaseConfig } from 'config'
import axios from 'axios'
import map from 'lodash/map'
import get from 'lodash/get'
export const TEMPLATE_WIDTH = 1920
export const TEMPLATE_HEIGHT = 1080

//http://jsfiddle.net/ovzLy5q1/

function parse_line(value, textareaElement) {
  var line = ''
  var origo = value

  while (true) {
    if (value === '') {
      return {
        line: origo,
        other: ''
      }
    }
    var char = value.substring(0, 1)
    value = value.substring(1)
    line += char
    if (char === '\n' && line.length > 1) {
      var result = line.substr(0, line.length - 1)
      return {
        line: result,
        other: origo.substr(result.length + 1)
      }
    }
    var testspan = document.createElement('span')
    var textSpan = document.createTextNode(line)
    testspan.appendChild(textSpan)
    testspan.style.position = 'absolute'
    testspan.style.top = '-9997px'
    testspan.style.whiteSpace = 'pre'
    //testspan.style.border = 'solid 1px white'
    testspan.style.padding = '4px'
    testspan.className = textareaElement.className
    testspan.style.width = 'auto'
    //testspan.style.font = '400 25px system-ui'
    //console.log(textareaElement.style.font)
    testspan.style.fontFamily = 'Open Sans,sans-serif'

    //document.body.insertBefore(testspan)
    document.body.appendChild(testspan)
    //console.log(textareaElement.offsetWidth,testspan.offsetWidth)
    if (textareaElement.offsetWidth < testspan.offsetWidth) {
      var result = line.substr(0, line.length - 1)
      if (char !== ' ' && char !== '\n') {
        var index = line.lastIndexOf(' ')
        if (index !== -1) {
          result = line.substr(0, index)
        }
      }
      testspan.remove()
      return {
        line: result,
        other: origo.substr(result.length).trim()
      }
    }
    testspan.remove()
  }
}

function parse_line_map(value, textareaElement) {
  var line = ''
  var origo = value

  while (true) {
    if (value === '') {
      return {
        line: origo,
        other: ''
      }
    }
    var char = value.substring(0, 1)
    value = value.substring(1)
    line += char
    if (char === '\n' && line.length > 1) {
      var result = line.substr(0, line.length - 1)
      return {
        line: result,
        other: origo.substr(result.length + 1)
      }
    }
    var testspan = document.createElement('span')
    var textSpan = document.createTextNode(line)
    testspan.appendChild(textSpan)
    testspan.style.position = 'absolute'
    testspan.style.top = '-9997px'
    testspan.style.whiteSpace = 'pre'
    //testspan.style.border = 'solid 1px white'
    //testspan.style.padding = '2px'
    testspan.className = textareaElement.className
    testspan.style.width = 'auto'
    //testspan.style.font = '400 25px system-ui'
    //console.log(textareaElement.style.font)
    testspan.style.fontFamily = 'Open Sans,sans-serif'

    //document.body.insertBefore(testspan)
    document.body.appendChild(testspan)

    if (textareaElement.offsetWidth < testspan.offsetWidth) {
      var result = line.substr(0, line.length - 1)
      if (char !== ' ' && char !== '\n') {
        var index = line.lastIndexOf(' ')
        if (index !== -1) {
          result = line.substr(0, index)
        }
      }
      return {
        line: result,
        other: origo.substr(result.length).trim()
      }
    }
    testspan.remove()
  }
}
export function test(textareaElement, maxLines = 5) {
  var lines = []
  var value = textareaElement.value
  var i = 0
  while (i < maxLines) {
    i++
    if (value === '') {
      lines.push('')
      continue
    }
    const result = parse_line(value, textareaElement)
    value = result.other
    lines.push(result.line.trim())
  }
  return lines
}

export function test2(textareaElement, maxLines = 5) {
  var lines = []
  var value = textareaElement.value
  var resultValue = ''
  var i = 0
  while (i < maxLines) {
    i++
    if (value === '') {
      lines.push('')
      continue
    }
    const result = parse_line(value, textareaElement)
    const len = value.length - result.other.length
    resultValue += value.substr(0, len)
    value = result.other
    lines.push(result.line.trim())
  }
  if (
    lines[maxLines - 1].length > 0 &&
    resultValue[resultValue.length - 1] === '\n'
  ) {
    resultValue = resultValue.substr(0, resultValue.length - 1)
  }
  return [lines, resultValue]
}

export function testMap(textareaElement, maxLines = 5) {
  var lines = []
  var value = textareaElement.value
  var i = 0
  while (i < maxLines) {
    i++
    if (value === '') {
      lines.push('')
      continue
    }
    const result = parse_line_map(value, textareaElement)
    value = result.other
    lines.push(result.line.trim())
  }
  return lines
}

export const resizeImage = file =>
  new Promise((resolve, reject) => {
    var c = document.createElement('canvas')
    var context = c.getContext('2d')

    var reader = new FileReader()
    reader.onload = evt => {
      var img = new Image()
      img.onerror = reject
      img.onload = () => {
        const MAX_WIDTH = TEMPLATE_WIDTH
        const MAX_HEIGHT = TEMPLATE_HEIGHT
        let width = img.width
        let height = img.height

        if (width / MAX_WIDTH > height / MAX_HEIGHT) {
          if (width > MAX_WIDTH) {
            height *= MAX_WIDTH / width
            width = MAX_WIDTH
          }
        } else {
          if (height > MAX_HEIGHT) {
            width *= MAX_HEIGHT / height
            height = MAX_HEIGHT
          }
        }

        context.canvas.height = height
        context.canvas.width = width
        context.drawImage(img, 0, 0, width, height)
        c.toBlob(blob =>
          resolve({
            file: blob,
            size: { width, height }
          })
        )
      }
      img.src = evt.target.result
    }
    reader.readAsDataURL(file)
  })

export const processingImage = file =>
  new Promise((resolve, reject) => {
    var url = URL.createObjectURL(file)
    var c = document.createElement('canvas')
    var context = c.getContext('2d')

    var reader = new FileReader()
    reader.onload = evt => {
      var img2 = new Image()
      img2.onerror = reject
      img2.onload = () => {
        if (img2.height / img2.width > TEMPLATE_HEIGHT / TEMPLATE_WIDTH) {
          var img = new Image()
          img.onload = () => {
            resolve(resizeImage(file))
          }
          img.onerror = reject
          img.src = url
        } else {
          const radius = TEMPLATE_HEIGHT / TEMPLATE_WIDTH
          const newWidth = Math.round(img2.height / radius)
          context.canvas.height = img2.height
          context.canvas.width = newWidth
          context.drawImage(img2, -(img2.width - newWidth) / 2, 0)
          c.toBlob(blob => {
            resolve(resizeImage(blob))
          })
        }
      }
      img2.src = evt.target.result
    }
    reader.readAsDataURL(file)
  })

export const isMaxRows = (element, maxLines = 5) => {
  const { value } = element
  var el = document.createElement('div')
  el.style.position = 'absolute'
  el.style.top = '-9993px'
  el.style.whiteSpace = 'pre'
  el.style.border = 'solid 1px white'
  el.style.whiteSpace = 'pre-wrap'
  el.style.padding = '2px'
  el.style.wordWrap = 'break-word'
  el.className = element.className
  el.innerHTML = '.'
  el.innerHTML = value + (value[value.length - 1] === '\n' ? '.' : '')
  document.body.appendChild(el)
  let result = false

  if (el.offsetHeight - 4 < 28 * 0.9 * (maxLines + 1)) {
    result = true
  }
  el.remove()
  return result
}

export const isMaxRowsMap = (element, maxLines) => {
  const { value } = element
  var el = document.createElement('div')
  el.style.position = 'absolute'
  el.style.top = '-9993px'
  el.style.whiteSpace = 'pre'
  el.style.whiteSpace = 'pre-wrap'
  el.style.wordWrap = 'break-word'
  el.style.width = '300px'
  el.style.fontSize = '16px'
  el.style.lineHeight = '18px'
  //el.className = element.className
  el.innerHTML = '.'
  el.innerHTML = value + (value[value.length - 1] === '\n' ? '.' : '')
  document.body.appendChild(el)
  let result = false

  if (el.offsetHeight - 4 < 18 * 0.9 * (maxLines + 1)) {
    result = true
  }
  el.remove()
  return result
}

const move = function(array, index, delta) {
  const nextArray = [...array]
  var newIndex = index + delta
  if (newIndex < 0 || newIndex == nextArray.length) return //Already at the top or bottom.
  var indexes = [index, newIndex].sort() //Sort the indixes
  nextArray.splice(indexes[0], 2, nextArray[indexes[1]], nextArray[indexes[0]]) //Replace from lowest index, two elements, reverting the order
  return nextArray
}

export const moveUp = function(array, index) {
  return move(array, index, -1)
}

export const moveDown = function(array, index) {
  return move(array, index, 1)
}

export const rotateImage = file => new Promise((resolve, reject) => {})

export const filterItem = (items, search) => {
  if (!search) {
    return items
  }
  const normSearch = normalize(search)
  return items.filter(i => normalize(i.name).indexOf(normSearch) !== -1)
}

var calculateContentHeight = function(ta, scanAmount) {
  var origHeight = ta.style.height,
    height = ta.offsetHeight,
    scrollHeight = ta.scrollHeight,
    overflow = ta.style.overflow
  /// only bother if the ta is bigger than content
  if (height >= scrollHeight) {
    /// check that our browser supports changing dimension
    /// calculations mid-way through a function call...
    ta.style.height = height + scanAmount + 'px'
    /// because the scrollbar can cause calculation problems
    ta.style.overflow = 'hidden'
    /// by checking that scrollHeight has updated
    if (scrollHeight < ta.scrollHeight) {
      /// now try and scan the ta's height downwards
      /// until scrollHeight becomes larger than height
      while (ta.offsetHeight >= ta.scrollHeight) {
        ta.style.height = (height -= scanAmount) + 'px'
      }
      /// be more specific to get the exact height
      while (ta.offsetHeight < ta.scrollHeight) {
        ta.style.height = height++ + 'px'
      }
      /// reset the ta back to it's original height
      ta.style.height = origHeight
      /// put the overflow back
      ta.style.overflow = overflow
      return height
    }
  } else {
    return scrollHeight
  }
}

var calculateHeight = function(ta) {
  const style = window.getComputedStyle
    ? window.getComputedStyle(ta)
    : ta.currentStyle

  const paddingTop = parseInt(
    style.paddingTop.substr(0, style.paddingTop.length - 2)
  )
  const paddingBottom = parseInt(
    style.paddingBottom.substr(0, style.paddingBottom.length - 2)
  )

  // This will get the line-height only if it is set in the css,
  // otherwise it's "normal"
  const taLineHeight = parseInt(style.lineHeight, 10)
  // Get the scroll height of the textarea
  const taHeight =
    calculateContentHeight(ta, taLineHeight) - paddingTop - paddingBottom
  // calculate the number of lines
  const numberOfLines = Math.floor(taHeight / taLineHeight)
  //console.log(taHeight, taLineHeight)

  // console.log(paddingBottom + paddingTop)

  return numberOfLines
}

export const getNumberOfLine = (el, values) => {
  const style = window.getComputedStyle
    ? window.getComputedStyle(el)
    : el.currentStyle
  const el2 = document.createElement('textarea')
  el2.style.cssText = style.cssText
  document.body.appendChild(el2)
  el2.value = values
  //el2.style.backgroundColor = 'white'
  //el2.style.top = '200px'
  //el2.style.left = '200px'
  const res = calculateHeight(el2)
  el2.remove()
  return res
}

export const maxLines = (el, values, maxLines = 3) => {
  let nextValues = values
  for (let i = 0; i < values.length; i++) {
    if (getNumberOfLine(el, nextValues) <= maxLines) {
      break
    } else {
      nextValues = nextValues.slice(0, -1)
    }
  }
  return nextValues
}

export const setPathInImages = (images, groupId, projectId) => {
  try {
    return images.map(i => {
      if (i.img) {
        const minPath = `https://firebasestorage.googleapis.com/v0/b/editor-53bde.appspot.com/o/groups%2F${groupId}%2Fproperties%2F${projectId}%2Fmin_${
          i.img.id
        }.jpg?alt=media&editDate=${i.img.editDate}`
        const path = `https://firebasestorage.googleapis.com/v0/b/editor-53bde.appspot.com/o/groups%2F${groupId}%2Fproperties%2F${projectId}%2F${
          i.img.id
        }.jpg?alt=media&editDate=${i.img.editDate}`

        return {
          ...i,
          img: {
            ...i.img,
            minPath: i.img.minPath ? minPath : null,
            path: i.img.path ? path : null
          }
        }
      }
      return i
    })
  } catch (e) {
    console.log('hell', e, groupId, projectId)
  }
}

export const addGroupFile = (groupId, path, file) =>
  firebase
    .auth()
    .currentUser.getIdToken()
    .then(token => {
      const formData = new FormData()
      formData.append('file', file)
      const productionUrl = `https://us-central1-${
        firebaseConfig.projectId
      }.cloudfunctions.net`
      const devUrl = `http://localhost:5000/${
        firebaseConfig.projectId
      }/us-central1`

      return axios.post(
        `${productionUrl}/video/upload?token=${token}&groupId=${groupId}&path=${path}`,
        formData
      )
    })

const getCurrentAgent = (project, agentItems) => {
  return agentItems.find(i => i.key === project.agentKey)
}

export const propertiesToGroup = (items, agentItems) =>
  items
    .filter(i => !i.parentId)
    .map(i => ({
      ...i,
      newsletter: items.find(k => k.parentId === i.key && k.newsletter),
      agent: getCurrentAgent(i, agentItems),
      subItemsFinal: items
        .filter(k => !k.preview && k.parentId === i.key && !k.newsletter)
        .sort((a, b) => a.creationTime - b.creationTime)
        .map((a, index) => ({ ...a, index })),
      subItemsTest: items
        .filter(k => k.preview && k.parentId === i.key && !k.newsletter)
        .sort((a, b) => a.creationTime - b.creationTime)
        .map((a, index) => ({ ...a, index }))
    }))


export const objectToArray = values => {
  return map(values, (value, key) => ({
    ...value,
    key
  }))
}
