import React from 'react'
import PropTypes from 'prop-types'
import HomeLayout from 'layouts/HomeLayout'
import { Redirect, Route, Switch } from 'react-router-dom'
import { PROPERTIES_LIST_PATH } from 'constants/paths'

import Properties from '../../routes/Properties'
import Newsletters from '../../routes/Newsletters'
import Help from '../../routes/Help'

const renderChildren = (routes, match, parentProps) =>
  routes.map(route => (
    <Route
      key={`${match.url}-${route.path}`}
      path={`${match.url}/${route.path}`}
      render={props => <route.component {...parentProps} {...props} />}
    />
  ))

export const Home = ({ classes, match }) => {
  return (
    <HomeLayout>
      <div className={classes.root}>
        <Switch>
          {renderChildren([Properties], match, {})}
          {renderChildren([Newsletters], match, {})}
          {renderChildren([Help], match, {})}
          <Redirect
            from={match.url}
            to={`${match.url}/${PROPERTIES_LIST_PATH}`}
          />
        </Switch>
      </div>
    </HomeLayout>
  )
}

Home.proptypes = {
  classes: PropTypes.object.isRequired, // from enhancer (withStyles),
  agentItems: PropTypes.array.isRequired,
  goToPropertyProject: PropTypes.func.isRequired,
  downloadProject: PropTypes.func.isRequired,
  cloneProject: PropTypes.func.isRequired,
  addProject: PropTypes.func.isRequired,
  t: PropTypes.func.isRequired
}

export default Home
