import { compose } from 'recompose'
import { withStyles } from '@material-ui/core/styles'
import styles from './Subscription.styles'
import { withI18n } from 'react-i18next'
import connect from 'react-redux/es/connect/connect'

export default compose(
  connect(({ firebase: { auth, profile } }) => ({
    auth,
    profile
  })),
  withStyles(styles),
  withI18n()
)
