import {
  SYSTEM_OPEN_SELECT_GROUP,
  SYSTEM_CLOSE_SELECT_GROUP
} from './actionTypes'

const system = (state = { showSelectGroup: false }, action) => {
  switch (action.type) {
    case SYSTEM_OPEN_SELECT_GROUP:
      return { ...state, showSelectGroup: true }
    case SYSTEM_CLOSE_SELECT_GROUP:
      return { ...state, showSelectGroup: false }
    default:
      return state
  }
}

export default system
