import { compose, withHandlers } from 'recompose'
import { withStore } from '../../../utils/components'
import * as actions from '../actions'

const withLoader = compose(
  withStore,
  withHandlers({
    setLoader: ({ store }) => progress => actions.setLoader(progress)(store.dispatch),
    clearLoader: ({ store }) => () => actions.clearLoader()(store.dispatch)
  })
)

export default withLoader
