import React from 'react'
import PropTypes from 'prop-types'
import { withStyles } from '@material-ui/core'
import Card from '@material-ui/core/Card'
import LeftIcon from '@material-ui/icons/ChevronLeft'
import RightIcon from '@material-ui/icons/ChevronRight'
import Fab from '@material-ui/core/Fab'

const styles = theme => ({
  card: {
    maxWidth: 170,
    position: 'relative',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    '& img': {
      display: 'block',
      maxWidth: '100%'
    }
  },
  leftIcon: {
    position: 'absolute',
    left: theme.spacing.unit * 2
  },
  rightIcon: {
    position: 'absolute',
    right: theme.spacing.unit * 2
  }
})

const SlideImagePicker = ({ classes, onChange, items, value }) => {
  const currentImage = items.find(i => i.id === value)
  const currentImageIndex = items.findIndex(i => i.id === value)
  const handleClickNext = () => {
    onChange(
      currentImageIndex === items.length - 1
        ? items[0].id
        : items[currentImageIndex + 1].id
    )
  }

  const handleClickPrev = () => {
    onChange(
      currentImageIndex === 0
        ? items[items.length - 1].id
        : items[currentImageIndex - 1].id
    )
  }

  if (!items.length || !currentImage) {
    return null
  }

  return (
    <Card className={classes.card} key={currentImageIndex}>
      <img src={currentImage.imgPath} />
      <Fab size="small" className={classes.leftIcon} onClick={handleClickNext}>
        <LeftIcon />
      </Fab>
      <Fab size="small" className={classes.rightIcon} onClick={handleClickPrev}>
        <RightIcon />
      </Fab>
    </Card>
  )
}

SlideImagePicker.propTypes = {
  classes: PropTypes.object.isRequired,
  value: PropTypes.string,
  onChange: PropTypes.func.isRequired,
  items: PropTypes.arrayOf({
    id: PropTypes.string.isRequired,
    imgPath: PropTypes.string.isRequired
  }).isRequired
}
export default withStyles(styles)(SlideImagePicker)
