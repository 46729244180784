import { withStyles } from '@material-ui/core/styles'
import styles from './PropertyPage.styles'
import { branch, compose, renderComponent, withHandlers } from 'recompose'
import { firebaseConnect } from 'react-redux-firebase'
import { connect } from 'react-redux'
import { spinnerWhileLoading } from 'utils/components'
import get from 'lodash/get'
import { withRouter } from 'react-router-dom'
import { CLIENT_PATH, URL_PREFIX } from 'constants/paths'
import withNotifications from '../../../../modules/notification/components/withNotifications'
import withLoader from '../../../../modules/loader/components/withLoader'
import ProjectNotFound from '../../../../components/ProjectNotFound'
import { setPathInImages } from '../../../../utils/utils'
import { groupColors } from '../../../../defaultValues'

export default compose(
  withRouter,
  connect(({ firebase: { profile } }, { match }) => ({
    profile,
    groupId: profile && profile.actualGroup,
    projectId: match.params.projectId
  })),
  spinnerWhileLoading(['profile']),
  firebaseConnect(({ groupId, match, projectId }) => {
    return [
      {
        path: `groups/${groupId}/properties/${projectId}`
      },
      {
        path: `groups/${groupId}/colors`
      }
    ]
  }),
  connect(({ firebase: { data } }, { groupId, projectId }) => {
    const project = get(data, `groups.${groupId}.properties.${projectId}`)
    const colors = Object.assign(
      {},
      groupColors,
      get(data, `groups.${groupId}.colors`) || groupColors
    )
    if (!project) {
      return {
        project
      }
    }

    return {
      colors,
      project: {
        ...project,
        images: setPathInImages(project.images, groupId, projectId)
      }
    }
  }),
  spinnerWhileLoading(['project']),
  branch(props => !props.project, renderComponent(ProjectNotFound)),
  withNotifications,
  withLoader,
  withHandlers({
    updateProjectName: props => name => {
      const { firebase, showError, groupId, projectId } = props
      return firebase
        .set(`groups/${groupId}/properties/${projectId}/name`, name)
        .catch(err => {
          console.error('Error:', err) // eslint-disable-line no-console
          showError(err.message || 'Could not save project name')
          return Promise.reject(err)
        })
    },
    goToHome: ({ history }) => () => {
      history.push(`${CLIENT_PATH}`)
    }
  }),
  withStyles(styles)
)
