/**
 * NOTE: This file is ignored from git tracking. In a CI environment it is
 * generated by firebase-ci based on config in .firebaserc (see .gitlab-ci.yaml).
 * This is done so that environment specific settings can be applied.
 */

export const env = 'local'

// Config for firebase
export const firebase = {
  apiKey: "AIzaSyBi0tnHLjvBX5hfws-NHkc2WjPyZxhLVBs",
  authDomain: "maxo.io",
  databaseURL: "https://editor-53bde.firebaseio.com",
  projectId: "editor-53bde",
  storageBucket: "editor-53bde.appspot.com",
}

// Config to override default reduxFirebase config in store/createStore
// which is not environment specific.
// For more details, visit http://react-redux-firebase.com/docs/api/enhancer.html
export const reduxFirebase = {
  enableLogging: false, // enable/disable Firebase Database Logging
}

export const SERVER_PRODUCTION = `https://us-central1-${firebase.projectId}.cloudfunctions.net`

export default {
  env,
  firebase,
  reduxFirebase
}
