import React, { Component, useRef, useState } from 'react'
import PropTypes from 'prop-types'
import ImageEditorWrap from 'components/ImageEditor/ImageEditorWrap'

const ImagePage = ({
  classes,
  project,
  image,
  updateImageValue,
  setImage,
  agent,
  agentCreated,
  match,
  onlyGroupColors,
  colors
}) => {
  const fileRef = useRef()
  const [fileId, setFileId] = useState(0)

  const handleChangeFile = e => {
    let file = e.target.files[0]
    if (file) {
      setImage(file, match.params.imageId)
      setFileId(fileId + 1)
    }
  }

  return (
    <React.Fragment>
      <input
        accept="image/x-png,image/gif,image/jpeg,image/png,image/bmp"
        type="file"
        style={{ display: 'none' }}
        onChange={handleChangeFile}
        ref={fileRef}
        key={fileId}
      />
      <ImageEditorWrap
        key={image.img.editDate}
        enableZoom
        onlyGroupColors={onlyGroupColors}
        colors={colors}
        agent={agent && agent.values}
        showAgent={!!(!agentCreated || agent)}
        imageSrc={image.img.path}
        imageWidth={image.img.width}
        imageHeight={image.img.height}
        values={image}
        onChangeImage={() => {
          fileRef.current.click()
        }}
        design={project.settings.design}
        onChange={item => {
          updateImageValue(item)
        }}
      />
    </React.Fragment>
  )
}

ImagePage.propTypes = {
  classes: PropTypes.object.isRequired,
  text: PropTypes.object.isRequired,
  updateImageValue: PropTypes.func.isRequired,
  setImage: PropTypes.func.isRequired,
  agent: PropTypes.object,
  agentCreated: PropTypes.bool.isRequired
}

export default ImagePage
