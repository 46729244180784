import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { withStyles } from '@material-ui/core/styles/index'
import cn from 'classnames'
import ReactColorPicker from 'react-color'

const styles = theme => ({
  wrap: {
    height: 30,
    border: '2px solid rgb(53, 53, 53)',
    display: 'inline-block',
    boxSizing: 'content-box',
    cursor: 'pointer'
  },
  active: {
    borderColor: theme.palette.primary.main
  },
  item: {
    width: 30,
    height: 30,
    display: 'inline-block',
    position: 'relative'
  },
  colorPicker: {
    position: 'absolute',
    left: 0,
    top: 30,
    zIndex: 1
  },
  pickerBg: {
    position: 'fixed',
    top: 0,
    left: 0,
    width: '100%',
    height: '100%',
    cursor: 'pointer',
    zIndex: 1
  },
  margin: {
    marginRight: 30
  }
})

class ColorsPalette extends Component {
  static propTypes = {
    classes: PropTypes.object.isRequired,
    colors: PropTypes.object.isRequired,
    onChange: PropTypes.object.isRequired,
    onClick: PropTypes.object.isRequired,
    active: PropTypes.bool,
    numberOfColors: PropTypes.number
  }

  static defaultProps = {
    numberOfColors: 4
  }

  state = {
    showPicker: null
  }

  handleClickItem = (e, name) => {
    if (this.props.onChange) {
      this.setState({ showPicker: name })
    }
  }

  handleClick = e => {
    if (this.props.onClick) {
      this.props.onClick()
    }
  }

  renderItem(name) {
    const { classes, colors } = this.props
    return (
      <div
        className={classes.item}
        onClick={e => this.handleClickItem(e, name)}
        style={{ backgroundColor: colors[name] }}>
        {this.state.showPicker === name && (
          <div className={classes.colorPicker}>
            <div
              className={classes.pickerBg}
              onClick={e => {
                e.stopPropagation()
                this.setState({ showPicker: null })
              }}
            />
            <div style={{ zIndex: 2, position: 'relative' }}>
              <ReactColorPicker
                disableAlpha
                color={colors[name]}
                onChange={color => {
                  this.props.onChange({ ...colors, [name]: color.hex })
                }}
              />
            </div>
          </div>
        )}
      </div>
    )
  }

  render() {
    const { classes, active } = this.props
    return (
      <div
        className={cn(
          classes.wrap,
          active && classes.active,
          this.props.numberOfColors === 3 && classes.margin
        )}
        onClick={this.handleClick}>
        {Array.from(Array(this.props.numberOfColors).keys()).map(id =>
          this.renderItem(`color${id}`)
        )}
      </div>
    )
  }
}

export default withStyles(styles)(ColorsPalette)
