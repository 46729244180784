export default theme => ({
  root: {
    paddingLeft: theme.spacing.unit * 2,
    width: '100%',
    height: '100%'
  },
  fields: {
    width: '60%'
  }
})
