import React, { Component } from 'react'
import PropTypes from 'prop-types'
import BubbleEditor from './BubbleEditor'
import ToolPanel from './ToolPanel'
import Grid from '@material-ui/core/Grid'
import AutosizeInput from 'react-input-autosize'
import { withStyles } from '@material-ui/core/styles'
import { PALETTE_ITEMS_PLACE } from 'defaultValues'
import { URL_PREFIX } from '../../constants/paths'
import { withI18n } from 'react-i18next'
import Avatar from './avatar.png'
import ImageEditorWrap from './ImageEditorWrap'

const styles = theme => ({
  wrap: {
    //border: solid 1px black;
    position: 'relative',
    overflow: 'hidden'
  },
  scale: {
    position: 'absolute',
    border: '3px dashed rgba(46, 226, 185, 1)',
    boxSizing: 'border-box'
  },
  cutImage: {
    position: 'absolute',
    width: '100%',
    background: `repeating-linear-gradient(
      45deg,
      rgba(0, 0, 0, 0.5),
      rgba(0, 0, 0, 0.5) 10px,
      rgba(0, 0, 0, 0.8) 10px,
      rgba(0, 0, 0, 0.8) 20px
      )`,
    zIndex: 300,
    pointerEvents: 'none'
  },

  usageImageStyle: {
    borderTop: 'solid 2px red',
    borderBottom: 'solid 3px red',
    position: 'absolute'
  },
  offset: {
    position: 'absolute',
    //border: '3px dashed rgba(46, 226, 185, 1)',
    boxSizing: 'content-box'
    //marginLeft: -3,
    //marginTop: -3
  },
  imageHeader: {
    position: 'absolute',
    top: 0,
    width: '100%',
    borderBottom: '1px solid rgba(255,255,255,0.5)',
    boxSizing: 'content-box',
    overflow: 'hidden'
  },
  imageFooter: {
    position: 'absolute',
    bottom: 0,
    width: '100%',
    boxSizing: 'content-box',
    borderTop: '1px solid white',
    overflow: 'hidden'
  },

  imageBlur: {
    position: 'absolute',
    filter: 'blur(3px)'
  },
  headerLeftText: {
    position: 'absolute',
    color: 'white'
  },
  roomTextsWrap: {
    position: 'absolute',
    whisteSpace: 'nowrap',
    transform: 'skewX(-10deg)',
    zIndex: 1
  },
  roomName: {
    border: 'none',
    color: 'white',
    backgroundColor: '#313131',
    display: 'inline-block',
    position: 'relative',
    zIndex: 1,
  },
  roomSize: {
    border: 'none',
    color: 'black',
    backgroundColor: 'white',
    display: 'inline-block',
    position: 'relative',
    '&::placeholder': {
      opacity: 1
    }
  },
  avatar: {
    position: 'absolute',
    boxShadow: `5px 5px 6px -4px rgba(0,0,0,0.75)`
  },
  avatarText: {
    position: 'absolute',
    backgroundColor: 'rgba(255,255,255,0.75)',
    color: 'black',
    whiteSpace: 'nowrap'
  },
  toolPanelWrap: {
    display: 'flex'
  }
})

const USAGE_ZOOM = 1.3
const TEMPLATE_WIDTH = 1920
const TEMPLATE_HEIGHT = 1080
const RATIO = TEMPLATE_WIDTH / TEMPLATE_HEIGHT
const HEADER_FOOTER_HEIGHT = 100
const HEADER_FOOTER_OFFSET = 30
const HEADER_FOOTER_FONT_SIZE = 40
const ROOM_RIGHT_POS = 100
const ROOM_BOTTOM_POS = 50
const ROOM_NAME_FONT_SIZE = 72
const ROOM_NAME_VERTICAL_MARGIN = 25
const ROOM_NAME_HORIZONTAL_MARGIN = 33
const ROOM_SIZE_FONT_SIZE = 60
const ROOM_SIZE_VERTICAL_MARGIN = 25
const ROOM_SIZE_HORIZONTAL_MARGIN = 30
const ROOM_BORDER_RADIUS = 12
const ROOM_SIZE_OFFSET_BOTTOM = 30
const ROOM_SIZE_OFFSET_LEFT = 10
const AVATAR_HEIGHT = 200
const AVATAR_WIDTH = 150
const AVATAR_OFFSET = 50
const AVATAR_TEXT_SIZE = 36
const AVATAR_LINE_HEIGHT = 78
const AVATAR_TEXT_MARGIN = 24
const AVATAR_TEXT_HORIZONTAL_PADDING = 9

const recalculateValues = (values, num) => ({
  ...values,
  callOut1: {
    ...values.callOut1,
    pointer: {
      ...values.callOut1.pointer,
      x: values.callOut1.pointer.x * num,
      y: values.callOut1.pointer.y * num
    },
    bubble: {
      ...values.callOut1.bubble,
      x: values.callOut1.bubble.x * num,
      y: values.callOut1.bubble.y * num
    }
  },
  callOut2: {
    ...values.callOut2,
    pointer: {
      ...values.callOut2.pointer,
      x: values.callOut2.pointer.x * num,
      y: values.callOut2.pointer.y * num
    },
    bubble: {
      ...values.callOut2.bubble,
      x: values.callOut2.bubble.x * num,
      y: values.callOut2.bubble.y * num
    }
  },
  zoom: values.zoom
    ? {
        ...values.zoom,
        pointer: {
          ...values.zoom.pointer,
          x: values.zoom.pointer.x * num,
          y: values.zoom.pointer.y * num
        },
        bubble: {
          ...values.zoom.bubble,
          x: values.zoom.bubble.x * num,
          y: values.zoom.bubble.y * num
        }
      }
    : undefined
})

const recalculateOffsetValues = (values, callback) => ({
  ...values,
  callOut1: {
    ...values.callOut1,
    pointer: {
      ...values.callOut1.pointer,
      y: callback(values.callOut1.pointer.y)
    }
  },
  callOut2: {
    ...values.callOut2,
    pointer: {
      ...values.callOut2.pointer,
      y: callback(values.callOut2.pointer.y)
    }
  },
  zoom: values.zoom
    ? {
        ...values.zoom,
        pointer: {
          ...values.zoom.pointer,
          y: callback(values.zoom.pointer.y)
        }
      }
    : undefined
})

class   ImageEditor extends Component {
  static propTypes = {
    imageSrc: PropTypes.string.isRequired,
    imageWidth: PropTypes.number.isRequired,
    imageHeight: PropTypes.number.isRequired,
    onDelete: PropTypes.func.isRequired,
    onChange: PropTypes.func.isRequired,
    onChangeImage: PropTypes.func.isRequired,
    values: PropTypes.object.isRequired,
    classes: PropTypes.object.isRequired,
    texts: PropTypes.object,
    roomTexts: PropTypes.object,
    agent: PropTypes.object,
    showAgent: PropTypes.bool,
    enableZoom: PropTypes.bool,
    design: PropTypes.object.isRequired,
    onlyGroupColors: PropTypes.bool.isRequired,
    colors: PropTypes.object.isRequired
  }

  constructor(props) {
    super(props)
    this.state = {
      offsetTop: 0.5,
      values: {
        callOut1: {
          enable: true,
          pointer: {
            x: 10,
            y: 10
          },
          bubble: {
            x: 100,
            y: 40
          }
        },
        callOut2: {
          enable: true,
          pointer: {
            x: 50,
            y: 50
          },
          bubble: {
            x: 30,
            y: 200
          }
        },
        infoText: {
          enable: true,
          position: 'top'
        }
      }
    }
  }

  getImageSize() {
    const {
      imageWidth,
      imageHeight,
      containerWidth,
      containerHeight
    } = this.props
    const maxRatio = containerWidth / containerHeight
    const imageRatio = imageWidth / imageHeight
    if (maxRatio < imageRatio) {
      return {
        width: containerWidth,
        height: containerWidth / imageRatio
      }
    } else {
      return {
        width: containerHeight * imageRatio,
        height: containerHeight
      }
    }
  }

  renderAvatar(WIDTH, offsetTop) {
    const { classes, agent = { name: this.props.t('agent name') } } = this.props
    const scale = WIDTH / TEMPLATE_WIDTH
    return (
      <div
        className={classes.avatar}
        style={{
          top: AVATAR_OFFSET * scale + offsetTop,
          left: AVATAR_OFFSET * scale
        }}>
        <div
          style={{
            height: AVATAR_HEIGHT * scale,
            width: AVATAR_WIDTH * scale,
            background: `url(${agent.image || Avatar}) center`,
            backgroundSize: 'cover'
          }}
        />
        <div
          className={classes.avatarText}
          style={{
            fontSize: AVATAR_TEXT_SIZE * scale,
            lineHeight: `${AVATAR_LINE_HEIGHT * scale}px`,
            left: (AVATAR_WIDTH - AVATAR_TEXT_MARGIN) * scale,
            top: AVATAR_TEXT_MARGIN * scale,
            padding: `0 ${AVATAR_TEXT_HORIZONTAL_PADDING * scale}px`
          }}>
          {agent.name}
        </div>
      </div>
    )
  }

  renderRoomTexts(WIDTH, imageStyle, offsetTop, offsetBottom) {
    const scale = WIDTH / TEMPLATE_WIDTH
    const {
      classes,
      values: { roomTexts },
      design: { place },
      onlyGroupColors,
      colors
    } = this.props

    let selectedPlace

    if (place.type === 'group' || onlyGroupColors) {
      selectedPlace = colors.imageDescriptionColors
    } else if (place.type === 'custom') {
      selectedPlace = place.custom
    } else {
      selectedPlace = PALETTE_ITEMS_PLACE[place.activeId].colors
    }

    const wrapStyle = {
      bottom: offsetBottom + scale * ROOM_BOTTOM_POS,
      right: scale * ROOM_RIGHT_POS,
      color: 'black'
    }

    const nameStyle = {
      fontSize: ROOM_NAME_FONT_SIZE * scale,
      lineHeight: `${ROOM_NAME_FONT_SIZE * scale}px`,
      padding: `${ROOM_NAME_VERTICAL_MARGIN *
        scale}px ${ROOM_NAME_HORIZONTAL_MARGIN * scale}px`,
      borderRadius: ROOM_BORDER_RADIUS * scale,
      color: selectedPlace.color0,
      backgroundColor: selectedPlace.color1
    }

    const sizeStyle = {
      fontSize: ROOM_SIZE_FONT_SIZE * scale,
      lineHeight: `${ROOM_SIZE_FONT_SIZE * scale}px`,
      padding: `${ROOM_SIZE_VERTICAL_MARGIN *
        scale}px ${ROOM_SIZE_HORIZONTAL_MARGIN * scale}px`,
      borderRadius: ROOM_BORDER_RADIUS * scale,
      bottom: -ROOM_SIZE_OFFSET_BOTTOM * scale,
      left: -ROOM_SIZE_OFFSET_LEFT * scale,
      color: selectedPlace.color2,
      backgroundColor: selectedPlace.color3
    }

    return (
      <div style={wrapStyle} className={classes.roomTextsWrap}>
        <style
          dangerouslySetInnerHTML={{
            __html: `
         .roomTextsWRap::-webkit-input-placeholder { /* Chrome/Opera/Safari */
          color: ${selectedPlace.color0};
        }
          .roomTextsWRap::-moz-placeholder { /* Firefox 19+ */
          color: ${selectedPlace.color0};
        }
          .roomTextsWRap:-ms-input-placeholder { /* IE 10+ */
          color: ${selectedPlace.color0};
        }
          .roomTextsWRap:-moz-placeholder { /* Firefox 18- */
          color: ${selectedPlace.color0};
        }
        .roomSizeWRap::-webkit-input-placeholder { /* Chrome/Opera/Safari */
          color: ${selectedPlace.color2};
        }
          .roomSizeWRap::-moz-placeholder { /* Firefox 19+ */
          color: ${selectedPlace.color2};
        }
          .roomSizeWRap:-ms-input-placeholder { /* IE 10+ */
          color: ${selectedPlace.color2};
        }
          .roomSizeWRap:-moz-placeholder { /* Firefox 18- */
          color: ${selectedPlace.color2};
        }
        `
          }}
        />
        <AutosizeInput
          value={roomTexts.name}
          onChange={e =>
            this.props.onChange({
              ...this.props.values,
              roomTexts: {
                ...roomTexts,
                name: e.target.value
              }
            })
          }
          placeholder={this.props.t('enter the name')}
          inputClassName={classes.roomName + ' roomTextsWRap'}
          type="text"
          inputStyle={nameStyle}
        />
        {this.props.values.showRoomSize && (
          <div className={classes.roomSize} style={sizeStyle}>
            <AutosizeInput
              value={roomTexts.size}
              onChange={e =>
                this.props.onChange({
                  ...this.props.values,
                  roomTexts: {
                    ...roomTexts,
                    size: e.target.value
                  }
                })
              }
              placeholder={this.props.t('enter the size')}
              type="text"
              inputStyle={{
                fontSize: ROOM_SIZE_FONT_SIZE * scale,
                lineHeight: `${ROOM_SIZE_FONT_SIZE * scale}px`,
                border: 'none',
                backgroundColor: 'transparent',
                color: selectedPlace.color2,
              }}
              inputClassName={'roomSizeWRap'}
            />
          </div>
        )}
      </div>
    )
  }

  renderFooterHeaderTexts(WIDTH, imageStyle, offsetTop, offsetBottom) {
    const currentWidth = WIDTH / TEMPLATE_WIDTH

    const headerFooterStyle = {
      height: HEADER_FOOTER_HEIGHT * currentWidth
    }

    const headerFooterTextStyle = {
      fontSize: HEADER_FOOTER_FONT_SIZE * currentWidth,
      lineHeight: `${HEADER_FOOTER_HEIGHT * currentWidth}px`
    }

    const { texts, classes } = this.props
    return (
      <React.Fragment>
        <div
          style={{ ...headerFooterStyle, top: offsetTop }}
          className={classes.imageHeader}>
          <img
            src={this.props.imageSrc}
            style={{
              ...imageStyle,
              top: -offsetTop
            }}
            className={classes.imageBlur}
          />
          <span
            style={{
              ...headerFooterTextStyle,
              left: HEADER_FOOTER_OFFSET * currentWidth
            }}
            className={classes.headerLeftText}>
            {texts.characteristic1}, {texts.characteristic2}
          </span>
          <span
            style={{
              ...headerFooterTextStyle,
              right: HEADER_FOOTER_OFFSET * currentWidth
            }}
            className={classes.headerLeftText}>
            {texts.price} Kč
          </span>
        </div>
        <div
          style={{ ...headerFooterStyle, bottom: offsetBottom }}
          className={classes.imageFooter}>
          <img
            src={this.props.imageSrc}
            style={{
              ...imageStyle,
              bottom: -offsetBottom
            }}
            className={classes.imageBlur}
          />
          <span
            style={{
              ...headerFooterTextStyle,
              left: HEADER_FOOTER_OFFSET * currentWidth
            }}
            className={classes.headerLeftText}>
            {texts.address1}, {texts.address2}
          </span>
          <span
            style={{
              ...headerFooterTextStyle,
              right: HEADER_FOOTER_OFFSET * currentWidth
            }}
            className={classes.headerLeftText}>
            {texts.code}
          </span>
        </div>
      </React.Fragment>
    )
  }

  render() {
    const { classes } = this.props
    const imageStyle = this.getImageSize()
    const maxOffset =
      (imageStyle.width / this.props.imageWidth) * this.props.imageHeight -
      imageStyle.width / RATIO
    const WIDTH = imageStyle.width
    const offsetTop = maxOffset * this.props.values.offsetTop
    const offsetBottom = imageStyle.height - WIDTH / RATIO - offsetTop

    const cutImageTopStyle = {
      height: offsetTop,
      top: 0
    }

    const cutImageBottomStyle = {
      height: offsetBottom,
      bottom: 0
    }

    const cutImageStyle = {
      width: WIDTH,
      height: WIDTH / RATIO,
      top: offsetTop
    }

    const scale = cutImageStyle.width / TEMPLATE_WIDTH

    return (
      <Grid container>
        <Grid item>
          <div style={imageStyle} className={classes.wrap}>
            {this.props.texts &&
              this.renderFooterHeaderTexts(
                WIDTH,
                imageStyle,
                offsetTop,
                offsetBottom
              )}
            <img src={this.props.imageSrc} style={imageStyle} alt="" />
            <div style={cutImageTopStyle} className={classes.cutImage} />
            <div style={cutImageBottomStyle} className={classes.cutImage} />
            <div className={classes.offset} style={cutImageStyle}>
              <BubbleEditor
                onlyGroupColors={this.props.onlyGroupColors}
                colors={this.props.colors}
                design={this.props.design.bubble}
                imageSrc={this.props.imageSrc}
                offset={cutImageStyle}
                key={WIDTH}
                values={recalculateValues(
                  this.props.values,
                  WIDTH / TEMPLATE_WIDTH
                )}
                onChange={values => {
                  const recalculated = recalculateValues(
                    values,
                    TEMPLATE_WIDTH / WIDTH
                  )
                  this.props.onChange(recalculated)
                }}
                imageSize={imageStyle}
                scale={cutImageStyle.width / TEMPLATE_WIDTH}
                width={cutImageStyle.width}
                height={cutImageStyle.height}
              />
            </div>
            {this.props.values.roomTexts &&
              this.renderRoomTexts(WIDTH, imageStyle, offsetTop, offsetBottom)}
            {this.props.showAgent && this.renderAvatar(WIDTH, offsetTop)}
          </div>
        </Grid>
        <Grid item className={classes.toolPanelWrap}>
          <ToolPanel
            showRoomSize={this.props.values.showRoomSize}
            onChangeRoomSize={showRoomSize =>
              this.props.onChange({ ...this.props.values, showRoomSize })
            }
            enableZoom={this.props.enableZoom}
            showRoomSizeToggle={!!this.props.values.roomTexts}
            offset={this.props.values.offsetTop * 100}
            onChangeOffset={offsetTop => {
              let offsetDiff = this.props.values.offsetTop - offsetTop / 100
              offsetDiff *= (maxOffset * TEMPLATE_WIDTH) / WIDTH
              const nextValues = recalculateOffsetValues(
                this.props.values,
                val => {
                  const newValue = val + offsetDiff
                  const top =
                    (TEMPLATE_HEIGHT - TEMPLATE_HEIGHT / USAGE_ZOOM) / 2
                  const bottom = TEMPLATE_HEIGHT - top
                  if (newValue < top) {
                    return top
                  } else if (newValue > bottom) {
                    return bottom
                  } else {
                    return newValue
                  }
                }
              )
              this.props.onChange({ ...nextValues, offsetTop: offsetTop / 100 })
            }}
            values={this.props.values}
            onChange={values => this.props.onChange(values)}
            onChangeImage={this.props.onChangeImage}
            showOffset={
              Math.abs(
                this.props.imageWidth / this.props.imageHeight -
                  TEMPLATE_WIDTH / TEMPLATE_HEIGHT
              ) > 0.002
            }
          />
        </Grid>
      </Grid>
    )
  }
}

export default withI18n()(withStyles(styles)(ImageEditor))
