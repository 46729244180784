import React from 'react'
import PropTypes from 'prop-types'
import { Field } from 'redux-form'
import Button from '@material-ui/core/Button'
import Grid from '@material-ui/core/Grid'
import { renderTextField } from 'utils/reduxFormComponents'

const VoucherForm = ({
  pristine,
  submitting,
  handleSubmit,
  classes,
  showNext,
  t
}) => {
  return (
    <form className={classes.root} onSubmit={handleSubmit}>
      <Grid container spacing={2}>
        <Grid item>
          <Field
            className={classes.field}
            variant="outlined"
            name="voucher"
            component={renderTextField}
            label={t('Voucher')}
            margin={'none'}
            validate={[]}
            InputLabelProps={{
              shrink: true
            }}
          />
        </Grid>
        <Grid item>
          {!pristine && !showNext && (
            <Button
              className={classes.button}
              color="secondary"
              type="submit"
              size="large"
              variant="contained"
              disabled={pristine || submitting}>
              {submitting ? 'Validating' : 'Validate'}
            </Button>
          )}
        </Grid>
      </Grid>
    </form>
  )
}

VoucherForm.propTypes = {
  classes: PropTypes.object.isRequired, // from enhancer (withStyles)
  pristine: PropTypes.bool.isRequired, // from enhancer (reduxForm)
  showNext: PropTypes.bool.isRequired, // from enhancer (reduxForm)
  submitting: PropTypes.bool.isRequired, // from enhancer (reduxForm)
  handleSubmit: PropTypes.func.isRequired // from enhancer (reduxForm - calls onSubmit)
}

export default VoucherForm
