import React from 'react'
import PropTypes from 'prop-types'
import { withI18n } from 'react-i18next'
import { withStyles } from '@material-ui/core'
import TextIcon from 'icons/texts_1.svg'
import DescriptionIcon from 'icons/description_1.svg'
import AvatarIcon from 'icons/avatar_1.svg'
import Tooltip from '@material-ui/core/Tooltip/Tooltip'

const styles = theme => ({
  icon: {
    width: 25,
    height: 25
  },
  compositionIcon: {
    width: 64,
    height: 64,
    borderRadius: '50%',
    marginRight: theme.spacing.unit,
    backgroundSize: 'cover',
    backgroundRepeat: 'no-repeat',
    backgroundPosition: 'center',
    display: 'inline-block'
  },
  tooltip: {
    fontSize: 14
  }
})

const PropertyBubblesInfo = ({ classes, project, t }) => {
  const parseItemsSort = () => {
    return (project.itemsSort || []).map(item => {
      if (!Array.isArray(project.images)) {
        project = {
          ...project,
          images: Object.keys(project.images).map(key => ({
            id: parseInt(key),
            ...project.images[key]
          }))
        }
      }
      const imageItem = (project.images || []).find(i => i.id === item.id)
      const textItem = project.textBlocks[item.id + '']
      return {
        ...item,
        text: textItem && textItem.intro,
        src:
          imageItem &&
          imageItem.img &&
          (imageItem.img.minPath || imageItem.img.path)
      }
    })
  }

  return (
    <React.Fragment>
      <Tooltip
        classes={{ tooltip: classes.tooltip }}
        title={
          project.texts && (
            <div>
              {project.texts.code}
              <br />
              {project.texts.basicProperty}
              <br />
              {project.texts.basicPrice}
              <br />
              {project.texts.basicLocation}
            </div>
          )
        }>
        <img src={TextIcon} className={classes.compositionIcon} />
      </Tooltip>
      {parseItemsSort(project).map(i =>
        i.type === 'image' ? (
          <div
            className={classes.compositionIcon}
            style={{
              backgroundImage: `url(${i.src})`
            }}
            alt=""
          />
        ) : (
          <Tooltip title={i.text} classes={{ tooltip: classes.tooltip }}>
            <img
              className={classes.compositionIcon}
              src={DescriptionIcon}
              alt=""
            />
          </Tooltip>
        )
      )}
      {project.agent && (
        <Tooltip
          classes={{ tooltip: classes.tooltip }}
          title={
            <div>
              {project.agent.email}
              <br />
              {project.agent.name}
              <br />
              {project.agent.photo}
            </div>
          }>
          <div
            style={{
              backgroundImage: `url(${project.agent.image || AvatarIcon})`
            }}
            className={classes.compositionIcon}
          />
        </Tooltip>
      )}
    </React.Fragment>
  )
}

PropertyBubblesInfo.propTypes = {
  t: PropTypes.func.isRequired,
  classes: PropTypes.func.isRequired,
  project: PropTypes.array.isRequired
}

export default withI18n()(withStyles(styles)(PropertyBubblesInfo))
