import {
  compose,
  lifecycle,
  withHandlers,
  withProps,
  withStateHandlers
} from 'recompose'
import { withStyles } from '@material-ui/core/styles'
import styles from './CoreLayout.styles'
import connect from 'react-redux/es/connect/connect'
import { isEmpty, isLoaded, withFirebase } from 'react-redux-firebase'
import { withRouter } from 'react-router-dom'
import i18n from 'i18next'
import { spinnerWhileTrue } from '../../utils/components'
import { withI18n } from 'react-i18next'
import get from 'lodash/get'

export default compose(
  withRouter,
  connect(({ firebase: { auth, profile } }) => ({
    auth,
    profile
  })),
  // Add props.router
  withStyles(styles),
  withI18n()
)
