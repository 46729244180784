import { compose } from 'redux'
import styles from './DescriptionPage.styles'
import { withStyles } from '@material-ui/core'
import { withI18n } from 'react-i18next'
import withNotifications from 'modules/notification/components/withNotifications'
import { withHandlers, withProps } from 'recompose'
import firebase from 'firebase/app'
import { withFirebase } from 'react-redux-firebase'
import { withEditTime } from 'utils/components'

export default compose(
  withNotifications,
  withFirebase,
  withEditTime,
  withHandlers({
    updateBackgroundType: props => id => {
      const { showError, groupId, projectId, setEditTime } = props
      return firebase
        .set(
          `groups/${groupId}/properties/${projectId}/settings/textBackgroundType`,
          id
        )
        .then(() => setEditTime())
        .catch(err => {
          console.error('Error:', err) // eslint-disable-line no-console
          showError(err.message || 'Could not update background type')
          return Promise.reject(err)
        })
    },
    changeCustomColors: props => id => {
      const { showError, groupId, projectId, setEditTime } = props
      return firebase
        .set(
          `groups/${groupId}/properties/${projectId}/settings/textBackgroundCustomColors`,
          id
        )
        .then(() => setEditTime())
        .catch(err => {
          console.error('Error:', err) // eslint-disable-line no-console
          showError(err.message || 'Could not update background type')
          return Promise.reject(err)
        })
    },
    updateBackground: props => id => {
      const { showError, groupId, projectId, setEditTime } = props
      return firebase
        .set(
          `groups/${groupId}/properties/${projectId}/settings/textBackgroundColors`,
          id
        )
        .then(() => setEditTime())
        .catch(err => {
          console.error('Error:', err) // eslint-disable-line no-console
          showError(err.message || 'Could not update background type')
          return Promise.reject(err)
        })
    },
    updateTextColor: props => id => {
      const { showError, groupId, projectId, setEditTime } = props
      return firebase
        .set(
          `groups/${groupId}/properties/${projectId}/settings/textColor`,
          id
        )
        .then(() => setEditTime())
        .catch(err => {
          console.error('Error:', err) // eslint-disable-line no-console
          showError(err.message || 'Could not update color')
          return Promise.reject(err)
        })
    },
    updateTextColorCustom: props => id => {
      const { showError, groupId, projectId, setEditTime } = props
      return firebase
        .set(
          `groups/${groupId}/properties/${projectId}/settings/textColorCustom`,
          id
        )
        .then(() => setEditTime())
        .catch(err => {
          console.error('Error:', err) // eslint-disable-line no-console
          showError(err.message || 'Could not update color')
          return Promise.reject(err)
        })
    }
  }),
  withI18n(),
  withStyles(styles)
)
