export default theme => ({
  '@global': {
    html: {
      backgroundColor: '#212121!important'
    },
    body: {
      backgroundColor: '#212121!important'
    }
  },
  appBar: {
    minHeight: `${theme.headerHeight}px!important`
  },
  tabs: {
    marginLeft: 20,
    display: 'flex'
  },
  tab: {
    minHeight: theme.headerHeight,
    textTransform: 'uppercase',
    lineHeight: `${theme.headerHeight}px`,
    padding: `0 20px`,
    marginLeft: 10,
    marginRight: 10,
    color: 'white',
    '&:hover': {
      backgroundColor: theme.palette.primary.dark
    }
  },
  activeTab: {
    backgroundColor: theme.palette.primary.dark
  },
  iconProperty: {
    width: 36,
    position: 'relative',
    top: 5
  },
  iconNewsletter: {
    marginRight: 10,
    width: 36,
    position: 'relative',
    top: 5
  },
  logo: {
    height: 22,
    top: 3,
    position: 'relative'
  },
  icon: {
    width: 24,
    height: 24
  },
  btnUpdateNewsletter: {
    color: 'black',
    marginLeft: theme.spacing(4),
    marginRight: theme.spacing(3),
    '& $icon': {
      marginRight: theme.spacing(1)
    }
  }
})
