import i18n from 'i18next'
import { reactI18nextModule } from 'react-i18next'
import LocizeBackend from 'i18next-locize-backend'
import enTranslation from 'i18n/en'
import czTranslation from 'i18n/cz'

/**
 * Log a message and return data passed. Useful for logging
 * messages within functional programming flows.
 * @param message - Message to log along with data.
 * @example Basic
 * import { flow, map as fpMap } from 'lodash'
 * const original = []
 * flow(
 *   fpLog('Before Map'),
 *   fpMap('branchName') // get branchName
 *   fpLog('After Map'),
 * )(original)
 * // => 'Before Map' [{ name: 'test' }]
 * // => 'After Map' ['test']
 */
export function fpLog(message) {
  return existing => {
    console.log(message, existing) // eslint-disable-line no-console
    return existing
  }
}

/**
 * Initialize global scripts including analytics and error handling
 */
export function initScripts() {
  // Initialize global scripts here

  i18n
    .use(LocizeBackend)
    .use(reactI18nextModule) // passes i18n down to react-i18next
    .init({
      lng: 'en',
      fallbackLng: 'en',

      ns: ['main'],
      defaultNS: 'main',

      backend: {
        projectId: '6e4b3ee4-53fb-4f4a-bdd1-756d05b6f9db',
        apiKey: 'cdd5a3c0-fe72-4ec2-9ada-bfdef5e1165c',
        referenceLng: 'en',
      },

      interpolation: {
        escapeValue: false
      }
    })
}
