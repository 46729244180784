import React from 'react'
import PropTypes from 'prop-types'
import Button from '@material-ui/core/Button'
import Dialog from '@material-ui/core/Dialog'
import DialogActions from '@material-ui/core/DialogActions'
import DialogContent from '@material-ui/core/DialogContent'
import DialogContentText from '@material-ui/core/DialogContentText'
import DialogTitle from '@material-ui/core/DialogTitle'
import { withI18n } from 'react-i18next'

const CancelSubscribeDialog = ({ t, onClose, open }) => (
  <Dialog open={open} onClose={onClose}>
    <DialogTitle>{t('subscribe cancel')}</DialogTitle>
    <DialogContent>
      <DialogContentText>{'subscribe warning'}</DialogContentText>
    </DialogContent>
    <DialogActions>
      <Button onClick={onClose} color="primary" autoFocus>
        {t('close')}
      </Button>
    </DialogActions>
  </Dialog>
)

CancelSubscribeDialog.propTypes = {
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.bool.isRequired
}

export default withI18n()(CancelSubscribeDialog)
