import React, { useState } from 'react'
import PropTypes from 'prop-types'
import Button from '@material-ui/core/Button'
import Dialog from '@material-ui/core/Dialog'
import DialogActions from '@material-ui/core/DialogActions'
import DialogContent from '@material-ui/core/DialogContent'
import DialogContentText from '@material-ui/core/DialogContentText'
import DialogTitle from '@material-ui/core/DialogTitle'
import { withI18n } from 'react-i18next'
import ConfirmDialog from 'components/ConfirmDialog'

const NoGroupDialog = ({ t, onSignOut, onCreateGroup }) => {
  const [showAddGroup, changeShowAddGroup] = useState(false)
  return (
    <React.Fragment>
      <Dialog open>
        <DialogTitle>
          {t('Registration not successful - No invitation for this e-mail')}
        </DialogTitle>
        <DialogContent>
          <DialogContentText>
            {t('We didnt find any group linked with your e-mail address')}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          {/*<Button
            onClick={() => changeShowAddGroup(true)}
            color="primary"
            autoFocus>
            {t('create group')}
          </Button>*/}
          <Button onClick={onSignOut} color="primary" autoFocus>
            {t('sign out')}
          </Button>
        </DialogActions>
      </Dialog>
      <ConfirmDialog
        open={showAddGroup}
        onAgree={() => {
          onCreateGroup()
          changeShowAddGroup(false)
        }}
        title={t('create group')}
        onDisagree={() => changeShowAddGroup(false)}
      />
    </React.Fragment>
  )
}

NoGroupDialog.propTypes = {
  t: PropTypes.func.isRequired,
  onSignOut: PropTypes.func.isRequired,
  onCreateGroup: PropTypes.func.isRequired
}

export default withI18n()(NoGroupDialog)
