import React, { Component } from 'react'
import { withStyles } from '@material-ui/core/styles/index'
import PropTypes from 'prop-types'
import { GoogleApiWrapper } from 'google-maps-react'
import { withI18n } from 'react-i18next'

const styles = theme => ({
  wrap: {
    position: 'relative'
  },
  map: {
    width: 640,
    height: 360,
    display: 'inline-block',
    position: 'relative'
  },
  search: {
    backgroundColor: '#fff',
    fontFamily: 'Roboto',
    fontSize: 15,
    fontWeight: 300,
    padding: '6px 10px',
    textOverflow: 'ellipsis',
    width: 200,
    top: 10,
    left: 12,
    position: 'absolute'
  },
  crossHorizontal: {
    width: 640,
    height: 1,
    position: 'absolute',
    top: 360 / 2,
    background: 'black',
    pointerEvents: 'none'
  },
  crossVertical: {
    height: '100%',
    width: 1,
    position: 'absolute',
    left: 640 / 2,
    background: 'black',
    pointerEvents: 'none',
    top: 0
  }
})

class SelectMap extends Component {
  componentDidMount() {
    const map = new this.props.google.maps.Map(this.refs.map, {
      center: this.props.values.center || { lat: -34.397, lng: 150.644 },
      zoom: this.props.values.zoom || 9,
      fullscreenControl: false,
      mapTypeControl: false,
      streetViewControl: false
    })

    this.map = map

    map.addListener('center_changed', e => {
      this.props.onChange({
        ...this.props.values,
        center: map.getCenter().toJSON(),
        offsetCenter: this.offsetCenter(map.getCenter(), 193, -83).toJSON()
      })
    })
    map.addListener('zoom_changed', e => {
      this.props.onChange({
        ...this.props.values,
        zoom: map.getZoom(),
        offsetCenter: this.offsetCenter(map.getCenter(), 193, -83).toJSON()
      })
    })

    var searchBox = new this.props.google.maps.places.SearchBox(this.refs.input)

    searchBox.addListener('places_changed', () => {
      var places = searchBox.getPlaces()
      if (places.length == 0) {
        return
      }
      map.fitBounds(places[0].geometry.viewport)
    })
  }

  offsetCenter(tra, offsetx, offsety) {
    var scale = Math.pow(2, this.map.getZoom())

    var worldCoordinateCenter = this.map.getProjection().fromLatLngToPoint(tra)
    var pixelOffset = new this.props.google.maps.Point(
      offsetx / scale || 0,
      offsety / scale || 0
    )

    var worldCoordinateNewCenter = new this.props.google.maps.Point(
      worldCoordinateCenter.x - pixelOffset.x,
      worldCoordinateCenter.y + pixelOffset.y
    )

    var newCenter = this.map
      .getProjection()
      .fromPointToLatLng(worldCoordinateNewCenter)
    return newCenter
  }

  render() {
    const { classes, t } = this.props
    return (
      <div className={classes.wrap}>
        <div className={classes.map} ref="map" />
        <input
          ref="input"
          className={classes.search}
          placeholder={t('search address')}
        />
        {this.props.showCross && (
          <React.Fragment>
            <div className={classes.crossVertical} />
            <div className={classes.crossHorizontal} />
          </React.Fragment>
        )}
      </div>
    )
  }
}

SelectMap.propTypes = {
  classes: PropTypes.object.isRequired,
  values: PropTypes.object,
  google: PropTypes.object.isRequired,
  onChange: PropTypes.func.isRequired,
  showCross: PropTypes.bool
}

export default withI18n()(
  GoogleApiWrapper({
    apiKey: 'AIzaSyB89wGAaV1FOG-bR-nFI2dsn7RF8R3IynU'
  })(withStyles(styles)(SelectMap))
)
