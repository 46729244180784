import { compose, withHandlers } from 'recompose'
import { withStyles } from '@material-ui/core/styles'
import styles from './BillingInformation.styles'
import { withI18n } from 'react-i18next'
import connect from 'react-redux/es/connect/connect'
import { withFirebase } from 'react-redux-firebase'
import { withNotifications } from '../../modules/notification'
import firebaseApp from 'firebase/app'
import SubmissionError from 'redux-form/es/SubmissionError'

export default compose(
  connect(({ firebase: { profile } }) => ({
    billingInformation: profile.billingInformation
  })),
  withFirebase,
  withNotifications,
  withHandlers({
    handleSave: props => values => {
      return props.firebase
        .updateProfile({
          billingInformation: values
        })
        .catch(err => {
          console.error('Error:', err) // eslint-disable-line no-console
          props.showError(err.message || 'Could not save form')
          return Promise.reject(err)
        })
    }
  }),
  withStyles(styles),
  withI18n()
)
