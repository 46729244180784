import { compose } from 'redux'
import { withStyles } from '@material-ui/core/styles'
import styles from './ImagePage.styles'
import { withHandlers, withProps } from 'recompose'
import withNotifications from 'modules/notification/components/withNotifications'
import { connect } from 'react-redux'
import firebase from 'firebase/app'
import { firebaseConnect } from 'react-redux-firebase'
import map from 'lodash/map'
import get from 'lodash/get'
import { processingImage } from 'utils/utils'
import withLoader from 'modules/loader/components/withLoader'
import { groupColors } from '../../../../../../../../defaultValues'

export default compose(
  firebaseConnect(({ groupId }) => {
    return [
      {
        path: `/groups/${groupId}/agents/`
      },
      {
        path: `groups/${groupId}/colors`
      },
      {
        path: `groups/${groupId}/onlyGroupColors`
      }
    ]
  }),
  connect(({ firebase: { data } }, { project, match, groupId }) => {
    const agentItems = map(
      get(data, `groups.${groupId}.agents`),
      (values, key) => ({
        key,
        values
      })
    ).filter(({ values }) => !values.deleted)

    const colors = Object.assign(
      {},
      groupColors,
      get(data, `groups.${groupId}.colors`) || groupColors
    )

    return {
      image: project.images.find(i => i.id === parseInt(match.params.imageId)),
      agent: agentItems.find(({ key }) => key === project.agentKey),
      onlyGroupColors: get(data, `groups.${groupId}.onlyGroupColors`, false),
      agentCreated: agentItems.length > 0,
      colors
    }
  }),
  withNotifications,
  withLoader,
  withHandlers({
    updateImageValue: props => values => {
      const {
        showError,
        groupId,
        projectId,
        match,
        project,
        setEditTime
      } = props
      const image = project.images.find(
        i => i.id === parseInt(match.params.imageId)
      )
      const { imageId } = match.params
      return firebase
        .set(`groups/${groupId}/properties/${projectId}/images/${imageId}`, {
          ...values,
          img: image.img
        })
        .then(() => setEditTime())
        .catch(err => {
          console.error('Error:', err) // eslint-disable-line no-console
          showError(err.message || 'Could not update description')
          return Promise.reject(err)
        })
    }
  }),
  withStyles(styles)
)
