import React from 'react'
import StyledFirebaseAuth from 'react-firebaseui/StyledFirebaseAuth'
import firebase from 'firebase/app'
import Paper from '@material-ui/core/Paper/Paper'
import { CLIENT_PATH } from 'constants/paths'
import logoImg from './logo.svg'
import bg from './bg.jpg'
import queryString from 'query-string'
import Button from '@material-ui/core/Button'
import Navbar from 'components/Navbar'
import warningImg from './warning.svg'
import UserGroupICon from './icons8-user-groups.svg'

import {
  SUBSCRIPTION_PATH,
  REGISTER_PATH,
  URL_PREFIX
} from '../../../../constants/paths'
import Typography from '@material-ui/core/Typography'
import Grid from '@material-ui/core/Grid'

export const LoginPage = ({ classes, location, history, t }) => {
  const { from = 'invitation', redirect } = queryString.parse(location.search)

  // Configure FirebaseUI.
  const uiConfig = {
    // Popup signin flow rather than redirect flow.
    signInFlow: 'popup',
    // Redirect to /signedIn after sign in is successful. Alternatively you can provide a callbacks.signInSuccess function.
    signInSuccessUrl: redirect || URL_PREFIX,
    // We will display Google and Facebook as auth providers.
    signInOptions: [
      firebase.auth.GoogleAuthProvider.PROVIDER_ID,
      firebase.auth.EmailAuthProvider.PROVIDER_ID
    ]
  }

  const text =
    {
      invitation: 'Sign in to Maxo',
      register: 'Register to Maxo'
    }[from] || 'Sign in to Maxo'

  return (
    <div className={classes.root}>
      <Navbar />
      <img src={logoImg} alt="logo" className={classes.logo} />
      <Typography variant="h5" className={classes.title}>
        {text}
      </Typography>
      <div className={classes.content}>
        {from ? (
          <React.Fragment>
            <StyledFirebaseAuth
              uiConfig={uiConfig}
              firebaseAuth={firebase.auth()}
            />
          </React.Fragment>
        ) : (
          <React.Fragment>
            <Button
              onClick={() =>
                history.push(
                  `${
                    history.location.pathname
                  }?from=invitation&redirect=${URL_PREFIX}`
                )
              }
              variant="contained"
              className={classes.button}>
              Enter through the invitation
            </Button>
            <Button
              onClick={() =>
                history.push(
                  `${
                    history.location.pathname
                  }?from=register&redirect=${REGISTER_PATH +
                  SUBSCRIPTION_PATH}`
                )
              }
              variant="contained"
              className={classes.button}>
              Enter through my own group
            </Button>
          </React.Fragment>
        )}
        {from === 'register' && (
          <React.Fragment>
            <div className={classes.divider} />
            <Typography variant={'subtitle1'} color={'primary'}>
              {t('Did you receive an invitation to existing group?')}
            </Typography>
            <Button
              color="primary"
              variant="contained"
              onClick={() =>
                history.push(
                  `${
                    history.location.pathname
                  }?from=invitation&redirect=${URL_PREFIX}`
                )
              }
              fullWidth
              className={classes.redirectButton}>
              <img src={UserGroupICon} alt="" />
              Sign in with invitation e-mail
            </Button>
            <Grid container spacing={2}>
              <Grid item>
                <img className={classes.warningImg} src={warningImg} alt="" />
              </Grid>
              <Grid item>
                <Typography variant={'body2'} className={classes.warningText}>
                  You must use the same email address, to which your invintation
                  was sent. This e-mail is linked to your group and servers as
                  your identificator. If you cannot use this e-mail address then
                  please ask your administrator to send you invitation to a
                  different e-mail address.
                </Typography>
              </Grid>
            </Grid>
          </React.Fragment>
        )}
      </div>
    </div>
  )
}

export default LoginPage
