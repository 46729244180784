import React from 'react'
import PropTypes from 'prop-types'
import Dialog from '@material-ui/core/Dialog'
import DialogTitle from '@material-ui/core/DialogTitle'
import { withI18n } from 'react-i18next'
import DialogContent from '@material-ui/core/DialogContent'
import SettingsNewsletter from '../../containers/SettingsNewsletter'
import DialogActions from '@material-ui/core/DialogActions'
import Button from '@material-ui/core/Button'

const SettingsNewsletterDialog = ({ open, groupId, t, onClose }) => (
  <Dialog open={open}>
    <DialogTitle>{t('newsletter settings')}</DialogTitle>
    <DialogContent>
      <SettingsNewsletter groupId={groupId} />
    </DialogContent>
    <DialogActions>
      <Button onClick={onClose}>{t('close')}</Button>
    </DialogActions>
  </Dialog>
)

SettingsNewsletterDialog.propTypes = {
  t: PropTypes.func.isRequired,
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired
}

export default withI18n()(SettingsNewsletterDialog)
