import React from 'react'
import PropTypes from 'prop-types'
import { withI18n } from 'react-i18next'
import { URL_PREFIX } from 'constants/paths'
import VideoPicker from 'components/VideoPicker'
import Label from 'components/Label'
import Grid from '@material-ui/core/Grid'
import Typography from '@material-ui/core/Typography'
import RadioGroup from '@material-ui/core/RadioGroup'
import FormControlLabel from '@material-ui/core/FormControlLabel'
import Radio from '@material-ui/core/Radio'
import VideoTransitionItem from 'components/VideoTransitionItem'
import ColorsPallete from 'components/ColorsPallete'
import {
  ITEMS_OTHER,
  ITEMS_STRIPES,
  ITEMS_WAVES
} from '../../../../../../../../defaultValues'

const ITEMS_VIDEOS = [
  {
    id: '01',
    src:
      'https://firebasestorage.googleapis.com/v0/b/editor-53bde/o/videos%2Ftext%2FTA1.mp4?alt=media&token=3d4cc771-5d73-4de4-bb2e-bdcad37f8133'
  },
  {
    id: '02',
    src:
      'https://firebasestorage.googleapis.com/v0/b/editor-53bde/o/videos%2Ftext%2FTA2.mp4?alt=media&token=805f9058-8d82-4301-8ba5-8ac2608b7dc3'
  },
  {
    id: '03',
    src:
      'https://firebasestorage.googleapis.com/v0/b/editor-53bde/o/videos%2Ftext%2FTA3.mp4?alt=media&token=05d06996-aa90-4208-8f19-220ab2598a17'
  },
  {
    id: '04',
    src:
      'https://firebasestorage.googleapis.com/v0/b/editor-53bde/o/videos%2Ftext%2FTA4.mp4?alt=media&token=608999a6-2007-4dc2-87d3-4c587217b32f'
  },
  {
    id: '05',
    src:
      'https://firebasestorage.googleapis.com/v0/b/editor-53bde/o/videos%2Ftext%2FTA5.mp4?alt=media&token=228c0482-d700-4c19-a4ef-f2bab8069d78'
  },
  {
    id: '06',
    src:
      'https://firebasestorage.googleapis.com/v0/b/editor-53bde/o/videos%2Ftext%2FTA6.mp4?alt=media&token=d6884259-fe3b-4f16-92b4-6a27c878c60d'
  },
  {
    id: '07',
    src:
      'https://firebasestorage.googleapis.com/v0/b/editor-53bde/o/videos%2Ftext%2FTA7.mp4?alt=media&token=213bc6b8-df6a-4df6-93ad-b12b88d94af5'
  },
  {
    id: '08',
    src:
      'https://firebasestorage.googleapis.com/v0/b/editor-53bde/o/videos%2Ftext%2FTA8.mp4?alt=media&token=4b87a9f1-cbce-44b0-9e28-bcab8ba3900b'
  },
  {
    id: '09',
    src:
      'https://firebasestorage.googleapis.com/v0/b/editor-53bde/o/videos%2Ftext%2FTA9.mp4?alt=media&token=40a0083c-ae7e-4ead-a619-b19fa654d3d7'
  },
  {
    id: '10',
    src:
      'https://firebasestorage.googleapis.com/v0/b/editor-53bde/o/videos%2Ftext%2FTA10.mp4?alt=media&token=72e5fb87-6809-4c52-bd6e-ece15cfa705a'
  },
  {
    id: '11',
    src:
      'https://firebasestorage.googleapis.com/v0/b/editor-53bde/o/videos%2Ftext%2FTA11.mp4?alt=media&token=46e67eaa-d321-406f-9e21-840a2a722e30'
  }
]

const DescriptionPage = ({ t, ...props }) => {
  const { textBackgroundType = 0 } = props.project.settings
  const allVideos = [ITEMS_WAVES, ITEMS_STRIPES, ITEMS_OTHER]
  const videoItems = allVideos[textBackgroundType]

  return (
    <React.Fragment>
      <Grid container spacing={4}>
        <Grid item xs={12}>
          <Label
            classes={{
              label: props.classes.labelTextColor
            }}
            primary={t('Text block color')}
            secondary={t(
              'Color of text paragrpahs in 3 text blocks. Default color is white, but you can set the text color to match the video background.'
            )}
            className={props.classes.label}
          />
          {!props.onlyGroupColors ? (
            <RadioGroup
              row
              value={props.project.settings.textColor || 0}
              onChange={e => props.updateTextColor(parseInt(e.target.value))}>
              <FormControlLabel
                value={0}
                control={<Radio />}
                label={
                  <ColorsPallete
                    colors={{ color0: '#FFFFFF' }}
                    numberOfColors={1}
                    active={false}
                  />
                }
              />
              <FormControlLabel
                value={1}
                control={<Radio />}
                label={
                  <ColorsPallete
                    colors={{ color0: '#000000' }}
                    numberOfColors={1}
                    active={false}
                  />
                }
              />
              <FormControlLabel
                value={2}
                control={<Radio />}
                label={
                  <Grid container alignItems={'center'}>
                    <Grid item>{t('custom')}</Grid>
                    <Grid item className={props.classes.colorsPalette}>
                      <ColorsPallete
                        colors={
                          props.project.settings.textColorCustom || {
                            color0: '#F00999'
                          }
                        }
                        numberOfColors={1}
                        onChange={props.updateTextColorCustom}
                      />
                    </Grid>
                  </Grid>
                }
              />
              <FormControlLabel
                value={3}
                control={<Radio />}
                label={
                  <Grid container alignItems={'center'} >
                    <Grid item>{t('Group colors')}</Grid>
                    <Grid item className={props.classes.colorsPalette}>
                      <ColorsPallete
                        active={false}
                        colors={props.colors.textBlocksFontColor}
                        numberOfColors={1}
                        onChange={value =>
                          props.isAdmin &&
                          props.updateGroupColors('textBlocksFontColor', value)
                        }
                      />
                    </Grid>
                  </Grid>
                }
              />
            </RadioGroup>
          ) : (
            <ColorsPallete
              active={false}
              colors={props.colors.textBlocksFontColor}
              numberOfColors={1}
              onChange={value =>
                props.isAdmin &&
                props.updateGroupColors('textBlocksFontColor', value)
              }
            />
          )}
        </Grid>
        <div className={props.classes.divider} />

        <Grid item xs={12}>
          <Typography variant="h6">{t('Video background')}</Typography>
          <RadioGroup
            row
            value={textBackgroundType}
            onChange={e => {
              const type = parseInt(e.target.value)
              props.updateBackgroundType(type)
              props.updateBackground(allVideos[type][0].id)
            }}>
            <FormControlLabel
              value={0}
              control={<Radio />}
              label={t('Waves (adjustable)')}
            />
            <FormControlLabel
              value={1}
              control={<Radio />}
              label={t('Stripes (adjustable)')}
            />
            <FormControlLabel
              value={2}
              control={<Radio />}
              label={t('Miscellaneous')}
            />
          </RadioGroup>
        </Grid>
        <div className={props.classes.divider} />
        <Grid item xs={12}>
          {!props.onlyGroupColors && textBackgroundType !== 2 && (
            <React.Fragment>
              <Label
                primary={t(
                  textBackgroundType === 0
                    ? 'Present waves backgrounds'
                    : 'Present stripes backgrounds'
                )}
                className={props.classes.label}
              />
              <RadioGroup
                value={
                  props.project.settings.textBackgroundColors === 'group' ||
                  props.project.settings.textBackgroundColors === 'custom'
                    ? props.project.settings.textBackgroundColors
                    : 'defined'
                }
                onChange={e =>
                  props.updateBackground(
                    e.target.value === 'group' || e.target.value === 'custom'
                      ? e.target.value
                      : videoItems[0].id
                  )
                }>
                <FormControlLabel
                  value={'defined'}
                  control={<Radio />}
                  label={
                    <VideoTransitionItem
                      key={textBackgroundType}
                      items={videoItems}
                      activeId={props.project.settings.textBackgroundColors}
                      onActive={props.updateBackground}
                    />
                  }
                />
                <Label
                  primary={t(
                    textBackgroundType === 0
                      ? 'Custom color settings for the waves background'
                      : 'Custom color settings for the stripes background'
                  )}
                  className={props.classes.label}
                />
                <FormControlLabel
                  value="custom"
                  control={<Radio />}
                  label={
                    <ColorsPallete
                      numberOfColors={3}
                      colors={
                        props.project.settings.textBackgroundCustomColors || {
                          color0: '#F00999',
                          color1: '#300D99',
                          color2: '#ffffff'
                        }
                      }
                      onChange={props.changeCustomColors}
                    />
                  }
                />
                <Label
                  primary={t(
                    textBackgroundType === 0
                      ? 'Group color settings for the waves background'
                      : 'Group color settings for the stripes background'
                  )}
                  className={props.classes.label}
                />
                <FormControlLabel
                  value="group"
                  control={<Radio />}
                  label={
                    <ColorsPallete
                      numberOfColors={3}
                      colors={props.colors.textBlocksBackgroundColors}
                      onChange={
                        props.isAdmin &&
                        (value =>
                          props.updateGroupColors(
                            'textBlocksBackgroundColors',
                            value
                          ))
                      }
                    />
                  }
                />
              </RadioGroup>
            </React.Fragment>
          )}
          {textBackgroundType === 2 && (
            <VideoTransitionItem
              items={videoItems}
              activeId={props.project.settings.textBackgroundColors}
              onActive={props.updateBackground}
            />
          )}
          {props.onlyGroupColors && textBackgroundType !== 2 && (
            <React.Fragment>
              <Label
                primary={t(
                  textBackgroundType === 0
                    ? 'Group color settings for the waves background'
                    : 'Group color settings for the stripes background'
                )}
                className={props.classes.label}
              />
              <ColorsPallete
                numberOfColors={3}
                colors={props.colors.textBlocksBackgroundColors}
                onChange={
                  props.isAdmin &&
                  (value =>
                    props.updateGroupColors(
                      'textBlocksBackgroundColors',
                      value
                    ))
                }
              />
            </React.Fragment>
          )}
        </Grid>
        <div className={props.classes.divider} />
        <Grid item xs={12}>
          <Label
            primary={t('paragraph random animation title')}
            className={props.classes.label}
          />
          <VideoPicker readOnly items={ITEMS_VIDEOS} stopTime={2} />
        </Grid>
      </Grid>
    </React.Fragment>
  )
}

DescriptionPage.propTypes = {
  value: PropTypes.number.isRequired,
  updateBackground: PropTypes.func.isRequired,
  changeCustomColors: PropTypes.func.isRequired,
  isAdmin: PropTypes.bool.isRequired,
  onlyGroupColors: PropTypes.bool.isRequired,
  updateGroupColors: PropTypes.func.isRequired
}

export default DescriptionPage
