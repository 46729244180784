import React from 'react'
import ContainerDimensions from 'react-container-dimensions'
import ImageEditor from './ImageEditor'

export const ImageEditorWrap = props => (
  <div style={{ height: `calc(100vh - 180px)` }}>
    <ContainerDimensions>
      {({ width, height }) => (
        <ImageEditor
          containerWidth={width - 200 - 20}
          containerHeight={height}
          {...props}
        />
      )}
    </ContainerDimensions>
  </div>
)

ImageEditorWrap.propTypes = ImageEditor.propTypes

export default ImageEditorWrap
