import grey from '@material-ui/core/colors/grey'
import cyan from '@material-ui/core/colors/cyan'

export default {
  palette: {
    background: {
      default: grey[200]
    },
    type: 'light',
    color: '#fff',
    primary: {
      light: cyan[500],
      main: cyan[600],
      dark: cyan[700],
      contrastText: '#fff'
    }
  },
  // Enable typography v2: https://material-ui.com/style/typography/#migration-to-typography-v2
  typography: {
    useNextVariants: true,
    fontFamily: "'Open Sans', sans-serif"
  },
  // Own
  headerHeight: 48,
  flexColumnCenter: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center'
  },
  flexColumn: {
    display: 'flex',
    flexDirection: 'column',
  },
  flexRowCenter: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center'
  }
}
