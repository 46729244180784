import { compose } from 'redux'
import styles from './MusicPage.styles'
import { withStyles } from '@material-ui/core'
import { withI18n } from 'react-i18next'
import { withHandlers } from 'recompose'
import firebase from 'firebase/app'
import { withEditTime } from 'utils/components'
import withNotifications from 'modules/notification/components/withNotifications'

export default compose(
  withEditTime,
  withNotifications,
  withHandlers({
    updateMusic: props => (name, active) => {
      const { showError, groupId, projectId, setEditTime } = props
      return firebase
        .set(
          `groups/${groupId}/properties/${projectId}/settings/${name}`,
          active
        )
        .then(() => setEditTime())
        .catch(err => {
          console.error('Error:', err) // eslint-disable-line no-console
          showError(err.message || 'Could not update music')
          return Promise.reject(err)
        })
    }
  }),
  withStyles(styles),
  withI18n()
)
