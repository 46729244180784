import React from 'react'
import PropTypes from 'prop-types'
import Radio, { RadioGroup } from '@material-ui/core/Radio'
import FormControlLabel from '@material-ui/core/FormControlLabel'
import { URL_PREFIX } from '../../../../../../../../constants/paths'
import Checkbox from '@material-ui/core/Checkbox'
import Grid from '@material-ui/core/Grid'

const ITEMS = [
  {
    id: 1,
    src: URL_PREFIX + '/music/1.mp3',
    label: 'Acoustic Calm'
  },
  {
    id: 2,
    src: URL_PREFIX + '/music/2.mp3',
    label: 'Dream Big'
  },
  {
    id: 3,
    src: URL_PREFIX + '/music/3.mp3',
    label: 'Happy Love Story'
  },
  {
    id: 4,
    src: URL_PREFIX + '/music/4.mp3',
    label: 'Hello Good Looking'
  },
  {
    id: 5,
    src: URL_PREFIX + '/music/5.mp3',
    label: 'Pulse'
  },
  {
    id: 6,
    src: URL_PREFIX + '/music/6.mp3',
    label: 'Rise And Shine'
  },
  {
    id: 7,
    src: URL_PREFIX + '/music/7.mp3',
    label: 'Rock That Party'
  },
  {
    id: 8,
    src: URL_PREFIX + '/music/8.mp3',
    label: 'Shining Through'
  },
  {
    id: 9,
    src: URL_PREFIX + '/music/9.mp3',
    label: 'Stomps And Claps'
  },
  {
    id: 10,
    src: URL_PREFIX + '/music/10.mp3',
    label: 'Uplifting Acoustic Background'
  },
  {
    id: 11,
    src: URL_PREFIX + '/music/11.mp3',
    label: 'Whither We Are Bound'
  },
  {
    id: 12,
    src: URL_PREFIX + '/music/12.mp3',
    label: 'Day Dreaming'
  },
  {
    id: 13,
    src: URL_PREFIX + '/music/13.mp3',
    label: 'Chillin In Cuzco'
  },
  {
    id: 14,
    src: URL_PREFIX + '/music/14.mp3',
    label: 'It Always Starts With A Dream'
  },
  {
    id: 15,
    src: URL_PREFIX + '/music/15.mp3',
    label: 'Love Is The Inspiration Shorter Edit'
  },
  {
    id: 16,
    src: URL_PREFIX + '/music/16.mp3',
    label: 'On A Spring Day'
  },
  {
    id: 17,
    src: URL_PREFIX + '/music/17.mp3',
    label: 'Rock Your Life'
  },
  {
    id: 18,
    src: URL_PREFIX + '/music/18.mp3',
    label: 'Apple Pie'
  },
  {
    id: 19,
    src: URL_PREFIX + '/music/19.mp3',
    label: 'Joy And Comfort'
  }
]

const MusicPage = ({ classes, value, onChange, t, project, updateMusic }) => {
  const { backgroundMusic, animationSounds } = project.settings
  const handleChange = name => e => {
    updateMusic(name, e.target.checked)
  }
  return (
    <div className={classes.content}>
      <Grid container spacing={2}>
        <Grid item>
          <FormControlLabel
            control={
              <Checkbox
                checked={backgroundMusic !== false}
                onChange={handleChange('backgroundMusic')}
              />
            }
            label={t('Background music')}
          />
        </Grid>
        <Grid item>
          <FormControlLabel
            control={
              <Checkbox
                checked={animationSounds !== false}
                onChange={handleChange('animationSounds')}
              />
            }
            label="Animation sounds"
          />
        </Grid>
      </Grid>
      {backgroundMusic !== false && (
        <React.Fragment>
          <div className={classes.divider} />
          {ITEMS.map(item => (
            <div className={classes.item} key={item.id}>
              <FormControlLabel
                control={
                  <Radio
                    checked={value === item.id}
                    classes={{
                      root: classes.checkbox,
                      checked: classes.checked
                    }}
                    onChange={() => onChange(item.id)}
                  />
                }
                className={classes.formControl}
                label={item.label}
              />
              <audio
                controls
                controlsList="nodownload"
                preload="none"
                className={classes.audio}>
                <source src={item.src} type="audio/mpeg" />
              </audio>
            </div>
          ))}
        </React.Fragment>
      )}
    </div>
  )
}

MusicPage.propTypes = {
  t: PropTypes.func.isRequired,
  activeId: PropTypes.number.isRequired,
  onActive: PropTypes.func.isRequired,
  updateMusic: PropTypes.func.isRequired,
  project: PropTypes.object.isRequired
}

export default MusicPage
