import React, { useState } from 'react'
import PropTypes from 'prop-types'
import Navbar from 'containers/Navbar'
import { Notifications } from 'modules/notification'
import { Loader } from '../../modules/loader'
import NoGroupDialog from './NoGroupDialog'
import SelectGroupDialog from './SelectGroupDialog'
import Select from '@material-ui/core/Select'
import MenuItem from '@material-ui/core/MenuItem'
import HelpIcon from '@material-ui/icons/Help'
import DropDownIcon from '@material-ui/icons/ArrowDropDown'
import AdminWelcomeDialog from '../../components/AdminWelcomeDialog'
import LanguageDialog from '../../components/LanguageDialog'
import AccountDialog from '../../containers/Account/components/AccountDialog'
import { CLIENT_PATH, HELP_PATH, LIST_PATH } from '../../constants/paths'
import IconButton from '@material-ui/core/IconButton/IconButton'
import { Link } from 'react-router-dom'
import SuccessSubscribeDialog from '../../components/SuccessSubscribeDialog'
import CancelSubscribeDialog from '../../components/CancelSubscribeDialog'
import SubscriptionDialog from '../../components/SubscriptionDialog'
import queryString from 'query-string'
import moment from 'moment'

import { createMuiTheme, MuiThemeProvider } from '@material-ui/core'
import ThemeSettings from '../../theme'
import Typography from '@material-ui/core/Typography'
import Button from '@material-ui/core/Button'
import AdminIcon from '@material-ui/icons/ExitToApp'
import Menu from '@material-ui/core/Menu'
import LanguageIcon from '@material-ui/core/SvgIcon/SvgIcon'

const theme = createMuiTheme({
  ...ThemeSettings,
  palette: {
    ...ThemeSettings.palette,
    type: 'dark'
  }
})

export const CoreLayout = ({
  children,
  handleLogout,
  authExists,
  profile,
  groupsNames,
  handleSelectGroup,
  handleCloseWelcomeDialog,
  handleChangeLanguage,
  handleAddPrivateGroup,
  classes,
  closePaymentInfo,
  t,
  location,
  history,
  lastPayment,
  goToAdmin,
  privateGroup
}) => {
  const currentGroup = groupsNames.find(i => i.groupId === profile.actualGroup)
  const [showAccount, setShowAccount] = useState(false)
  const [showLanguage, setShowLanguage] = useState(false)
  const [showSelectGroup, setShowSelectGroup] = useState(false)

  const onlyPrivate =
    privateGroup &&
    Object.keys(profile.groupsAdmin || {}).length === 1 &&
    profile.groupsAdmin[privateGroup.groupId]

  const subscriptionType =
    profile.lastPayment && moment(profile.lastPayment.time).isAfter(moment())
      ? profile.lastPayment.subscriptionIndex
      : null

  console.log('CORE')
  return (
    <React.Fragment>
      <Navbar
        onClickAccount={() => setShowAccount(true)}
        onClickLanguage={() => setShowLanguage(true)}
        showSubscription={profile.subscription}
        lastPayment={lastPayment}
      />
      {groupsNames.length === 0 && authExists && (
        <NoGroupDialog
          onSignOut={handleLogout}
          onCreateGroup={handleAddPrivateGroup}
        />
      )}
      <Menu
        id="menu-select-group"
        className={classes.menu}
        anchorEl={showSelectGroup}
        anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
        transformOrigin={{ vertical: 'top', horizontal: 'right' }}
        open={Boolean(showSelectGroup)}
        onClose={() => setShowSelectGroup(null)}>
        {groupsNames.map(i => (
          <MenuItem
            onClick={() => {
              setShowSelectGroup(null)
              handleSelectGroup(i.groupId)
            }}>
            {i.name}
          </MenuItem>
        ))}
      </Menu>
      <MuiThemeProvider theme={theme}>
        {!(groupsNames.length === 0 && authExists) &&
          (profile.actualGroup || !authExists) &&
          children}
        <div className={classes.addons}>
          {((profile.groupsAdmin && !onlyPrivate) ||
            [2, 3, 5, 6].indexOf(subscriptionType) !== -1) && (
            <Button
              variant="flat"
              onClick={goToAdmin}
              className={classes.goToAdminBtn}>
              <AdminIcon className={classes.buttonIcon} />
              {t('admin')}
            </Button>
          )}
          {/*lastPayment && (
            <Typography
              variant={'button'}
              component={'span'}
              className={classes.paymentInfo}>
              {t('Tests')} <b>{moment(lastPayment.time).isAfter(moment()) ? lastPayment.tests : 0}</b> {t('Videos')}{' '}
              <b>{moment(lastPayment.time).isAfter(moment()) ? lastPayment.videos : 0}</b>
            </Typography>
          )*/}
          {groupsNames.length > 1 ? (
            <Button onClick={e => setShowSelectGroup(e.target)}>
              {currentGroup.name}
              <DropDownIcon className={classes.dropDownIcon} />
            </Button>
          ) : (
            <span className={classes.groupName}>
              {currentGroup && currentGroup.name}
            </span>
          )}
        </div>
        <Link to={`${CLIENT_PATH}${LIST_PATH}/${HELP_PATH}`}>
          <IconButton className={classes.help}>
            <HelpIcon />
          </IconButton>
        </Link>
      </MuiThemeProvider>
      {/*<AdminWelcomeDialog
        open={!profile.adminWelcomeShowed && profile.groupsAdmin}
        onClose={handleCloseWelcomeDialog}
      />*/}
      {/*<AccountDialog open={showAccount} onClose={() => setShowAccount(false)} />*/}
      <LanguageDialog
        value={profile.language}
        onChange={handleChangeLanguage}
        open={showLanguage}
        onClose={() => setShowLanguage(false)}
      />
      <Notifications />
      <Loader />
      <SuccessSubscribeDialog
        open={profile.showPaymentInfo === true}
        onClose={() => closePaymentInfo()}
      />
      <CancelSubscribeDialog
        open={profile.showPaymentInfo === false}
        onClose={() => closePaymentInfo()}
      />
      <SubscriptionDialog
        open={showAccount}
        onClose={() => setShowAccount(false)}
        showSubscription={profile.subscription}
      />
    </React.Fragment>
  )
}

CoreLayout.propTypes = {
  classes: PropTypes.object.isRequired,
  children: PropTypes.element.isRequired,
  handleLogout: PropTypes.func.isRequired,
  goToAdmin: PropTypes.func.isRequired,
  handleAddPrivateGroup: PropTypes.func.isRequired,
  t: PropTypes.func.isRequired,
  handleChangeLanguage: PropTypes.func.isRequired,
  handleCloseWelcomeDialog: PropTypes.func.isRequired,
  groupFound: PropTypes.bool.isRequired,
  isAdmin: PropTypes.bool.isRequired
}

export default CoreLayout
