import React, { useRef } from 'react'

export default function(defaultValue, debounce, afterEffect) {
  const [value, onInputChange] = React.useState(defaultValue || '')
  const mounted = useRef()
  React.useEffect(
    () => {
      if (mounted.current) {
        const timer = setTimeout(() => {
          onInputChange(value)
          typeof afterEffect === 'function' && afterEffect()
        }, debounce)
        return () => {
          clearTimeout(timer)
        }
      } else {
        mounted.current = true
      }
    },
    [value]
  )

  return [value, onInputChange]
}
