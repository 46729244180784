export default theme => ({
  root: {
    marginTop: theme.spacing(3)
  },
  field: {
    width: 200
  },
  button: {
    position: 'relative',
    top: 7
  }
})
