import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { withI18n } from 'react-i18next'
import ListItem from '@material-ui/core/ListItem'
import { withStyles } from '@material-ui/core'
import grey from '@material-ui/core/colors/grey'
import { formatDateTime } from 'utils/date'
import Button from '@material-ui/core/Button/Button'
import DownIcon from '@material-ui/icons/KeyboardArrowDown'
import ColorLensIcon from '@material-ui/icons/ColorLens'
import UpIcon from '@material-ui/icons/KeyboardArrowUp'
import DeleteIcon from '@material-ui/icons/Delete'
import SvgIcon from '@material-ui/core/SvgIcon/SvgIcon'
import PropertyBubblesInfo from '../PropertyBubblesInfo'
import LazyLoad, { forceCheck } from 'react-lazyload'
import CannotOpenProjectDialog from '../../CannotOpenProjectDialog'
import { useSessionStorageState } from 'react-storage-hooks'
import Menu from '@material-ui/core/Menu'
import cn from 'classnames'
import MenuItem from '@material-ui/core/MenuItem'
import SubItems from './SubItems'
import Grid from '@material-ui/core/Grid'
import FormControlLabel from '@material-ui/core/FormControlLabel'
import Checkbox from '@material-ui/core/Checkbox'
import { objectToArray } from 'utils/utils'
import ConfirmDialog from '../ConfirmDialog'

const CopyIcon = props => (
  <SvgIcon
    component={() => (
      <svg
        {...props}
        version="1.1"
        viewBox="0 0 224 224"
        width="32px"
        height="32px">
        <g
          fill="none"
          fill-rule="nonzero"
          stroke="none"
          stroke-width="1"
          stroke-linecap="butt"
          stroke-linejoin="miter"
          stroke-miterlimit="10"
          stroke-dasharray=""
          stroke-dashoffset="0"
          font-family="none"
          font-weight="none"
          font-size="none"
          text-anchor="none"
          style={{ mixBlendMode: 'normal' }}>
          <path d="M0,224v-224h224v224z" fill="none" />
          <g fill="#ffffff">
            <g id="surface1">
              <path d="M35,14c-11.53907,0 -21,9.46093 -21,21v112c0,11.53907 9.46093,21 21,21h21v7c0,11.53907 9.46093,21 21,21h112c11.53907,0 21,-9.46093 21,-21v-112c0,-11.53907 -9.46093,-21 -21,-21h-21v-7c0,-11.53907 -9.46093,-21 -21,-21zM35,28h112c3.9375,0 7,3.0625 7,7v112c0,3.9375 -3.0625,7 -7,7h-112c-3.9375,0 -7,-3.0625 -7,-7v-112c0,-3.9375 3.0625,-7 7,-7zM168,56h21c3.9375,0 7,3.0625 7,7v112c0,3.9375 -3.0625,7 -7,7h-112c-3.9375,0 -7,-3.0625 -7,-7v-7h77c11.53907,0 21,-9.46093 21,-21z" />
            </g>
          </g>
        </g>
      </svg>
    )}
  />
)

const MoreActionsIcon = props => (
  <SvgIcon
    component={() => (
      <svg
        {...props}
        xmlns="http://www.w3.org/2000/svg"
        width="32px"
        height="32px"
        viewBox="0 0 32 32"
        version="1.1">
        <g id="surface340700">
          <path
            style={{
              stroke: 'none',
              fillRule: 'nonzero',
              fill: 'rgb(100%,100%,100%)',
              fillOpacity: 1
            }}
            d="M 20 2 C 15.59375 2 12 5.59375 12 10 C 12 10.964844 12.308594 11.824219 12.621094 12.667969 L 7.265625 18.027344 C 7.171875 18.019531 7.101562 18 7 18 C 4.25 18 2 20.25 2 23 C 2 25.75 4.25 28 7 28 C 9.75 28 12 25.75 12 23 C 12 22.902344 11.984375 22.839844 11.976562 22.75 L 17.339844 17.382812 C 18.179688 17.691406 19.039062 18 20 18 C 24.40625 18 28 14.40625 28 10 C 28 8.972656 27.789062 8.007812 27.449219 7.125 L 26.894531 5.6875 L 25.808594 6.777344 L 22.585938 10 L 20 10 L 20 7.414062 L 24.3125 3.105469 L 22.875 2.550781 C 21.992188 2.210938 21.027344 2 20 2 Z M 20 4 C 20.171875 4 20.324219 4.078125 20.492188 4.09375 L 18 6.585938 L 18 12 L 23.414062 12 L 25.90625 9.507812 C 25.921875 9.675781 26 9.828125 26 10 C 26 13.324219 23.324219 16 20 16 C 19.085938 16 18.226562 15.777344 17.433594 15.402344 L 16.796875 15.097656 L 9.871094 22.027344 L 9.949219 22.53125 C 9.980469 22.726562 10 22.878906 10 23 C 10 24.671875 8.671875 26 7 26 C 5.328125 26 4 24.671875 4 23 C 4 21.328125 5.328125 20 7 20 C 7.128906 20 7.285156 20.019531 7.484375 20.050781 L 7.992188 20.132812 L 14.90625 13.210938 L 14.601562 12.570312 C 14.222656 11.773438 14 10.914062 14 10 C 14 6.675781 16.675781 4 20 4 Z M 7 22 C 6.449219 22 6 22.449219 6 23 C 6 23.550781 6.449219 24 7 24 C 7.550781 24 8 23.550781 8 23 C 8 22.449219 7.550781 22 7 22 Z M 7 22 "
          />
        </g>
      </svg>
    )}
  />
)
const PencilIcon = props => (
  <SvgIcon
    component={() => (
      <svg
        {...props}
        xmlns="http://www.w3.org/2000/svg"
        version="1.1"
        viewBox="0 0 224 224"
        width="32px"
        height="32px">
        <g
          fill="none"
          fill-rule="nonzero"
          stroke="none"
          stroke-width="1"
          stroke-linecap="butt"
          stroke-linejoin="miter"
          stroke-miterlimit="10"
          stroke-dasharray=""
          stroke-dashoffset="0"
          font-family="none"
          font-weight="none"
          font-size="none"
          text-anchor="none"
          style={{ mixBlendMode: 'normal' }}>
          <path d="M0,224v-224h224v224z" fill="none" />
          <g fill="#ffffff">
            <g id="surface1">
              <path d="M168,28.05468c-7.16407,0 -14.38282,2.6797 -19.79687,8.14845h-0.0547l-105.27343,107.07812l-18.92187,56.82032l56.76562,-18.97657l1.20313,-1.14843l105.875,-104.17968c10.8828,-10.88282 10.8828,-28.71095 0,-39.59375c-5.41407,-5.41407 -12.63282,-8.14845 -19.79688,-8.14845zM168,42c3.55468,0 7.10938,1.3125 9.89843,4.10157c5.52345,5.52343 5.52345,14.27343 0,19.79687l-11.04687,10.88282c-1.53125,-3.77343 -3.99218,-7.82032 -7.875,-11.70312c-3.9375,-3.9375 -7.98438,-6.34375 -11.75782,-7.875l10.9375,-11.15625c2.73438,-2.6797 6.28907,-4.10157 9.84375,-4.04688zM137.97657,69.01563c2.57032,0.21875 6.34375,1.20312 11.04687,5.90625c4.75782,4.7578 5.7422,8.53125 6.01563,11.10155c0.16407,1.96875 -0.10938,2.2422 -0.21875,2.57032l-72.35157,71.14843c-1.53125,-3.4453 -3.9375,-7.10937 -7.54687,-10.71875c-3.5547,-3.55468 -7.21875,-5.96093 -10.71875,-7.49218l71.20312,-72.29687c0.32813,-0.16407 0.60157,-0.38282 2.57032,-0.21875zM54.57813,153.125c2.1875,0.16407 5.6875,1.03907 10.5,5.85157c4.7578,4.75782 5.6328,8.25782 5.79687,10.39062c0,0.27345 -0.05468,0.10938 0,0.32813l-13.34375,4.42968c-1.09375,-1.36718 -2.24218,-2.73437 -3.60937,-4.10157c-1.3125,-1.3125 -2.6797,-2.46093 -4.04688,-3.5l4.48438,-13.39843c0.16405,0.05468 0,0 0.21875,0z" />
            </g>
          </g>
        </g>
      </svg>
    )}
  />
)

const styles = theme => ({
  statusDone: {
    color: theme.palette.primary.light
  },
  icon: {
    width: 25,
    height: 25
  },
  avatarAdd: {
    backgroundColor: theme.palette.primary.light
  },
  checkboxAddToNewsletter: {
    marginRight: 1
  },
  compositionIcon: {
    width: 64,
    height: 64,
    borderRadius: '50%',
    marginRight: theme.spacing.unit,
    backgroundSize: 'cover',
    backgroundRepeat: 'no-repeat',
    backgroundPosition: 'center',
    display: 'inline-block'
  },
  item: {
    backgroundColor: '#2d2d2d',
    padding: theme.spacing.unit,
    display: 'flex',
    alignItems: 'normal',
    '&+*': {
      marginTop: theme.spacing.unit * 2
    },
    color: 'white',
    minHeight: 155,
    width: `calc(100% - ${theme.spacing(2)}px)`,
    borderRight: `12px solid ${grey[800]}`
  },
  itemActive: {
    borderRightColor: theme.palette.secondary.main
  },
  projectName: {
    marginBottom: theme.spacing.unit
  },
  itemLeft: {
    flex: 1
  },
  textBlock: {
    textAlign: 'right',
    color: grey[500],
    fontSize: 12,
    paddingRight: theme.spacing(2),
    marginTop: theme.spacing(2)
  },
  cloneIcon: {
    width: 26,
    height: 26
  },
  itemRight: {
    textAlign: 'right'
  },
  cloneButton: {
    marginBottom: 5
  },
  tooltip: {
    fontSize: 14
  },
  moreButton: {
    position: 'absolute',
    left: 705,
    top: 111
  },
  menuIcon: {
    marginRight: theme.spacing.unit
  },
  actionsIcon: {
    marginLeft: theme.spacing.unit * 2,
    width: 25,
    height: 25
  }
})

const PropertiesList = ({
  classes,
  items,
  onDownload,
  onClone,
  onSelect,
  agentItems,
  withActions,
  onCopySettings,
  aggregated,
  onChangeActiveNewsletter,
  onChangeNewsletterItem,
  onDeleteProperty,
  newsletter,
  t
}) => {
  console.log('LIST')
  const [anchorEl, setAnchorEl] = React.useState(null)
  const [selectedActive, setSelectedActive] = React.useState(false)
  const [deleteConfirm, setDeleteConfirm] = React.useState(false)
  const [currentItem, setCurrentItem] = React.useState(null)
  const [showCannotOpenDialog, setShowCannotOpenDialog] = useState(false)
  const [hiddenItems, setHiddenItems, writeError] = useSessionStorageState(
    'lessItems',
    '[]'
  )
  const parseHiddenItems = JSON.parse(hiddenItems)

  let filteredItems = items.map(i => {
    const newsletterItem = newsletter.items.find(k => k.projectKey === i.key)
    return {
      ...i,
      showLess: hiddenItems.indexOf(i.key) !== -1,
      newsletterActive: newsletterItem,
      itemsFinal: objectToArray(i.itemsFinal).map((i, index) => ({
        ...i,
        index
      })),
      itemsPreview: objectToArray(i.itemsPreview).map((i, index) => ({
        ...i,
        index
      })),
      agent: agentItems.find(k => k.key === i.agentKey)
    }
  })

  return (
    <React.Fragment>
      {filteredItems.map(item => (
        <LazyLoad
          height={
            115 + 16 + withActions
              ? 49 *
                (item.showLess
                  ? 1
                  : items.filter(i => i.parentId === item.key).length)
              : 0
          }
          once>
          <ListItem
            className={cn(
              classes.item,
              item.newsletterActive && classes.itemActive
            )}
            key={item.key}
            button={(!item.processing && !item.done) || !withActions}
            onClick={() => {
              if (!item.processing || !withActions) {
                onSelect(item)
              } else {
                setShowCannotOpenDialog(true)
              }
            }}>
            <div className={classes.itemLeft}>
              <div className={classes.projectName}>
                {item.name || t('no name')}
              </div>
              <div>
                <PropertyBubblesInfo project={item} />
              </div>
              {aggregated &&
                (item.itemsFinal.length > 1 || item.itemsPreview.length > 1) &&
                withActions && (
                  <Button
                    color="primary"
                    onClick={e => {
                      e.stopPropagation()
                      setTimeout(() => {
                        forceCheck()
                      }, 200)
                      if (!item.showLess) {
                        setHiddenItems(
                          JSON.stringify([...parseHiddenItems, item.key])
                        )
                      } else {
                        setHiddenItems(
                          JSON.stringify(
                            parseHiddenItems.filter(i => i !== item.key)
                          )
                        )
                      }
                    }}
                    className={classes.moreButton}>
                    {t(item.showLess ? 'Show more' : 'Show less')}
                    {item.showLess ? (
                      <DownIcon
                        nativeColor={'white'}
                        className={classes.icon}
                      />
                    ) : (
                      <UpIcon nativeColor={'white'} className={classes.icon} />
                    )}
                  </Button>
                )}
              {withActions && aggregated && (
                <Grid container spacing={2}>
                  <Grid item>
                    <SubItems
                      label={t('Preview')}
                      items={item.itemsPreview}
                      showLess={item.showLess}
                      onDownload={onDownload}
                    />
                  </Grid>
                  <Grid item>
                    <SubItems
                      label={t('final video')}
                      items={item.itemsFinal}
                      showLess={item.showLess}
                      onDownload={onDownload}
                      withCheckbox
                      onChangeItem={videoKey =>
                        onChangeNewsletterItem(item.key, videoKey)
                      }
                      activeItem={item.newsletterVideoKey}
                    />
                  </Grid>
                </Grid>
              )}
            </div>
            {withActions && (
              <div className={classes.itemRight}>
                <FormControlLabel
                  onClick={e => e.stopPropagation()}
                  className={classes.checkboxAddToNewsletter}
                  labelPlacement="start"
                  control={
                    <Checkbox
                      checked={!!item.newsletterActive}
                      onChange={e =>
                        onChangeActiveNewsletter(item.key, e.target.checked)
                      }
                    />
                  }
                  label={t('Add to newsletter')}
                />
                <br />
                <Button
                  className={classes.cloneButton}
                  onClick={e => {
                    e.stopPropagation()
                    setAnchorEl(e.currentTarget)
                    setSelectedActive(item.newsletterActive)
                    setCurrentItem(item)
                    //onClone(item.key)
                  }}>
                  {t('Toolbox')}
                  <MoreActionsIcon className={classes.actionsIcon} />
                </Button>
                <br />
                <div className={classes.textBlock}>
                  <b>{t('created')}:</b> {formatDateTime(item.creationTime)}
                </div>
                {/*<IconButton
                  className={classes.margin}
                  onClick={e => onSelect(item.key)}>
                  <PencilIcon className={classes.icon} />
                </IconButton>*/}
              </div>
            )}
          </ListItem>
        </LazyLoad>
      ))}
      <CannotOpenProjectDialog
        open={showCannotOpenDialog}
        onClose={() => setShowCannotOpenDialog(false)}
      />
      <Menu
        style={{ marginTop: 55 }}
        anchorEl={anchorEl}
        keepMounted
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center'
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'center'
        }}
        open={Boolean(anchorEl)}
        onClose={() => setAnchorEl(null)}>
        <MenuItem
          onClick={() => {
            setAnchorEl(null)
            onClone(currentItem.key)
          }}>
          <CopyIcon className={cn(classes.menuIcon, classes.cloneIcon)} />
          {t('copy project')}
        </MenuItem>
        <MenuItem
          onClick={() => {
            setAnchorEl(null)
            onCopySettings(currentItem.key)
          }}>
          <ColorLensIcon className={classes.menuIcon} />
          {t('Save settings')}
        </MenuItem>
        <MenuItem
          disabled={selectedActive}
          onClick={() => {
            setAnchorEl(null)
            setDeleteConfirm(true)
          }}>
          <DeleteIcon className={classes.menuIcon} />
          {t('Delete property')}
        </MenuItem>
      </Menu>
      <ConfirmDialog
        open={deleteConfirm}
        onAgree={() => {
          onDeleteProperty(currentItem.key)
          setDeleteConfirm(false)
        }}
        onDisagree={() => {
          setDeleteConfirm(false)
        }}
        title={t('Delete property confirm')}
      />
    </React.Fragment>
  )
}

PropertiesList.propTypes = {
  t: PropTypes.func.isRequired,
  classes: PropTypes.func.isRequired,
  items: PropTypes.array.isRequired,
  agentItems: PropTypes.array.isRequired,
  onClone: PropTypes.func,
  onCopySettings: PropTypes.func,
  onDownload: PropTypes.func,
  onSelect: PropTypes.func.isRequired,
  withActions: PropTypes.bool,
  aggregated: PropTypes.bool,
  onChangeActiveNewsletter: PropTypes.func,
  onChangeNewsletterItem: PropTypes.func,
  onDeleteProperty: PropTypes.func
}

export default withI18n()(withStyles(styles)(PropertiesList))
