import React, { useRef, useState } from 'react'
import PropTypes from 'prop-types'
import { withI18n } from 'react-i18next'
import { withStyles } from '@material-ui/core'
import Grid from '@material-ui/core/Grid'
import RadioGroup from '@material-ui/core/RadioGroup'
import FormControlLabel from '@material-ui/core/FormControlLabel'
import Radio from '@material-ui/core/Radio'
import Label from 'components/Label'
import Button from '@material-ui/core/Button'
import ImageRotationDialog from 'components/ImageRotationDialog'
import AddIcon from '@material-ui/icons/Add'
import { TEMPLATE_HEIGHT, TEMPLATE_WIDTH } from 'utils/utils'
import SlideImagePicker from './SlideImagePicker'

const styles = theme => ({
  radio: {
    padding: '6px 12px'
  },
  label: {
    marginBottom: theme.spacing.unit * 2
  },
  rightPanel: {
    borderLeft: '1px solid rgba(255, 255, 255, 0.12)'
  },
  addButton: {
    marginBottom: theme.spacing.unit,
    '& svg': {
      marginRight: theme.spacing.unit
    }
  }
})

const BackgroundPicker = ({
  classes,
  type,
  onChangeType,
  t,
  propertyImages,
  projectImages,
  value,
  onChange,
  onUploadImage,
  isAdmin
}) => {
  const inputEl = useRef(null)
  const [file, onChangeFile] = useState(null)
  const [inputInstance, onChangeInputInstance] = useState(null)

  const imagesFromProperty = propertyImages.map(i => ({
    id: i.id,
    imgPath: i.img.minPath || i.img.path
  }))

  const imagesFromGroup = projectImages.map(i => ({
    id: i.id,
    imgPath: i.url
  }))

  return (
    <Grid container spacing={4}>
      <Grid item>
        <Label
          primary={t('Template')}
          secondary={t('for the duration of the input animation')}
          className={classes.label}
        />
        <RadioGroup
          value={type}
          onChange={e => {
            if (e.target.value === 'picture') {
              onChange(propertyImages[0].id)
              onChangeType(e.target.value)
            }
            if (e.target.value === 'uploaded') {
              if (projectImages.length > 0) {
                onChange(projectImages[0].id)
                onChangeType(e.target.value)
              } else {
                inputEl.current.click()
              }
            } else {
              onChangeType(e.target.value)
            }
          }}>
          <FormControlLabel
            disabled={propertyImages.length === 0}
            value={'picture'}
            control={<Radio className={classes.radio} />}
            label={`${t('Image')} (${t('any of the project')})`}
          />
          <FormControlLabel
            value={'basic'}
            control={<Radio className={classes.radio} />}
            label={`${t('Basic wallpaper')} (${t(
              'is chosen in the design section'
            )})`}
          />
          <FormControlLabel
            value={'uploaded'}
            disabled={projectImages.length === 0 && !isAdmin}
            control={<Radio className={classes.radio} />}
            label={t('Upload your own picture background')}
          />
        </RadioGroup>
      </Grid>
      <Grid item className={classes.rightPanel}>
        {type === 'picture' && (
          <SlideImagePicker
            items={imagesFromProperty}
            onChange={onChange}
            value={value}
          />
        )}
        {type === 'uploaded' && isAdmin && (
          <Button
            color={'primary'}
            className={classes.addButton}
            variant="contained"
            onClick={() => inputEl.current.click()}>
            <AddIcon />
            Add image
          </Button>
        )}
        {type === 'uploaded' && (
          <SlideImagePicker
            items={imagesFromGroup}
            onChange={onChange}
            value={value}
          />
        )}
      </Grid>
      <input
        accept="image/x-png,image/gif,image/jpeg,image/png,image/bmp"
        type="file"
        style={{ display: 'none' }}
        onChange={e => {
          let file = e.target.files[0]
          if (file) {
            const reader = new FileReader()
            reader.onload = () => {
              const dataURL = reader.result
              onChangeFile({
                file,
                dataURL
              })
              onChangeInputInstance(inputInstance + 1)
            }
            reader.readAsDataURL(file)
          }
        }}
        ref={inputEl}
        key={inputInstance}
      />
      <ImageRotationDialog
        width={TEMPLATE_WIDTH}
        height={TEMPLATE_HEIGHT}
        open={!!file}
        file={file ? { file: file.file, dataURL: file.dataURL } : null}
        onClose={() => onChangeFile(null)}
        onSubmit={file => {
          onUploadImage(file)
        }}
        mimeType="image/jpeg"
      />
    </Grid>
  )
}

BackgroundPicker.propTypes = {
  classes: PropTypes.object.isRequired,
  type: PropTypes.string,
  value: PropTypes.string,
  onChangeType: PropTypes.func.isRequired,
  onChange: PropTypes.func.isRequired,
  propertyImages: PropTypes.array.isRequired,
  projectImages: PropTypes.array.isRequired,
  isAdmin: PropTypes.bool.isRequired,
  t: PropTypes.func.isRequired
}
export default withStyles(styles)(withI18n()(BackgroundPicker))
