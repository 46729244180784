import React from 'react'
import PropTypes from 'prop-types'
import Label from 'components/Label'
import Radio from '@material-ui/core/Radio'
import Grid from '@material-ui/core/Grid'
import ColorsPallete from '../../../../../../../../components/ColorsPallete'
import { withStyles } from '@material-ui/core/styles'
import { withI18n } from 'react-i18next'
import { withProps } from 'recompose'

const styles = theme => ({
  label: {
    marginTop: 20,
    marginBottom: 15,
    '&:first-child': {
      marginTop: 0
    }
  },
  customWrap: {
    display: 'flex',
    alignItems: 'center'
  }
})

const ColorsPalettePicker = ({
  classes,
  values,
  onChange,
  numberOfColors,
  onChangeGroup,
  canUpdateGroup,
  groupColors,
  onlyGroupColors,
  t,
  ...props
}) => (
  <React.Fragment>
    {!onlyGroupColors && (
      <React.Fragment>
        <Label className={classes.label} primary={t('preset color palettes')} />
        <div className={classes.customWrap}>
          <Radio
            checked={values.type === 'defined'}
            onChange={() => onChange({ ...values, type: 'defined' })}
          />
          <Grid container spacing={2}>
            {props.paletteItems.map(i => (
              <Grid item>
                <ColorsPallete
                  active={!values.customActive && values.activeId === i.id}
                  colors={i.colors}
                  numberOfColors={numberOfColors}
                  onClick={() =>
                    onChange({ ...values, activeId: i.id, type: 'defined' })
                  }
                />
              </Grid>
            ))}
          </Grid>
        </div>
        <Label className={classes.label} primary={t('custom color settings')} />
        <div className={classes.customWrap}>
          <Radio
            checked={values.type === 'custom'}
            onChange={() => onChange({ ...values, type: 'custom' })}
          />
          <ColorsPallete
            active={values.customActive}
            colors={values.custom}
            numberOfColors={numberOfColors}
            onChange={colors =>
              onChange({ ...values, custom: colors, type: 'custom' })
            }
          />
        </div>
      </React.Fragment>
    )}
    <Label className={classes.label} primary={t('group color settings')} />
    <div className={classes.customWrap}>
      {!onlyGroupColors && (
        <Radio
          checked={values.type === 'group'}
          onChange={() => onChange({ ...values, type: 'group' })}
        />
      )}
      <ColorsPallete
        active={values.customActive}
        colors={groupColors}
        numberOfColors={numberOfColors}
        onChange={
          canUpdateGroup &&
          (colors => {
            onChangeGroup(colors)
            onChange({ ...values, type: 'group' })
          })
        }
      />
    </div>
  </React.Fragment>
)

ColorsPalettePicker.propTypes = {
  classes: PropTypes.object.isRequired,
  values: PropTypes.object.isRequired,
  onChange: PropTypes.func.isRequired,
  paletteItems: PropTypes.array.isRequired,
  numberOfColors: PropTypes.number,
  groupColors: PropTypes.array.isRequired,
  canUpdateGroup: PropTypes.func.isRequired,
  onChangeGroup: PropTypes.func.isRequired,
  onlyGroupColors: PropTypes.bool.isRequired,
  t: PropTypes.func.isRequired
}

export default withI18n()(withStyles(styles)(ColorsPalettePicker))
