import { LOADER_SET_PROGRESS, LOADER_CLEAR } from './actionTypes'

const loader = (state = { show: false, progress: undefined }, action) => {
  switch (action.type) {
    case LOADER_SET_PROGRESS:
      return { show: true, progress: action.payload }
    case LOADER_CLEAR:
      return { show: false }
    default:
      return state
  }
}

export default loader
