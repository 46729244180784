import { compose } from 'redux'
import { withStyles } from '@material-ui/core/styles'
import styles from './CompositionPage.styles'
import { withRouter } from 'react-router-dom'
import { withHandlers } from 'recompose'
import { MAX_IMAGES, MAX_TEXT_BLOCKS } from 'defaultValues'
import firebase from 'firebase/app'
import { DESCRIPTION_PATH, IMAGE_PATH } from 'constants/paths'
import withNotifications from 'modules/notification/components/withNotifications'
import withLoader from 'modules/loader/components/withLoader'
import { propertyImageItem } from '../../../../../../defaultValues'
import {
  addGroupFile,
  TEMPLATE_HEIGHT,
  TEMPLATE_WIDTH
} from '../../../../../../utils/utils'
import { withEditTime } from '../../../../../../utils/components'
import firebaseApp from 'firebase/app'

export default compose(
  withRouter,
  withNotifications,
  withLoader,
  withEditTime,
  withHandlers({
    addTextBlock: props => () => {
      const {
        groupId,
        projectId,
        project,
        showError,
        history,
        match,
        setEditTime
      } = props
      const newId = Array.from(Array(MAX_TEXT_BLOCKS).keys())
        .map(i => i + MAX_IMAGES)
        .find(i => !(props.project.itemsSort || []).find(k => k.id === i))

      return firebase
        .set(`groups/${groupId}/properties/${projectId}/itemsSort`, [
          ...(project.itemsSort || []),
          { id: newId, type: 'text' }
        ])
        .then(() => {
          history.push(`${match.path}/${DESCRIPTION_PATH}/${newId}`)
        })
        .then(() => setEditTime())
        .catch(err => {
          console.error('Error:', err) // eslint-disable-line no-console
          showError(err.message || 'Could not add desc block')
          return Promise.reject(err)
        })
    },
    addImageBlock: props => file => {
      const {
        groupId,
        projectId,
        project,
        showError,
        history,
        match,
        setLoader,
        clearLoader,
        setEditTime
      } = props
      const newId = Array.from(Array(MAX_IMAGES).keys()).find(
        i => !(project.itemsSort || []).find(k => k.id === i)
      )

      const path = `properties/${projectId}/${newId}.jpg`

      setLoader()
      return firebase
        .set(`groups/${groupId}/properties/${projectId}/itemsSort`, [
          ...(project.itemsSort || []),
          { id: newId, type: 'image' }
        ])
        .then(() => {
          return addGroupFile(groupId, path, file)
        })
        .then(() => {
          const storageRef = firebase.storage().ref()
          const ref = storageRef.child(
            `groups/${groupId}/properties/${projectId}/${newId}.jpg`
          )

          return ref.getDownloadURL().then(function(downloadURL) {
            return firebase.set(
              `groups/${groupId}/properties/${projectId}/images/${newId}/img`,
              {
                path: downloadURL,
                width: TEMPLATE_WIDTH,
                height: TEMPLATE_HEIGHT,
                id: newId
              }
            )
          })
        })
        .then(() => {
          history.push(`${match.path}/${IMAGE_PATH}/${newId}`)
        })
        .then(() => setEditTime())
        .catch(err => {
          console.error('Error:', err) // eslint-disable-line no-console
          showError(err.message || 'Could not delete desc block')
          return Promise.reject(err)
        })
        .finally(clearLoader)
    },
    setImage: props => (file, imageId) => {
      const {
        groupId,
        projectId,
        showError,
        setLoader,
        clearLoader,
        setEditTime
      } = props
      setLoader()
      const path = `properties/${projectId}/${imageId}.jpg`
      const storageRef = firebase.storage().ref()
      return addGroupFile(groupId, path, file)
        .then(function() {
          const ref = storageRef.child(`/groups/${groupId}/${path}`)
          return ref.getDownloadURL().then(function(downloadURL) {
            return firebase.set(
              `groups/${groupId}/properties/${projectId}/images/${imageId}/img`,
              {
                path: downloadURL + `&editDate=${Date.now()}`,
                width: TEMPLATE_WIDTH,
                height: TEMPLATE_HEIGHT,
                imageId,
                id: imageId,
                editDate: Date.now()
              }
            )
          })
        })
        .then(() => setEditTime())
        .catch(err => {
          console.error('Error:', err) // eslint-disable-line no-console
          showError(err.message || 'Could not update image')
          return Promise.reject(err)
        })
        .finally(clearLoader)
    },
    removeTextBlock: props => (id, redirect = true) => {
      const {
        groupId,
        projectId,
        showError,
        history,
        match,
        project,
        setEditTime
      } = props
      if (redirect) {
        history.push(`${match.path}`)
      }
      return firebase
        .set(
          `groups/${groupId}/properties/${projectId}/itemsSort`,
          project.itemsSort.filter(i => id !== i.id)
        )
        .then(() => {
          return firebase.set(
            `groups/${groupId}/properties/${projectId}/textBlocks/${id}`,
            {
              intro: '',
              introRows: ['', '', '', '', '']
            }
          )
        })
        .then(() => setEditTime())
        .catch(err => {
          console.error('Error:', err) // eslint-disable-line no-console
          showError(err.message || 'Could not delete desc block')
          return Promise.reject(err)
        })
    },
    removeImageBlock: props => id => {
      const {
        groupId,
        projectId,
        showError,
        history,
        match,
        project,
        setEditTime
      } = props
      const imageIndex = project.images.findIndex(i => i.id === id)
      const fileId = project.images[imageIndex].img.id
      history.push(`${match.path}`)
      return firebase
        .set(
          `groups/${groupId}/properties/${projectId}/itemsSort`,
          project.itemsSort.filter(i => id !== i.id)
        )
        .then(() => {
          return firebase.set(
            `groups/${groupId}/properties/${projectId}/images/${imageIndex}`,
            { ...propertyImageItem(id, true), img: null }
          )
        })
        .then(() => {
          return firebaseApp.functions().httpsCallable('deleteFile')({
            path: `/properties/${projectId}/${id}.jpg`,
            groupId
          })
        })
        .then(() => {
          if (
            project.settings.background &&
            id === project.settings.background
          ) {
            return firebase.set(
              `groups/${groupId}/properties/${projectId}/settings/background`,
              ''
            )
          }
        })
        .then(() => setEditTime())
        .catch(err => {
          console.error('Error:', err) // eslint-disable-line no-console
          showError(err.message || 'Could not delete image block')
          return Promise.reject(err)
        })
    },
    setItemsSort: props => itemsSort => {
      const { groupId, projectId, showError, setEditTime } = props
      return firebase
        .set(`groups/${groupId}/properties/${projectId}/itemsSort`, itemsSort)
        .then(() => setEditTime())
        .catch(err => {
          console.error('Error:', err) // eslint-disable-line no-console
          showError(err.message || 'Could not set items sort')
          return Promise.reject(err)
        })
    },
    goToPage: ({ history, match }) => name => {
      history.push(`${match.path}/${name}`)
    }
  }),
  withStyles(styles)
)
