import React from 'react'
import { withStyles } from '@material-ui/core/styles/index'
import PropTypes from 'prop-types'
import VideoWrap from '../components/VideoWrap'
import Grid from '@material-ui/core/Grid'

const styles = theme => ({
  item: {
    border: 'solid 2px transparent'
  },
  label: {
    color: theme.palette.primary.main,
    marginBottom: 5
  },
  active: {
    border: `solid 2px ${theme.palette.primary.main}`
  },
  noneActive: {
    border: 'solid 2px transparent'
  }
})

const ITEMS = [
  { id: 1, src: 'https://www.w3schools.com/html/mov_bbb.mp4', label: 'Twirl fast' },
  { id: 2, src: 'https://www.w3schools.com/html/mov_bbb.mp4', label: 'Twirl fast' },
  { id: 3, src: 'https://www.w3schools.com/html/mov_bbb.mp4', label: 'Twirl fast' },
  { id: 4, src: 'https://www.w3schools.com/html/mov_bbb.mp4', label: 'Twirl fast' },
  { id: 5, src: 'https://www.w3schools.com/html/mov_bbb.mp4', label: 'Twirl fast' },
]

const VideoPicker = ({ classes, activeId, onActive, items, videoWidth, startTime, stopTime, readOnly }) =>
  <Grid container className={classes.content}>
    {(items || ITEMS).map((item) => (
      <Grid
        item
        className={classes.item}
        style={{cursor: !readOnly ? 'pointer' : 'default'}}
        onClick={(e) => !readOnly && onActive(item.id, e)}
      >
        <div className={classes.label}>
          {item.label}
        </div>
        <VideoWrap
          startTime={startTime !== undefined ? startTime : 0.5}
          src={item.src}
          className={activeId === item.id ? classes.active : classes.noneActive}
          style={{width: videoWidth}}
          stopTime={stopTime}
        />
      </Grid>
    ))}
  </Grid>

VideoPicker.propTypes = {
  classes: PropTypes.object.isRequired,
  activeId: PropTypes.number.isRequired,
  onActive: PropTypes.func.isRequired,
  items: PropTypes.array.isRequired,
  videoWidth: PropTypes.number,
  startTime: PropTypes.number,
  stopTime: PropTypes.number,
  readOnly: PropTypes.bool,
}

export default withStyles(styles)(VideoPicker)
