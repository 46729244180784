import React from 'react'
import PropTypes from 'prop-types'
import Button from '@material-ui/core/Button'
import Dialog from '@material-ui/core/Dialog'
import DialogActions from '@material-ui/core/DialogActions'
import DialogContent from '@material-ui/core/DialogContent'
import DialogContentText from '@material-ui/core/DialogContentText'
import DialogTitle from '@material-ui/core/DialogTitle'
import { URL_PREFIX } from '../../../../constants/paths'
import { Link } from 'react-router-dom'
import { withI18n } from 'react-i18next'

const ProjectDisabledDialog = ({ open, t }) => (
  <div>
    <Dialog open={open}>
      <DialogTitle>{t('project submitted')}</DialogTitle>
      <DialogContent>
        <DialogContentText>{t('project submitted info')}</DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button
          component={Link}
          to={`${URL_PREFIX}/`}
          color="primary"
          autoFocus>
          {t('go to list of projects')}
        </Button>
      </DialogActions>
    </Dialog>
  </div>
)

ProjectDisabledDialog.propTypes = {
	t: PropTypes.func.isRequired,
  open: PropTypes.bool.isRequired
}

export default withI18n()(ProjectDisabledDialog)
