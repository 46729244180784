export default theme => ({
  '@global': {
    html: {
      backgroundColor: '#212121!important'
    },
    body: {
      backgroundColor: '#212121!important'
    }
  },
  root: {
    margin: '20px 20px 0',
    color: 'white'
  },
  icon: {
    paddingRight: theme.spacing.unit
  },
  link: {
    marginLeft: theme.spacing.unit * 2,
    color: 'white'
  },
  openBtn: {
    marginRight: theme.spacing.unit * 2
  }
})
