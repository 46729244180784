import React from 'react'
import PropTypes from 'prop-types'
import moment from 'moment'
import { formatDateTime } from '../../utils/date'

const TYPES = [
  'Trial',
  'Solo',
  'Office',
  'Extra',
  'Trial',
  'Solo',
  'Office',
  'Extra'
]

const SubscriptionInfo = ({ t, classes, profile }) => {
  const { lastPayment, subscription } = profile
  return (
    <React.Fragment>
      {lastPayment && subscription && (
        <table className={classes.table}>
          <tbody>
            <tr>
              <td>{t('Plan Name')}</td>
              <td>{TYPES[lastPayment.subscriptionIndex]}</td>
            </tr>
            <tr>
              <td>{t('Subscription Status')}</td>
              <td>{t(subscription.status)}</td>
            </tr>
            <tr>
              <td>{t('Billing Period')}</td>
              <td>
                {formatDateTime(lastPayment.createdAt)} -{' '}
                {formatDateTime(
                  lastPayment.subscriptionIndex > 3
                    ? moment(lastPayment.createdAt).add('1', 'year')
                    : moment(lastPayment.createdAt).add('31', 'day')
                )}
              </td>
            </tr>
          </tbody>
        </table>
      )}
      {subscription &&
        subscription.status === 'active' &&
        !lastPayment &&
        'Wait to first payment'}
    </React.Fragment>
  )
}

SubscriptionInfo.propTypes = {
  t: PropTypes.func.isRequired,
  classes: PropTypes.object.isRequired,
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  unsubscribe: PropTypes.func.isRequired,
  createSubscription: PropTypes.func.isRequired,
  subscription: PropTypes.object,
  lastPayment: PropTypes.object
}

export default SubscriptionInfo
