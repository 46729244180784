import React, { useState } from 'react'
import PropTypes from 'prop-types'
import SelectMap from 'components/SelectMap'
import Label from 'components/Label'
import TextField from '@material-ui/core/TextField'
import cn from 'classnames'
import { isMaxRowsMap, testMap } from 'utils/utils'
import { useDebounce } from 'react-use'
import isEqual from 'react-fast-compare'

const TabMap = ({ map, updateMap, classes, t }) => {
  const [values, updateValues] = useState(map)
  useDebounce(
    () => {
      if (!isEqual(map, values)) {
        updateMap(values)
      }
    },
    200,
    [values]
  )

  return (
    <div>
      <Label
        className={cn(classes.label, classes.labelText)}
        primary={t('texts')}
      />

      <TextField
        label={t('address in map')}
        value={values.textAddress}
        onChange={e => updateValues({ ...values, textAddress: e.target.value })}
        className={classes.textField}
        margin="normal"
        placeholder={t('paste')}
        InputLabelProps={{
          shrink: true,
          className: classes.inputLabel
        }}
      />
      <br />
      <TextField
        label={t('location information')}
        value={values.textBubble}
        onChange={e => {
          if (isMaxRowsMap(e.target, 3)) {
            updateValues({
              ...values,
              textBubble: e.target.value,
              textBubbleRows: testMap(e.target, 3)
            })
          }
        }}
        rowsMax={3}
        className={classes.textField}
        margin="normal"
        placeholder={t('paste')}
        InputLabelProps={{
          shrink: true,
          className: classes.inputLabel
        }}
        multiline
        inputProps={{
          style: { overflow: 'hidden' }
        }}
      />
      <Label
        className={classes.label}
        primary={t('main map')}
        secondary={t('farther')}
      />
      <SelectMap
        values={values.mapFull || { center: null, label: '' }}
        onChange={mapFull => updateValues({ ...values, mapFull })}
      />
      <Label className={classes.label} primary={t('more detailed')} />
      <SelectMap
        showCross
        values={values.mapDetail || { center: null, label: '' }}
        onChange={mapDetail => updateValues({ ...values, mapDetail })}
      />
    </div>
  )
}

TabMap.propTypes = {
  updateMap: PropTypes.func.isRequired,
  map: PropTypes.object.isRequired,
  classes: PropTypes.object.isRequired,
  t: PropTypes.func.isRequired
}

export default TabMap
