import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { withI18n } from 'react-i18next'
import AddIcon from '@material-ui/icons/Add'

import Avatar from '@material-ui/core/Avatar'
import List from '@material-ui/core/List'
import { withStyles } from '@material-ui/core'
import PropertiesList from 'components/PropertisList/PropertiesList'
import { forceCheck } from 'react-lazyload'
import Button from '@material-ui/core/Button/Button'
import Grid from '@material-ui/core/Grid/Grid'
import SearchInput from 'components/SearchInput'
import { filterItem } from '../../../../../../utils/utils'
import SortMenu from '../../../../../../components/SortMenu'
import { useSessionStorageState } from 'react-storage-hooks'

const LOAD_MORE_COUNT = 5

const styles = theme => ({
  header: {
    marginBottom: theme.spacing.unit,
    color: 'white'
  },
  title: {
    paddingLeft: 12
  },
  list: {
    height: 'calc(100vh - 88px - 60px)',
    overflow: 'auto',
    color: 'white'
  },
  addIcon: {
    backgroundColor: theme.palette.primary.light,
    marginRight: theme.spacing.unit * 2
  }
})

const getNewestChild = (id, items) => {
  const sorted = items.filter(i => i.parentId === id).sort((a, b) => b - a)
  return sorted.length > 0 ? sorted[0].finishedTime || 0 : 0
}

const CreateProjectDialog = ({
  classes,
  items,
  onDownload,
  onClone,
  onAdd,
  onSelect,
  agentItems,
  t,
  onCopySettings,
  onDeleteProperty,
  changePropertyValue,
  newsletter,
  updateNewsletter
}) => {
  const [loadCount, setLoadCount] = useState(0)
  const [sortItems, setSortItems, writeError] = useSessionStorageState(
    'sortItems',
    1
  )

  const [search, setSearch] = useState('')
  if (sortItems === 2) {
    items = items.sort((a, b) => {
      const maxA = getNewestChild(a.key, items)
      const maxB = getNewestChild(b.key, items)
      return maxB - maxA
    })
  } else if (sortItems === 1) {
    items = items.sort((a, b) => (b.updatedTime || 0) - (a.updatedTime || 0))
  } else {
    items = items.sort((a, b) => b.creationTime - a.creationTime)
  }

  if (sortItems === 3) {
    items = items.filter(i =>
      newsletter.items.find(k => i.key === k.projectKey)
    )
  }

  const filteredItems = filterItem(items, search)
  return (
    <React.Fragment>
      <Grid
        container
        justify="space-between"
        alignItems="center"
        className={classes.header}>
        <Grid item>
          <Button onClick={onAdd}>
            <Avatar className={classes.addIcon}>
              <AddIcon />
            </Avatar>
            {t('create new project')}
          </Button>
        </Grid>
        <Grid item>
          <Grid container alignItems="center">
            <Grid item>
              <SortMenu value={sortItems} onChange={setSortItems} />
            </Grid>
            <Grid item>
              <SearchInput
                value={search}
                onChange={value => {
                  setLoadCount(0)
                  setSearch(value)
                }}
              />
            </Grid>
          </Grid>
        </Grid>
      </Grid>
      <List
        component="nav"
        className={classes.list}
        onScroll={() => forceCheck()}>
        <PropertiesList
          aggregated
          withActions
          items={filteredItems}
          agentItems={agentItems}
          onDownload={onDownload}
          onSelect={onSelect}
          onClone={onClone}
          newsletter={newsletter}
          onChangeActiveNewsletter={(projectKey, active) => {
            if (active) {
              updateNewsletter({
                ...newsletter,
                items: [
                  {
                    projectKey
                  },
                  ...newsletter.items
                ]
              })
            } else {
              updateNewsletter({
                ...newsletter,
                items: newsletter.items.filter(i => i.projectKey !== projectKey)
              })
            }
          }}
          onChangeNewsletterItem={(projectKey, newsletterItem) =>
            changePropertyValue(
              projectKey,
              'newsletterVideoKey',
              newsletterItem
            )
          }
          onCopySettings={onCopySettings}
          onDeleteProperty={onDeleteProperty}
        />
      </List>
    </React.Fragment>
  )
}

CreateProjectDialog.propTypes = {
  t: PropTypes.func.isRequired,
  classes: PropTypes.func.isRequired,
  items: PropTypes.array.isRequired,
  agentItems: PropTypes.array.isRequired,
  onClone: PropTypes.func.isRequired,
  onDownload: PropTypes.func.isRequired,
  onAdd: PropTypes.func.isRequired,
  onSelect: PropTypes.func.isRequired,
  changePropertyValue: PropTypes.func.isRequired,
  onCopySettings: PropTypes.func.isRequired
}

export default withI18n()(withStyles(styles)(CreateProjectDialog))
