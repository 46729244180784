export default theme => ({
  label: {
    marginBottom: theme.spacing.unit * 2
  },
  divider: {
    height: 1,
    backgroundColor: 'rgba(255, 255, 255, 0.12)',
    width: '100%',
    margin: `0 ${theme.spacing.unit * 3}px`
  },
  labelTextColor: {
    ...theme.typography.h6,
    marginRight: theme.spacing.unit
  },
  colorsPalette: {
    marginLeft: theme.spacing.unit
  }
})
