import { compose, withHandlers } from 'recompose'
import { withStyles } from '@material-ui/core/styles'
import styles from './SubscriptionHistory.styles'
import { withI18n } from 'react-i18next'
import connect from 'react-redux/es/connect/connect'
import { firebaseConnect } from 'react-redux-firebase'
import get from 'lodash/get'
import map from 'lodash/map'
import { withNotifications } from 'modules/notification'
import firebaseApp from 'firebase'
import withLoader from '../../modules/loader/components/withLoader'

export default compose(
  connect(({ firebase: { auth, profile } }) => ({
    auth,
    profile
  })),
  firebaseConnect(({ auth }) => [`/paymentsHistoryPublic/${auth.uid}`]),
  connect(({ firebase: { data } }, { auth }) => ({
    items: map(
      get(data, `paymentsHistoryPublic.${auth.uid}`),
      (value, key) => ({
        ...value,
        key
      })
    ).sort((a, b) => b.time - a.time)
  })),
  withLoader,
  withNotifications,
  withHandlers({
    handleDownloadInvoice: props => key => {
      props.setLoader()
      firebaseApp
        .functions()
        .httpsCallable('getInvoice')({
          key
        })
        .then(d => {
          var w = window.open('about:blank')
          w.document.open()
          w.document.write(d.data)
          w.document.close()
        })
        .finally(() => {
          props.clearLoader()
        })
    }
  }),
  withStyles(styles),
  withI18n()
)
