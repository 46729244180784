import React from 'react'
import { withStyles } from '@material-ui/core/styles/index'
import PropTypes from 'prop-types'
import cn from 'classnames'

const styles = theme => ({
  label: {
    marginBottom: 5,
    color: theme.palette.primary.main
  },
  secondary: {
    color: 'white',
    opacity: 0.7
  },
  labelMain: {
    fontSize: 20
  }
})

const Label = ({ classes, primary, secondary, className, main }) => (
  <div className={className}>
    <label className={cn(classes.label, main && classes.labelMain)}>
      {primary}
    </label>{' '}
    {secondary && <span className={classes.secondary}>({secondary})</span>}
  </div>
)

Label.propTypes = {
  primary: PropTypes.string.isRequired,
  secondary: PropTypes.string.isRequired,
  classes: PropTypes.object.isRequired,
  className: PropTypes.object,
  main: PropTypes.bool.isRequired
}

export default withStyles(styles)(Label)
