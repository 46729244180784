import {branch, compose, renderComponent, withHandlers, withProps} from 'recompose'
import { withStyles } from '@material-ui/core/styles'
import styles from './RegisterLayout.styles'
import connect from 'react-redux/es/connect/connect'
import { withFirebase } from 'react-redux-firebase'
import { withRouter } from 'react-router-dom'
import withSystem from '../../modules/system/components/withSystem'
import { spinnerWhileLoading } from '../../utils/components'
import { UserIsAuthenticated, UserIsNotRegistered } from '../../utils/router'
import { LOGIN_PATH } from '../../constants/paths'
import { connectedRouterRedirect } from 'redux-auth-wrapper/history4/redirect'
import LoadingSpinner from '../../components/LoadingSpinner'
import DisableSubscriptionInfo from '../../components/DisableSubscriptionInfo'

export default compose(
  withFirebase,
  withRouter,
  UserIsAuthenticated, // redirect to /login if user is not authenticated,
  UserIsNotRegistered,
  withSystem,
  connect(({ firebase: { auth, profile }, system }) => ({
    auth,
    profile,
    showSelectGroup: system.showSelectGroup
  })),
  withProps(console.log),
  spinnerWhileLoading(['profile']),
  withHandlers({
    handleLogout: props => () => {
      props.firebase.logout()
      props.history.push(LOGIN_PATH)
    }
  }),
  withStyles(styles)
)
