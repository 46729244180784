export default theme => ({
  addons: {
    position: 'absolute',
    top: 7,
    right: 145
  },
  buttonIcon: {
    marginRight: 6
  },
  dropDownIcon: {
    marginLeft: 6
  },
  groupName: {
    color: 'white',
    position: 'relative',
    top: 2
  },
  help: {
    position: 'absolute',
    top: 0,
    right: 75
  },
  paymentInfo: {
    display: 'inline-block',
    marginRight: theme.spacing.unit * 3,
    border: '1px solid white',
    padding: '2px 10px',
    position: 'relative',
    top: 1
  },
  goToAdminBtn: {
    marginRight: theme.spacing.unit * 3
  },
  menu: {
    marginTop: 33
  }
})
