import React from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { compose } from 'recompose'
import { withStyles } from '@material-ui/core/styles'
import * as actions from '../actions'
import LinearProgress from '@material-ui/core/LinearProgress'
import Dialog from '@material-ui/core/Dialog'
import DialogTitle from '@material-ui/core/DialogTitle'

const styles = theme => ({
  root: {
    zIndex: `${theme.zIndex.snackbar}!important`
  }
})

export const Loader = ({ show, progress, classes }) => (
  <Dialog open={show} keepMounted className={classes.root}>
    <DialogTitle id="form-dialog-title">Processing ...</DialogTitle>
    <LinearProgress
      variant={progress ? 'determinate' : 'indeterminate'}
      value={progress && progress * 100}
    />
  </Dialog>
)

Loader.propTypes = {
  show: PropTypes.bool.isRequired,
  progress: PropTypes.number,
  classes: PropTypes.object.isRequired
}

const enhance = compose(
  connect(
    ({ loader: { show, progress } }) => ({ show, progress }),
    actions
  ),
  withStyles(styles)
)

export default enhance(Loader)
