import {
  SYSTEM_OPEN_SELECT_GROUP,
  SYSTEM_CLOSE_SELECT_GROUP
} from './actionTypes'

export const openSelectGroup = () => dispatch => {
  dispatch({
    type: SYSTEM_OPEN_SELECT_GROUP
  })
}

export const closeSelectGroup = () => dispatch => {
  dispatch({
    type: SYSTEM_CLOSE_SELECT_GROUP
  })
}
