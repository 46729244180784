import React from 'react'
import PropTypes from 'prop-types'
import Button from '@material-ui/core/Button'
import TextField from '@material-ui/core/TextField'
import Dialog from '@material-ui/core/Dialog'
import DialogActions from '@material-ui/core/DialogActions'
import DialogContent from '@material-ui/core/DialogContent'
import DialogTitle from '@material-ui/core/DialogTitle'
import { withStyles } from '@material-ui/core/styles/index'
import { withI18n } from 'react-i18next'
import ImageRotationDialog from 'components/ImageRotationDialog'

const styles = theme => ({
  avatarWrap: {
    marginTop: 11
  },
  avatarImage: {
    maxWidth: 200,
    marginBottom: 10
  }
})

class DetailDialog extends React.Component {
  static propTypes = {
    open: PropTypes.bool,
    onSubmit: PropTypes.func,
    onClose: PropTypes.func,
    edit: PropTypes.bool,
    classes: PropTypes.object.isRequired,
    onAddImage: PropTypes.func.isRequired,
    onDeleteImage: PropTypes.func.isRequired
  }

  state = {
    text: '',
    inputInstance: 0,
    image: null
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.open && !this.props.open) {
      this.setState({
        text: '',
        image: null
      })
    }
  }

  handleChangeFile = e => {
    let file = e.target.files[0]
    if (file) {
      const reader = new FileReader()
      reader.onload = () => {
        const dataURL = reader.result
        this.setState({
          file,
          dataURL,
          inputInstance: this.state.inputInstance + 1
        })
      }
      reader.readAsDataURL(file)

      return
      if (this.props.edit) {
        this.props.onAddImage(file)
      } else {
        const reader = new FileReader()

        reader.onload = e => {
          this.setState({
            image: { file, src: e.target.result }
          })
        }
        reader.readAsDataURL(file)
      }
    }
  }

  handleClickDeleteAvatar = e => {
    if (this.props.edit) {
      this.props.onDeleteImage()
    } else {
      this.setState({
        image: null
      })
    }
  }

  renderTextBlock(name, label) {
    return (
      <TextField
        autoFocus={name === 'name'}
        margin="dense"
        label={label}
        fullWidth
        value={this.props.values[name]}
        onChange={e =>
          this.props.onChange({
            ...this.props.values,
            [name]: e.target.value
          })
        }
      />
    )
  }

  render() {
    const { classes, edit, values, t } = this.props
    const { image } = this.state
    const imgSrc = (image && image.src) || values.image
    return (
      <React.Fragment>
        <input
          accept="image/x-png,image/gif,image/jpeg,image/png,image/bmp"
          type="file"
          style={{ display: 'none' }}
          onChange={this.handleChangeFile}
          ref="input"
          key={this.state.inputInstance}
        />
        <Dialog
          open={this.props.open}
          onClose={this.props.onClose}
          fullWidth
          scroll={'body'}>
          <DialogTitle>
            {this.props.edit ? t('edit agent') : t('new agent')}
          </DialogTitle>
          <DialogContent>
            {this.renderTextBlock('name', t('name and surname'))}
            {this.renderTextBlock('phone', t('phone number'))}
            {this.renderTextBlock('email', t('email'))}
            <div className={classes.avatarWrap}>
              {imgSrc && (
                <img
                  className={classes.avatarImage}
                  src={imgSrc}
                  alt="avatar"
                />
              )}
              <div>
                {!imgSrc && (
                  <Button
                    variant="contained"
                    color="primary"
                    onClick={() => this.refs.input.click()}>
                    {t('add photo')}
                  </Button>
                )}
                {imgSrc && (
                  <React.Fragment>
                    <Button
                      variant="contained"
                      color="primary"
                      onClick={() => this.refs.input.click()}>
                      {t('change photo')}
                    </Button>{' '}
                    <Button
                      variant="contained"
                      color="primary"
                      onClick={this.handleClickDeleteAvatar}>
                      {t('delete photo')}
                    </Button>
                  </React.Fragment>
                )}
              </div>
            </div>
          </DialogContent>
          <DialogActions>
            <Button onClick={this.props.onClose} color="primary">
              {t('close')}
            </Button>
            {!edit && (
              <Button
                onClick={e =>
                  this.props.onSubmit(this.state.image && this.state.image.file)
                }
                variant="contained"
                color="primary"
                disabled={!this.props.values.name}>
                {t('create')}
              </Button>
            )}
          </DialogActions>
        </Dialog>
        <ImageRotationDialog
          width={150}
          height={200}
          open={this.state.file}
          file={
            this.state.file
              ? { file: this.state.file, dataURL: this.state.dataURL }
              : null
          }
          onClose={() => this.setState({ file: null })}
          onSubmit={file => {
            if (this.props.edit) {
              this.props.onAddImage(file)
            } else {
              const reader = new FileReader()
              reader.onload = e => {
                this.setState({
                  image: { file, src: e.target.result }
                })
              }
              reader.readAsDataURL(file)
            }
          }}
        />
      </React.Fragment>
    )
  }
}

export default withI18n()(withStyles(styles)(DetailDialog))
