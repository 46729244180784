import React from 'react'
import PropTypes from 'prop-types'
import ProjectHeader from 'components/ProjectHeader/ProjectHeader'
import { Route, Switch, Redirect } from 'react-router-dom'
import Composition from '../../routes/Composition'
import Settings from '../../routes/Settings'
import Send from '../../routes/Send'
import ProjectDisabledDialog from '../ProjectDisabledDialog/ProjectDisabledDialog'

const renderChildren = (routes, match, parentProps) =>
  routes.map(route => (
    <Route
      key={`${match.url}-${route.path}`}
      path={`${match.url}/${route.path}`}
      render={props => <route.component {...parentProps} {...props} />}
    />
  ))

export const PropertyPage = ({
  classes,
  project,
  updateProjectName,
  goToHome,
  projectId,
  match,
  groupId,
  colors
}) => {
  console.log(project)
  return (
    <React.Fragment>
      <ProjectHeader
        name={project.name}
        onChangeName={updateProjectName}
        goToHome={goToHome}
        prevUrl={match.url}
      />
      <div className={classes.root}>
        <Switch>
          {renderChildren([Composition], match, {
            project,
            groupId,
            projectId
          })}
          {renderChildren([Settings], match, {
            project,
            groupId,
            projectId,
            colors
          })}
          {renderChildren([Send], match, {
            project,
            groupId,
            projectId
          })}
          <Redirect from={'/'} to={`${projectId}/${Composition.path}`} />
        </Switch>
      </div>
      <ProjectDisabledDialog open={project.processing} />
    </React.Fragment>
  )
}

PropertyPage.proptypes = {
  classes: PropTypes.object.isRequired,
  goToHome: PropTypes.func.isRequired,
  project: PropTypes.object.isRequired,
  updateProjectName: PropTypes.func.isRequired,
  match: PropTypes.object.isRequired,
  colors: PropTypes.object.isRequired
}

export default PropertyPage
