import grey from '@material-ui/core/es/colors/grey'

export default theme => ({
  root: {
    ...theme.flexColumnCenter,
    justifyContent: 'center',
    height: '100%',
    width: '100%',
    fontWeight: 400
  },
  title: {
    marginTop: theme.spacing.unit * 2,
    marginBottom: theme.spacing.unit * 2
  },
  wrap: {
    width: 400
  },
  panel: {
    //minWidth: '250px',
    //minHeight: '270px',
    backgroundColor: 'rgba(255,255,255,0.75)',
    color: 'rgba(0, 0, 0, 0.87)'
  },
  header: {
    padding: theme.spacing.unit * 4,
    paddingBottom: 0,
    textAlign: 'center',
    '&>img': {
      width: 170
    }
  },
  content: {
    padding: theme.spacing.unit * 4,
    paddingTop: 0,
    textAlign: 'center'
  },
  button: {
    display: 'block',
    width: '100%',
    marginTop: theme.spacing.unit * 2,
    backgroundColor: 'white'
  },
  logo: {
    width: 350
  },
  divider: {
    height: 1,
    backgroundColor: grey[400],
    marginLeft: -theme.spacing.unit * 8,
    marginRight: -theme.spacing.unit * 8,
    marginBottom: theme.spacing.unit * 4,
    marginTop: theme.spacing.unit * 4
  },
  warningImg: {
    width: 30
  },
  warningText: {
    textAlign: 'justify',
    width: 305,
    color: grey[500]
  },
  redirectButton: {
    marginBottom: theme.spacing.unit * 2,
    marginTop: theme.spacing.unit * 2,
    '& img': {
      height: 21,
      marginRight: theme.spacing.unit * 2
    }
  }
})
