import React from 'react'
import MenuItem from '@material-ui/core/MenuItem'
import { withI18n } from 'react-i18next'
import Select from '@material-ui/core/Select'

const SortMenu = ({ value, onChange, t }) => {
  const handleChange = e => {
    onChange(e.target.value)
  }

  return (
    <Select value={value} onChange={handleChange} displayEmpty>
      <MenuItem value={0}>{t('By creation')}</MenuItem>
      <MenuItem value={1}>{t('By last activity')}</MenuItem>
      <MenuItem value={2}>{t('By created video')}</MenuItem>
      <MenuItem value={3}>{t('Only newsletter')}</MenuItem>
    </Select>
  )
}

export default withI18n()(SortMenu)
