import React, { Component } from 'react'
import Button from '@material-ui/core/Button'
import PropTypes from 'prop-types'
import List from '@material-ui/core/List'
import ListItem from '@material-ui/core/ListItem'
import Radio from '@material-ui/core/Radio'
import ListItemText from '@material-ui/core/ListItemText'
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction'
import IconButton from '@material-ui/core/IconButton'
import DeleteIcon from '@material-ui/icons/Delete'
import EditIcon from '@material-ui/icons/Edit'
import TitleIcon from '@material-ui/icons/Title'
import moment from 'moment'
import CreateContactDialog from './CreateContactDialog'
import EditContactTitleDialog from './EditContactTitleDialog'
import ContactDetailDialog from '../ContactDetailDialog'
import ConfirmDialog from 'components/ConfirmDialog'
import ListItemAvatar from '@material-ui/core/ListItemAvatar'
import Avatar from '@material-ui/core/Avatar'
import AvatarIcon from '../../../AgentList/components/AgentListPage/avatar.png'

class Contact extends Component {
  state = {
    showCreateDialog: false,
    showEditTitleDialog: false,
    showConfirmDelete: false,
    editTitleValue: '',
    editKey: null
  }

  getMaxNumberOfContacts() {
    const { lastPayment } = this.props
    if (!lastPayment || lastPayment.status === 'ACTIVE') {
      return 1
    } else {
      const { subscriptionIndex } = lastPayment
      if ([2, 6].indexOf(subscriptionIndex) !== -1) {
        return 20
      } else if (subscriptionIndex === 3 || subscriptionIndex === 7) {
        return Infinity
      } else {
        return 1
      }
    }
  }

  getContactValues = contactKey =>
    this.props.contactItems.find(({ key }) => contactKey === key)

  render() {
    const {
      classes,
      contactItems,
      match,
      history,
      addContact,
      deleteContact,
      updateContactName,
      updateContact,
      addLogo,
      project,
      setContact,
      isAdmin,
      colors,
      updateGroupColors,
      onlyGroupColors,
      selectEnable,
      modifyEnable,
      t
    } = this.props
    const { contactId } = match.params
    const detailContact = contactId && this.getContactValues(contactId)

    console.log(contactItems)

    return (
      <div className={classes.content}>
        {isAdmin && modifyEnable && (
          <Button
            variant="contained"
            color="primary"
            className={classes.button}
            disabled={this.getMaxNumberOfContacts() <= contactItems.length}
            onClick={() => this.setState({ showCreateDialog: true })}>
            {t('create new contact')}
          </Button>
        )}
        <div className={classes.listWrap}>
          <List component="nav">
            {contactItems.map(item => (
              <ListItem
                button={selectEnable}
                onClick={() => selectEnable && setContact(item.key)}>
                {selectEnable && (
                  <Radio
                    checked={project.contactKey === item.key}
                    onChange={() => setContact(item.key)}
                    tabIndex={-1}
                    disableRipple
                  />
                )}
                <ListItemAvatar>
                  <Avatar
                    alt="avatar"
                    src={item.values.contact.logo}
                                     />
                </ListItemAvatar>
                <ListItemText
                  primary={item.values.name}
                  secondary={moment(item.values.creationTime).format(
                    'YYYY-MM-DD HH:mm'
                  )}
                />
                {isAdmin && modifyEnable && (
                  <ListItemSecondaryAction>
                    <IconButton
                      onClick={() => history.push(match.url + '/' + item.key)}>
                      <EditIcon />
                    </IconButton>
                    <IconButton
                      onClick={() => {
                        this.setState({
                          showEditTitleDialog: true,
                          editTitleValue: item.values.name,
                          editKey: item.key
                        })
                      }}>
                      <TitleIcon />
                    </IconButton>
                    <IconButton
                      onClick={() => {
                        this.setState({
                          showConfirmDelete: true,
                          currentKey: item.key
                        })
                      }}>
                      <DeleteIcon />
                    </IconButton>
                  </ListItemSecondaryAction>
                )}
              </ListItem>
            ))}
          </List>
        </div>
        {detailContact && (
          <ContactDetailDialog
            open={detailContact}
            values={detailContact && detailContact.values}
            onClose={() => {
              const path = match.url.substr(
                0,
                match.url.length - contactId.length - 1
              )
              history.push(path)
            }}
            addLogo={addLogo}
            onChange={values => updateContact(contactId, values)}
            isAdmin={isAdmin}
            colors={colors.contact}
            onlyGroupColors={onlyGroupColors}
            updateGroupColors={(name, contactItemColor) =>
              updateGroupColors('contact', {
                ...colors.contact,
                [name]: contactItemColor
              })
            }
          />
        )}
        <CreateContactDialog
          open={this.state.showCreateDialog}
          onClose={() => this.setState({ showCreateDialog: false })}
          onSubmit={name => {
            this.setState({ showCreateDialog: false })
            addContact(name)
          }}
        />
        <EditContactTitleDialog
          open={this.state.showEditTitleDialog}
          value={this.state.editTitleValue}
          onClose={() => this.setState({ showEditTitleDialog: false })}
          onSubmit={name => {
            this.setState({ showEditTitleDialog: false })
            updateContactName(this.state.editKey, name)
          }}
        />
        <ConfirmDialog
          open={this.state.showConfirmDelete}
          onDisagree={() => {
            this.setState({
              showConfirmDelete: false,
              currentKey: null
            })
          }}
          onAgree={() => {
            deleteContact(this.state.currentKey)
            this.setState({
              showConfirmDelete: false,
              currentKey: null
            })
          }}
          title={'delete contact'}
        />
      </div>
    )
  }
}

Contact.propTypes = {
  isAdmin: PropTypes.bool.isRequired,
  classes: PropTypes.object.isRequired,
  contacts: PropTypes.array.isRequired,
  onChange: PropTypes.func.isRequired,
  values: PropTypes.func.isRequired,
  updateGroupColors: PropTypes.func.isRequired,
  onlyGroupColors: PropTypes.bool.isRequired,
  selectEnable: PropTypes.bool,
  modifyEnable: PropTypes.bool,
  colors: PropTypes.object.isRequired,
  lastPayment: PropTypes.object.isRequired
}

export default Contact
