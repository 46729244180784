import React, { Fragment } from 'react'
import PropTypes from 'prop-types'
import { withStyles } from '@material-ui/core/styles'
import Button from '@material-ui/core/Button'
import grey from '@material-ui/core/colors/grey'
import red from '@material-ui/core/colors/red'
import FormControl from '@material-ui/core/FormControl'
import InputLabel from '@material-ui/core/InputLabel'
import Select from '@material-ui/core/Select'
import MenuItem from '@material-ui/core/MenuItem'
import FormControlLabel from '@material-ui/core/FormControlLabel'
import Checkbox from '@material-ui/core/Checkbox'
import Slider from '@material-ui/core/Slider'
import { withI18n } from 'react-i18next'

const styles = theme => ({
  root: {
    width: 200,
    marginLeft: 20,
    position: 'relative'
  },
  cutLabel: {
    fontWeight: 600
  },
  infoLabel: {
    color: grey[400]
  },
  offset: {
    margin: '10px 0',
    display: 'flex',
    height: 81
  },
  offsetLabel: {
    paddingLeft: 32,
    color: grey[500]
  },
  buttonDelete: {
    width: '100%',
    marginTop: 10,
    backgroundColor: red[500],
    '&:hover': {
      backgroundColor: red[700]
    }
  },
  buttonChange: {
    width: '100%',
    marginTop: 20
  },
  selectBubbleType: {
    width: '100%'
  },
  labelCut: {
    display: 'block',
    marginTop: 23
  },
  roomSize: {
    position: 'absolute',
    bottom: 0,
    left: 0
  },
  slider: {
    padding: '0px 22px'
  },
  sliderWrap: {
    height: 82,
    marginLeft: -9,
    position: 'absolute'
  }
})

const ToolPanel = props => {
  const { classes, values, onChange, enableZoom, t } = props
  return (
    <div className={classes.root}>
      <FormControl className={classes.selectBubbleType}>
        <InputLabel>{t('info bubbles')}</InputLabel>
        <Select
          value={values.bubbleType}
          onChange={e => onChange({ ...values, bubbleType: e.target.value })}>
          <MenuItem value="empty">
            <em>{t('not selected')}</em>
          </MenuItem>
          <MenuItem value="info-text">{t('info text')}</MenuItem>
          <MenuItem value="call-out-1">{t('info bubble 1x')}</MenuItem>
          <MenuItem value="call-out-2">{t('info bubble 2x')}</MenuItem>
          {enableZoom && <MenuItem value="zoom">{t('magnifier')}</MenuItem>}
        </Select>
      </FormControl>

      {props.showOffset && (
        <Fragment>
          <InputLabel className={classes.labelCut}>
            {t('clipping mask')}
          </InputLabel>
          <div className={classes.offset}>
            <div className={classes.sliderWrap}>
              <Slider
                classes={{ container: classes.slider }}
                value={100 - props.offset}
                onChange={(e, value) => props.onChangeOffset(100 - value)}
                vertical
                min={0}
                max={100}
              />
            </div>

            <div className={classes.offsetLabel}>{t('clipping mask desc')}</div>
          </div>
        </Fragment>
      )}

      <Button
        variant="contained"
        color="primary"
        className={classes.buttonChange}
        onClick={props.onChangeImage}>
        {t('change image')}
      </Button>

      {props.showRoomSizeToggle && (
        <FormControlLabel
          classes={{ root: classes.roomSize }}
          control={
            <Checkbox
              checked={props.showRoomSize}
              onChange={e => props.onChangeRoomSize(e.target.checked)}
              color="primary"
            />
          }
          label={t('area')}
        />
      )}
    </div>
  )
}

ToolPanel.propTypes = {
  classes: PropTypes.object.isRequired,
  showOffset: PropTypes.number.isRequired,
  offset: PropTypes.number.isRequired,
  onChangeOffset: PropTypes.func.isRequired,
  values: PropTypes.object.isRequired,
  onChange: PropTypes.func.isRequired,
  onChangeImage: PropTypes.func.isRequired,
  enableZoom: PropTypes.bool,
  showRoomSizeToggle: PropTypes.bool,
  showRoomSize: PropTypes.bool,
  onChangeRoomSize: PropTypes.func
}

export default withI18n()(withStyles(styles)(ToolPanel))
