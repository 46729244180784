import React, { useRef, useState } from 'react'
import PropTypes from 'prop-types'
import { Redirect, Route, Switch } from 'react-router-dom'
import CompositionHeader from 'components/CompositionHeader/CompositionHeader'
import { MAX_IMAGES, MAX_TEXT_BLOCKS } from 'defaultValues'
import Texts from '../../routes/Texts'
import Map from '../../routes/Map'
import Agent from '../../routes/Agent'
import Contact from '../../routes/Contact'
import Description from '../../routes/Description'
import Image from '../../routes/Image'
import ImageRotationDialog from 'components/ImageRotationDialog'
import { DESCRIPTION_PATH } from 'constants/paths'
import { TEMPLATE_HEIGHT, TEMPLATE_WIDTH } from 'utils/utils'

const renderChildren = (routes, match, parentProps) =>
  routes.map(route => (
    <Route
      key={`${match.url}-${route.path}`}
      path={`${match.url}/${route.path}`}
      render={props => <route.component {...parentProps} {...props} />}
    />
  ))

const CompositionPage = ({
  classes,
  match,
  project,
  goToPage,
  projectId,
  groupId,
  addTextBlock,
  removeTextBlock,
  setItemsSort,
  setImage,
  addImageBlock,
  removeImageBlock,
  setEditTime
}) => {
  const itemText = null
  const selected = null

  const fileRef = useRef()
  const [fileId, setFileId] = useState(0)
  const [file, setFile] = useState(null)

  const handleChangeFile = e => {
    let file = e.target.files[0]
    if (file) {
      //addImageBlock(file)

      const reader = new FileReader()
      reader.onload = function() {
        const dataURL = reader.result
        setFile({ file, dataURL })
        setFileId(fileId + 1)
      }
      reader.readAsDataURL(file)
    }
  }

  const handleChangeFileImage = (file, imageId) => {
    const reader = new FileReader()
    reader.onload = function() {
      const dataURL = reader.result
      setFile({ file, dataURL, imageId })
      setFileId(fileId + 1)
    }
    reader.readAsDataURL(file)
  }

  return (
    <React.Fragment>
      <input
        accept="image/x-png,image/gif,image/jpeg,image/png,image/bmp"
        type="file"
        style={{ display: 'none' }}
        onChange={handleChangeFile}
        ref={fileRef}
        key={fileId}
      />
      <CompositionHeader
        items={(project.itemsSort || []).map(item => {
          const imageItem = project.images.find(i => i.id === item.id)
          const isEnable =
            !(item.type === 'image' && !imageItem) &&
            !(item.type === 'text' && !item.active)
          return {
            ...item,
            src:
              imageItem &&
              imageItem.img &&
              (imageItem.img.minPath || imageItem.img.path),
            isEnable,
            editDate: imageItem && imageItem.img && imageItem.img.editDate
          }
        })}
        onChange={values =>
          setItemsSort(values.map(i => ({ id: i.id, type: i.type })))
        }
        maxImages={MAX_IMAGES}
        maxTextBlocks={MAX_TEXT_BLOCKS}
        onAddTextBlock={() => {
          if (itemText && !itemText.intro) {
            // eslint-disable-next-line no-restricted-globals
            confirm(
              "The description field can't stay empty. Do you want to remove it?"
            )
          } else {
            addTextBlock()
          }
        }}
        onRemoveTextBlock={id => {
          removeTextBlock(
            id,
            document.location.pathname.endsWith(`${DESCRIPTION_PATH}/${id}`)
          )
        }}
        onRemoveImage={id => {
          removeImageBlock(id)
        }}
        selected={selected}
        showAgent
        showMap
        showContact
        onSelect={selected => {
          goToPage(selected)
        }}
        onAddImageBlock={itemId => {
          fileRef.current.click()
        }}
        path={match.path}
      />
      <Switch>
        <Redirect exact from={match.path} to={`${match.path}/${Texts.path}`} />
        {renderChildren([Texts], match, {
          project,
          projectId,
          groupId,
          setEditTime
        })}
        {renderChildren([Map], match, {
          project,
          projectId,
          groupId,
          setEditTime
        })}
        {renderChildren([Agent], match, {
          project,
          projectId,
          groupId,
          setEditTime,
          selectEnable: true
        })}
        {renderChildren([Contact], match, {
          project,
          projectId,
          groupId,
          selectEnable: true
        })}
        {renderChildren([Image], match, {
          project,
          projectId,
          groupId,
          setImage: handleChangeFileImage,
          setEditTime
        })}
        {renderChildren([Description], match, {
          project,
          projectId,
          groupId,
          removeTextBlock,
          setEditTime
        })}
      </Switch>
      <ImageRotationDialog
        width={TEMPLATE_WIDTH}
        height={TEMPLATE_HEIGHT}
        open={!!file}
        file={file}
        onClose={() => setFile(null)}
        onSubmit={f => {
          if (file.imageId === undefined) {
            addImageBlock(f)
          } else {
            setImage(f, file.imageId)
          }
        }}
        mimeType="image/jpeg"
      />
    </React.Fragment>
  )
}

CompositionPage.propTypes = {
  children: PropTypes.element,
  project: PropTypes.object.isRequired,
  goToPage: PropTypes.func.isRequired,
  addTextBlock: PropTypes.func.isRequired,
  removeTextBlock: PropTypes.func.isRequired,
  setItemsSort: PropTypes.func.isRequired,
  addImageBlock: PropTypes.func.isRequired,
  setImage: PropTypes.func.isRequired,
  removeImageBlock: PropTypes.func.isRequired,
  setEditTime: PropTypes.func.isRequired
}

export default CompositionPage
