export default theme => ({
  content: {
    maxWidth: 700
  },
  item: {
    '&+*': {
      marginTop: 50
    }
  },
  listWrap: {
    backgroundColor: '#313131',
    marginTop: 11
  }
})
