import React from 'react'
import PropTypes from 'prop-types'
import ColorsPallete from './ColorsPallete'

const ColorPicker = props => (
  <ColorsPallete
    numberOfColors={1}
    colors={{ color0: props.color }}
    onChange={v => props.onChange(v.color0)}
  />
)

ColorPicker.propTypes = {
  color: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired
}

export default ColorPicker
