import { connect } from 'react-redux'
import { compose, withHandlers } from 'recompose'
import { withStyles } from '@material-ui/core/styles'
import { firebaseConnect } from 'react-redux-firebase'
import styles from './SettingsNewsletter.styles'
import { get } from 'lodash'
import { spinnerWhileLoading } from '../../utils/components'
import { withRouter } from 'react-router-dom'
import { withI18n } from 'react-i18next'

export default compose(
  firebaseConnect(({ groupId }) => [`groups/${groupId}/newsletter`]),
  connect(({ firebase: { data } }, { groupId }) => ({
    newsletter: get(data, `groups.${groupId}.newsletter`)
  })),
  spinnerWhileLoading(['newsletter']),
  firebaseConnect(({ newsletter, groupId }) =>
    get(newsletter, 'items', []).map(
      ({ projectKey }) => `groups/${groupId}/properties/${projectKey}/name`
    )
  ),
  connect(({ firebase: { data } }, { groupId, newsletter }) => ({
    properties: newsletter.items.map(({ projectKey }) => ({
      projectKey,
      name: get(data, `groups.${groupId}.properties.${projectKey}.name`)
    })),
    name: newsletter.name,
    newsletterItems: newsletter.items
  })),
  withHandlers({
    changeNewsletterItems: props => items => {
      const { firebase, showError, groupId } = props
      return firebase
        .database()
        .ref(`/groups/${groupId}/newsletter/items`)
        .set(items)
        .catch(err => {
          console.error('Error:', err) // eslint-disable-line no-console
          showError(err.message || 'Could not update newsletter')
          return Promise.reject(err)
        })
    },
    changeName: props => name => {
      const { firebase, showError, groupId } = props
      return firebase
        .database()
        .ref(`/groups/${groupId}/newsletter/name`)
        .set(name)
        .catch(err => {
          console.error('Error:', err) // eslint-disable-line no-console
          showError(err.message || 'Could not update newsletter')
          return Promise.reject(err)
        })
    }
  }),
  // Show loading spinner while projects and collabProjects are loading
  // Add styles as classes prop
  withStyles(styles),
  withI18n()
)
