import React, { Component } from 'react'
import PropTypes from 'prop-types'

export default class VideoWrap extends Component {
  static propTypes = {
    src: PropTypes.string.isRequired,
    className: PropTypes.string,
    style: PropTypes.object,
    startTime: PropTypes.number,
    stopTime: PropTypes.number
  }

  constructor(props) {
    super(props)
    this.video = React.createRef()
  }

  componentDidMount() {
    this.video.current.currentTime = this.props.startTime || 0
  }

  handleMouseEnter = () => {
    this.video.current.currentTime = 0
    try {
      this.video.current.play().catch(e => console.log(e))
    } catch (e) {
      console.log(e)
    }
  }

  handleMouseLeave = () => {
    this.video.current.pause()
    this.video.current.currentTime = this.props.startTime || 0
  }

  handleTimeUpdate = e => {
    if (
      this.props.stopTime &&
      this.video.current.currentTime > this.props.stopTime
    ) {
      this.video.current.currentTime = 0
    }
  }

  render() {
    return (
      <video
        ref={this.video}
        style={this.props.style}
        className={this.props.className}
        loop
        onMouseEnter={this.handleMouseEnter}
        onMouseLeave={this.handleMouseLeave}
        onTimeUpdate={this.handleTimeUpdate}>
        <source src={this.props.src} type="video/mp4" />
      </video>
    )
  }
}
