import { compose } from 'redux'
import styles from './ContactPage.styles'
import { withStyles } from '@material-ui/core'
import { withI18n } from 'react-i18next'
import { firebaseConnect } from 'react-redux-firebase'
import connect from 'react-redux/es/connect/connect'
import map from 'lodash/map'
import get from 'lodash/get'
import withNotifications from 'modules/notification/components/withNotifications'
import withLoader from 'modules/loader/components/withLoader'
import { withHandlers } from 'recompose'
import firebase from 'firebase/app'
import { contact } from 'defaultValues'
import firebaseApp from 'firebase/app'
import { withEditTime } from 'utils/components'
import { addGroupFile } from 'utils/utils'

export default compose(
  firebaseConnect(({ groupId }) => {
    return [`/groups/${groupId}/contacts/`, `/groups/${groupId}/createdBy`]
  }),
  connect(({ firebase: { data, profile } }, { groupId, project, match }) => {
    const { contactId } = match.params

    const contactData = get(data, `groups.${groupId}.contacts`)
    const contactItems = contactData
      ? map(contactData, (values, key) => ({
          key,
          values
        })).filter(({ values }) => values && !values.deleted)
      : []

    const currentContact = contactItems.find(i => i.key === contactId)
    const currentLogo = currentContact && currentContact.values.contact.logo

    return {
      groupCreatedBy: get(data, `groups.${groupId}.createdBy`),
      currentLogo,
      contactItems
    }
  }),
  firebaseConnect(({ groupCreatedBy }) => {
    return [`users/${groupCreatedBy}/lastPayment`]
  }),
  connect(({ firebase: { data } }, { groupCreatedBy }) => {
    const lastPayment = get(data, `users.${groupCreatedBy}.lastPayment`)
    return {
      lastPayment
    }
  }),
  withLoader,
  withNotifications,
  withEditTime,
  withHandlers({
    addContact: props => name => {
      const { showError, groupId, history, match, projectId, setEditTime } = props
      return firebase
        .push(`groups/${groupId}/contacts`, contact(name))
        .then(snap => {
          const key = snap.key
          return firebase
            .set(`groups/${groupId}/properties/${projectId}/contactKey`, key)
            .then(() => firebase.updateProfile({ lastContact: key }))
            .then(() => history.push(`${match.url}/${key}`))
        })
        .then(() => setEditTime())
        .catch(err => {
          console.error('Error:', err) // eslint-disable-line no-console
          showError(err.message || 'Could not create contact')
          return Promise.reject(err)
        })
    },
    setContact: props => id => {
      const { showError, groupId, projectId, setEditTime } = props
      return firebase
        .set(`groups/${groupId}/properties/${projectId}/contactKey`, id)
        .then(() => firebase.updateProfile({ lastContact: id }))
        .then(() => setEditTime())
        .catch(err => {
          console.error('Error:', err) // eslint-disable-line no-console
          showError(err.message || 'Could not set contact')
          return Promise.reject(err)
        })
    },
    updateContactName: props => (contactId, name) => {
      const { showError, groupId } = props
      return firebase
        .set(`groups/${groupId}/contacts/${contactId}/name`, name)
        .catch(err => {
          console.error('Error:', err) // eslint-disable-line no-console
          showError(err.message || 'Could not create contact')
          return Promise.reject(err)
        })
    },
    updateContact: props => (contactId, values) => {
      const { showError, groupId } = props
      return firebase
        .set(`groups/${groupId}/contacts/${contactId}`, values)
        .catch(err => {
          console.error('Error:', err) // eslint-disable-line no-console
          showError(err.message || 'Could not update contact')
          return Promise.reject(err)
        })
    },
    deleteContact: props => (contactId, name) => {
      const { showError, groupId, clearLoader, setLoader } = props
      setLoader()
      return firebaseApp
        .functions()
        .httpsCallable('deleteContact')({
          contactId,
          groupId
        })
        .catch(err => {
          console.error('Error:', err) // eslint-disable-line no-console
          showError(err.message || 'Could not delete contact')
          return Promise.reject(err)
        })
        .finally(() => {
          clearLoader()
        })
    },
    addLogo: props => file => {
      const { showError, groupId, match, clearLoader, setLoader } = props
      const { contactId } = match.params
      setLoader()
      const storageRef = firebase.storage().ref()
      const path = `contacts/${contactId}.png`
      const ref = storageRef.child(`groups/${groupId}/${path}`)
      return addGroupFile(groupId, path, file)
        .then(() => {
          return ref.getDownloadURL()
        })
        .then(downloadURL => {
          return firebase.update(
            `groups/${groupId}/contacts/${contactId}/contact`,
            { logo: downloadURL, editDate: Date.now() }
          )
        })
        .catch(err => {
          console.error('Error:', err) // eslint-disable-line no-console
          showError(err.message || 'Could not upload image')
          return Promise.reject(err)
        })
        .finally(clearLoader)
    }
  }),
  withNotifications,
  withLoader,
  withI18n(),
  withStyles(styles)
)
