import React from 'react'
import PropTypes from 'prop-types'
import Navbar from 'components/Navbar'
import Subscription from 'containers/Subscription'
import BillingInformationForm from '../../components/BillingInformationForm'
import Paper from '@material-ui/core/Paper'
import Grid from '@material-ui/core/Grid'
import Typography from '@material-ui/core/Typography'
import Button from '@material-ui/core/Button'
import SubmissionError from 'redux-form/es/SubmissionError'

export const NotFoundPage = ({
  classes,
  billingInformation,
  handleSave,
  handleContinue,
  t
}) => (
  <div>
    <Typography variant="h4" className={classes.title}>
      {t('Invoicing info')}
    </Typography>
    <Paper className={classes.billingForm}>
      <BillingInformationForm
        onSubmit={handleSave}
        showNext
        initialValues={billingInformation}
      />
    </Paper>
  </div>
)

NotFoundPage.propTypes = {
  classes: PropTypes.object,
  t: PropTypes.func.isRequired
}

export default NotFoundPage
