import React from 'react'
import PropTypes from 'prop-types'
import { withStyles } from '@material-ui/core/styles/index'
import { withI18n } from 'react-i18next'

const styles = theme => ({
  roomTextsWrap: {
    whisteSpace: 'nowrap',
    transform: 'skewX(-10deg)',
    marginBottom: 12,
    marginLeft: 5
  },
  roomName: {
    border: 'none',
    color: 'white',
    backgroundColor: '#313131',
    display: 'inline-block',
    position: 'relative',
    zIndex: 1,
    fontSize: 30,
    padding: '10px 13px',
    borderRadius: 3
  },
  roomSize: {
    border: 'none',
    color: 'black',
    backgroundColor: 'white',
    display: 'inline-block',
    position: 'relative',
    fontSize: 24,
    padding: '10px 13px',
    borderRadius: 3,
    bottom: -12,
    left: -4
  }
})

const RoomInfo = ({ classes, t, ...props }) => (
  <div className={classes.roomTextsWrap}>
    <div
      className={classes.roomName}
      style={{ color: props.nameColor, backgroundColor: props.nameBg }}>
      {t('living room')}
    </div>
    <div
      className={classes.roomSize}
      style={{ color: props.sizeColor, backgroundColor: props.sizeBg }}>
      <span>{t('room size example')}</span>
    </div>
  </div>
)

RoomInfo.propTypes = {
  classes: PropTypes.object.isRequired,
  nameColor: PropTypes.string.isRequired,
  sizeColor: PropTypes.string.isRequired,
  nameBg: PropTypes.string.isRequired,
  sizeBg: PropTypes.string.isRequired
}

export default withI18n()(withStyles(styles)(RoomInfo))
