import React from 'react'
import Button from '@material-ui/core/Button'
import TextField from '@material-ui/core/TextField'
import Dialog from '@material-ui/core/Dialog'
import DialogActions from '@material-ui/core/DialogActions'
import DialogContent from '@material-ui/core/DialogContent'
import DialogTitle from '@material-ui/core/DialogTitle'
import { withI18n } from 'react-i18next'

class EditContactTitleDialog extends React.Component {
  state = {
    text: ''
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.open && !this.props.open) {
      this.setState({
        text: nextProps.value
      })
    }
  }

  render() {
    const { t } = this.props
    return (
      <div>
        <Dialog open={this.props.open} onClose={this.props.onClose}>
          <DialogTitle>{t('edit contact name')}</DialogTitle>
          <DialogContent>
            <TextField
              autoFocus
              margin="dense"
              label={t('contact name')}
              fullWidth
              value={this.state.text}
              onChange={e =>
                this.setState({
                  text: e.target.value
                })
              }
            />
          </DialogContent>
          <DialogActions>
            <Button onClick={this.props.onClose}>{t('close')}</Button>
            <Button
              onClick={e => this.props.onSubmit(this.state.text, e)}
              color="primary"
              disabled={this.state.text === ''}>
              {t('submit')}
            </Button>
          </DialogActions>
        </Dialog>
      </div>
    )
  }
}

export default withI18n()(EditContactTitleDialog)
