import { withStyles } from '@material-ui/core/styles'
import styles from './BillingInformationPage.styles'
import { compose, withHandlers } from 'recompose'
import connect from 'react-redux/es/connect/connect'
import { withFirebase } from 'react-redux-firebase'
import { REGISTER_PATH, SUBSCRIPTION_PATH } from '../../../../constants/paths'
import { withI18n } from 'react-i18next'
import firebaseApp from 'firebase/app'
import SubmissionError from 'redux-form/es/SubmissionError'
import withNotifications from 'modules/notification/components/withNotifications'

export default compose(
  connect(({ firebase: { auth, profile } }) => ({
    auth,
    billingInformation: profile.billingInformation
  })),
  withFirebase,
  withNotifications,
  withHandlers({
    handleSave: props => values => {
      const voucher = (values.voucher || '').trim()
      return new Promise(resolve => {
        if (voucher) {
          resolve(
            firebaseApp.functions().httpsCallable('checkVoucher')({
              code: voucher
            })
          )
        } else {
          resolve()
        }
      })
        .then(() => {
          return props.firebase.updateProfile({
            billingInformation: values
          })
        })
        .then(() => {
          props.history.push(
            `${REGISTER_PATH}${SUBSCRIPTION_PATH}${props.location.search}`
          )
        })
        .catch(err => {
          if (err.message) {
            if (err.message === 'voucher_not_exists') {
              throw new SubmissionError({
                voucher: 'Voucher is not exists',
                _error: 'Submit failed!'
              })
            } else if (err.message === 'voucher_inactive') {
              throw new SubmissionError({
                voucher: 'Voucher is inactive',
                _error: 'Submit failed!'
              })
            }
          }
          console.error('Error:', err) // eslint-disable-line no-console
          props.showError(err.message || 'Could not save form')
          return Promise.reject(err)
        })
    },
    handleContinue: props => () => {
      props.history.push(
        `${REGISTER_PATH}${SUBSCRIPTION_PATH}${props.location.search}`
      )
    }
  }),
  withStyles(styles),
  withI18n()
)
