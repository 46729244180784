import { connect } from 'react-redux'
import {
  withHandlers,
  compose,
  withProps,
  flattenProp,
  withStateHandlers
} from 'recompose'
import { withStyles } from '@material-ui/core/styles'
import { withRouter } from 'react-router-dom'
import {
  withFirebase,
  isEmpty,
  isLoaded,
  firebaseConnect
} from 'react-redux-firebase'
import { ACCOUNT_PATH, LOGIN_PATH } from 'constants/paths'
import styles from './Navbar.styles'
import { ADMIN_PATH, CLIENT_PATH } from 'constants/paths'
import get from 'lodash/get'

export default compose(
  // Map redux state to props
  connect(({ firebase: { auth, profile } }) => ({
    auth,
    profile
  })),
  firebaseConnect(({ groupsIds, auth, profile }) => [
    `/privateGroups/${auth.uid}`
  ]),
  connect(({ firebase: { data } }, { groupsIds, profile, auth }) => ({
    privateGroup: get(data, `privateGroups.${auth.uid}`)
  })),
  // State handlers as props
  withStateHandlers(
    ({ accountMenuOpenInitially = false }) => ({
      accountMenuOpen: accountMenuOpenInitially,
      anchorEl: null
    }),
    {
      closeAccountMenu: ({ accountMenuOpen }) => () => ({
        anchorEl: null
      }),
      handleMenu: () => event => ({
        anchorEl: event.target
      })
    }
  ),
  // Add props.router (used in handlers)
  withRouter,
  // Add props.firebase (used in handlers)
  withFirebase,
  // Handlers as props
  withHandlers({
    handleLogout: props => () => {
      props.firebase.logout()
      props.history.push(LOGIN_PATH)
      props.closeAccountMenu()
    },
    goToAdmin: props => () => {
      props.history.push(ADMIN_PATH)
      props.closeAccountMenu()
    },
    goToAccount: props => {
      props.onClickAccount()
      props.closeAccountMenu()
    },
    goToClient: props => {
      props.history.push(CLIENT_PATH)
      props.closeAccountMenu()
    },
    goToLanguage: props => {
      props.onClickLanguage()
      props.closeAccountMenu()
    },
    goToSubscription: props => {
      props.onClickSubscription()
      props.closeAccountMenu()
    }
  }),
  // Add custom props
  withProps(({ auth, profile }) => ({
    authExists: isLoaded(auth) && !isEmpty(auth)
  })),
  // Flatten profile so that avatarUrl and displayName are props
  //flattenProp('profile'),
  // Add styles as classes prop
  withStyles(styles)
)
