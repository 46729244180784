import React from 'react'
import PropTypes from 'prop-types'
import { withI18n } from 'react-i18next'
import { withStyles } from '@material-ui/core/styles'
import PropertyBubblesInfo from '../../../components/PropertyBubblesInfo'
import { formatDateTime } from '../../../utils/date'
import Grid from '@material-ui/core/Grid/Grid'
import TextFieldWithDebounce from '../../../components/TextFieldWithDebounce'
import IconButton from '@material-ui/core/IconButton/IconButton'
import cn from 'classnames'
import { moveDown, moveUp } from '../../../utils/utils'
import TrashIcon from 'icons/trash.svg'
import SortUpIcon from 'icons/sort-up.svg'
import { validateUrl } from '../../../utils/form'
import Select from '@material-ui/core/Select'
import InputLabel from '@material-ui/core/InputLabel'
import MenuItem from '@material-ui/core/MenuItem'

const styles = theme => ({
  textField: {
    width: `calc(100% - 40px)`
  },
  webAddress: {
    width: '100%',
    maxWidth: 350
  },
  item: {
    backgroundColor: '#2d2d2d',
    padding: theme.spacing.unit,
    position: 'relative',
    color: 'white'
  },
  actions: {
    position: 'absolute',
    display: 'grid',
    top: 46,
    right: 2
  },
  creationDate: {
    position: 'absolute',
    top: theme.spacing.unit * 2,
    right: theme.spacing.unit * 2
  },
  icon: {
    width: 25
  },
  buttonDown: {
    marginTop: -10,
    transform: 'scaleY(-1)'
  }
})

const PropertyList = props => {
  const {
    t,
    items,
    agentItems,
    classes,
    onChangeItems,
    propertyItems,
    propertyItemsGroup
  } = props

  const handleChangeDesc = (key, desc) =>
    onChangeItems(items.map(i => (i.propertyKey === key ? { ...i, desc } : i)))

  const handleChangeWeb = (key, web) =>
    onChangeItems(items.map(i => (i.propertyKey === key ? { ...i, web } : i)))

  const handleChangeVersion = (key, newKey, newsletter) =>
    onChangeItems(
      items.map(i =>
        i.propertyKey === key ? { ...i, propertyKey: newKey, newsletter } : i
      )
    )

  const getCurrentAgent = item => {
    return agentItems.find(i => i.key === item.project.agentKey)
  }
  const getCurrentProject = item => {
    return propertyItems.find(i => i.key === item.propertyKey)
  }

  const getSubItemsAndLabel = item => {
    const groupItem = propertyItemsGroup.find(
      i => i.key === item.project.parentId
    )
    const items = []
    groupItem.subItemsFinal.forEach(i => {
      items.push(i)
    })
    if (groupItem.newsletter) {
      items.push(groupItem.newsletter)
    }
    return {
      subItems: items.sort((a, b) => a.creationTime - b.creationTime),
      name: groupItem.name
    }
  }

  const handleMoveUp = index => {
    onChangeItems(moveUp(items, index))
  }

  const handleMoveDown = index => {
    onChangeItems(moveDown(items, index))
  }

  const handleDelete = key => {
    onChangeItems(items.filter(i => i.propertyKey !== key))
  }

  const properties = items
    .map(i => ({ ...i, project: getCurrentProject(i) }))
    .map(i => ({ ...i, agent: getCurrentAgent(i) }))
    .map(i => ({ ...i, ...getSubItemsAndLabel(i) }))
    .filter(i => i.project)

  return (
    <Grid container spacing={3}>
      {properties.map((item, index) => (
        <Grid key={item.propertyKey} item xs={12}>
          <Grid spacing={1} container className={classes.item}>
            <Grid xs={12} item>
              {item.name}
              <span className={classes.creationDate}>
                {formatDateTime(item.project.creationTime)}
              </span>
            </Grid>
            {item.subItems.length > 1 && (
              <Grid xs={12} item>
                <InputLabel id={`select-version-${item.parentId}`}>
                  Version
                </InputLabel>
                <Select
                  value={item.propertyKey}
                  labelId={`select-version-${item.parentId}`}
                  onChange={e => {
                    const newsletter = !!item.subItems.find(
                      i => i.key === e.target.value
                    ).newsletter
                    handleChangeVersion(
                      item.propertyKey,
                      e.target.value,
                      newsletter
                    )
                  }}>
                  {item.subItems.map(i => (
                    <MenuItem key={i.key} value={i.key}>
                      {formatDateTime(i.creationTime)} -{' '}
                      {i.newsletter ? 'newsletter' : 'video'}
                    </MenuItem>
                  ))}
                </Select>
              </Grid>
            )}
            <Grid xs={12} item>
              <PropertyBubblesInfo project={item.project} />
            </Grid>
            <Grid xs={12} item>
              <TextFieldWithDebounce
                label={t('property description')}
                className={classes.textField}
                margin="normal"
                value={item.desc}
                onChange={value => handleChangeDesc(item.propertyKey, value)}
              />
              <TextFieldWithDebounce
                label={t('web address')}
                className={classes.webAddress}
                margin="normal"
                value={item.web}
                error={item.web ? validateUrl(item.web) : undefined}
                onChange={value => handleChangeWeb(item.propertyKey, value)}
              />
            </Grid>
            <div className={classes.actions}>
              <IconButton
                className={classes.margin}
                onClick={() => handleDelete(item.propertyKey)}>
                <img src={TrashIcon} className={classes.icon} alt="trash" />
              </IconButton>
              {index !== 0 && (
                <IconButton
                  className={classes.margin}
                  onClick={() => handleMoveUp(index)}>
                  <img src={SortUpIcon} className={classes.icon} alt="" />
                </IconButton>
              )}
              {index < items.length - 1 && (
                <IconButton
                  className={cn(classes.margin, classes.buttonDown)}
                  onClick={() => handleMoveDown(index)}>
                  <img src={SortUpIcon} className={classes.icon} alt="" />
                </IconButton>
              )}
            </div>
          </Grid>
        </Grid>
      ))}
    </Grid>
  )
}

PropertyList.propTypes = {}

export default withStyles(styles)(withI18n()(PropertyList))
