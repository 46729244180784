export default theme => ({
  root: {
    zIndex: theme.zIndex.drawer + 1,
    height: theme.headerHeight
  },
  rootFullWidth: {
    zIndex: theme.zIndex.drawer + 1
  },
  title: {
    flexGrow: 1,
    height: theme.headerHeight
  },
  logo: {
    top: 11,
    height: 27,
    position: 'relative'
  },
  navbarText: {
    position: 'relative',
    marginLeft: 11,
    top: 6
  },
  buttonIcon: {
    marginRight: theme.spacing.unit
  },
  toolbar: {
    minHeight: 48
  },
  goToClientBtn: {
    position: 'relative',
    right: 52
  }
})
