import React from 'react'
import PropTypes from 'prop-types'
import Button from '@material-ui/core/Button'
import Dialog from '@material-ui/core/Dialog'
import DialogActions from '@material-ui/core/DialogActions'
import DialogContent from '@material-ui/core/DialogContent'
import Select from '@material-ui/core/Select'
import MenuItem from '@material-ui/core/MenuItem'
import DialogTitle from '@material-ui/core/DialogTitle'
import { withI18n } from 'react-i18next'

const LanguageDialog = ({ open, value, onClose, t, onChange }) => (
  <Dialog open={open} onClose={onClose}>
    <DialogTitle>{t('language')}</DialogTitle>
    <DialogContent>
      <Select value={value} onChange={e => onChange(e.target.value)}>
        <MenuItem value="en">English</MenuItem>
        <MenuItem value="cs-CZ">Čeština</MenuItem>
      </Select>
    </DialogContent>
    <DialogActions>
      <Button onClick={onClose} color="primary">
        {t('close')}
      </Button>
    </DialogActions>
  </Dialog>
)

LanguageDialog.propTypes = {
  t: PropTypes.func.isRequired,
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  onChange: PropTypes.func.isRequired,
  title: PropTypes.string.isRequired
}

export default withI18n()(LanguageDialog)
