import React from 'react'
import PropTypes from 'prop-types'
import Button from '@material-ui/core/Button'
import Dialog from '@material-ui/core/Dialog'
import DialogActions from '@material-ui/core/DialogActions'
import DialogContent from '@material-ui/core/DialogContent'
import DialogContentText from '@material-ui/core/DialogContentText'
import { withI18n } from 'react-i18next'

const CannotOpenProjectDialog = ({ open, onClose, t }) => (
  <Dialog open={open} onClose={onClose}>
    <DialogContent>
      <DialogContentText>
        {t(
          'Opening a project that has been sent to production is not possible. Please use the "CLONE" button, if you want to create its editable copy.'
        )}
      </DialogContentText>
    </DialogContent>
    <DialogActions>
      <Button onClick={onClose} color="primary">
        {t('close')}
      </Button>
    </DialogActions>
  </Dialog>
)

CannotOpenProjectDialog.propTypes = {
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.bool.isRequired
}

export default withI18n()(CannotOpenProjectDialog)
