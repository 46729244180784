import React from 'react'
import { withStyles } from '@material-ui/core/styles/index'
import PropTypes from 'prop-types'
import TextField from '@material-ui/core/TextField'
import Grid from '@material-ui/core/Grid'
import Radio from '@material-ui/core/Radio'

const styles = theme => ({
  radio: {
    height: 27,
    marginLeft: -13
  }
})

const RadioWithText = ({
  text,
  onChangeText,
  onChangeRadio,
  checked,
  placeholder,
  classes
}) => (
  <Grid container spacing={1} alignItems="flex-end">
    <Grid item>
      <Radio
        classes={{ root: classes.radio }}
        checked={checked}
        onChange={e => onChangeRadio(e.target.checked)}
      />
    </Grid>
    <Grid item>
      <TextField
        value={text}
        onChange={e => onChangeText(e.target.value)}
        placeholder={placeholder}
      />
    </Grid>
  </Grid>
)

RadioWithText.propTypes = {
  text: PropTypes.string.isRequired,
  checked: PropTypes.bool.isRequired,
  onChangeRadio: PropTypes.func.isRequired,
  onChangeText: PropTypes.func.isRequired,
  placeholder: PropTypes.string.isRequired
}

export default withStyles(styles)(RadioWithText)
