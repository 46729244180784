import React from 'react'
import { Switch, Route, Redirect } from 'react-router-dom'
import Home from './Home'
import AdminLayout from 'layouts/AdminLayout'
import CoreLayout from 'layouts/CoreLayout'
import LoginRoute from './Login'
import SignupRoute from './Signup'
import AccountRoute from './Account'
import AdminHomeRoute from './Admin/Home'
import AdminGroupsRoute from './Admin/Groups'
import PropertyRoute from './Property'
import NewsletterRoute from './Newsletter'
import NotFoundRoute from './NotFound'
import SubscriptionRoute from './Subscription'
import BillingInformationRoute from './BillingInformation'
import {
  ADMIN_PATH,
  CLIENT_PATH,
  LIST_PATH,
  PROPERTIES_LIST_PATH,
  REGISTER_PATH, URL_PREFIX
} from '../constants/paths'
import RegisterLayout from '../layouts/RegisterLayout'

export default function createRoutes(store) {
  return (
    <Switch>
      <Route
        path={REGISTER_PATH}
        component={() => (
          <RegisterLayout>
            <Switch>
              <Route
                path={REGISTER_PATH+BillingInformationRoute.path}
                component={BillingInformationRoute.component}
              />
              <Route
                path={REGISTER_PATH+SubscriptionRoute.path}
                component={SubscriptionRoute.component}
              />
            </Switch>
          </RegisterLayout>
        )}
      />
      <Route
        path={ADMIN_PATH}
        component={() => (
          <CoreLayout>
            <AdminLayout>
              <Switch>
                <Route
                  path={ADMIN_PATH + AdminGroupsRoute.path}
                  component={AdminGroupsRoute.component}
                />
                <Route
                  path={ADMIN_PATH + AdminHomeRoute.path}
                  component={AdminHomeRoute.component}
                />
              </Switch>
            </AdminLayout>
          </CoreLayout>
        )}
      />
      <Route path={LoginRoute.path} component={LoginRoute.component} />
      <Route
        path={CLIENT_PATH}
        component={() => (
          <CoreLayout>
            <Switch>
              {/* Build Route components from routeSettings */
              [
                Home,
                PropertyRoute,
                NewsletterRoute,
                AccountRoute,
                SignupRoute,
                SubscriptionRoute,
                //BillingInformationRoute
                /* Add More Routes Here */
              ]
                .map(i => ({ ...i, path: CLIENT_PATH + i.path }))
                .map((settings, index) => (
                  <Route key={`Route-${index}`} {...settings} />
                ))}
              <Redirect from={CLIENT_PATH} to={`${CLIENT_PATH}${LIST_PATH}`} />
              <Route component={NotFoundRoute.component} />
            </Switch>
          </CoreLayout>
        )}
      />
      <Redirect from={URL_PREFIX} to={`${CLIENT_PATH}${LIST_PATH}`} />
    </Switch>
  )
}
