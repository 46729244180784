import React from 'react'
import PropTypes from 'prop-types'
import Button from '@material-ui/core/Button'
import Dialog from '@material-ui/core/Dialog'
import DialogActions from '@material-ui/core/DialogActions'
import DialogContent from '@material-ui/core/DialogContent'
import DialogContentText from '@material-ui/core/DialogContentText'
import DialogTitle from '@material-ui/core/DialogTitle'
import { withI18n } from 'react-i18next'

const ConfirmDialog = ({ title, open, onAgree, onDisagree, t, header, agreeText, disagreeText }) => (
  <Dialog open={open} onClose={onDisagree}>
    <DialogTitle>{header || t('are you sure?')}</DialogTitle>
    <DialogContent>
      <DialogContentText>{title}</DialogContentText>
    </DialogContent>
    <DialogActions>
      <Button onClick={onDisagree} color="primary">
        {disagreeText || t('disagree')}
      </Button>
      <Button onClick={onAgree} color="primary" autoFocus>
        {agreeText || t('agree')}
      </Button>
    </DialogActions>
  </Dialog>
)

ConfirmDialog.propTypes = {
  open: PropTypes.bool.isRequired,
  onDisagree: PropTypes.bool.isRequired,
  onAgree: PropTypes.bool.isRequired,
  title: PropTypes.string,
  header: PropTypes.string,
  agreeText: PropTypes.string,
  disagreeText: PropTypes.string
}

export default withI18n()(ConfirmDialog)
