import React from 'react'
import PropTypes from 'prop-types'
import BillingInformationForm from 'components/BillingInformationForm'

const BillingInformation = ({ billingInformation, handleSave }) => {
  return (
    <BillingInformationForm
      onSubmit={handleSave}
      initialValues={billingInformation}
    />
  )
}

BillingInformation.propTypes = {
  t: PropTypes.func.isRequired,
  classes: PropTypes.object.isRequired,
  billingInformation: PropTypes.object,
  handleSave: PropTypes.func.isRequired
}

export default BillingInformation
