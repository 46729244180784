export default theme => ({
  table: {
    borderCollapse: 'collapse',
    width: '100%',
    '& td': {
      border: `solid 1px ${theme.palette.grey[400]}`,
      padding: theme.spacing.unit * 1
    }
  }
})
