import React from 'react'
import PropTypes from 'prop-types'
import { formatDateTime } from '../../utils/date'
import List from '@material-ui/core/List'
import ListItem from '@material-ui/core/ListItem'
import ListItemText from '@material-ui/core/ListItemText'
import IconButton from '@material-ui/core/IconButton'
import DownloadIcon from '@material-ui/icons/CloudDownload'
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction'
import { types as subscriptionTypes } from 'constants/subscriptions'
import Divider from '@material-ui/core/Divider'
import Paper from '@material-ui/core/Paper'

const SubscriptionInfo = ({ t, classes, items, handleDownloadInvoice }) => {
  return (
    <React.Fragment>
      <div>
        <List component="nav" className={classes.list}>
          {items.map(item => (
            <React.Fragment>
              <ListItem key={item.key} className={classes.item} button>
                <ListItemText
                  primary={`${
                    subscriptionTypes[item.subscriptionIndex].label
                  } ${item.data.amount.total} ${item.data.amount.currency}`}
                  secondary={formatDateTime(item.data.create_time)}
                />
                <ListItemSecondaryAction>
                  <IconButton onClick={() => handleDownloadInvoice(item.key)}>
                    <DownloadIcon />
                  </IconButton>
                </ListItemSecondaryAction>
              </ListItem>
              <Divider />
            </React.Fragment>
          ))}
        </List>
      </div>
    </React.Fragment>
  )
}

SubscriptionInfo.propTypes = {
  t: PropTypes.func.isRequired,
  classes: PropTypes.object.isRequired,
  handleDownloadInvoice: PropTypes.func.isRequired,
  items: PropTypes.array.isRequired
}

export default SubscriptionInfo
