import { withStyles } from '@material-ui/core/styles'
import styles from './PropertiesPage.styles'
import { compose, withHandlers, withProps } from 'recompose'
import { withI18n } from 'react-i18next'
import { firebaseConnect } from 'react-redux-firebase'
import connect from 'react-redux/es/connect/connect'
import { spinnerWhileLoading } from 'utils/components'
import get from 'lodash/get'
import map from 'lodash/map'
import { withRouter } from 'react-router-dom'
import { CLIENT_PATH, PROPERTY_PATH } from 'constants/paths'
import firebaseApp from 'firebase/app'
import { SERVER_PRODUCTION } from 'config'
import withNotifications from 'modules/notification/components/withNotifications'
import withLoader from 'modules/loader/components/withLoader'
import * as defaultValues from 'defaultValues'
import { setPathInImages } from '../../../../../../utils/utils'
import { propertiesToGroup } from 'utils/utils'

const getLastContact = (profile, groupId) =>
  firebaseApp
    .database()
    .ref(`groups/${groupId}/contacts/${profile.lastContact}`)
    .once('value')
    .then(s => {
      if (!s.exists() || s.val().deleted) {
        return null
      } else {
        return profile.lastContact
      }
    })

const getSettings = (groupId, propertyId) =>
  firebaseApp
    .database()
    .ref(`groups/${groupId}/properties/${propertyId}/settings`)
    .once('value')
    .then(s => {
      return s.val()
    })

const getLastAgent = (profile, groupId) => {
  const { email } = firebaseApp.auth().currentUser
  return firebaseApp
    .database()
    .ref(`groups/${groupId}/agents/`)
    .once('value')
    .then(s => {
      const val = s.val()
      if (!val) {
        return null
      }
      const currentAgent = Object.keys(val).find(
        key => val[key].email === email && !val[key].deleted
      )
      return currentAgent || null
    })
}

export default compose(
  connect(({ firebase: { profile } }) => ({
    profile,
    groupId: profile && profile.actualGroup
  })),
  spinnerWhileLoading(['profile']),
  firebaseConnect(props => {
    return [
      {
        path: `groups/${props.groupId}/properties`
      },
      {
        path: `groups/${props.groupId}/name`
      },
      {
        path: `groups/${props.groupId}/agents`
      },
      {
        path: `groups/${props.groupId}/newsletter`
      }
    ]
  }),
  connect(({ firebase: { data } }, { groupId }) => {
    const agentItems = map(
      get(data, `groups.${groupId}.agents`),
      (value, key) => ({
        ...value,
        key
      })
    )
    return {
      propertyData: get(data, `groups.${groupId}.properties`),
      propertyItems: map(
        get(data, `groups.${groupId}.properties`),
        (value, key) => ({
          ...value,
          images: setPathInImages(value.images, groupId, key),
          key
        })
      ).filter(i => !i.parentId),
      agentItems,
      groupName: get(data, `groups.${groupId}.name`),
      newsletter: Object.assign(
        { items: [] },
        get(data, `groups.${groupId}.newsletter`) || {}
      )
    }
  }),
  spinnerWhileLoading(['propertyData']),
  withStyles(styles),
  withRouter,
  withNotifications,
  withLoader,
  withHandlers({
    cloneProject: props => projectId => {
      const { showError, groupId, setLoader, clearLoader, history } = props
      setLoader()
      firebaseApp
        .functions()
        .httpsCallable('cloneProject')({
          projectId,
          groupId,
          type: 'properties'
        })
        .then(({ data }) => {
          history.push(`${CLIENT_PATH}${PROPERTY_PATH}/${data.projectId}`)
        })
        .catch(err => {
          console.error('Error:', err) // eslint-disable-line no-console
          showError(err.message || 'Project clone error')
          return Promise.reject(err)
        })
        .finally(() => {
          clearLoader()
        })
    },
    publishNewsletter: props => () => {
      const { showError, groupId, setLoader, clearLoader } = props
      setLoader()
      firebaseApp
        .functions()
        .httpsCallable('publishNewsletter')({
          groupId
        })
        .catch(err => {
          console.error('Error:', err) // eslint-disable-line no-console
          showError(err.message || 'Project clone error')
          return Promise.reject(err)
        })
        .finally(() => {
          clearLoader()
        })
    },
    deleteProperty: props => (projectKey) => {
      const { showError, groupId, setLoader, clearLoader } = props
      setLoader()
      return firebaseApp
        .functions()
        .httpsCallable('deleteProperty')({
          groupId,
          projectKey
        })
        .catch(err => {
          console.error('Error:', err) // eslint-disable-line no-console
          showError(err.message || 'Project delete error')
          return Promise.reject(err)
        })
        .finally(() => {
          clearLoader()
        })
    },
    onCopySettings: props => projectId => {
      const { showError, setLoader, clearLoader, firebase, groupId } = props
      return getSettings(groupId, projectId)
        .then(data => {
          return firebase.updateProfile({
            defaultSettings: data
          })
        })
        .catch(err => {
          console.error('Error:', err) // eslint-disable-line no-console
          showError(err.message || 'Could not copy settings')
          return Promise.reject(err)
        })
    },
    addProject: props => name => {
      const { firebase, showError, history, groupId, profile } = props

      Promise.all([
        getLastAgent(profile, groupId),
        getLastContact(profile, groupId)
      ]).then(([agentKey, contactKey]) => {
        const values = {
          ...defaultValues.propertyProject(name, contactKey, agentKey),
          createdBy: firebase.auth().currentUser.uid
        }

        if (profile.defaultSettings) {
          values.settings = profile.defaultSettings
        }

        return firebase
          .push(`groups/${groupId}/properties`, values)
          .then(snap => {
            history.push(`${CLIENT_PATH}${PROPERTY_PATH}/${snap.key}`)
          })
          .catch(err => {
            console.error('Error:', err) // eslint-disable-line no-console
            showError(err.message || 'Could not delete project')
            return Promise.reject(err)
          })
      })
    },
    downloadProject: props => projectId => {
      props.firebase
        .auth()
        .currentUser.getIdToken()
        .then(token => {
          const url = `${SERVER_PRODUCTION}/video/download_video?token=${token}&projectId=${projectId}&groupId=${
            props.groupId
          }&type=properties`
          window.open(url, '_self')
        })
    },
    goToPropertyProject: ({ history }) => project => {
      history.push(`${CLIENT_PATH}${PROPERTY_PATH}/${project.key}`)
    },
    changePropertyValue: props => (projectKey, name, value) => {
      const { firebase, showError, groupId } = props
      return firebase
        .database()
        .ref(`/groups/${groupId}/properties/${projectKey}/${name}`)
        .set(value)
        .catch(err => {
          console.error('Error:', err) // eslint-disable-line no-console
          showError(err.message || 'Could not update project')
          return Promise.reject(err)
        })
    },
    updateNewsletter: props => newsletter => {
      const { firebase, showError, groupId } = props
      return firebase
        .database()
        .ref(`/groups/${groupId}/newsletter`)
        .set(newsletter)
        .catch(err => {
          console.error('Error:', err) // eslint-disable-line no-console
          showError(err.message || 'Could not update newsletter')
          return Promise.reject(err)
        })
    }
  }),
  withI18n()
)
