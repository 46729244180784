import cyan from '@material-ui/core/colors/cyan'

export default theme => ({
  label: {
    color: cyan[600],
    margin: '20px 0 10px',
    display: 'block',
  },
  textArea: {
    textAlign: 'center',
    border: `solid 1px white`,
    backgroundColor: 'transparent',
    color: 'white',
    textArea: 'center',
    lineHeight: '28px',
    fontFamily: 'Open Sans,sans-serif',
    fontSize: 25,
    '&:focus': {
      border: `solid 1px ${cyan[600]}`,
      outline: 'none',
      boxShadow: `0 0 0 1px ${cyan[600]}`,
    },
    width: 412,
    overflow: 'hidden'
  }
})
