export const URL_PREFIX = process.env.PUBLIC_URL || ''
export const CLIENT_PATH = URL_PREFIX + '/client'
export const ADMIN_PATH = URL_PREFIX + '/admin'
export const LIST_PATH = '/projects'
export const PROPERTY_PATH = '/property'
export const NEWSLETTER_PATH = '/newsletter'
export const ACCOUNT_PATH = '/account'
export const LOGIN_PATH = URL_PREFIX + '/login'
export const SIGNUP_PATH = '/signup'
export const REGISTER_PATH = URL_PREFIX + '/register'
export const GROUPS_PATH = '/groups'
export const GROUPS_ACCOUNTS_PATH = 'accounts'
export const COMPOSITION_PATH = 'composition'
export const TEXTS_PATH = 'texts'
export const MAP_PATH = 'map'
export const AGENT_PATH = 'agent'
export const DESCRIPTION_PATH = 'description'
export const IMAGE_PATH = 'image'
export const SETTINGS_PATH = 'settings'
export const SEND_PATH = 'send'
export const INTRO_PATH = 'intro'
export const TRANSITIONS_PATH = 'transitions'
export const MUSIC_PATH = 'music'
export const LOOK_PATH = 'look'
export const CONTACT_PATH = 'contact'
export const PROPERTIES_LIST_PATH = 'properties'
export const NEWSLETTERS_LIST_PATH = 'newsletters'
export const HELP_PATH = 'help'
export const SUBSCRIPTION_PATH = '/subscription'
export const BILLING_INFORMATION_PATH = '/billing_info'
