import React from 'react'
import { withStyles } from '@material-ui/core/styles/index'
import PropTypes from 'prop-types'
import cn from 'classnames'
import VideoWrap from '../components/VideoWrap'
import Grid from '@material-ui/core/Grid'

const styles = theme => ({
  item: {
    cursor: 'pointer',
    border: 'solid 2px transparent'
  },
  label: {
    color: theme.palette.primary.main,
    marginBottom: 5
  },
  active: {
    border: `solid 2px ${theme.palette.primary.main}`
  },
  noneActive: {
    border: 'solid 2px transparent'
  },
  image: {
    maxWidth: 300
  }
})

const ImagePicker = ({ classes, activeId, onActive, items, readOnly }) => (
  <Grid container spacing={1} className={classes.content}>
    {items.map(item => (
      <Grid
        item
        className={classes.item}
        style={{ cursor: !readOnly ? 'pointer' : 'default' }}
        onClick={e => !readOnly && onActive(item.id, e)}>
        {item.label && <div className={classes.label}>{item.label}</div>}
        <img
          src={item.src}
          alt="layout preview"
          className={cn(
            classes.image,
            activeId === item.id ? classes.active : classes.noneActive
          )}
        />
      </Grid>
    ))}
  </Grid>
)

ImagePicker.propTypes = {
  classes: PropTypes.object.isRequired,
  activeId: PropTypes.number.isRequired,
  onActive: PropTypes.func.isRequired,
  items: PropTypes.array.isRequired,
  readOnly: PropTypes.bool
}

export default withStyles(styles)(ImagePicker)
