import React, { useRef, useState } from 'react'
import PropTypes from 'prop-types'
import Button from '@material-ui/core/Button'
import Dialog from '@material-ui/core/Dialog'
import DialogActions from '@material-ui/core/DialogActions'
import RotateLeftIcon from '@material-ui/icons/RotateLeft'
import RotateRightIcon from '@material-ui/icons/RotateRight'
import RestoreIcon from '@material-ui/icons/Restore'
import { withI18n } from 'react-i18next'
import withStyles from '@material-ui/core/styles/withStyles'
import IconButton from '@material-ui/core/IconButton/IconButton'
import CropperJS from 'components/CropperjsReact'

const styles = () => ({
  root: {
    display: 'flex'
  },
  container: {
    maxWidth: '100%',
    margin: 20,
    maxHeight: 'calc(100% - 66px)'
  },
  actions: {
    justifyContent: 'space-between'
  },
  backdrop: {
    background: 'rgba(0, 0, 0, 0.85)'
  }
})

// https://github.com/ajgarn/CanvasImageUploader

const ImageRotationDialog = ({
  t,
  onClose,
  open,
  file,
  classes,
  onSubmit,
  width,
  height,
  mimeType = 'image/png'
}) => {
  const cropperRef = useRef(null)

  const handleSubmit = () => {
    // image in dataUrl
    cropperRef.current
      .getCroppedCanvas({ width, height })
      .toBlob(onSubmit, mimeType)
    onClose()
  }

  return (
    <Dialog
      open={open}
      classes={{ paper: classes.container }}
      BackdropProps={{
        classes: {
          root: classes.backdrop
        }
      }}>
      {file && (
        <CropperJS
          ref={cropperRef}
          src={file.dataURL}
          checkOrientation={true}
          // Cropper.js options
          aspectRatio={width / height}
          guides={true}
          responsive
          restore
          center
          viewMode={1}
          autoCrop
          autoCropArea={1}
          movable={false}
          scalable={false}
          zoomOnWheel={false}
          zoomOnTouch={false}
        />
      )}
      <DialogActions className={classes.actions}>
        <div>
          <IconButton
            onClick={() => {
              cropperRef.current.clear()
              cropperRef.current.rotate(90)
              cropperRef.current.crop()
            }}
            color="primary"
            className={classes.button}>
            <RotateRightIcon />
          </IconButton>

          <IconButton
            onClick={() => {
              cropperRef.current.clear()
              cropperRef.current.rotate(-90)
              cropperRef.current.crop()
            }}
            color="primary"
            className={classes.button}>
            <RotateLeftIcon />
          </IconButton>

          <IconButton
            onClick={() => {
              cropperRef.current.reset()
            }}
            color="primary"
            className={classes.button}>
            <RestoreIcon />
          </IconButton>
        </div>
        <div>
          <Button onClick={onClose}>{t('close')}</Button>
          <Button onClick={handleSubmit} color="primary" autoFocus>
            {t('submit')}
          </Button>
        </div>
      </DialogActions>
    </Dialog>
  )
}

ImageRotationDialog.propTypes = {
  t: PropTypes.func.isRequired,
  file: PropTypes.object.isRequired,
  classes: PropTypes.object.isRequired,
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired,
  width: PropTypes.number.isRequired,
  height: PropTypes.number.isRequired,
  mimeType: PropTypes.string
}

export default withStyles(styles)(withI18n()(ImageRotationDialog))
