import React from 'react'
import PropTypes from 'prop-types'
import { withI18n } from 'react-i18next'
import { URL_PREFIX } from 'constants/paths'
import VideoTransitionItem from 'components/VideoTransitionItem'

const ITEMS_TRS = [
  {
    id: '1',
    src:
      'https://firebasestorage.googleapis.com/v0/b/editor-53bde/o/videos%2Ftransition%2Ftr1.mp4?alt=media&token=410a775c-2747-446c-ab2d-eebaecb212b2',
    label: '1'
  },
  {
    id: '2',
    src:
      'https://firebasestorage.googleapis.com/v0/b/editor-53bde/o/videos%2Ftransition%2Ftr2.mp4?alt=media&token=16afc64f-62d6-4407-943e-17462823da41',
    label: '2'
  },
  {
    id: '3',
    src:
      'https://firebasestorage.googleapis.com/v0/b/editor-53bde/o/videos%2Ftransition%2Ftr3.mp4?alt=media&token=35cc51b3-a39c-44d3-a046-6605a82f1252',
    label: '3'
  },
  {
    id: '4',
    src:
      'https://firebasestorage.googleapis.com/v0/b/editor-53bde/o/videos%2Ftransition%2Ftr4.mp4?alt=media&token=802606d2-36da-4e3e-a344-da51a1f3f768',
    label: '4'
  },
  {
    id: '5',
    src:
      'https://firebasestorage.googleapis.com/v0/b/editor-53bde/o/videos%2Ftransition%2Ftr5.mp4?alt=media&token=309d9914-30ac-457a-821a-31bb1e877662',
    label: '5'
  },
  {
    id: '6',
    src:
      'https://firebasestorage.googleapis.com/v0/b/editor-53bde/o/videos%2Ftransition%2Ftr6.mp4?alt=media&token=e1bb66de-9e9d-4f23-984b-5e25e485281e',
    label: '6'
  },
  {
    id: '7',
    src:
      'https://firebasestorage.googleapis.com/v0/b/editor-53bde/o/videos%2Ftransition%2Ftr7.mp4?alt=media&token=1446bfd1-f589-45a2-8843-4ed46f52cf83',
    label: '7'
  },
  {
    id: '8',
    src:
      'https://firebasestorage.googleapis.com/v0/b/editor-53bde/o/videos%2Ftransition%2Ftr8.mp4?alt=media&token=ae9f106d-b1f4-4fe7-891d-d24445baa4fb',
    label: '8'
  }
]

const TransitionsPage = props => (
  <VideoTransitionItem
    items={ITEMS_TRS}
    activeId={props.value}
    onActive={props.onChange}
  />
)

TransitionsPage.propTypes = {
  value: PropTypes.number.isRequired,
  onChange: PropTypes.func.isRequired
}

export default withI18n()(TransitionsPage)
