import { connect } from 'react-redux'
import { compose, lifecycle, withHandlers } from 'recompose'
import { withStyles } from '@material-ui/core/styles'
import { firebaseConnect } from 'react-redux-firebase'
import styles from './HomePage.styles'
import { get } from 'lodash'
import { spinnerWhileLoading } from 'utils/components'
import { withRouter } from 'react-router-dom'
import { GROUPS_ACCOUNTS_PATH, GROUPS_PATH } from 'constants/paths'
import firebaseApp from 'firebase/app'
import withNotifications from 'modules/notification/components/withNotifications'
import { ADMIN_PATH } from 'constants/paths'
import { withI18n } from 'react-i18next'
import withLoader from '../../../../../modules/loader/components/withLoader'
import moment from 'moment'

export default compose(
  withRouter,
  connect(({ firebase: { profile } }) => ({
    groupIds: profile.isLoaded
      ? profile.groupsAdmin
        ? Object.keys(profile.groupsAdmin)
        : []
      : undefined,
    superAdmin:
      profile.lastPayment &&
      moment(profile.lastPayment.time).isAfter(moment()) &&
      (profile.lastPayment.subscriptionIndex === 3 ||
        profile.lastPayment.subscriptionIndex === 7)
  })),
  spinnerWhileLoading(['groupIds']),
  // Create listeners based on current users UID
  firebaseConnect(({ groupIds }) => {
    return groupIds.length === 0
      ? []
      : groupIds
          .map(groupId => [
            `groups/${groupId}/name`,
            `groups/${groupId}/emails`
          ])
          .join()
          .split(',')
  }),
  // Map projects from state to props
  connect(({ firebase: { data } }, { groupIds }) => ({
    groups: data.groups
      ? groupIds.map(groupId => ({
          key: groupId,
          name: get(data, `groups.${groupId}.name`),
          emailsCount: Object.keys(get(data, `groups.${groupId}.emails`) || {})
            .length
        }))
      : []
  })),
  // Show loading spinner while projects and collabProjects are loading
  spinnerWhileLoading(['groups']),
  withNotifications,
  withLoader,
  withI18n(),
  lifecycle({
    componentDidMount() {
      if (!this.props.superAdmin && this.props.groups.length === 1) {
        this.props.history.replace(
          `${ADMIN_PATH}${GROUPS_PATH}/${
            this.props.groups[0].key
          }/${GROUPS_ACCOUNTS_PATH}`
        )
      }
    }
  }),
  withHandlers({
    goToGroup: props => key =>
      props.history.push(
        `${ADMIN_PATH}${GROUPS_PATH}/${key}/${GROUPS_ACCOUNTS_PATH}`
      ),
    onAddGroup: props => (name, email) => {
      const { showError, showSuccess, setLoader, clearLoader } = props
      setLoader()
      return firebaseApp
        .functions()
        .httpsCallable('addGroup')({
          name: name + '',
          addToGroup: false,
          adminEmail: email.trim() + '',
          inviteText: props.t('invite text all'),
          inviteTextAdmin: props.t('invite text all admin')
        })
        .then(() => {
          showSuccess('Group added successfully')
        })
        .catch(err => {
          console.error('Error:', err) // eslint-disable-line no-console
          showError(err.message || 'Could not add group')
          return Promise.reject(err)
        })
        .finally(() => clearLoader())
    }
  }),
  // Add styles as classes prop
  withStyles(styles)
)
