import {
  branch,
  compose,
  lifecycle,
  renderComponent,
  withHandlers,
  withProps,
  withStateHandlers
} from 'recompose'
import { withStyles } from '@material-ui/core/styles'
import styles from './CoreLayout.styles'
import connect from 'react-redux/es/connect/connect'
import {
  firebaseConnect,
  isEmpty,
  isLoaded,
  withFirebase
} from 'react-redux-firebase'
import { withRouter } from 'react-router-dom'
import i18n from 'i18next'
import { spinnerWhileLoading, spinnerWhileTrue } from '../../utils/components'
import withSystem from '../../modules/system/components/withSystem'
import withLoader from '../../modules/loader/components/withLoader'
import map from 'lodash/map'
import get from 'lodash/get'
import { ADMIN_PATH, URL_PREFIX } from '../../constants/paths'
import ProjectNotFound from '../../components/ProjectNotFound'
import LoadingSpinner from '../../components/LoadingSpinner'
import { withI18n } from 'react-i18next'
import firebaseApp from 'firebase'
import withNotifications from '../../modules/notification/components/withNotifications'
import {
  UserIsAuthenticated,
  UserIsRegisteredOrInvited
} from '../../utils/router'

export default compose(
  withRouter,
  UserIsAuthenticated,
  UserIsRegisteredOrInvited,
  withFirebase,
  withSystem,
  connect(({ firebase: { auth, profile }, system }) => ({
    auth,
    profile,
    showSelectGroup: system.showSelectGroup
  })),
  spinnerWhileLoading(['profile']),
  lifecycle({
    componentDidMount() {
      if (
        !this.props.profile.actualGroup ||
        Object.keys(this.props.profile.groups).indexOf(
          this.props.profile.actualGroup
        ) === -1
      ) {
        this.props.firebase.updateProfile({
          actualGroup: Object.keys(this.props.profile.groups)[0]
        })
      }
    }
  }),
  withProps(props => ({
    groupsIds: map(props.profile.groups, (values, key) => key)
  })),
  //UserIsRegisteredOrInvited,
  firebaseConnect(({ groupsIds, auth, profile }) => {
    if (!profile.actualGroup) {
      return []
    }

    return [
      { path: `/groups/${profile.actualGroup}/createdBy` },
      { path: `/privateGroups/${auth.uid}` },
      ...groupsIds.map(groupId => ({
        path: `/groups/${groupId}/name`
      }))
    ]
  }),
  connect(({ firebase: { data } }, { groupsIds, profile, auth }) => ({
    privateGroup: get(data, `privateGroups.${auth.uid}`),
    groupsNames: groupsIds.map(groupId => ({
      groupId,
      name: get(data, `groups.${groupId}.name`)
    })),
    isAdmin: get(profile, `groupsAdmin.${profile.actualGroup}`),
    groupCreatedBy: get(data, `groups.${profile.actualGroup}.createdBy`)
  })),
  //spinnerWhileLoading(['groupCreatedBy']),
  firebaseConnect(({ groupCreatedBy }) => {
    return [{ path: `/users/${groupCreatedBy}/lastPayment` }]
  }),
  connect(({ firebase: { data } }, { groupCreatedBy }) => ({
    lastPayment: get(data, `users.${groupCreatedBy}.lastPayment`)
  })),
  withProps(({ auth, location }) => ({
    authExists: isLoaded(auth) && !isEmpty(auth),
    locationKey: location.key
  })),
  branch(
    props =>
      (props.authExists && !props.profile.isLoaded) ||
      (props.authExists &&
        props.groupsIds.length > 0 &&
        props.groupsNames.filter(i => i.name).length === 0),
    renderComponent(LoadingSpinner)
  ),
  withStateHandlers(
    () => ({
      languageInit: false,
      groupFound: false,
      groupInit: false,
      selectedGroup: false
    }),
    {
      setLanguageInit: () => () => ({
        languageInit: true
      }),
      setGroupFound: () => () => ({
        groupFound: true
      }),
      setGroupInit: () => () => ({
        groupInit: true
      }),
      setSelectedGroup: () => () => ({
        selectedGroup: true
      })
    }
  ),
  lifecycle({
    componentDidMount() {
      if (this.props.profile.isLoaded) {
        if (!this.props.authExists) {
          this.props.setLanguageInit()
          this.props.setGroupInit()
          return
        }

        if (!this.props.profile.language) {
          this.props.firebase
            .updateProfile({
              language: 'en',
              displayName: this.props.auth.displayName,
              email: this.props.auth.email
            })
            .then(() => {
              this.props.setLanguageInit()
            })
        } else {
          i18n.changeLanguage(this.props.profile.language).then(() => {
            this.props.setLanguageInit()
          })
        }

        if (this.props.profile.actualGroup) {
          // check is still account
          if (
            this.props.profile.groups &&
            !this.props.profile.groups[this.props.profile.actualGroup]
          ) {
            // user is not account
            this.props.firebase.updateProfile({
              actualGroup: null
            })
          }
        } else {
          if (
            this.props.profile.groups &&
            Object.keys(this.props.profile.groups).length > 0
          ) {
            this.props.firebase.updateProfile({
              actualGroup: Object.keys(this.props.profile.groups)[0]
            })
          }
        }

        this.props.setGroupInit()
      }
    },
    componentWillReceiveProps(nextProps) {
      if (!this.props.profile.isLoaded && nextProps.profile.isLoaded) {
        if (!nextProps.authExists) {
          this.props.setLanguageInit()
          this.props.setGroupInit()
          return
        }

        if (!nextProps.profile.language) {
          nextProps.firebase
            .updateProfile({
              language: 'en',
              displayName: nextProps.auth.displayName,
              email: nextProps.auth.email
            })
            .then(() => {
              this.props.setLanguageInit()
            })
        } else {
          i18n.changeLanguage(nextProps.profile.language).then(() => {
            this.props.setLanguageInit()
          })
        }

        if (nextProps.profile.actualGroup) {
          // check is still account

          if (
            nextProps.profile.groups &&
            !nextProps.profile.groups[nextProps.profile.actualGroup]
          ) {
            // user is not account
            nextProps.firebase.updateProfile({
              actualGroup: null
            })
          }
        } else {
          if (
            nextProps.profile.groups &&
            Object.keys(nextProps.profile.groups).length > 0
          ) {
            nextProps.firebase.updateProfile({
              actualGroup: Object.keys(nextProps.profile.groups)[0]
            })
          }
        }

        this.props.setGroupInit()
      }
    }
  }),
  spinnerWhileTrue(['languageInit', 'groupInit']),
  withLoader,
  withNotifications,
  withFirebase,
  withI18n(),
  // Handlers as props
  withHandlers({
    handleLogout: props => () => {
      props.firebase.logout()
      props.history.push(URL_PREFIX)
    },
    closePaymentInfo: props => () => {
      props.history.replace(props.location.pathname)
      props.firebase.updateProfile({
        showPaymentInfo: null
      })
    },
    handleCloseWelcomeDialog: props => () => {
      props.firebase.updateProfile({
        adminWelcomeShowed: true
      })
    },
    handleChangeLanguage: props => language => {
      return i18n.changeLanguage(language).then(() => {
        return props.firebase.updateProfile({
          language
        })
      })
    },
    handleAddPrivateGroup: props => () => {
      const { showError, showSuccess, setLoader, clearLoader } = props
      setLoader()
      return firebaseApp
        .functions()
        .httpsCallable('addPrivateGroup')({
          inviteText: props.t('invite text all'),
          inviteTextAdmin: props.t('invite text all admin')
        })
        .then(() => {
          showSuccess('Group added successfully')
        })
        .catch(err => {
          console.error('Error:', err) // eslint-disable-line no-console
          showError(err.message || 'Could not add group')
          return Promise.reject(err)
        })
        .finally(() => clearLoader())
    },
    handleSelectGroup: props => key => {
      props.history.push(URL_PREFIX)
      props.firebase.updateProfile({
        actualGroup: key
      })
      props.closeSelectGroup()
    },
    handleOpenSelectGroup: props => () => props.openSelectGroup(),
    goToAdmin: props => () => {
      props.history.push(ADMIN_PATH)
    }
  }),
  withStyles(styles)
)
