import React from 'react'
import PropTypes from 'prop-types'
import Subscription from 'containers/Subscription'
import Typography from '@material-ui/core/Typography'
import queryString from 'query-string'

export const NotFoundPage = ({ classes, location, t }) => {
  const { selected } = queryString.parse(location.search)
  return (
    <div>
      <Typography variant="h4" className={classes.title}>
        {t('Account Plans')}
      </Typography>
      <div className={classes.root}>
        <Subscription
          selected={selected ? parseInt(selected) : null}
        />
      </div>
    </div>
  )
}
NotFoundPage.propTypes = {
  classes: PropTypes.object // from enhancer (withStyles)
}

export default NotFoundPage
