import React, { useState } from 'react'
import PropTypes from 'prop-types'
import tutorialIcon from './icons8-classroom.svg'
import houseIcon from './icons8-house-3.svg'
import Grid from '@material-ui/core/Grid/Grid'
import VideoList from './VideoList'

const TUTORIALS = [
  {
    id: 'AJsddF-Hvk0',
    title: 'Basic tutorial',
    subtitle: 'Control, functions and effects'
  },
  {
    id: 'XTjSvYZ4edM',
    title: 'Application settings',
    subtitle: 'Colors, animations and contact settings'
  }
]

const EXAMPLES = [
  {
    id: 'KwaMKc71z9s',
    title: 'Basic tutorial',
    subtitle: 'Control, functions and effects'
  }
]

export const HelpPage = ({ classes, t }) => {
  const [createProjectType, setCreateProjectType] = useState(null)
  return (
    <React.Fragment>
      <div className={classes.item}>
        <Grid className={classes.panel} container alignItems={'center'}>
          <Grid item container alignItems={'center'}>
            <img
              src={tutorialIcon}
              className={classes.icon}
              alt="tutorial icon"
            />
            {t('Video tutorials')}
          </Grid>
        </Grid>
        <VideoList items={TUTORIALS} />
      </div>
      <div className={classes.item}>
        <Grid className={classes.panel} container alignItems={'center'}>
          <Grid item container alignItems={'center'}>
            <img src={houseIcon} className={classes.icon} alt="examples icon" />
            {t('Examples of videos created with More Than Video')}
          </Grid>
        </Grid>
        <VideoList items={EXAMPLES} />
      </div>
    </React.Fragment>
  )
}

HelpPage.proptypes = {
  classes: PropTypes.object.isRequired, // from enhancer (withStyles),
  t: PropTypes.func.isRequired
}

export default HelpPage
