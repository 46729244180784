import React, { useState } from 'react'
import PropTypes from 'prop-types'
import Grid from '@material-ui/core/Grid'
import TextField from '@material-ui/core/TextField'
import Select from '@material-ui/core/Select'
import MenuItem from '@material-ui/core/MenuItem'
import FormControl from '@material-ui/core/FormControl'
import InputLabel from '@material-ui/core/InputLabel'
import { useDebounce } from 'react-use'
import isEqual from 'react-fast-compare'
import Label from '../../../../../../../../components/Label'
import FormControlLabel from '@material-ui/core/FormControlLabel'
import Checkbox from '@material-ui/core/Checkbox'

const FormText = ({ classes, texts, updateTexts, t }) => {
  const [values, updateValues] = useState(texts)
  useDebounce(
    () => {
      if (!isEqual(texts, values)) {
        updateTexts(values)
      }
    },
    200,
    [values]
  )

  const updateField = (name, checkbox = false) => e =>
    updateValues({
      ...values,
      [name]: checkbox ? e.target.checked : e.target.value
    })

  const INPUT_ITEMS = [
    { id: 'characteristic1', label: t('property characteristics 1 example') },
    { id: 'address1', label: t('address 1') },
    { id: 'characteristic2', label: t('property characteristics 2 example') },
    { id: 'address2', label: t('address 2') },
    { id: 'price', label: t('property price') },
    { id: 'code', label: t('order code') }
  ]

  const INPUT_ITEMS_PROPERTY = [
    { id: 'code', label: t('order code') },
    {
      id: 'basicType',
      label: t('offer type'),
      select: [
        { id: 'sale', label: 'sale' },
        { id: 'lease', label: 'lease' },
        { id: 'auction', label: 'auction' }
      ]
    },
    {
      id: 'basicProperty',
      label: t('property type'),
      helper: t('property type hint')
    },
    {
      id: 'basicLocation',
      label: t('property location'),
      helper: t('property location hint')
    },
    {
      id: 'basicPrice',
      label: t('property price'),
      helper: values =>
        values['basicType'] === 'lease'
          ? t('basic type lease hint')
          : t('basic type hint')
    }
  ]

  const inputItems = INPUT_ITEMS_PROPERTY
  return (
    <div>
      <Grid className={classes.wrapGlobal} container spacing={5}>
        <Grid item>
          <Label className={classes.label} primary={t('basic data')} />
          <Grid container spacing={4}>
            {inputItems.map(item => (
              <Grid key={item.id} item sm={6}>
                {item.select ? (
                  <FormControl  variant="outlined" fullWidth className={classes.formControl}>
                    <InputLabel htmlFor={`form-text-${item.id}`}>
                      {item.label}
                    </InputLabel>
                    <Select
                      label={item.label}
                      value={values[item.id]}
                      onChange={e => {
                        updateValues({
                          ...values,
                          [item.id]: e.target.value
                        })
                      }}
                      inputProps={{
                        id: `form-text-${item.id}`
                      }}>
                      <MenuItem value="">
                        <em>{t('not selected')}</em>
                      </MenuItem>
                      {item.select.map(i => (
                        <MenuItem key={i.id} value={i.id}>
                          {t(i.label)}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                ) : (
                  <TextField
                    variant="outlined"
                    helperText={
                      item.helper &&
                      (typeof item.helper === 'string'
                        ? item.helper
                        : item.helper(values))
                    }
                    label={item.label}
                    className={classes.textField}
                    InputLabelProps={{
                      classes: { root: classes.inputLabel }
                    }}
                    value={values[item.id]}
                    onChange={e => {
                      if (e.target.value.length < 29) {
                        updateValues({
                          ...values,
                          [item.id]: e.target.value
                        })
                      }
                    }}
                    margin="none"
                  />
                )}
              </Grid>
            ))}
          </Grid>
        </Grid>
      </Grid>
      <Grid item className={classes.wrapNewsletter}>
        <Label
          className={classes.label}
          primary={t('Additional data')}
          secondary={t('only appears in the Newsletter')}
        />
        <Grid container spacing={3}>
          <Grid item xs={12}>
            <TextField
              variant="outlined"
              label={t('Note to the property')}
              helperText={t('Note to the property more')}
              className={classes.textField}
              InputLabelProps={{
                classes: { root: classes.inputLabel }
              }}
              value={values.description}
              onChange={updateField('description')}
              margin="none"
            />
          </Grid>
        </Grid>
        <Grid container spacing={4}>
          <Grid item xs={7}>
            <TextField
              variant="outlined"
              label={t('Web link to the property')}
              helperText={t('Link to the presentation')}
              className={classes.textField}
              InputLabelProps={{
                classes: { root: classes.inputLabel }
              }}
              value={values.url}
              onChange={updateField('url')}
              margin="none"
            />
          </Grid>
          <Grid item>
            <FormControlLabel
              control={
                <Checkbox
                  checked={values.urlActive}
                  onChange={updateField('urlActive', true)}
                  color="secondary"
                />
              }
              label={t('Show link in newsletter')}
            />
          </Grid>
        </Grid>
      </Grid>
    </div>
  )
}

FormText.propTypes = {
  classes: PropTypes.object.isRequired,
  texts: PropTypes.object.isRequired,
  updateTexts: PropTypes.func.isRequired,
  t: PropTypes.func.isRequired
}

export default FormText
