import { compose, withHandlers, withProps, withState } from 'recompose'
import { withStyles } from '@material-ui/core/styles'
import styles from './HomeLayout.styles'
import connect from 'react-redux/es/connect/connect'
import { firebaseConnect, isEmpty, isLoaded } from 'react-redux-firebase'
import { withRouter } from 'react-router-dom'
import { withI18n } from 'react-i18next'
import map from 'lodash/map'
import get from 'lodash/get'
import firebaseApp from 'firebase'
import withNotifications from '../../modules/notification/components/withNotifications'
import withLoader from '../../modules/loader/components/withLoader'
import copy from 'copy-to-clipboard'

export default compose(
  withRouter,
  connect(({ firebase: { auth, profile } }) => ({
    auth,
    groupsIds: map(profile.groups, (values, key) => key),
    profile
  })),
  firebaseConnect(({ groupsIds }) => {
    return groupsIds.map(groupId => ({
      path: `/groups/${groupId}/name`,
    }))
  }),
  connect(({ firebase: { data } }, { groupsIds }) => ({
    groupsNames: groupsIds.map(groupId => ({
      groupId,
      name: get(data, `groups.${groupId}.name`)
    }))
  })),
  withProps(({ auth, location }) => ({
    authExists: isLoaded(auth) && !isEmpty(auth),
    locationKey: location.key
  })),
  withNotifications,
  withLoader,
  withState('showNewsletterSettings', 'setShowNewsletterSettings', false),
  withHandlers({
    publishNewsletter: props => () => {
      const { showError, setLoader, clearLoader, profile } = props
      setLoader()
      firebaseApp
        .functions()
        .httpsCallable('publishNewsletter')({
          groupId: profile.actualGroup
        })
        .catch(err => {
          console.error('Error:', err) // eslint-disable-line no-console
          showError(err.message || 'Project clone error')
          return Promise.reject(err)
        })
        .finally(() => {
          clearLoader()
        })
    },
    onCopyLink: props => link => {
      copy(link)
      props.showSuccess(props.t('copied'))
    }
  }),
  withI18n(),
  withStyles(styles)
)
