import {
  LOADER_SET_PROGRESS,
  LOADER_CLEAR
} from './actionTypes'


/**
 * Set loader
 * @param {Number} progress
 */
export const setLoader = (progress) => (dispatch) => {
  dispatch({type: LOADER_SET_PROGRESS, payload: progress})
}

/**
 * Clear loader
 */
export const clearLoader = () => (dispatch) => {
  dispatch({ type: LOADER_CLEAR })
}
