import React, { Component } from 'react'
import { withStyles } from '@material-ui/core/styles/index'
import PropTypes from 'prop-types'
import cyan from '@material-ui/core/colors/cyan'
import { NavLink } from 'react-router-dom'
import AutosizeInput from 'react-input-autosize'
import IconButton from '@material-ui/core/IconButton'
import IconHomeProperty from 'assets/property.svg'
import IconHomeNewsletter from 'assets/newsletter.svg'
import { SETTINGS_PATH, URL_PREFIX } from 'constants/paths'
import { COMPOSITION_PATH, SEND_PATH } from '../../constants/paths'
import { withI18n } from 'react-i18next'
import useDebounce from '../../utils/useDebounce'

const styles = theme => ({
  wrap: {
    borderBottom: `2px solid  ${cyan[600]}`,
    padding: '0 20px',
    display: 'flex'
  },
  menuItem: {
    display: 'inline-block',
    padding: '13px 20px',
    cursor: 'pointer',
    textTransform: 'uppercase',
    textDecoration: 'none',
    color: 'white',
    '&+*': {
      marginLeft: 10
    },
    '&:first-child': {
      marginLeft: 20
    },
    '&:hover': {
      backgroundColor: cyan[600]
    }
  },
  menuItemActive: {
    backgroundColor: cyan[600]
  },
  info: {
    margin: '0 30px',
    lineHeight: '48px',
    display: 'inline-block'
  },
  inputName: {
    backgroundColor: 'transparent',
    border: 'none',
    color: 'white',
    fontSize: 16,
    fontWeight: 500,
    borderBottom: '1px solid #808080ba'
  },
  btnAdd: {
    position: 'relative',
    display: 'inline-block',
    top: 10,
    cursor: 'pointer',
    marginRight: 70
  },
  btnAddImg: {
    width: 30,
    height: 30
  },
  iconProperty: {
    width: 24,
    height: 24,
    display: 'inline-block',
    background: `url(${IconHomeProperty}) no-repeat 0 -2px`,
    backgroundSize: 28
  },
  iconNewsletter: {
    width: 24,
    height: 24,
    display: 'inline-block',
    background: `url(${IconHomeNewsletter}) no-repeat 0 2px`,
    backgroundSize: 24
  }
})

const ProjectHeader = ({
  classes,
  isNewsletter,
  name,
  onChangeName,
  goToHome,
  prevUrl,
  t
}) => {
  const [localName, updateName] = useDebounce(name, 200, () => {
    if (localName !== name) {
      onChangeName(localName)
    }
  })

  const PROPERTY_MENU_ITEMS = [
    { label: t('composition'), id: COMPOSITION_PATH },
    { label: t('settings'), id: SETTINGS_PATH },
    { label: t('send'), id: SEND_PATH }
  ]
  const NEWSLETTER_MENU_ITEMS = []

  const menuItems = (items, prefix) =>
    items.map(({ label, id }) => (
      <NavLink
        className={classes.menuItem}
        key={id}
        activeClassName={classes.menuItemActive}
        to={`${prefix}/${id}`}>
        {label}
      </NavLink>
    ))

  return (
    <div className={classes.wrap}>
      <IconButton color="inherit" onClick={goToHome}>
        <span className={isNewsletter ? classes.iconNewsletter : classes.iconProperty} />
      </IconButton>
      <div className={classes.info}>
        <AutosizeInput
          value={localName}
          inputClassName={classes.inputName}
          onChange={e => {
            updateName(e.target.value)
          }}
        />
      </div>
      {menuItems(
        isNewsletter ? NEWSLETTER_MENU_ITEMS : PROPERTY_MENU_ITEMS,
        prevUrl
      )}
    </div>
  )
}

ProjectHeader.propTypes = {
  classes: PropTypes.object.isRequired,
  prevUrl: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  onChangeName: PropTypes.func.isRequired,
  goToHome: PropTypes.func,
  isNewsletter: PropTypes.bool
}

export default withI18n()(withStyles(styles)(ProjectHeader))
