export default theme => ({
  root: {
    zIndex: theme.zIndex.drawer + 1,
    height: theme.headerHeight
  },
  rootFullWidth: {
    zIndex: theme.zIndex.drawer + 1
  },
  toolbar: {
    height: theme.headerHeight,
    minHeight: theme.headerHeight
  },
  title: {
    flexGrow: 1,
    display: 'flex'
  },
  logo: {
    top: 5,
    height: 27,
    position: 'relative'
  },
  navbarText: {
    position: 'relative',
    top: -3,
    marginLeft: 11
  },
  logout: {
    color: 'white'
  }
})
