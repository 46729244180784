import React from 'react'
import PropTypes from 'prop-types'
import { Field } from 'redux-form'
import Button from '@material-ui/core/Button'
import { required } from 'utils/form'
import Grid from '@material-ui/core/Grid'
import { renderTextField } from 'utils/reduxFormComponents'
import Box from '@material-ui/core/Box'

const BillingInformationForm = ({
  pristine,
  submitting,
  handleSubmit,
  classes,
  showNext,
  t
}) => {
  return (
    <form className={classes.root} onSubmit={handleSubmit}>
      <Grid container spacing={2}>
        <Grid item md={6}>
          <Field
            variant="outlined"
            name="companyName"
            component={renderTextField}
            label={t('Company')}
            validate={[required]}
            InputLabelProps={{
              shrink: true
            }}
            fullWidth
          />
        </Grid>
        <Grid item md={6} className={classes.helper}>
          <Box m={2}>
            {t('Name of your company will be added to billing receipt')}
          </Box>
        </Grid>
        <Grid item md={6}>
          <Field
            variant="outlined"
            name="companyInfo"
            multiline
            rows="4"
            component={renderTextField}
            label={t('Company Profile')}
            validate={[required]}
            InputLabelProps={{
              shrink: true
            }}
            fullWidth
          />
        </Grid>
        <Grid item md={6} className={classes.helper}>
          <Box m={2}>{t('Additional company information')}</Box>
        </Grid>
        <Grid item xs={12}>
          <div className={classes.submit}>
            {!pristine && !showNext && (
              <Button
                color="primary"
                type="submit"
                variant="outlined"
                disabled={pristine || submitting}>
                {submitting ? 'Saving' : 'Save'}
              </Button>
            )}
            {showNext && (
              <Button
                variant="contained"
                color="primary"
                type="submit"
                variant="outlined"
                disabled={submitting}>
                {t(submitting ? 'Saving' : 'Next')}
              </Button>
            )}
          </div>
        </Grid>
      </Grid>
    </form>
  )
}

BillingInformationForm.propTypes = {
  classes: PropTypes.object.isRequired, // from enhancer (withStyles)
  pristine: PropTypes.bool.isRequired, // from enhancer (reduxForm)
  showNext: PropTypes.bool.isRequired, // from enhancer (reduxForm)
  submitting: PropTypes.bool.isRequired, // from enhancer (reduxForm)
  handleSubmit: PropTypes.func.isRequired // from enhancer (reduxForm - calls onSubmit)
}

export default BillingInformationForm
