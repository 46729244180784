import React, { useState } from 'react'
import PropTypes from 'prop-types'
import Button from '@material-ui/core/Button'
import AddIcon from '@material-ui/icons/Add'
import OpenInNewIcon from '@material-ui/icons/OpenInNew'
import PublishIcon from '@material-ui/icons/Public'
import ProjectHeader from 'components/ProjectHeader/ProjectHeader'
import AddPropertyDialog from '../AddPropertyDialog'
import PropertyList from '../PropertyList'
import Grid from '@material-ui/core/Grid/Grid'
import FormControlLabel from '@material-ui/core/FormControlLabel/FormControlLabel'
import Checkbox from '@material-ui/core/Checkbox/Checkbox'
import IconButton from '@material-ui/core/IconButton'
import copyIcon from './icons8-copy-link.svg'

export const NewsletterPage = ({
  classes,
  project,
  updateProjectName,
  goToHome,
  projectId,
  match,
  groupId,
  propertyItems,
  agentItems,
  addProperty,
  updateItems,
  publish,
  onCopyLink,
  setShoWeb,
  propertyItemsGroup,
  t
}) => {
  const [showDialog, setShowDialog] = useState(false)
  const localItems = (project.properties || [])
  const publishUrl = `https://maxo.io/newsletter/?groupId=${groupId}&newsletterId=${projectId}`

  const getParentKey = (item) =>
    propertyItems.find(i => i.key === item.propertyKey).parentId


  return (
    <React.Fragment>
      <ProjectHeader
        name={project.name}
        onChangeName={updateProjectName}
        goToHome={goToHome}
        isNewsletter
        prevUrl={match.url}
      />
      <div className={classes.root}>
        <Grid justify="space-between" container>
          <Grid item>
            <Button
              disabled={project.publishing}
              variant="contained"
              color="primary"
              onClick={() => setShowDialog(true)}>
              <AddIcon className={classes.icon} />
              {t('add property')}
            </Button>
          </Grid>

          <Grid item>
            <FormControlLabel
              control={
                <Checkbox
                  checked={project.showWeb}
                  onChange={e => setShoWeb(e.target.checked)}
                />
              }
              label={t('Show web link(s) to your properties')}
            />
            {project.published && (
              <React.Fragment>
                <Button
                  className={classes.link}
                  disabled={localItems.length === 0}
                  variant="contained"
                  color="primary"
                  onClick={() => {
                    onCopyLink(publishUrl)
                  }}>
                  i
                  {t('copy the newsletter link')}
                </Button>
                <IconButton
                  href={publishUrl}
                  target={'_blank'}
                  color="primary"
                  className={classes.openBtn}>
                  <OpenInNewIcon />
                </IconButton>
              </React.Fragment>
            )}
            {(!project.published || project.changed) && !project.publishing && (
              <Button
                disabled={localItems.length === 0}
                variant="contained"
                color="primary"
                onClick={publish}>
                <PublishIcon className={classes.icon} />
                {t(!project.changed ? 'publish' : 'publish changes')}
              </Button>
            )}
            {(!project.published || project.changed) && project.publishing && (
              <Button disabled variant="contained" color="primary">
                <PublishIcon className={classes.icon} />
                {t('publishing')}...
              </Button>
            )}
          </Grid>
        </Grid>
      </div>

      <div
        className={classes.root}
        style={{
          pointerEvents: project.publishing ? 'none' : 'auto',
          cursor: project.publishing ? 'not-allowed' : 'auto'
        }}>
        <PropertyList
          onChangeItems={updateItems}
          items={localItems}
          propertyItemsGroup={propertyItemsGroup}
          propertyItems={propertyItems.sort(
            (a, b) => b.creationTime - a.creationTime
          )}
          agentItems={agentItems}
        />
      </div>

      <AddPropertyDialog
        propertyItems={propertyItemsGroup.filter(
          i => !localItems.find(k => getParentKey(k) === i.key)
        )}
        agentItems={agentItems}
        open={showDialog}
        onClose={() => setShowDialog(false)}
        onSelect={property => {
          setShowDialog(false)
          if (property.newsletter && property.subItemsFinal.length === 0) {
            addProperty(property.newsletter.key, true)
          } else if (!property.newsletter) {
            addProperty(property.subItemsFinal[0].key, false)
          } else if (property.newsletter.creationTime > property.subItemsFinal[0].creationTime) {
            addProperty(property.newsletter.key, true)
          } else {
            addProperty(property.subItemsFinal[0].key, false)
          }
        }}
      />
    </React.Fragment>
  )
}

NewsletterPage.proptypes = {
  classes: PropTypes.object.isRequired,
  goToHome: PropTypes.func.isRequired,
  project: PropTypes.object.isRequired,
  updateProjectName: PropTypes.func.isRequired,
  addProperty: PropTypes.func.isRequired,
  updateItems: PropTypes.func.isRequired,
  onCopyLink: PropTypes.func.isRequired,
  publish: PropTypes.func.isRequired,
  setShoWeb: PropTypes.func.isRequired,
  match: PropTypes.object.isRequired
}

export default NewsletterPage
