import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { isMaxRows, test } from 'utils/utils'
import { Prompt } from 'react-router'
import { test2 } from 'utils/utils'

class FormText extends Component {
  componentDidMount(prevProps) {
    // focus to new textarea block
    this.refs['textarea'].focus()
  }

  componentWillUnmount() {
    if (!this.props.text.intro) {
      this.props.removeTextBlock(this.props.descriptionId, false)
    }
  }

  componentWillReceiveProps(nextProps) {
    if (
      this.props.descriptionId !== nextProps.descriptionId &&
      !this.props.text.intro
    ) {
      this.props.removeTextBlock(this.props.descriptionId, false)
    }
  }

  renderTextArea(value, onChange) {
    return (
      <textarea
        cols="30"
        spellCheck
        ref={'textarea'}
        rows="5"
        className={this.props.classes.textArea}
        value={value}
        onChange={e => {
          if (Math.abs(e.target.value.length - value.length) === 1) {
            if (isMaxRows(e.target)) {
              onChange({ value: e.target.value, valueRows: test(e.target) })
            }
          } else {
            const data = test2(e.target)
            onChange({ value: data[1], valueRows: data[0] })
          }
        }}
      />
    )
  }

  render() {
    const { classes, t } = this.props
    return (
      <React.Fragment>
        <Prompt
          when={!this.props.text.intro}
          message={
            "The description field can't stay empty. Do you want to remove it?"
          }
        />
        <label className={classes.label}>
          {t('property description max lines')}
        </label>
        {this.renderTextArea(this.props.text.intro, ({ value, valueRows }) =>
          this.props.updateText({ intro: value, introRows: valueRows })
        )}
      </React.Fragment>
    )
  }
}

FormText.propTypes = {
  classes: PropTypes.object.isRequired,
  text: PropTypes.object.isRequired,
  updateText: PropTypes.func.isRequired,
  descriptionId: PropTypes.number.isRequired,
  t: PropTypes.object.isRequired
}

export default FormText
