const drawerWidth = 280;

export default theme => ({
  root: {
    zIndex: theme.zIndex.drawer + 1,
    width: `calc(100% - ${drawerWidth}px)`
  },
  rootFullWidth: {
    zIndex: theme.zIndex.drawer + 1
  },
  flex: {
    flexGrow: 1
  }
})
