import React from 'react'
import PropTypes from 'prop-types'
import RadioGroup from '@material-ui/core/RadioGroup'
import FormControlLabel from '@material-ui/core/FormControlLabel'
import Radio from '@material-ui/core/Radio'
import { withStyles } from '@material-ui/core'

const styles = {
  radio: {
    padding: '6px 12px'
  }
}

const DurationPicker = ({ value, onChange, classes }) => {
  return (
    <RadioGroup value={(value || 2) + ''} onChange={e => onChange(parseInt(e.target.value))}>
      <FormControlLabel
        value={'2'}
        control={<Radio className={classes.radio} />}
        label={'2 s'}
      />
      <FormControlLabel
        value={'6'}
        control={<Radio className={classes.radio} />}
        label={'6 s'}
      />
    </RadioGroup>
  )
}

DurationPicker.propTypes = {
  classes: PropTypes.object.isRequired,
  value: PropTypes.string,
  onChange: PropTypes.func.isRequired
}
export default withStyles(styles)(DurationPicker)
