import { grey } from '@material-ui/core/colors'

export default theme => ({
  root: {
    //padding: theme.spacing.unit * 7,
    maxWidth: 992,
    //backgroundColor: grey[300]
  },
  title: {
    marginBottom: theme.spacing.unit * 3
  },
  desc: {
    marginTop: theme.spacing.unit * 2,
    marginBottom: theme.spacing.unit * 2
  }
})
