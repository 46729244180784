import React from 'react'
import Button from '@material-ui/core/Button'
import TextField from '@material-ui/core/TextField'
import Dialog from '@material-ui/core/Dialog'
import DialogActions from '@material-ui/core/DialogActions'
import DialogContent from '@material-ui/core/DialogContent'
import DialogContentText from '@material-ui/core/DialogContentText'
import DialogTitle from '@material-ui/core/DialogTitle'
import { withI18n } from 'react-i18next'

class CreateContactDialog extends React.Component {
  state = {
    text: ''
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.open && !this.props.open) {
      this.setState({
        text: ''
      })
    }
  }

  render() {
    const { t } = this.props
    return (
      <Dialog open={this.props.open} onClose={this.props.onClose}>
        <DialogTitle>{t('create new contact')}</DialogTitle>
        <DialogContent>
          <DialogContentText>{t('add contact name')}</DialogContentText>
          <TextField
            autoFocus
            margin="dense"
            label={t('contact name')}
            fullWidth
            value={this.state.text}
            onChange={e =>
              this.setState({
                text: e.target.value
              })
            }
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={this.props.onClose}>{t('close')}</Button>
          <Button
            onClick={e => this.props.onSubmit(this.state.text, e)}
            color="primary"
            disabled={this.state.text === ''}>
            {t('create')}
          </Button>
        </DialogActions>
      </Dialog>
    )
  }
}

export default withI18n()(CreateContactDialog)
