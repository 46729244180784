import { compose } from 'redux'
import { withStyles } from '@material-ui/core/styles'
import styles from './SendPage.styles'
import { withI18n } from 'react-i18next'
import { firebaseConnect } from 'react-redux-firebase'
import connect from 'react-redux/es/connect/connect'
import get from 'lodash/get'
import firebaseApp from 'firebase/app'
import { withHandlers, withState } from 'recompose'
import withLoader from 'modules/loader/components/withLoader'
import withNotifications from 'modules/notification/components/withNotifications'
import { spinnerWhileLoading } from 'utils/components'
import moment from 'moment'

export default compose(
  firebaseConnect(({ groupId, match, project }) => {
    return [
      `groups/${groupId}/agents/${project.agentKey}`,
      `groups/${groupId}/contacts/${project.contactKey}`,
      `groups/${groupId}/createdBy`
    ]
  }),
  connect(
    ({ firebase: { data, profile } }, { groupId, projectId, project }) => ({
      agent: get(data, `groups.${groupId}.agents.${project.agentKey}`),
      contact: get(data, `groups.${groupId}.contacts.${project.contactKey}`),
      groupCreatedBy: get(data, `groups.${groupId}.createdBy`),
      profile
    })
  ),
  spinnerWhileLoading(['contact', 'groupCreatedBy', 'agent']),
  firebaseConnect(({ groupCreatedBy }) => {
    return [`users/${groupCreatedBy}/lastPayment`]
  }),
  connect(({ firebase: { data } }, { groupCreatedBy }) => {
    const lastPayment = get(data, `users.${groupCreatedBy}.lastPayment`)
    return {
      lastPayment
    }
  }),
  spinnerWhileLoading(['lastPayment']),
  withLoader,
  withNotifications,
  withState('showPreviewSentDialog', 'setShowPreviewSentDialog', false),
  withState('showFinalSentDialog', 'setShowFinalSentDialog', false),
  withHandlers({
    sendVideo: props => type => {
      const {
        showError,
        groupId,
        setLoader,
        clearLoader,
        projectId,
        profile,
        setShowPreviewSentDialog,
        setShowFinalSentDialog
      } = props
      setLoader()
      return firebaseApp
        .functions()
        .httpsCallable('sendProject')({
          projectId,
          groupId,
          type,
          lang: profile.language
        })
        .then(() => {
          if (type === 'preview') {
            setShowPreviewSentDialog(true)
          } else {
            setShowFinalSentDialog(true)
          }
        })
        .catch(err => {
          console.error('Error:', err) // eslint-disable-line no-console
          showError(err.message || 'Project send error')
          return Promise.reject(err)
        })
        .finally(() => {
          clearLoader()
        })
    }
  }),
  withStyles(styles),
  withI18n()
)
