import React, { useState } from 'react'
import PropTypes from 'prop-types'
import Table from '@material-ui/core/Table'
import TableRow from '@material-ui/core/TableRow'
import TableHead from '@material-ui/core/TableHead'
import TableCell from '@material-ui/core/TableCell'
import TableBody from '@material-ui/core/TableBody'
import Paper from '@material-ui/core/Paper'
import AddIcon from '@material-ui/icons/Add'
import Button from '@material-ui/core/Button/Button'
import AddGroupDialog from '../AddGroupDialog'

export const Home = ({
  classes,
  groups,
  goToGroup,
  superAdmin,
  onAddGroup,
  t
}) => {
  const [showAddDialog, setShowAddDialog] = useState(false)
  return (
    <div className={classes.root}>
      <AddGroupDialog
        open={showAddDialog}
        onClose={() => setShowAddDialog(false)}
        onAdd={onAddGroup}
      />
      <div className="flex-row-center">
        <h2>{t('groups')}</h2>
      </div>
      <Paper>
        {superAdmin && (
          <div className={classes.tableTopPanel}>
            <Button
              className={classes.addButton}
              onClick={() => setShowAddDialog(true)}>
              <AddIcon className={classes.addIcon} />
              {t('add group')}
            </Button>
          </div>
        )}
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>{t('name')}</TableCell>
              <TableCell align="right">{t('users')}</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {groups.map(row => (
              <TableRow
                className={classes.tableRow}
                key={row.key}
                onClick={() => goToGroup(row.key)}
                hover>
                <TableCell component="th" scope="row">
                  {row.name}
                </TableCell>
                <TableCell align="right">{row.emailsCount}</TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </Paper>
    </div>
  )
}

Home.proptypes = {
  t: PropTypes.func.isRequired,
  classes: PropTypes.object.isRequired, // from enhancer (withStyles),
  goToGroup: PropTypes.func.isRequired,
  onAddGroup: PropTypes.func.isRequired,
  superAdmin: PropTypes.bool.isRequired
}

export default Home
