import React from 'react'
import PropTypes from 'prop-types'
import Button from '@material-ui/core/Button'
import Dialog from '@material-ui/core/Dialog'
import DialogActions from '@material-ui/core/DialogActions'
import DialogContent from '@material-ui/core/DialogContent'
import DialogContentText from '@material-ui/core/DialogContentText'
import DialogTitle from '@material-ui/core/DialogTitle'
import { withI18n } from 'react-i18next'
import AccountPage from './AccountPage'

const NoGroupDialog = ({ t, onClose, open }) => (
  <Dialog open={open}>
    <DialogTitle>{t('account')}</DialogTitle>
    <DialogContent>
      <AccountPage />
    </DialogContent>
    <DialogActions>
      <Button onClick={onClose} color="primary" autoFocus>
        {t('close')}
      </Button>
    </DialogActions>
  </Dialog>
)

NoGroupDialog.propTypes = {
  t: PropTypes.func.isRequired,
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired
}

export default withI18n()(NoGroupDialog)
