import React from 'react'
import { withStyles } from '@material-ui/core/styles/index'
import PropTypes from 'prop-types'
import grey from '@material-ui/core/colors/grey'
import green from '@material-ui/core/colors/green'
import red from '@material-ui/core/colors/red'
import Circle from './Circle'
import okImg from './OK.svg'
import warningImg from './Warning.svg'

const styles = theme => ({
  label: {
    color: theme.palette.primary.main,
    fontWeight: 500,
    margin: '15px 0'
  },
  table: {
    borderCollapse: 'collapse',
    '& table, th, td': {
      border: `1px solid ${grey[500]}`,
      textAlign: 'center'
    },
    '& td:first-child': {
      textAlign: 'left'
    },
    '& th, td': {
      padding: '10px 14px',
      color: 'white',
      fontWeight: 400
    },
    '& svg+svg': {
      marginLeft: 3
    },
    '& tbody tr:last-child td': {
      border: 'none'
    }
  },
  icon: {
    width: 35,
    height: 37
  }
})

const getColor = (item, optional) => {
  if (item) {
    return green[600]
  } else {
    return !optional ? red[400] : grey[400]
  }
}

const SummaryTable = ({ classes, items, label, scheme, namePrefix }) => {
  const isMulti = !!namePrefix
  const cols = items.length

  return (
    <React.Fragment>
      <div className={classes.label}>{label}</div>
      <table className={classes.table}>
        {isMulti && (
          <thead>
            <tr>
              <th />
              {[...Array(cols)].map((_, index) => (
                <th key={index}>
                  {namePrefix} {index + 1}
                </th>
              ))}
            </tr>
          </thead>
        )}
        <tbody>
          {scheme.map((item, index) => (
            <tr key={index}>
              <td>{item.label}</td>
              {items.map(subItem => (
                <td>
                  <Circle color={getColor(subItem[item.name], item.optional)} />
                </td>
              ))}
            </tr>
          ))}
          <tr>
            <td />
            {items
              .map(
                i =>
                  Object.keys(i).filter(
                    k => !i[k] && !scheme.find(l => l.name === k).optional
                  ).length === 0
              )
              .map((item, index2) => (
                <td key={index2}>
                  <img
                    className={classes.icon}
                    src={item ? okImg : warningImg}
                  />
                </td>
              ))}
          </tr>
        </tbody>
      </table>
    </React.Fragment>
  )
}

SummaryTable.propTypes = {
  classes: PropTypes.object.isRequired,
  items: PropTypes.array.isRequired,
  label: PropTypes.string.isRequired,
  namePrefix: PropTypes.string.isRequired,
  scheme: PropTypes.object.isRequired
}

export default withStyles(styles)(SummaryTable)
