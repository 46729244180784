import React from 'react'
import PropTypes from 'prop-types'
import Navbar from '../../components/Navbar'
import { Notifications } from '../../modules/notification'
import { Loader } from '../../modules/loader'
import DisableSubscriptionInfo from '../../components/DisableSubscriptionInfo'
import { createMuiTheme, MuiThemeProvider } from '@material-ui/core'
import ThemeSettings from '../../theme'
const theme = createMuiTheme({
  ...ThemeSettings,
  palette: { ...ThemeSettings.palette, type: 'light' }
})

export const CoreLayout = ({ children, handleLogout, classes, profile }) => {
  return (
    <React.Fragment>
      <Navbar onLogout={handleLogout} showLogout />
      <MuiThemeProvider theme={theme}>
        <div className={classes.content}>
          {profile.groups && !profile.subscription ? (
            <DisableSubscriptionInfo />
          ) : (
            children
          )}
        </div>
      </MuiThemeProvider>
      <Notifications />
      <Loader />
    </React.Fragment>
  )
}

CoreLayout.propTypes = {
  classes: PropTypes.object.isRequired,
  children: PropTypes.element.isRequired,
  handleLogout: PropTypes.func.isRequired
}

export default CoreLayout
