import { connectedRouterRedirect } from 'redux-auth-wrapper/history4/redirect'
import locationHelperBuilder from 'redux-auth-wrapper/history4/locationHelper'
import createHistory from 'history/createBrowserHistory'
import LoadingSpinner from 'components/LoadingSpinner'
import { LIST_PATH, SUBSCRIPTION_PATH } from 'constants/paths'
import {
  BILLING_INFORMATION_PATH,
  CLIENT_PATH,
  LOGIN_PATH,
  REGISTER_PATH,
  URL_PREFIX
} from '../constants/paths'

const locationHelper = locationHelperBuilder({})
const history = createHistory()

const AUTHED_REDIRECT = 'AUTHED_REDIRECT'
const UNAUTHED_REDIRECT = 'UNAUTHED_REDIRECT'

/**
 * Higher Order Component that redirects to `/login` instead
 * rendering if user is not authenticated (default of redux-auth-wrapper).
 * @param {Component} componentToWrap - Component to wrap
 * @return {Component} wrappedComponent
 */
export const UserIsAuthenticated = connectedRouterRedirect({
  redirectPath: LOGIN_PATH + '?from=invitation',
  AuthenticatingComponent: LoadingSpinner,
  wrapperDisplayName: 'UserIsAuthenticated',
  // Want to redirect the user when they are done loading and authenticated
  authenticatedSelector: ({ firebase: { auth } }) => {
    return !auth.isEmpty && !!auth.uid
  },
  authenticatingSelector: ({ firebase: { auth, isInitializing } }) => {
    return !auth.isLoaded || isInitializing
  },
  redirectAction: newLoc => dispatch => {
    // Use push, replace, and go to navigate around.
    history.push(newLoc)
    dispatch({
      type: UNAUTHED_REDIRECT,
      payload: { message: 'User is not authenticated.' }
    })
  }
})

/**
 * Higher Order Component that redirects to listings page or most
 * recent route instead rendering if user is not authenticated. This is useful
 * routes that should not be displayed if a user is logged in, such as the
 * login route.
 * @param {Component} componentToWrap - Component to wrap
 * @return {Component} wrappedComponent
 */
export const UserIsNotAuthenticated = connectedRouterRedirect({
  AuthenticatingComponent: LoadingSpinner,
  wrapperDisplayName: 'UserIsNotAuthenticated',
  allowRedirectBack: false,
  // Want to redirect the user when they are done loading and authenticated
  authenticatedSelector: ({ firebase: { auth } }) => {
    return auth.isEmpty && auth.isLoaded
  },
  authenticatingSelector: ({ firebase: { auth, isInitializing } }) => {
    return !auth.isLoaded || isInitializing
  },
  redirectPath: (state, ownProps) =>
    locationHelper.getRedirectQueryParam(ownProps) || CLIENT_PATH,
  redirectAction: newLoc => dispatch => {
    // Use push, replace, and go to navigate around.
    history.push(newLoc)
    dispatch({
      type: AUTHED_REDIRECT,
      payload: { message: 'User is not authenticated.' }
    })
  }
})

export const UserIsNotRegistered = connectedRouterRedirect({
  redirectPath: CLIENT_PATH,
  AuthenticatingComponent: LoadingSpinner,
  wrapperDisplayName: 'UserIsNotRegistered',
  // Want to redirect the user when they are done loading and authenticated
  authenticatedSelector: ({ firebase: { auth, profile } }) =>
    !auth.isEmpty &&
    !(
      (profile.subscription && profile.subscription.status === 'ACTIVE') ||
      profile.lastPayment
    ),
  authenticatingSelector: ({ firebase: { auth, isInitializing } }) =>
    !auth.isLoaded || isInitializing,
  redirectAction: newLoc => dispatch => {
    // Use push, replace, and go to navigate around.
    history.push(newLoc)
    dispatch({
      type: UNAUTHED_REDIRECT,
      payload: { message: 'User is not authenticated.' }
    })
  }
})

export const UserIsRegisteredOrInvited = connectedRouterRedirect({
  redirectPath: REGISTER_PATH + SUBSCRIPTION_PATH,
  AuthenticatingComponent: LoadingSpinner,
  wrapperDisplayName: 'UserIsRegisteredOrInvited',
  // Want to redirect the user when they are done loading and authenticated
  authenticatedSelector: ({ firebase: { auth, profile } }) =>
    !auth.isEmpty &&
    ((profile.subscription && profile.subscription.status === 'ACTIVE') ||
      profile.lastPayment ||
      profile.groups ||
      profile.groupsAdmin),
  authenticatingSelector: ({ firebase: { auth, isInitializing, profile } }) => {
    return !auth.isLoaded || isInitializing || !profile.isLoaded
  },
  redirectAction: newLoc => dispatch => {
    // Use push, replace, and go to navigate around.
    history.push(newLoc)
    dispatch({
      type: UNAUTHED_REDIRECT,
      payload: { message: 'User is not authenticated.' }
    })
  }
})
