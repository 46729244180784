import React, { useState } from 'react'
import PropTypes from 'prop-types'
import Button from '@material-ui/core/Button'
import Dialog from '@material-ui/core/Dialog'
import DialogActions from '@material-ui/core/DialogActions'
import { withStyles } from '@material-ui/core/styles'
import AppBar from '@material-ui/core/AppBar'
import Tabs from '@material-ui/core/Tabs'
import Tab from '@material-ui/core/Tab'
import Typography from '@material-ui/core/Typography'
import Texts from './TabTexts'
import ContactI from './TabContact'
import { withI18n } from 'react-i18next'
import useDebounce from 'react-use/lib/useDebounce'
import isEqual from 'react-fast-compare'

function TabContainer(props) {
  return (
    <Typography component="div" style={{ padding: 8 * 3 }}>
      {props.children}
    </Typography>
  )
}

TabContainer.propTypes = {
  children: PropTypes.node.isRequired
}

const styles = theme => ({
  content: {
    padding: 10,

    overflow: 'auto'
  },
  widthMd: {
    width: 1000
  }
})

const Contact = props => {
  const { classes, t, isAdmin, colors, updateGroupColors, onlyGroupColors } = props
  const [tab, changeTab] = useState(0)
  const values = props.values

  const [localValues, updateValues] = useState(values)
  useDebounce(
    () => {
      if (!isEqual(localValues, values)) {
        props.onChange(localValues)
      }
    },
    200,
    [localValues]
  )

  const handleChangeValues = (name, val) => {
    updateValues({
      ...values,
      [name]: val
    })
  }

  const handleClose = () => props.onClose()

  return (
    <Dialog
      onClose={handleClose}
      scroll={'body'}
      open={props.open}
      maxWidth="md"
      classes={{ paperWidthMd: classes.widthMd }}>
      <div className={classes.root}>
        <AppBar position="static">
          <Tabs value={tab} onChange={(_, tabId) => changeTab(tabId)}>
            <Tab label={t('animation at the beginning of the contact')} />
            <Tab label={t('contact information')} />
          </Tabs>
        </AppBar>
        <div className={classes.content}>
          {tab === 0 && (
            <Texts
              texts={localValues.texts}
              onChangeTexts={texts => handleChangeValues('texts', texts)}
              design={localValues.design}
              onChangeDesign={texts => handleChangeValues('design', texts)}
              isAdmin={isAdmin}
              onlyGroupColors={onlyGroupColors}
              colors={colors}
              updateGroupColors={updateGroupColors}
            />
          )}
          {tab === 1 && (
            <ContactI
              addLogo={props.addLogo}
              values={localValues.contact}
              onChange={contact => handleChangeValues('contact', contact)}
              isAdmin={isAdmin}
              onlyGroupColors={onlyGroupColors}
              colors={colors}
              updateGroupColors={updateGroupColors}
              originalValues={values.contact}
            />
          )}
        </div>
        <DialogActions>
          <Button onClick={handleClose} color="primary">
            {t('close')}
          </Button>
        </DialogActions>
      </div>
    </Dialog>
  )
}

Contact.propTypes = {
  classes: PropTypes.object.isRequired,
  addLogo: PropTypes.func.isRequired,
  onChange: PropTypes.func.isRequired,
  onClose: PropTypes.func.isRequired,
  values: PropTypes.object.isRequired,
  open: PropTypes.bool.isRequired,
  isAdmin: PropTypes.bool.isRequired,
  updateGroupColors: PropTypes.func.isRequired,
  colors: PropTypes.object.isRequired,
  onlyGroupColors: PropTypes.bool.isRequired
}

export default withI18n()(withStyles(styles)(Contact))
