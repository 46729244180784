import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { withStyles } from '@material-ui/core/styles'
import TextField from '@material-ui/core/TextField'
import Button from '@material-ui/core/Button'
import FormHelperText from '@material-ui/core/FormHelperText'
import FormControl from '@material-ui/core/FormControl'
import Grid from '@material-ui/core/Grid'
import FormControlLabel from '@material-ui/core/FormControlLabel'
import Checkbox from '@material-ui/core/Checkbox'
import Radio from '@material-ui/core/Radio'
import ColorPicker from 'components/ColorPicker'
import RadioWithText from 'components/RadioWithText'
import SelectMap from 'components/SelectMap'
import Label from 'components/Label'
import InfoIcon from '@material-ui/icons/Info'
import TimeIcon from '@material-ui/icons/AccessTime'
import { withI18n } from 'react-i18next'
import RadioGroup from '@material-ui/core/RadioGroup'
import Typography from '@material-ui/core/Typography'

const styles = theme => ({
  container: {
    display: 'flex',
    flexWrap: 'wrap'
  },
  formControl: {
    margin: theme.spacing.unit
  },
  formLabel: {
    whiteSpace: 'nowrap'
  },
  colorInfo: {
    marginLeft: 50,
    verticalAlign: 'middle'
  },
  iconWrap: {
    padding: 5,
    marginTop: 10
  },
  checkboxLabel: {
    fontSize: '1rem',
    marginBottom: -2
    //height: 27,
  },
  checkboxWithText: {
    height: 27,
    marginLeft: 10
  },
  selectIconWrap: {
    paddingLeft: 20
  },
  subText: {
    color: '#949494'
  },
  logo: {
    maxWidth: 200,
    marginTop: 10
  },
  logoWrap: {
    marginTop: 20
  },
  map: {
    marginBottom: 30,
    marginTop: 20
  },
  icon: {
    marginTop: 4
  },
  labelMap: {
    marginTop: 30,
    marginBottom: 15
  },
  contactColorInfo: {
    lineHeight: '47px'
  },
  colorPicker: {
    marginLeft: theme.spacing.unit
  }
})

class Texts extends Component {
  state = {
    showPicker: null,
    inputInstance: 0
  }

  handleChangeFile = e => {
    const file = e.target.files[0]
    if (file) {
      this.props.addLogo(file)
    }
  }

  handleChange = (name, value) =>
    this.props.onChange({ ...this.props.values, [name]: value })

  renderText(name) {
    const { t } = this.props
    const textPlaceholder = {
      email: t('email'),
      phone: t('phone number'),
      web: t('web'),
      office: t('office address')
    }

    return (
      <Grid item xs>
        <FormControl className={this.props.classes.formControl}>
          <TextField
            label={textPlaceholder[name]}
            className={this.props.classes.textField}
            value={this.props.values[name]}
            onChange={e => this.handleChange(name, e.target.value)}
            margin="none"
          />
          {this.props.values[name] === '' && (
            <FormHelperText error>{t('required field')}</FormHelperText>
          )}
        </FormControl>
      </Grid>
    )
  }

  renderOtherLineNote() {
    const { t } = this.props
    return (
      <div>
        {t('5 line of contact')}{' '}
        <span className={this.props.classes.subText}>
          {t('5 line of contact desc')}
        </span>
      </div>
    )
  }

  render() {
    const { classes, t } = this.props

    return (
      <div>
        <Grid container spacing={2}>
          {this.renderText('email')}
          {this.renderText('phone')}
          {this.renderText('web')}
          {this.renderText('office')}
        </Grid>

        <div className={classes.iconWrap}>
          <FormControlLabel
            control={
              <Checkbox
                checked={this.props.values.showIcon}
                onChange={e => this.handleChange('showIcon', e.target.checked)}
              />
            }
            classes={{ label: classes.checkboxLabel }}
            label={t('Enable icons')}
          />
          {!this.props.onlyGroupColors ? (
            <RadioGroup
              row
              value={this.props.values.activeGroupColor ? '1' : '0'}
              onChange={e =>
                this.handleChange('activeGroupColor', e.target.value === '1')
              }>
              <FormControlLabel
                value={'0'}
                control={<Radio />}
                label={
                  <Grid container alignItems={'center'}>
                    <Grid item>{t('custom')}</Grid>
                    <Grid className={classes.colorPicker} item>
                      <ColorPicker
                        onChange={color => this.handleChange('color', color)}
                        color={this.props.values.color}
                      />
                    </Grid>
                  </Grid>
                }
              />
              <FormControlLabel
                value={'1'}
                control={<Radio />}
                label={
                  <Grid container alignItems={'center'}>
                    <Grid item>{t('Group colors')}</Grid>
                    <Grid item className={classes.colorPicker}>
                      <ColorPicker
                        color={this.props.colors.main}
                        onChange={
                          this.props.isAdmin &&
                          (color => this.props.updateGroupColors('main', color))
                        }
                      />
                    </Grid>
                  </Grid>
                }
              />
              <Typography className={classes.contactColorInfo}>
                {t('contact color desc')}
              </Typography>
            </RadioGroup>
          ) : (
            <Grid container alignItems={'center'} spacing={1}>
              <Grid item>
                <ColorPicker
                  color={this.props.colors.main}
                  onChange={
                    this.props.isAdmin &&
                    (color => this.props.updateGroupColors('main', color))
                  }
                />
              </Grid>
              <Grid item>
                <Typography className={classes.contactColorInfo}>
                  {t('contact color desc')}
                </Typography>
              </Grid>
            </Grid>
          )}
        </div>
        <div style={{ padding: 5 }}>
          <FormControlLabel
            control={
              <Checkbox
                checked={this.props.values.enableOtherline}
                onChange={e =>
                  this.handleChange('enableOtherline', e.target.checked)
                }
              />
            }
            classes={{ label: classes.checkboxLabel }}
            label={this.renderOtherLineNote()}
          />
        </div>
        {this.props.values.enableOtherline && (
          <div>
            <div style={{ padding: 5 }}>
              <RadioWithText
                checked={this.props.values.otherLine === 'address'}
                onChangeRadio={checked =>
                  checked && this.handleChange('otherLine', 'address')
                }
                text={this.props.values.otherAddress}
                onChangeText={value => this.handleChange('otherAddress', value)}
                placeholder={t('office address 2')}
              />
              <Grid container spacing={1} alignItems="flex-end">
                <Grid item>
                  <RadioWithText
                    checked={this.props.values.otherLine === 'note'}
                    onChangeRadio={checked =>
                      checked && this.handleChange('otherLine', 'note')
                    }
                    text={this.props.values.otherNote}
                    onChangeText={value =>
                      this.handleChange('otherNote', value)
                    }
                    placeholder={t('general information')}
                  />
                </Grid>
                {this.props.values.otherLine === 'note' && (
                  <Grid item>
                    <div className={classes.selectIconWrap}>
                      <FormControlLabel
                        control={
                          <Radio
                            checked={this.props.values.otherNoteIcon === 'info'}
                            onChange={e =>
                              e.target.checked &&
                              this.handleChange('otherNoteIcon', 'info')
                            }
                            classes={{
                              root: this.props.classes.checkboxWithText
                            }}
                          />
                        }
                        label={<InfoIcon className={classes.icon} />}
                      />
                      <FormControlLabel
                        control={
                          <Radio
                            checked={
                              this.props.values.otherNoteIcon === 'clock'
                            }
                            onChange={e =>
                              e.target.checked &&
                              this.handleChange('otherNoteIcon', 'clock')
                            }
                            classes={{
                              root: this.props.classes.checkboxWithText
                            }}
                          />
                        }
                        label={<TimeIcon className={classes.icon} />}
                      />
                    </div>
                  </Grid>
                )}
              </Grid>
            </div>
          </div>
        )}
        <div style={{ padding: 5 }}>
          <FormControlLabel
            control={
              <Checkbox
                checked={this.props.values.enableZoom}
                onChange={e =>
                  this.handleChange('enableZoom', e.target.checked)
                }
              />
            }
            classes={{ label: classes.checkboxLabel }}
            label={t('enable magnifier')}
          />
          <Label
            primary={t('address')}
            className={classes.labelMap}
            secondary={t('contact map info')}
          />
          <SelectMap
            values={this.props.values.map}
            onChange={values => this.handleChange('map', values)}
            className={classes.map}
          />
          <input
            accept="image/x-png,image/gif,image/jpeg,image/png,image/bmp"
            type="file"
            style={{ display: 'none' }}
            onChange={this.handleChangeFile}
            ref="input"
            key={this.state.inputInstance}
          />
          <div className={classes.logoWrap}>
            <Button
              variant="contained"
              className={classes.button}
              onClick={() => this.refs.input.click()}>
              {this.props.originalValues.logo ? t('change') : t('upload')}{' '}
              {t('logo image suffix')}
            </Button>
            <div>
              {this.props.originalValues.logo && (
                <img
                  key={this.props.originalValues.editDate}
                  className={classes.logo}
                  src={`${this.props.originalValues.logo}&editDate=${
                    this.props.originalValues.editDate
                  }`}
                />
              )}
            </div>
          </div>
        </div>
      </div>
    )
  }
}

Texts.propTypes = {
  classes: PropTypes.object.isRequired,
  values: PropTypes.object.isRequired,
  onChange: PropTypes.func.isRequired,
  addLogo: PropTypes.func.isRequired,
  onlyGroupColors: PropTypes.bool.isRequired,
  originalValues: PropTypes.object.isRequired
}

export default withI18n()(withStyles(styles)(Texts))
