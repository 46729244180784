import { withStyles } from '@material-ui/core/styles'
import styles from './NewsletterPage.styles'
import { branch, compose, renderComponent, withHandlers } from 'recompose'
import { firebaseConnect } from 'react-redux-firebase'
import { connect } from 'react-redux'
import { spinnerWhileLoading } from 'utils/components'
import get from 'lodash/get'
import { withRouter } from 'react-router-dom'
import { CLIENT_PATH, URL_PREFIX } from 'constants/paths'
import withNotifications from '../../../../modules/notification/components/withNotifications'
import withLoader from '../../../../modules/loader/components/withLoader'
import ProjectNotFound from '../../../../components/ProjectNotFound'
import map from 'lodash/map'
import { withI18n } from 'react-i18next'
import firebaseApp from 'firebase'
import { LIST_PATH, NEWSLETTERS_LIST_PATH } from '../../../../constants/paths'
import copy from 'copy-to-clipboard'
import { setPathInImages } from '../../../../utils/utils'
import { propertiesToGroup } from 'utils/utils'

const addParentName = items => {
  return items.map(item => {
    if (item.parentId) {
      const currentParent = items.find(i => i.key === item.parentId)
      if (currentParent) {
        const childIndex = items
          .filter(i => i.parentId === item.parentId && !i.preview)
          .sort((a, b) => a.finishedTime - b.finishedTime)
          .findIndex(i => i.key === item.key)
        return { ...item, name: currentParent.name }
      } else {
        return { ...item, name: 'nor fou' }
      }
    }
    return item
  })
}

export default compose(
  withRouter,
  connect(({ firebase: { profile } }, { match }) => ({
    profile,
    groupId: profile && profile.actualGroup,
    projectId: match.params.projectId
  })),
  spinnerWhileLoading(['profile']),
  firebaseConnect(({ groupId, match, projectId }) => {
    return [
      {
        path: `groups/${groupId}/properties`
      },
      {
        path: `groups/${groupId}/newsletters/${projectId}`
      },
      {
        path: `groups/${groupId}/agents`
      }
    ]
  }),
  connect(({ firebase: { data } }, { groupId, projectId }) => {
    const agentItems = map(get(data, `groups.${groupId}.agents`), (value, key) => ({
      ...value,
      key
    }))
    const propertyItems = map(get(data, `groups.${groupId}.properties`), (value, key) => ({
        ...value,
        images: setPathInImages(value.images, groupId, key),
        key
      }))

    return {
      project: get(data, `groups.${groupId}.newsletters.${projectId}`),
      agentItems,
      propertyItems,
      propertyItemsGroup: propertiesToGroup(propertyItems, agentItems)
        .filter((i) => i.newsletter || i.subItemsFinal.length > 0)
  }}),
  spinnerWhileLoading(['project', 'propertyItems']),
  branch(props => !props.project, renderComponent(ProjectNotFound)),
  withNotifications,
  withLoader,
  withI18n(),
  withHandlers({
    updateProjectName: props => name => {
      const { firebase, showError, groupId, projectId } = props
      return firebase
        .update(`groups/${groupId}/newsletters/${projectId}`, {
          name,
          changed: true
        })
        .catch(err => {
          console.error('Error:', err) // eslint-disable-line no-console
          showError(err.message || 'Could not save project name')
          return Promise.reject(err)
        })
    },
    setShoWeb: props => showWeb => {
      const { firebase, showError, groupId, projectId } = props
      return firebase
        .update(`groups/${groupId}/newsletters/${projectId}`, {
          showWeb,
          changed: true
        })
        .catch(err => {
          console.error('Error:', err) // eslint-disable-line no-console
          showError(err.message || 'Could not save update show web')
          return Promise.reject(err)
        })
    },
    goToHome: ({ history }) => () => {
      history.push(`${CLIENT_PATH}${LIST_PATH}/${NEWSLETTERS_LIST_PATH}`)
    },
    addProperty: props => (propertyKey, newsletter) => {
      const { firebase, groupId, projectId, project } = props
      const nextProperties = [
        ...(project.properties || []),
        { propertyKey, desc: '', web: '', newsletter }
      ]
      return firebase
        .set(
          `groups/${groupId}/newsletters/${projectId}/properties`,
          nextProperties
        )
        .then(() => {
          return firebase.set(
            `groups/${groupId}/newsletters/${projectId}/changed`,
            true
          )
        })
    },
    updateItems: props => items => {
      const { firebase, groupId, projectId, project } = props
      return firebase
        .set(`groups/${groupId}/newsletters/${projectId}/properties`, items)
        .then(() => {
          return firebase.set(
            `groups/${groupId}/newsletters/${projectId}/changed`,
            true
          )
        })
    },
    publish: props => () => {
      const { showError, groupId, setLoader, clearLoader, projectId } = props
      setLoader()
      return firebaseApp
        .functions()
        .httpsCallable('publishNewsletter')({
          newsletterId: projectId,
          groupId
        })
        .catch(err => {
          console.error('Error:', err) // eslint-disable-line no-console
          showError(err.message || 'Project send error')
          return Promise.reject(err)
        })
        .finally(() => {
          clearLoader()
        })
    },
    onCopyLink: props => link => {
      copy(link)
      props.showSuccess(props.t('copied'))
    }
  }),
  withStyles(styles)
)
