import React, { useState } from 'react'
import TextField from '@material-ui/core/TextField/TextField'
import { useDebounce } from 'react-use'

const TextFieldWithDebounce = ({ value, onChange, ...otherProps }) => {
  const [localValue, updateValue] = useState(value)
  useDebounce(
    () => {
      if (localValue !== value) {
        onChange(localValue)
      }
    },
    200,
    [localValue]
  )

  return (
    <TextField
      {...otherProps}
      value={localValue}
      onChange={e => updateValue(e.target.value)}
    />
  )
}

export default TextFieldWithDebounce
