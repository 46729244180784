export default theme => ({
  title: {
    marginTop: theme.spacing.unit * 6
  },
  root: {
    ...theme.flexColumnCenter,
    paddingTop: theme.spacing.unit * 4
  },
  layout: {
    width: 'auto',
    marginLeft: theme.spacing.unit * 3,
    marginRight: theme.spacing.unit * 3
  },
  cardGrid: {
    padding: `${theme.spacing.unit * 2}px 0`
  },
  storeIcon: {
    width: '3em',
    height: '3em'
  },
  card: {
    textAlign: 'center'
  },
  activeLink: {
    backgroundColor: 'red'
  }
})
