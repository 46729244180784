import React from 'react'
import PropTypes from 'prop-types'
import AppBar from '@material-ui/core/AppBar'
import Toolbar from '@material-ui/core/Toolbar'
import cn from 'classnames'
import Typography from '@material-ui/core/Typography'
import { NavLink, Link } from 'react-router-dom'
import { CLIENT_PATH, URL_PREFIX } from 'constants/paths'
import iconProperty from '../../assets/property.svg'
import iconNewsletter from '../../assets/newsletter.svg'
import { NEWSLETTERS_LIST_PATH, PROPERTIES_LIST_PATH } from 'constants/paths'
import { LIST_PATH } from '../../constants/paths'
import LogoImg from 'assets/logo.svg'
import Button from '@material-ui/core/Button'
import PublishIcon from '@material-ui/icons/Public'
import IconButton from '@material-ui/core/IconButton'
import OpenInNewIcon from '@material-ui/icons/OpenInNew'
import copyIcon from './icons8-copy-link.svg'
import linkIcon from './icons8-external-link-2.svg'
import uploadIcon from './icons8-upload.svg'
import settingsIcon from './icons8-settings.svg'
import Tooltip from '@material-ui/core/Tooltip'
import SettingsNewsletterDialog from './SettingsNewsletterDialog'

export const HomeLayout = ({
  children,
  classes,
  publishNewsletter,
  onCopyLink,
  profile,
  t,
  showNewsletterSettings,
  setShowNewsletterSettings
}) => {
  const newsletterUrl = `https://maxo.io/newsletter/?groupId=${
    profile.actualGroup
  }`
  console.log('HOME')
  return (
    <div>
      <AppBar position="static">
        <Toolbar className={classes.appBar}>
          <Typography
            component={Link}
            to={`${URL_PREFIX}/`}
            variant="title"
            color="inherit">
            <img className={classes.logo} src={LogoImg} alt="logo" />
          </Typography>
          <div className={classes.tabs}>
            <Button
              className={classes.btnUpdateNewsletter}
              onClick={publishNewsletter}>
              <img className={classes.icon} src={uploadIcon} />
              {t('Update newsletter')}
            </Button>
            <React.Fragment>
              <Tooltip title={t('Copy link')}>
                <IconButton
                  variant="contained"
                  onClick={() => {
                    onCopyLink(newsletterUrl)
                  }}>
                  <img className={classes.icon} src={copyIcon} />
                </IconButton>
              </Tooltip>
              <Tooltip title={t('Open in a new windows')}>
                <IconButton
                  href={newsletterUrl}
                  target={'_blank'}
                  className={classes.openBtn}>
                  <img className={classes.icon} src={linkIcon} />
                </IconButton>
              </Tooltip>
              <Tooltip title={t('Settings')}>
                <IconButton
                  onClick={() => setShowNewsletterSettings(true)}
                  className={classes.openBtn}>
                  <img className={classes.icon} src={settingsIcon} />
                </IconButton>
              </Tooltip>
            </React.Fragment>
          </div>
        </Toolbar>
      </AppBar>
      {children}
      <SettingsNewsletterDialog
        open={showNewsletterSettings}
        groupId={profile.actualGroup}
        onClose={() => setShowNewsletterSettings(false)}
      />
    </div>
  )
}

HomeLayout.propTypes = {
  classes: PropTypes.object.isRequired, // from enhancer (withStyles)
  children: PropTypes.element.isRequired,
  groups: PropTypes.arrayOf(PropTypes.string.isRequired),
  groupName: PropTypes.string.isRequired,
  handleOpenSelectGroup: PropTypes.func.isRequired,
  setShowNewsletterSettings: PropTypes.func.isRequired,
  showNewsletterSettings: PropTypes.bool.isRequired,
  t: PropTypes.func.isRequired
}

export default HomeLayout
