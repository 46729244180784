import React from 'react'
import PropTypes from 'prop-types'

import AccountMenu from 'components/AccountMenu'
import moment from 'moment'
import { ADMIN_PATH } from '../../constants/paths'

export const Navbar = ({
  authExists,
  goToAccount,
  goToLanguage,
  handleLogout,
  closeAccountMenu,
  anchorEl,
  handleMenu,
  showSubscription,
  profile,
  lastPayment
}) => {
  return (
    <React.Fragment>
      {authExists && (
        <AccountMenu
          avatarUrl={profile.avatarUrl}
          displayName={profile.displayName}
          onLogoutClick={handleLogout}
          goToAccount={goToAccount}
          goToLanguage={goToLanguage}
          closeAccountMenu={closeAccountMenu}
          handleMenu={handleMenu}
          anchorEl={anchorEl}
          showSubscription={showSubscription}
          lastPayment={lastPayment}
        />
      )}
    </React.Fragment>
  )
}

Navbar.propTypes = {
  classes: PropTypes.object.isRequired, // from enhancer (withStyles)
  displayName: PropTypes.string, // from enhancer (flattenProps - profile)
  avatarUrl: PropTypes.string, // from enhancer (flattenProps - profile)
  authExists: PropTypes.bool, // from enhancer (withProps - auth),
  showSubscription: PropTypes.bool,
  goToAccount: PropTypes.func.isRequired, // from enhancer (withHandlers - router)
  goToLanguage: PropTypes.func.isRequired, // from enhancer (withHandlers - router)
  handleLogout: PropTypes.func.isRequired, // from enhancer (withHandlers - firebase)
  closeAccountMenu: PropTypes.func.isRequired, // from enhancer (withHandlers - firebase)
  handleMenu: PropTypes.func.isRequired, // from enhancer (withHandlers - firebase)
  anchorEl: PropTypes.object, // from enhancer (withStateHandlers - handleMenu),
  lastPayment: PropTypes.object
}

export default Navbar
