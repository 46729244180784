import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { withI18n } from 'react-i18next'
import { withStyles } from '@material-ui/core'
import ModalVideo from 'react-modal-video'
import 'react-modal-video/css/modal-video.min.css'
import Grid from '@material-ui/core/Grid/Grid'
import Typography from '@material-ui/core/Typography/Typography'
import ButtonBase from '@material-ui/core/ButtonBase/ButtonBase'
import grey from '@material-ui/core/colors/grey'

const styles = theme => ({
  item: {
    cursor: 'pointer'
  },
  texts: {
    padding: `0 ${theme.spacing.unit}px`,
    color: '#fff'
  },
  content: {
    display: 'block',
    textAlign: 'left'
  },
  img: {
    border: `1px solid ${grey[800]}`
  }
})

const VideoList = ({ classes, items, t }) => {
  const [selectedVideo, setSelectedVideo] = useState(null)
  return (
    <Grid container spacing={2}>
      {items.map(video => (
        <Grid
          item
          key={video.id}
          className={classes.item}
          onClick={() => setSelectedVideo(video.id)}>
          <ButtonBase className={classes.content}>
            <img
              className={classes.img}
              src={`https://img.youtube.com/vi/${video.id}/mqdefault.jpg`}
              alt=""
            />
            <div className={classes.texts}>
              <Typography color={'primary'}>{video.title}</Typography>
              <Typography>{video.subtitle}</Typography>
            </div>
          </ButtonBase>
        </Grid>
      ))}
      <ModalVideo
        youtube={{ autoplay: 1 }}
        channel="youtube"
        isOpen={!!selectedVideo}
        videoId={selectedVideo}
        onClose={() => setSelectedVideo(null)}
      />
    </Grid>
  )
}

VideoList.propTypes = {
  t: PropTypes.func.isRequired,
  classes: PropTypes.func.isRequired,
  items: PropTypes.array.isRequired
}

export default withI18n()(withStyles(styles)(VideoList))
