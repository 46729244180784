import React, { Component, Fragment } from 'react'
import PropTypes from 'prop-types'
import AutosizeInput from 'react-input-autosize'
import { withStyles } from '@material-ui/core/styles/index'
import TextareaAutosize from 'react-textarea-autosize'
import { PALETTE_ITEMS_BUBBLE } from 'defaultValues'
import { withI18n } from 'react-i18next'
import arrowUpIcon from './iconArrowUp.svg'
import arrowDownIcon from './iconArrowDown.svg'
import arrowMoveIcon from './iconMove_callOut.svg'
import { getNumberOfLine, maxLines } from '../../utils/utils'

const POINTER_SIZE = 20
const ICON_POINTER_SIZE = 20
const CALLOUT_FONT_SIZE = 36
const CALLOUT_HORIZONTAL_PADDING = 30
const CALLOUT_VERTICAL_PADDING = 15
const ZOOM_HORIZONTAL_PADDING = 30
const ZOOM_VERTICAL_PADDING = 15
const ZOOM_TEXT_OFFSET = 200
const ZOOM_CIRCLE_RADIUS = 250
const ZOOM_CIRCLE_BORDER = 12
const ZOOM_SCALE = 2
const ZOOM_FONT_SIZE = 36
const ZOOM_LINE_HEIGHT = 40
const ZOOM_TEXT_WIDTH = 340
const ZOOM_TEXT_MAX_LENGTH = 4
const USAGE_ZOOM = 1.3
const INFO_TEXT_OFFSET = 250

const styles = theme => ({
  scale: {
    position: 'absolute',
    border: '3px dashed rgba(46, 226, 185, 1)',
    boxSizing: 'border-box'
  },
  pointer: {
    position: 'absolute',
    overflow: 'visible',
    width: 1,
    height: 1,
    zIndex: 2
  },
  text: {
    position: 'absolute',
    borderRadius: '500px',
    border: 'none'
  },
  moveBg: {
    cursor: 'pointer',
    position: 'fixed',
    top: 0,
    left: 0,
    width: '100%',
    height: '100%',
    zIndex: 1
  },
  imgCircle: {
    position: 'absolute',
    borderRadius: '50%',
    boxShadow:
      '0px 2px 4px -1px rgba(0, 0, 0, 0.2), 0px 4px 5px 0px rgba(0, 0, 0, 0.14), 0px 1px 10px 0px rgba(0, 0, 0, 0.12)'
  },
  zoomTextArea: {
    border: 'none',
    resize: 'none',
    background: 'none',
    transform: 'translateY(-50%)',
    position: 'absolute',
    '&:focus': {
      border: 'none',
      outline: 'none'
    },
    overflow: 'hidden',
    fontFamily: 'Open Sans,sans-serif'
  },
  zoomTextBg: {
    position: 'absolute',
    transform: 'translateY(-50%)'
  },
  zoomTextBgChild: {
    backgroundColor: 'yellow',
    display: 'inline-block',
    whiteSpace: 'pre-wrap',
    wordWrap: 'break-word',
    fontFamily: 'Open Sans,sans-serif',
    boxShadow:
      '0px 2px 4px -1px rgba(0, 0, 0, 0.2), 0px 4px 5px 0px rgba(0, 0, 0, 0.14), 0px 1px 10px 0px rgba(0, 0, 0, 0.12)'
  }
})

class BubbleEditor extends Component {
  static propTypes = {
    width: PropTypes.number.isRequired,
    height: PropTypes.number.isRequired,
    scale: PropTypes.number.isRequired,
    offset: PropTypes.object.isRequired,
    classes: PropTypes.object.isRequired,
    values: PropTypes.number.isRequired,
    onChange: PropTypes.func.isRequired,
    imageSrc: PropTypes.string.isRequired,
    imageSize: PropTypes.bool.isRequired,
    design: PropTypes.object.isRequired,
    colors: PropTypes.object.isRequired,
    onlyGroupColors: PropTypes.bool.isRequired
  }

  static getDerivedStateFromProps(props, state) {
    if (props.values !== state.values) {
      return {
        values: props.values
      }
    }
    return null
  }

  state = {
    move: null,
    values: this.props.values
  }

  timeout = null

  handleMouseDownPointer = (e, type, subType) => {
    e.stopPropagation()
    this.setState({
      move: {
        type,
        subType,
        startPos: this.getMousePos(e),
        actualPos: this.getMousePos(e)
      }
    })
  }

  handleMouseUpPointer = e => {
    e.stopPropagation()
    this.stopMove()
  }

  onChange(values) {
    this.props.onChange(values)
  }

  handleMouseMove = e => {
    if (this.state.move) {
      e.stopPropagation()
      const pos = this.getMousePos(e)
      this.setState({
        move: {
          ...this.state.move,
          actualPos: pos
        }
      })
    }
  }

  handleMouseMoveBg = e => {
    if (this.state.move) {
      const bound = this.refs.wrap.getBoundingClientRect()
      e.stopPropagation()
      const pos = this.getMousePos(e, this.refs.bg)
      pos.x -= bound.x
      pos.y -= bound.y
      this.setState({
        move: {
          ...this.state.move,
          actualPos: pos
        }
      })
    }
  }

  stopMove = () => {
    const { move } = this.state
    if (move) {
      const pos = this.getPos(move.type, move.subType)
      this.onChange({
        ...this.props.values,
        [move.type]: {
          ...this.props.values[move.type],
          [move.subType]: pos
        }
      })
      this.setState({
        move: null
      })
    }
  }

  getMousePos(e, parent = this.refs.wrap) {
    const rect = parent.getBoundingClientRect()
    const offset = {
      y: rect.top + document.body.scrollTop,
      x: rect.left + document.body.scrollLeft
    }

    const { clientX, clientY } = e.changedTouches ? e.changedTouches[0] : e
    return {
      x: clientX - offset.x,
      y: clientY - offset.y
    }
  }

  getPos(type, subType) {
    const item = this.props.values[type][subType]
    if (
      this.state.move &&
      this.state.move.type === type &&
      this.state.move.subType === subType
    ) {
      const { startPos, actualPos } = this.state.move

      const top = (this.props.height - this.props.height / USAGE_ZOOM) / 2
      const left = (this.props.width - this.props.width / USAGE_ZOOM) / 2

      const x = this.props.values[type][subType].x - (startPos.x - actualPos.x)
      const y = this.props.values[type][subType].y - (startPos.y - actualPos.y)
      return {
        x: this.cutValue(left, this.props.width - left, x),
        y: this.cutValue(top, this.props.height - top, y)
      }
    } else {
      return {
        x: item.x,
        y: item.y
      }
    }
  }

  getColors = () => {

    let selectedBubble

    if (this.props.design.type === 'group' || this.props.onlyGroupColors) {
      selectedBubble = this.props.colors.infoBubbleColors
    } else if (this.props.design.type === 'custom') {
      selectedBubble = this.props.design.custom
    } else {
      selectedBubble = PALETTE_ITEMS_BUBBLE[this.props.design.activeId].colors
    }

    return selectedBubble
  }

  renderCallOutSvg(type) {
    const { values } = this.state

    if (type === 'zoom' && values.bubbleType !== 'zoom') {
      return
    }

    if (
      !(
        values.bubbleType === 'call-out-2' ||
        (values.bubbleType === 'call-out-1' && type === 'callOut1')
      ) &&
      !(values.bubbleType === 'zoom' && type === 'zoom')
    ) {
      return null
    }

    const scaleStyle = {
      width: this.props.width / USAGE_ZOOM,
      height: this.props.height / USAGE_ZOOM
    }


    const pointerSize = POINTER_SIZE * this.props.scale
    const pointerTopOffset =
      (CALLOUT_FONT_SIZE / 2 + CALLOUT_VERTICAL_PADDING) * this.props.scale +
      ICON_POINTER_SIZE / 2
    const bubblePos = this.getPos(type, 'bubble')
    const pointerPos = this.getPos(type, 'pointer')
    const colors = this.getColors()
    return (
      <React.Fragment>
        <image
          onMouseDown={e => this.handleMouseDownPointer(e, type, 'bubble')}
          onMouseUp={this.handleMouseUpPointer}
          onDragStart={e => e.preventDefault()}
          x={bubblePos.x - ICON_POINTER_SIZE / 2}
          y={
            bubblePos.y -
            ICON_POINTER_SIZE / 2 -
            (type !== 'zoom' ? pointerTopOffset : 0)
          }
          width={ICON_POINTER_SIZE}
          height={ICON_POINTER_SIZE}
          xlinkHref={arrowMoveIcon}
          style={{
            cursor: 'pointer'
          }}
        />
        <image
          onDragStart={e => e.preventDefault()}
          onMouseDown={e => this.handleMouseDownPointer(e, type, 'pointer')}
          onMouseUp={this.handleMouseUpPointer}
          x={pointerPos.x - ICON_POINTER_SIZE / 2}
          y={pointerPos.y - ICON_POINTER_SIZE / 2}
          width={ICON_POINTER_SIZE}
          height={ICON_POINTER_SIZE}
          xlinkHref={arrowMoveIcon}
          style={{
            cursor: 'pointer'
          }}
        />
        <line
          style={{ pointerEvents: 'none' }}
          x1={bubblePos.x}
          y1={bubblePos.y}
          x2={pointerPos.x}
          y2={pointerPos.y}
          strokeWidth="2"
          stroke={colors.color2}
        />
        <circle
          style={{ pointerEvents: 'none' }}
          cx={pointerPos.x}
          cy={pointerPos.y}
          r={pointerSize / 2}
          fill={colors.color2}
        />
      </React.Fragment>
    )
  }

  renderInfoText() {
    const { scale, classes } = this.props
    const {
      values: { infoText, bubbleType }
    } = this.state
    const colors = this.getColors()
    const fontSize = CALLOUT_FONT_SIZE * scale
    const horizontalPadding = CALLOUT_HORIZONTAL_PADDING * scale
    const verticalPadding = CALLOUT_VERTICAL_PADDING * scale
    const offset = INFO_TEXT_OFFSET * scale
    let top = fontSize + verticalPadding / 2 + offset
    if (infoText.position === 'middle') {
      top = this.props.height / 2
    } else if (infoText.position === 'footer') {
      top = this.props.height - (fontSize + verticalPadding / 2 + offset)
    }

    if (bubbleType !== 'info-text') {
      return null
    }
    return (
      <div
        style={{
          position: 'absolute',
          fontSize,
          lineHeight: `${fontSize}px`,
          top: top,
          left: 0,
          width: '100%',
          height: 1,
          textAlign: 'center',
          zIndex: 2
        }}>
        {infoText.position !== 'top' && (
          <img
            src={arrowUpIcon}
            style={{
              position: 'absolute',
              cursor: 'pointer',
              bottom: fontSize / 2 + verticalPadding + 3,
              transform: 'translate(-50%, 0)'
            }}
            onClick={e =>
              this.onChange({
                ...this.props.values,
                infoText: {
                  ...infoText,
                  position: infoText.position === 'middle' ? 'top' : 'middle'
                }
              })
            }
          />
        )}
        {infoText.position !== 'footer' && (
          <img
            src={arrowDownIcon}
            style={{
              position: 'absolute',
              cursor: 'pointer',
              top: fontSize / 2 + verticalPadding + 5,
              transform: 'translate(-50%, 0)'
            }}
            onClick={e =>
              this.onChange({
                ...this.props.values,
                infoText: {
                  ...infoText,
                  position: infoText.position === 'middle' ? 'footer' : 'middle'
                }
              })
            }
          />
        )}
        <AutosizeInput
          value={infoText.text}
          onChange={e => {
            if (e.target.value.length > 50) {
              return
            }
            this.onChange({
              ...this.props.values,
              infoText: {
                ...infoText,
                text: e.target.value
              }
            })
          }}
          onKeyDown={e => {
            if (e.keyCode === 13) {
              e.target.blur()
            }
          }}
          placeholder="Enter text"
          inputClassName={classes.text}
          type="text"
          inputStyle={{
            margin: '0 auto',
            padding: `${verticalPadding}px ${horizontalPadding}px`,
            fontSize,
            lineHeight: `${fontSize}px`,
            top: -verticalPadding - fontSize / 2,
            overflow: 'visible',
            transform: 'translate(-50%, 0)',
            color: colors.color0,
            backgroundColor: colors.color1
          }}
        />
      </div>
    )
  }

  renderCallOutType(type) {
    const { scale, classes } = this.props
    const colors = this.getColors()
    const { values } = this.state
    const callout = this.props.values[type]

    if (
      !(
        values.bubbleType === 'call-out-2' ||
        (values.bubbleType === 'call-out-1' && type === 'callOut1')
      )
    ) {
      return null
    }

    const bubblePos = this.getPos(type, 'bubble')
    const pointerPos = this.getPos(type, 'pointer')
    const isLeftAlign = bubblePos.x > pointerPos.x
    const fontSize = CALLOUT_FONT_SIZE * scale
    const horizontalPadding = CALLOUT_HORIZONTAL_PADDING * scale
    const verticalPadding = CALLOUT_VERTICAL_PADDING * scale
    return (
      <div
        style={{
          position: 'absolute',
          fontSize,
          lineHeight: `${fontSize}px`,
          top: bubblePos.y,
          left: bubblePos.x,
          width: 1,
          height: 1,
          zIndex: 2
        }}>
        <AutosizeInput
          value={callout.text}
          onChange={e =>
            this.onChange({
              ...this.props.values,
              [type]: {
                ...this.props.values[type],
                text: e.target.value
              }
            })
          }
          onKeyDown={e => {
            if (e.keyCode === 13) {
              e.target.blur()
            }
          }}
          placeholder="Enter text"
          inputClassName={classes.text}
          type="text"
          inputStyle={{
            padding: `${verticalPadding}px ${horizontalPadding}px`,
            fontSize,
            lineHeight: `${fontSize}px`,
            top: -verticalPadding - fontSize / 2,
            textAlign: isLeftAlign ? 'left' : 'right',
            [isLeftAlign ? 'left' : 'right']: -horizontalPadding,
            overflow: 'visible',
            color: colors.color0,
            backgroundColor: colors.color1
          }}
        />
      </div>
    )
  }

  renderZoomImage() {
    const { scale, classes, t } = this.props
    const { values } = this.state
    const { zoom } = this.props.values
    const colors = this.getColors()

    if (values.bubbleType !== 'zoom') {
      return null
    }

    const bubblePos = this.getPos('zoom', 'bubble')
    const pointerPos = this.getPos('zoom', 'pointer')
    const isLeftAlign = bubblePos.x > pointerPos.x
    const fontSize = ZOOM_FONT_SIZE * scale
    const lineHeight = ZOOM_LINE_HEIGHT * scale
    const horizontalPadding = ZOOM_HORIZONTAL_PADDING * scale
    const verticalPadding = ZOOM_VERTICAL_PADDING * scale
    const circleStyle = {
      pointerEvents: 'none',
      width: ZOOM_CIRCLE_RADIUS * scale * 2,
      height: ZOOM_CIRCLE_RADIUS * scale * 2,
      boxSizing: 'border-box',
      border: `${ZOOM_CIRCLE_BORDER * scale}px solid ${colors.color2}`,
      marginTop: -ZOOM_CIRCLE_RADIUS * scale,
      marginLeft: -ZOOM_CIRCLE_RADIUS * scale,
      backgroundImage: `url('${this.props.imageSrc}')`,
      backgroundSize: `${this.props.imageSize.width * ZOOM_SCALE}px ${this.props
        .imageSize.height * ZOOM_SCALE}px`,
      backgroundPositionX:
        -pointerPos.x * ZOOM_SCALE + ZOOM_CIRCLE_RADIUS * scale,
      backgroundPositionY:
        (-pointerPos.y - this.props.offset.top) * ZOOM_SCALE +
        ZOOM_CIRCLE_RADIUS * scale
    }
    return (
      <div
        style={{
          position: 'absolute',
          borderRadius: 1,
          top: bubblePos.y,
          left: bubblePos.x,
          width: 1,
          height: 1,
          zIndex: 2
        }}>
        <img
          src={arrowMoveIcon}
          style={{
            position: 'absolute',
            top: -ICON_POINTER_SIZE / 2,
            left: -ICON_POINTER_SIZE / 2,
            zIndex: 1,
            pointerEvents: 'none',
            width: ICON_POINTER_SIZE,
            height: ICON_POINTER_SIZE
          }}
        />
        <div className={classes.imgCircle} style={circleStyle} alt="" />
        <div
          className={classes.zoomTextBg}
          style={{
            lineHeight: `${lineHeight}px`,
            textAlign: isLeftAlign ? 'left' : 'right',
            [isLeftAlign ? 'left' : 'right']: ZOOM_TEXT_OFFSET * scale,
            fontSize: fontSize,
            width: ZOOM_TEXT_WIDTH * scale * 2
          }}>
          <div
            className={classes.zoomTextBgChild}
            style={{
              padding: `${verticalPadding}px ${horizontalPadding}px`,
              color: zoom.text ? 'transparent' : 'grey',
              maxWidth: ZOOM_TEXT_WIDTH * scale,
              backgroundColor: colors.color1
            }}>
            {zoom.text || t('enter the text')}
            {zoom.text[zoom.text.length - 1] === '\n' ? '.' : ''}
          </div>
        </div>
        <TextareaAutosize
          maxRows={ZOOM_TEXT_MAX_LENGTH}
          className={classes.zoomTextArea}
          rows={1}
          style={{
            color: colors.color0,
            padding: `${verticalPadding}px ${horizontalPadding}px`,
            fontSize: fontSize,
            lineHeight: `${lineHeight}px`,
            width: ZOOM_TEXT_WIDTH * scale,
            textAlign: isLeftAlign ? 'left' : 'right',
            [isLeftAlign ? 'left' : 'right']: ZOOM_TEXT_OFFSET * scale
          }}
          onPaste={e => {
            let paste = (e.clipboardData || window.clipboardData).getData(
              'text'
            )
            const { value } = e.target
            let nextValue =
              value.substring(0, e.target.selectionStart) +
              paste +
              value.substring(e.target.selectionStart, value.length)
            nextValue = maxLines(e.target, nextValue, ZOOM_TEXT_MAX_LENGTH)
            this.onChange({
              ...this.props.values,
              zoom: {
                ...this.props.values.zoom,
                text: nextValue
              }
            })
          }}
          value={zoom.text || ''}
          onChange={e => {
            const { value } = e.target
            if (getNumberOfLine(e.target, value) <= ZOOM_TEXT_MAX_LENGTH) {
              this.onChange({
                ...this.props.values,
                zoom: {
                  ...this.props.values.zoom,
                  text: e.target.value
                }
              })
            }
          }}
        />
      </div>
    )
  }

  cutValue = (min, max, value) => Math.min(Math.max(min, value), max)

  render() {
    const { classes, scale } = this.props
    const scaleStyle = {
      width: this.props.width / USAGE_ZOOM,
      height: this.props.height / USAGE_ZOOM,
      top: (this.props.height - this.props.height / USAGE_ZOOM) / 2,
      left: (this.props.width - this.props.width / USAGE_ZOOM) / 2
    }

    const _scaleStyle = {
      width: this.props.width / USAGE_ZOOM,
      height: this.props.height / USAGE_ZOOM
    }

    return (
      <React.Fragment>
        {this.state.move && (
          <div
            ref="bg"
            onMouseUp={() => this.stopMove()}
            onMouseMove={this.handleMouseMoveBg}
            className={classes.moveBg}
          />
        )}
        <div
          style={{
            zIndex: this.state.move ? 3 : undefined,
            position: 'relative'
          }}
          onMouseMove={this.handleMouseMove}
          onMouseUp={() => this.stopMove()}
          ref="wrap">
          <div style={scaleStyle} className={classes.scale} />
          {['call-out-1', 'call-out-2', 'zoom'].indexOf(this.state.values.bubbleType) !== -1 && (
            <svg className={this.props.classes.pointer} style={_scaleStyle}>
              {this.renderCallOutSvg('callOut1')}
              {this.renderCallOutSvg('callOut2')}
              {this.renderCallOutSvg('zoom')}
            </svg>
          )}
          {this.renderCallOutType('callOut1')}
          {this.renderCallOutType('callOut2')}
          {this.renderZoomImage()}
          {this.renderInfoText()}
        </div>
      </React.Fragment>
    )
  }
}

export default withI18n()(withStyles(styles)(BubbleEditor))
