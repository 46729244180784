import React, { useEffect, useState } from 'react'
import List from '@material-ui/core/List'
import ListItem from '@material-ui/core/ListItem'
import ListItemText from '@material-ui/core/ListItemText'
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction'
import IconButton from '@material-ui/core/IconButton'
import UpIcon from '@material-ui/icons/KeyboardArrowUp'
import DownIcon from '@material-ui/icons/KeyboardArrowDown'
import { moveDown, moveUp } from 'utils/utils'
import Label from '../../components/Label'
import { useDebounce } from 'react-use'
import TextField from '@material-ui/core/TextField'

const SettingsNewsletter = ({
  properties,
  changeNewsletterItems,
  changeName,
  newsletterItems,
  classes,
  name,
  t
}) => {
  const [items, setItems] = useState(newsletterItems)
  const [text, setText] = useState(name)

  console.log(name)

  const handleClickUp = index => {
    setItems(moveUp(items, index))
  }
  const handleClickDown = index => {
    setItems(moveDown(items, index))
  }

  useDebounce(
    () => {
      if (items !== newsletterItems) {
        changeNewsletterItems(items)
      }
    },
    2000,
    [items]
  )

  useDebounce(
    () => {
      if (text !== name) {
        changeName(text)
      }
    },
    2000,
    [text]
  )

  useEffect(() => {
    return () => {
      if (items !== newsletterItems) {
        changeNewsletterItems(items)
      }
      if (text !== name) {
        changeName(text)
      }
    }
  }, [])

  return (
    <div>
      <TextField
        value={text}
        className={classes.name}
        onChange={e => setText(e.target.value)}
        label={t('Name')}
        margin="normal"
      />
      <Label primary={t('Order of items')} />
      <List>
        {properties
          .sort((a, b) => {
            const aIndex = items.findIndex(i => i.projectKey === a.projectKey)
            const bIndex = items.findIndex(i => i.projectKey === b.projectKey)
            return bIndex - aIndex
          })
          .map((item, index) => (
            <ListItem
              key={item.propertyKey}
              className={classes.propertyItem}
              classes={{ container: classes.propertyItemContainer }}>
              <ListItemText primary={item.name} />
              <ListItemSecondaryAction>
                <IconButton
                  onClick={() => handleClickUp(index)}
                  disabled={index === 0}>
                  <UpIcon />
                </IconButton>
                <IconButton
                  onClick={() => handleClickDown(index)}
                  disabled={index === properties.length - 1}>
                  <DownIcon />
                </IconButton>
              </ListItemSecondaryAction>
            </ListItem>
          ))}
      </List>
    </div>
  )
}

SettingsNewsletter.propTypes = {}

export default SettingsNewsletter
