import React from 'react'
import PropTypes from 'prop-types'
import Menu from '@material-ui/core/Menu'
import MenuItem from '@material-ui/core/MenuItem'
import IconButton from '@material-ui/core/IconButton'
import AccountCircle from '@material-ui/icons/AccountCircle'
import LanguageIcon from '@material-ui/icons/Language'
import SettingsIcon from '@material-ui/icons/Settings'
import LogoutIcon from '@material-ui/icons/ExitToApp'
import AdminIcon from '@material-ui/icons/SupervisedUserCircle'
import PaymentIcon from '@material-ui/icons/Payment'
import { withStyles } from '@material-ui/core/styles'
import { withI18n } from 'react-i18next'
import moment from 'moment'

const styles = theme => ({
  buttonRoot: {
    color: 'white',
    position: 'absolute',
    top: 0,
    right: 24,
    zIndex: 1202
  },
  icon: {
    marginRight: theme.spacing.unit
  },
  credits: {
    margin: `0 4px`
  },
  menu: {
    marginTop: 33
  }
})

const AccountMenu = ({
  avatarUrl,
  displayName,
  goToAccount,
  goToLanguage,
  goToSubscription,
  onLogoutClick,
  closeAccountMenu,
  goToClient,
  goToAdmin,
  anchorEl,
  handleMenu,
  classes,
  showSubscription,
  lastPayment,
  t
}) => (
  <React.Fragment>
    <IconButton
      aria-owns={anchorEl ? 'menu-appbar' : null}
      aria-haspopup="true"
      onClick={handleMenu}
      classes={{ root: classes.buttonRoot }}>
      <AccountCircle />
    </IconButton>
    <Menu
      id="menu-appbar"
      className={classes.menu}
      anchorEl={anchorEl}
      anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
      transformOrigin={{ vertical: 'top', horizontal: 'right' }}
      open={Boolean(anchorEl)}
      onClose={closeAccountMenu}>
      {displayName && <MenuItem disabled>{displayName}</MenuItem>}
      {lastPayment && (
        <MenuItem disabled>
          {t('Tests')}
          <b className={classes.credits}>{moment(lastPayment.time).isAfter(moment()) ? lastPayment.tests : 0}</b>
          {t('Videos')}
          <b className={classes.credits} >{moment(lastPayment.time).isAfter(moment()) ? lastPayment.videos : 0}</b>
        </MenuItem>
      )}
      <MenuItem onClick={goToLanguage}>
        <LanguageIcon className={classes.icon} />
        {t('language')}
      </MenuItem>
      {showSubscription && (
        <MenuItem onClick={goToAccount}>
          <SettingsIcon className={classes.icon} />
          {t('account')}
        </MenuItem>
      )}
      <MenuItem onClick={onLogoutClick}>
        <LogoutIcon className={classes.icon} />
        {t('sign out')}
      </MenuItem>
    </Menu>
  </React.Fragment>
)

AccountMenu.propTypes = {
  classes: PropTypes.object.isRequired, // from enhancer (withStyles)
  goToAccount: PropTypes.func.isRequired,
  goToLanguage: PropTypes.func.isRequired,
  onLogoutClick: PropTypes.func.isRequired,
  closeAccountMenu: PropTypes.func.isRequired,
  handleMenu: PropTypes.func.isRequired,
  showSubscription: PropTypes.bool,
  displayName: PropTypes.string,
  avatarUrl: PropTypes.string,
  anchorEl: PropTypes.object,
  t: PropTypes.func.isRequired,
  lastPayment: PropTypes.object
}

export default withI18n()(withStyles(styles)(AccountMenu))
