export default theme => ({
  '@global': {
    html: {
      backgroundColor: '#212121!important'
    },
    body: {
      backgroundColor: '#212121!important'
    }
  },
  root: {
    margin: '20px 20px 0',
    color: 'white'
  }
})
