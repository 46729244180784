import React from 'react'
import PropTypes from 'prop-types'

const Circle = ({color}) =>
  <svg height='14' width='14'>
    <circle cx='7' cy='7' r='6' stroke={color} strokeWidth='2' fill='transparent'/>
  </svg>

Circle.propTypes = {
  color: PropTypes.string.isRequired
}

export default Circle