import { withStyles } from '@material-ui/core/styles'
import styles from './SubscriptionPage.styles'
import { compose } from 'recompose'
import { withRouter } from 'react-router-dom'
import { withI18n } from 'react-i18next'

export default compose(
  withRouter,
  withStyles(styles),
  withI18n()
)
