import { compose } from 'redux'
import { withStyles } from '@material-ui/core/styles'
import styles from './TextsPage.styles'
import { withHandlers } from 'recompose'
import withNotifications from 'modules/notification/components/withNotifications'
import { connect } from 'react-redux'
import firebase from 'firebase/app'
import { withI18n } from 'react-i18next'

export default compose(
  connect((_, { project }) => ({
    texts: project.texts
  })),
  withNotifications,
  withHandlers({
    updateTexts: props => texts => {
      const { showError, groupId, projectId, setEditTime } = props
      return firebase
        .set(`groups/${groupId}/properties/${projectId}/texts`, texts)
        .then(() => setEditTime())
        .catch(err => {
          console.error('Error:', err) // eslint-disable-line no-console
          showError(err.message || 'Could not save texts')
          return Promise.reject(err)
        })
    }
  }),
  withStyles(styles),
  withI18n()
)
