import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd'
import cyan from '@material-ui/core/colors/cyan'
import Tooltip from '@material-ui/core/Tooltip'
import { withStyles } from '@material-ui/core'
import { withI18n } from 'react-i18next'
import AvatarIcon from '../../icons/avatar_1.svg'
import AvatarSelectedIcon from '../../icons/avatar_2.svg'
import DescriptionIcon from '../../icons/description_0.svg'
import DescriptionActiveIcon from '../../icons/description_1.svg'
import DescriptionSelectedIcon from '../../icons/description_2.svg'
import CancelIcon from '../../icons/icons8-cancel.svg'
import MapIcon from '../../icons/map_1.svg'
import MapSelectedIcon from '../../icons/map_2.svg'
import PictureIcon from '../../icons/picture_0.svg'
import TextsIcon from '../../icons/texts_1.svg'
import TextsSelectedIcon from '../../icons/texts_2.svg'
import ContactIcon from '../../icons/Contact_1-1.svg'
import ContactSelectedIcon from '../../icons/Contact_2.svg'
import { DESCRIPTION_PATH, IMAGE_PATH } from 'constants/paths'

// a little function to help us with reordering the result
const reorder = (list, startIndex, endIndex) => {
  const result = Array.from(list)
  const [removed] = result.splice(startIndex, 1)
  result.splice(endIndex, 0, removed)

  return result
}

const getItemStyle = (isDragging, draggableStyle, image) => ({
  // some basic styles to make the items look a bit nicer
  userSelect: 'none',
  //width: IMAGE_WIDTH,
  //height: IMAGE_HEIGHT,
  cursor: 'pointer',
  //margin: `0 ${grid}px 0 0`,
  //boxShadow: '0px 2px 4px -1px rgba(0, 0, 0, 0.2), 0px 4px 5px 0px rgba(0, 0, 0, 0.14), 0px 1px 10px 0px rgba(0, 0, 0, 0.12)',

  // change background colour if dragging
  // background: isDragging ? 'lightgreen' : 'grey',
  //background: '#333333',
  // styles we need to apply on draggables
  ...draggableStyle
})

const getListStyle = (isDraggingOver, numberOfImages) => ({
  background: isDraggingOver ? 'lightblue' : 'transparent',
  display: 'flex',
  padding: `3px 0px`,
  overflow: 'hidden',
  //marginLeft: -grid / 2,
  //marginRight: -grid / 2,
  width: 74 * numberOfImages
})

const styles = () => ({
  root: {
    position: 'relative',
    '&:hover img': {
      display: 'block!important'
    }
  },
  cancelIcon: {
    position: 'absolute',
    right: -6,
    top: -6,
    width: 30,
    height: 30,
    display: 'none'
  },
  img: {
    backgroundRepeat: 'no-repeat',
    backgroundPosition: 'center',
    backgroundSize: 'cover',
    borderRadius: '50%',
    borderColor: `${cyan[600]}!important`
  }
})

let Tab = ({
  onClick,
  iconPath,
  classes,
  removeable,
  onClickRemove,
  imagePath,
  isActive,
  isLast
}) => (
  <div
    onClick={onClick}
    style={{
      display: 'block',
      cursor: 'pointer',
      marginRight: 5,
      marginLeft: 5
    }}
    className={classes.root}>
    {iconPath && (
      <img
        src={iconPath}
        style={{ width: 64, height: 64, display: 'block' }}
        alt=""
      />
    )}
    {imagePath && (
      <div
        className={classes.img}
        style={{
          backgroundImage: `url(${imagePath})`,
          width: 64,
          height: 64,
          border: isActive && 'solid 2px'
        }}
        alt=""
      />
    )}
    {removeable && (
      <img
        src={CancelIcon}
        onClick={e => {
          e.stopPropagation()
          onClickRemove()
        }}
        className={classes.cancelIcon}
      />
    )}
  </div>
)

Tab = withStyles(styles)(Tab)

class CompositionHeader extends Component {
  static propTypes = {
    items: PropTypes.array.isRequired,
    values: PropTypes.object.isRequired,
    onChange: PropTypes.func.isRequired,
    onSelect: PropTypes.func.isRequired,
    selected: PropTypes.string.isRequired,
    enableDelete: PropTypes.bool.isRequired,
    showAgent: PropTypes.bool,
    showContact: PropTypes.bool,
    showMap: PropTypes.bool,
    onDelete: PropTypes.func.isRequired
  }

  onDragEnd = result => {
    // dropped outside the list
    if (!result.destination) {
      return
    }

    const items = reorder(
      this.props.items,
      result.source.index,
      result.destination.index
    )

    this.props.onChange(items)
  }

  handleClick = (e, item) => {
    this.props.onSelect(
      `${item.type === 'image' ? IMAGE_PATH : DESCRIPTION_PATH}/${item.id}`
    )
  }

  // Normally you would want to split things out into separate components.
  // But in this example everything is just done in one place for simplicity

  renderTab(name, iconPath, hoverIconPath) {
    return (
      <Tab
        iconPath={
          `${this.props.path}/${name}` === window.location.pathname
            ? hoverIconPath
            : iconPath
        }
        onClick={() => this.props.onSelect(name)}
      />
    )
  }

  render() {
    const { t } = this.props
    const activeItems = this.props.items.map(item => ({
      ...item,
      isActive:
        `${this.props.path}/${
          item.type === 'image' ? IMAGE_PATH : DESCRIPTION_PATH
        }/${item.id}` === window.location.pathname
    }))
    return (
      <div style={{ marginBottom: 20, display: 'flex' }}>
        {this.renderTab('texts', TextsIcon, TextsSelectedIcon)}
        <div
          style={{
            display: 'inline-block',
            verticalAlign: 'top',
            position: 'relative',
            marginTop: -3
          }}>
          {activeItems.length > 0 && (
            <React.Fragment>
              <div
                style={{
                  width: 10,
                  height: 10,
                  position: 'absolute',
                  border: '2px #a9352f solid',
                  borderWidth: '2px 0 0 2px',
                  top: 0,
                  left: 0,
                  pointerEvents: 'none'
                }}
              />
              <div
                style={{
                  width: 10,
                  height: 10,
                  position: 'absolute',
                  border: '2px #a9352f solid',
                  borderWidth: '2px 2px 0 0',
                  top: 0,
                  right: 0,
                  pointerEvents: 'none'
                }}
              />
              <div
                style={{
                  width: 10,
                  height: 10,
                  position: 'absolute',
                  border: '2px #a9352f solid',
                  borderWidth: '0px 0px 2px 2px',
                  bottom: 0,
                  left: 0,
                  pointerEvents: 'none'
                }}
              />
              <div
                style={{
                  width: 10,
                  height: 10,
                  position: 'absolute',
                  border: '2px #a9352f solid',
                  borderWidth: '0px 2px 2px 0',
                  bottom: 0,
                  right: 0,
                  pointerEvents: 'none'
                }}
              />
            </React.Fragment>
          )}
          <DragDropContext onDragEnd={this.onDragEnd}>
            <Droppable droppableId="droppable" direction="horizontal">
              {(provided, snapshot) => (
                <div
                  ref={provided.innerRef}
                  style={getListStyle(
                    snapshot.isDraggingOver,
                    activeItems.length
                  )}
                  {...provided.droppableProps}>
                  {activeItems.map((item, index) => (
                    <Draggable key={item.id} draggableId={index} index={index}>
                      {(provided, snapshot) => (
                        <div
                          onClick={e =>
                            !item.isActive && this.handleClick(e, item, index)
                          }
                          ref={provided.innerRef}
                          {...provided.draggableProps}
                          {...provided.dragHandleProps}
                          style={getItemStyle(
                            snapshot.isDragging,
                            provided.draggableProps.style,
                            item.img
                          )}>
                          {item.type === 'image' ? (
                            <Tab
                              key={item.editDate}
                              imagePath={item.src || PictureIcon}
                              isActive={item.isActive}
                              removeable
                              onClickRemove={() =>
                                this.props.onRemoveImage(item.id)
                              }
                              isLast={activeItems.length === index + 1}
                            />
                          ) : (
                            <Tab
                              onClickRemove={() =>
                                this.props.onRemoveTextBlock(item.id)
                              }
                              iconPath={
                                item.isActive
                                  ? DescriptionSelectedIcon
                                  : DescriptionActiveIcon
                              }
                              removeable
                              isLast={activeItems.length === index + 1}
                            />
                          )}
                        </div>
                      )}
                    </Draggable>
                  ))}
                  {provided.placeholder}
                </div>
              )}
            </Droppable>
          </DragDropContext>
        </div>
        {new Array(
          this.props.maxTextBlocks -
            this.props.items.filter(i => i.type === 'text').length
        )
          .fill(1)
          .map((i, index) => (
            <Tab
              key={index}
              iconPath={DescriptionIcon}
              onClick={() => this.props.onAddTextBlock()}
            />
          ))}
        {new Array(
          this.props.maxImages -
            this.props.items.filter(i => i.type === 'image').length
        )
          .fill(1)
          .map((i, index) => (
            <Tab
              key={index}
              iconPath={PictureIcon}
              onClick={() => this.props.onAddImageBlock()}
            />
          ))}
        {this.props.showAgent &&
          this.renderTab('agent', AvatarIcon, AvatarSelectedIcon)}
        {this.props.showMap && this.renderTab('map', MapIcon, MapSelectedIcon)}
        {this.props.showContact &&
          this.renderTab('contact', ContactIcon, ContactSelectedIcon)}
        {this.props.enableDelete && (
          <Tooltip title={t('delete property')}>
            <img
              onClick={this.props.onDelete}
              src={CancelIcon}
              style={{
                width: 34,
                marginLeft: 13,
                position: 'relative',
                top: 6,
                cursor: 'pointer'
              }}
            />
          </Tooltip>
        )}
      </div>
    )
  }
}

export default withI18n()(CompositionHeader)
