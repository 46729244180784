import React from 'react'
import PropTypes from 'prop-types'
import { Link } from 'react-router-dom'
import AppBar from '@material-ui/core/AppBar'
import Toolbar from '@material-ui/core/Toolbar'
import Typography from '@material-ui/core/Typography'
import { ADMIN_PATH } from 'constants/paths'
import LogoImg from './logo.svg'
import Button from '@material-ui/core/Button'

export const Navbar = ({ classes, title, showLogout, onLogout }) => (
  <AppBar position="fixed" className={classes.root}>
    <Toolbar className={classes.toolbar}>
      <Typography
        variant="h6"
        color="inherit"
        className={classes.title}
        component={Link}
        to={ADMIN_PATH}>
        <img className={classes.logo} src={LogoImg} alt="logo" />
        <span className={classes.navbarText}>{title}</span>
      </Typography>
      {showLogout && (
        <Button size="medium" className={classes.logout} onClick={onLogout}>
          Logout
        </Button>
      )}
    </Toolbar>
  </AppBar>
)

Navbar.propTypes = {
  classes: PropTypes.object.isRequired, // from enhancer (withStyles),
  title: PropTypes.string.isRequired
}

export default Navbar
