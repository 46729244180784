import { compose } from 'redux'
import { withStyles } from '@material-ui/core/styles'
import styles from './AgentListPage.styles'
import { withHandlers } from 'recompose'
import withNotifications from 'modules/notification/components/withNotifications'
import { connect } from 'react-redux'
import firebase from 'firebase/app'
import { withI18n } from 'react-i18next'
import { firebaseConnect } from 'react-redux-firebase'
import map from 'lodash/map'
import get from 'lodash/get'
import withLoader from 'modules/loader/components/withLoader'
import { addGroupFile } from 'utils/utils'

const addImageAgent = (groupId, agentKey, file) => {
  const path = `/agents/${agentKey}.png`
  return addGroupFile(groupId, path, file).then(() => {
    const storageRef = firebase.storage().ref()
    const ref = storageRef.child(`groups/${groupId}/agents/${agentKey}.png`)
    return ref.getDownloadURL().then(function(downloadURL) {
      return firebase.set(
        `groups/${groupId}/agents/${agentKey}/image`,
        downloadURL
      )
    })
  })
}

export default compose(
  firebaseConnect(({ groupId }) => {
    return [`/groups/${groupId}/agents/`, `/groups/${groupId}/createdBy`]
  }),
  connect(({ firebase: { data, profile } }, { groupId }) => ({
    groupCreatedBy: get(data, `groups.${groupId}.createdBy`),
    agentItems: map(get(data, `groups.${groupId}.agents`), (values, key) => ({
      key,
      values
    })).filter(({ values }) => values && !values.deleted)
  })),
  firebaseConnect(({ groupCreatedBy }) => {
    return [`users/${groupCreatedBy}/lastPayment`]
  }),
  connect(({ firebase: { data } }, { groupCreatedBy }) => {
    const lastPayment = get(data, `users.${groupCreatedBy}.lastPayment`)
    return {
      lastPayment
    }
  }),
  withNotifications,
  withLoader,
  withHandlers({
    updateAgentValues: props => (agentId, values) => {
      const { showError, groupId, projectId } = props
      return firebase
        .set(`groups/${groupId}/agents/${agentId}`, values)
        .catch(err => {
          console.error('Error:', err) // eslint-disable-line no-console
          showError(err.message || 'Could not set agent')
          return Promise.reject(err)
        })
    },
    setAgent: props => agentId => {
      const { showError, groupId, projectId, setEditTime } = props
      return firebase
        .set(`groups/${groupId}/properties/${projectId}/agentKey`, agentId)
        .then(() => firebase.updateProfile({ lastAgent: agentId }))
        .then(() => setEditTime())
        .catch(err => {
          console.error('Error:', err) // eslint-disable-line no-console
          showError(err.message || 'Could not set agent')
          return Promise.reject(err)
        })
    },
    deleteAgent: props => agentId => {
      const { showError, groupId, clearLoader, setLoader } = props
      setLoader()
      return firebase
        .functions()
        .httpsCallable('deleteAgent')({
          agentId,
          groupId
        })
        .catch(err => {
          console.error('Error:', err) // eslint-disable-line no-console
          showError(err.message || 'Could not delete contact')
          return Promise.reject(err)
        })
        .finally(() => {
          clearLoader()
        })
    },
    addAgent: props => (values, file) => {
      const { showError, groupId, setLoader, clearLoader, projectId } = props
      setLoader()
      return firebase
        .push(`groups/${groupId}/agents`, values)
        .then(snap => {
          const key = snap.key
          if (file) {
            return addImageAgent(groupId, key, file).then(() => {
              return key
            })
          } else {
            return key
          }
        })
        .then(key => {
          return firebase
            .set(`groups/${groupId}/properties/${projectId}/agentKey`, key)
            .then(() => firebase.updateProfile({ lastAgent: key }))
        })
        .catch(err => {
          console.error('Error:', err) // eslint-disable-line no-console
          showError(err.message || 'Could create agent')
          return Promise.reject(err)
        })
        .finally(clearLoader)
    },
    addImageAgent: props => (agentId, file) => {
      const { showError, groupId, setLoader, clearLoader } = props
      setLoader()
      return addImageAgent(groupId, agentId, file)
        .catch(err => {
          console.error('Error:', err) // eslint-disable-line no-console
          showError(err.message || 'Could set agent image')
          return Promise.reject(err)
        })
        .finally(clearLoader)
    },
    deleteImageAgent: props => (agentId, file) => {
      const { showError, groupId, setLoader, clearLoader } = props
      setLoader()
      return firebase
        .set(`groups/${groupId}/agents/${agentId}/image`, null)
        .then(() => {
          return firebase.functions().httpsCallable('deleteFile')({
            path: `/agents/${agentId}.png`,
            groupId
          })
        })
        .catch(err => {
          console.error('Error:', err) // eslint-disable-line no-console
          showError(err.message || 'Could delete agent image')
          return Promise.reject(err)
        })
        .finally(clearLoader)
    }
  }),
  withStyles(styles),
  withI18n()
)
