export default theme => ({
  item: {
    cursor: 'pointer',
    display: 'flex',
    verticalAlign: 'middle',
    alignItems: 'center',
    '&+*': {
      marginTop: 30
    }
  },
  label: {
    color: theme.palette.primary.main,
    lineHeight: '48px',
    marginRight: 20
  },
  activeId: {
    border: `2px solid ${theme.palette.primary.main}`
  },
  checkbox: {
    color: 'white',
    '&$checked': {
      color: 'white'
    }
  },
  checked: {},
  audio: {
    width: 400
  },
  formControl: {
    width: 270
  },
  divider: {
    height: 1,
    backgroundColor: 'rgba(255, 255, 255, 0.12)',
    margin: `${theme.spacing.unit * 3}px 0`
  }
})
