import { grey } from '@material-ui/core/colors'

export default theme => ({
  top: {
    marginBottom: theme.spacing.unit * 2
  },
  actionsButtons: {
    margin: `${theme.spacing.unit*2}px 0`,
    '& button': {
      marginRight: theme.spacing.unit
    }
  },
  toggleLabel: {
    display: 'inline-block'
  },
  billingType: {
    marginRight: theme.spacing.unit * 2,
    color: grey[500],
    display: 'inline-block'
  }
})
