import React from 'react'
import PropTypes from 'prop-types'
import Radio from '@material-ui/core/Radio'
import RadioGroup from '@material-ui/core/RadioGroup'
import FormControlLabel from '@material-ui/core/FormControlLabel'
import { PALETTE_ITEMS_BUBBLE, PALETTE_ITEMS_PLACE } from 'defaultValues'
import Paper from '@material-ui/core/Paper/Paper'
import ColorsPalettePicket from '../Design/ColorsPalettePicker'
import Grid from '@material-ui/core/Grid/Grid'
import cn from 'classnames'
import Label from 'components/Label'
import CalloutColorsExample from '../Design/CalloutColorsExample'
import RoomInfo from '../Design/RoomInfo'

const Look = ({
  classes,
  value,
  onChange,
  t,
  isAdmin,
  updateGroupColors,
  colors,
  onlyGroupColors
}) => {
  let selectedBubble

  if (value.bubble.type === 'group' || onlyGroupColors) {
    selectedBubble = colors.infoBubbleColors
  } else if (value.bubble.type === 'custom') {
    selectedBubble = value.bubble.custom
  } else {
    selectedBubble = PALETTE_ITEMS_BUBBLE[value.bubble.activeId].colors
  }

  let selectedPlace
  if (value.place.type === 'group' || onlyGroupColors) {
    selectedPlace = colors.imageDescriptionColors
  } else if (value.place.type === 'custom') {
    selectedPlace = value.place.custom
  } else {
    selectedPlace = PALETTE_ITEMS_PLACE[value.place.activeId].colors
  }

  return (
    <div className={classes.content}>
      <Label
        main
        className={classes.label}
        primary={t('colors of transitions')}
      />

      <ColorsPalettePicket
        numberOfColors={3}
        paletteItems={PALETTE_ITEMS_BUBBLE}
        values={value.transitions}
        onChange={transitions => onChange({ ...value, transitions })}
        canUpdateGroup={isAdmin}
        groupColors={colors.videoTransitionsColors}
        onChangeGroup={value =>
          updateGroupColors('videoTransitionsColors', value)
        }
        onlyGroupColors={onlyGroupColors}
      />

      <div className={classes.divider} />

      <Label main className={classes.label} primary={t('info bubble')} />
      <div className={classes.example1}>
        <CalloutColorsExample
          textBg={selectedBubble.color1}
          text={selectedBubble.color0}
          pointer={selectedBubble.color3}
          rope={selectedBubble.color2}
        />
      </div>
      <ColorsPalettePicket
        paletteItems={PALETTE_ITEMS_BUBBLE}
        values={value.bubble}
        onChange={bubble => onChange({ ...value, bubble })}
        canUpdateGroup={isAdmin}
        groupColors={colors.infoBubbleColors}
        onChangeGroup={value => updateGroupColors('infoBubbleColors', value)}
        onlyGroupColors={onlyGroupColors}
      />
      <Grid item>
        <Label
          className={classes.label}
          primary={t('info bubble pointer type')}
        />
        <Grid container spacing={2}>
          {['round', 'square'].map(i => (
            <Grid item>
              <div
                key={i}
                onClick={() =>
                  onChange({
                    ...value,
                    bubble: { ...value.bubble, pointer: i }
                  })
                }
                className={cn(
                  classes.pointerItem,
                  value.bubble.pointer === i && classes.pointerItemActive
                )}>
                <img
                  alt="pointer"
                  src={require(`./pointery_switch_${i}.svg`)}
                />
              </div>
            </Grid>
          ))}
        </Grid>
      </Grid>

      <div className={classes.divider} />

      <Label main className={classes.label} primary={t('room description')} />
      <RoomInfo
        nameColor={selectedPlace.color0}
        nameBg={selectedPlace.color1}
        sizeColor={selectedPlace.color2}
        sizeBg={selectedPlace.color3}
      />
      <ColorsPalettePicket
        paletteItems={PALETTE_ITEMS_PLACE}
        values={value.place}
        onChange={place => onChange({ ...value, place })}
        canUpdateGroup={isAdmin}
        groupColors={colors.imageDescriptionColors}
        onChangeGroup={value =>
          updateGroupColors('imageDescriptionColors', value)
        }
        onlyGroupColors={onlyGroupColors}
      />

      <div className={classes.divider} />

      <Label className={classes.label} primary={t('direction of block')} />
      <Grid container spacing={2}>
        <RadioGroup
          className={classes.placeAnimationWrap}
          value={value.place.animation}
          onChange={e =>
            onChange({
              ...value,
              place: { ...value.place, animation: e.target.value }
            })
          }>
          {[
            { name: 'bottom', label: t('from below') },
            { name: 'right', label: t('from right') }
          ].map(i => (
            <FormControlLabel
              key={i.name}
              value={i.name}
              control={<Radio />}
              label={i.label}
            />
          ))}
        </RadioGroup>
      </Grid>
    </div>
  )
}

Look.propTypes = {
  classes: PropTypes.object.isRequired,
  value: PropTypes.number.isRequired,
  onChange: PropTypes.func.isRequired,
  t: PropTypes.func.isRequired,
  isAdmin: PropTypes.bool.isRequired,
  onlyGroupColors: PropTypes.bool.isRequired,
  updateGroupColors: PropTypes.func.isRequired,
  colors: PropTypes.object.isRequired
}

export default Look
